import React, { MouseEvent } from "react";
import { observer } from "mobx-react";
import BaseColumn from "../BaseColumn";
import { UIText } from "../../../../client/lang";
import "./styles.less";
import QuotationServiceCard from "../QuotationServiceCard";
import { FreightService, QuotationService } from "../../lib/types/dataTypes";
import Accordion from "../../../../components/Accordion";
import { toJS } from "mobx";
import { groupArray, preventDefaultStopProp } from "../../../../utils/helpers";
import { IonButton, IonIcon, IonText } from "@ionic/react";
import { getIonStyleVal } from "../../../../config/styles/style-utils";
import { RfqDetailColumnProps } from "../../lib/types/propTypes";
import { ui } from "../../../../client/ui";
import { sortQuotationServicesByDate } from "../../lib/common";
import { filterOutline } from "ionicons/icons";
import MdIcon from "../../../../components/MdIcon";
import { mdiStar } from "@mdi/js";

export interface QuotesByTypesColumnProps extends RfqDetailColumnProps {
  quotationServices: QuotationService[];
  isInSolution: (id: QuotationService["id"]) => boolean;
  onStarClick?: (event: MouseEvent, id: QuotationService["id"]) => void;
}

interface QuotationServiceTypeGroup {
  typeName: string;
  quotationServices: QuotationService[];
  providerCount?: number;
}

@observer
class QuotesByTypesColumn extends React.Component<QuotesByTypesColumnProps> {
  getExpanded = (typeGroupName: string) => {
    const { isAccordionExpanded } = this.props;
    return isAccordionExpanded(typeGroupName);
  };

  sortTypeGroups = (a: QuotationServiceTypeGroup, b: QuotationServiceTypeGroup) => {
    const cheapestA: QuotationService = a.quotationServices[0]; // First one is always cheapest because pre-sorted.
    const cheapestB: QuotationService = b.quotationServices[0]; // First one is always cheapest because pre-sorted.
    return sortQuotationServicesByDate(cheapestA, cheapestB);
  };

  handleAccordionClick = (typeGroupName: string) => {
    const { onAccordionFold } = this.props;
    const isExpanded = this.getExpanded(typeGroupName);
    if (onAccordionFold) return onAccordionFold(typeGroupName, !isExpanded);
  };

  handleFilterSortClick = (event: MouseEvent) => {
    preventDefaultStopProp(event);
    return ui.comingSoon("Filter & Sort");
  };

  renderServiceTypeGroup = (tg: QuotationServiceTypeGroup) => {
    const handleAccordionClick = event => {
      preventDefaultStopProp(event);
      return this.handleAccordionClick(tg.typeName);
    };
    let { quotationServices } = tg;
    quotationServices = quotationServices.filter(qs =>
      qs.serviceType !== "Freight" ||
      !quotationServices.some(q => (q as FreightService).segments && (q as FreightService).segments.includes(qs.id))
    );
    return <Accordion
      className="ion-margin"
      key={tg.typeName || ""}
      color="light"
      backgroundColor={getIonStyleVal("--ion-color-light")}
      title={<span className="textPrimary textBold">
        {`${tg.typeName} • ${tg.providerCount} ${tg.providerCount > 1 ? UIText.providers : UIText.provider}`}
      </span>}
      expanded={this.getExpanded(tg.typeName)}
      isMobile={ui.isMobile}
      onClick={handleAccordionClick}
    >
      <div className="ion-padding">
        {toJS(quotationServices)
        .map(quote => {
          const handleStarClick = event => this.props.onStarClick(event, quote.id);
          const handleChatClick = event => this.props.onChatClick(event, quote.id);
          const handleCardClick = event => {
            preventDefaultStopProp(event);
            return this.props.onQuotationServiceCardClick(null, quote.id)
          };
          return <QuotationServiceCard
            key={quote.id}
            quote={quote}
            // Reverse header and sub-header text for this column.
            serviceType={quote.provider}
            provider={quote.displayTitle || quote.serviceType}
            noStar={this.props.readonly}
            // starColor={this.props.isInSolution(quote.id) ? "warning" : "medium"}
            starIcon={<MdIcon icon={mdiStar} color={this.props.isInSolution(quote.id) ? "warning" : "medium"} />}
            handleStarClick={handleStarClick}
            handleChatClick={handleChatClick}
            onClick={handleCardClick}
          />;
        })}
      </div>
    </Accordion>;
  };

  render() {
    const {
      quotationServices,
    } = this.props;

    const typeGroups: QuotationServiceTypeGroup[] = [];
    for (const quote of quotationServices) {
      const group = typeGroups.find(tg => tg.typeName === quote.serviceType);
      if (group) {
        group.quotationServices.push(quote);
      } else {
        typeGroups.push({
          typeName: quote.serviceType,
          quotationServices: [quote]
        });
      }
    }
    for (const group of typeGroups) {
      const quotationServices = toJS(group.quotationServices);
      group.quotationServices = quotationServices.sort((a, b) => a.price - b.price);
      group.providerCount = Object.keys(groupArray(quotationServices, "provider")).length;
    }

    const Header = <div className="flex ion-justify-content-between ion-align-items-center">
      <IonText className="font-m baseColumnTitle">{UIText.servicesByTypes}</IonText>
      <IonButton color="light" size="small" shape="round" onClick={this.handleFilterSortClick}>
        <IonIcon slot="icon-only" color="primary" icon={filterOutline}/>
      </IonButton>
    </div>;

    return <BaseColumn className="quotationsColumn" header={Header}>
      {typeGroups
      .sort(this.sortTypeGroups)
      .map(this.renderServiceTypeGroup)
      }
    </BaseColumn>
  }
}

export default QuotesByTypesColumn;