import { computed, observable } from "mobx";
import { Controller } from "../../lib/controller";
import { client } from "../../client/client";
import { User, UserLoginData, VerificationParams, VerificationResult } from "../../lib/types/dataTypes";
import { getQueryParameters, isEmpty } from "../../utils/helpers";
import { AxiosError } from "axios";
import { UIException } from "../../client/lang";
import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";
import { serverConfig } from "../../config/api/base";
import { VerificationController } from "../../lib/verification";
import { stateCtrl } from "../../client/state";

export class LoginController extends Controller {
  @observable loginData: UserLoginData<string>;

  @computed get isLoggedIn(): boolean { return client.isLoggedIn };

  constructor() {
    super();
    this.reset();
  }

  reset = () => this.loginData = {
    email: "",
    password: ""
  };

  onFieldChange = (field: keyof UserLoginData<any>, value: string): string => {
    if (this.loginData[field] === value) return;
    this.loginData[field] = value;
  };

  login = async (): Promise<UserLoginData<boolean>> => {
    const errors: UserLoginData<boolean> = {
      email: false,
      password: false
    };
    if (isEmpty(this.loginData)) return errors;
    if (!this.loginData.email) errors.email = true;
    if (!this.loginData.password) errors.password = true;
    if (!isEmpty(errors)) return errors;

    const user: User = await client.loginAndStoreUser({
      email: this.loginData.email,
      password: this.loginData.password
    })
    .catch(this.handleError);
    if (isEmpty(user)) return { email: true, password: true };
    return { email: false, password: false };
  };

  handleError = (err: AxiosError) => {
    if (err.constructor && err.constructor.name === "UIException") throw err;
    if (!err.response || (err.response.status >= 503 || !err.message)) {
      return Promise.reject(new UIException("SERVICE_UNAVAILABLE", err));
    }
    if (err.response && err.response.status === 400) {
      // Simply wrong cred.
      return Promise.resolve(null);
    }
    if (err.response && err.response.status === 403) {
      // Need verification
      return Promise.reject(new UIException("ACCOUNT_NOT_VERIFIED"));
    }
    return Promise.reject(err);
  };

  onResendVerification = async () => {
    const oauth = client.oauth;
    const data = {
      authToken: oauth["access_token"]
    };
    return api.async("POST", {
      endpoint: endpointConfig.resend_verification,
      headers: serverConfig.defaultHeaders,
      data
    });
  };

  execVerification = async (queryString: string): Promise<VerificationResult["user"]> => {
    const {
      hash,
      user,
      email,
      type
    }: VerificationParams = getQueryParameters(queryString) || {};
    if (!hash || (!user && !email)) {
      return Promise.reject(new UIException("VERIFICATION_EXPIRE_INVALID"));
    }
    await client.logout();
    return new VerificationController()
    .verify(type)
    .identify(user || email)
    .post(hash) as Promise<VerificationResult["user"]>;
  };

  setIdentifierField = (id: string) => this.loginData.email = id || "";

  logout = () => stateCtrl.logoutResetPathname();
}