import { observer, Observer } from "mobx-react";
import {
  AnySupportedFieldTypes,
  AutocompleteField,
  CheckboxField,
  InputField,
  LinkField,
  PickerField,
  TypeClassField,
} from "../../lib/types/formTypes";
import React from "react";
import Input from "./Input";
import Checkbox from "./Checkbox";
import Picker from "./Picker";
import Link from "./Link";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import "./styles.less";
import { getIonStyleVal } from "../../config/styles/style-utils";
import { ui } from "../../client/ui";
import PlacesAutocomplete from "./PlaceAutocomplete";

interface FormFieldProps extends Partial<React.ComponentProps<typeof IonItem>> {
  field: TypeClassField<AnySupportedFieldTypes>,
  lines?: React.ComponentProps<typeof IonItem>["lines"];
  autoFocus?: React.ComponentProps<typeof IonInput>["autofocus"];
  disabled?: boolean;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
}

const FormField: React.FC<FormFieldProps> = observer(
  ({
     className,
     lines,
     field,
     disabled,
     onChange,
     onBlur,
     autoFocus
   }) => {
    const {
      name,
      type,
      title,
      labelStyle,
      label,
      placeholder,
      cssClass,
      color,
      required,
      value,
    } = field;
    const {
      keyboardType,
      clearOnEdit,
      multiLine,
      minLength,
      maxLength,
      autoLineHeight,
      autoComplete
    } = field as InputField;
    const {
      options,
      pickerInterface
    } = field as PickerField;
    const {
      checkedColor,
      checkedIcon,
      uncheckedIcon
    } = field as CheckboxField;
    const {
      biasCenter,
      mapOptions
    } = field as AutocompleteField;
    const {
      linkText,
      url,
      target
    } = field as LinkField;
    const isDisabled = disabled || field.disabled;
    const errorMessage = field._errorMessage;
    const withLabel = Field => Field && <Observer>{() => (
      <IonItem
        id={`formField_${name}`}
        lines={lines || "full"}
        className={`formField ${isDisabled ? "noEvents" : "editable"} ${className || ""}`}
      >
        <style>
          {`#formField_${name} {
            --highlight-color-focused: ${errorMessage ? getIonStyleVal("--ion-color-danger") : getIonStyleVal(`--ion-color-${color || "primary"}`)};
            ${errorMessage && `--border-color: ${getIonStyleVal("--ion-color-danger")};`}
          }`}
          {`#formField_${name} > ion-label {
            color: ${errorMessage
            ? getIonStyleVal("--ion-color-danger")
            : (isDisabled && type !== "checkbox")
            ? getIonStyleVal("--ion-color-medium")
            : getIonStyleVal(`--ion-color-${color}`)};
            min-width: ${(ui.isMobile && errorMessage) ? "330px" : ""};
            opacity: 1;
          }`}
        </style>
        <IonLabel
          position={labelStyle ||
            type === "checkbox"
            ? undefined
            : type === "link"
            ? "stacked"
            : "floating"
          }
          color={errorMessage ? "danger" : color || undefined}
        >
          {(typeof errorMessage === "string" && errorMessage) || label || placeholder}&nbsp;{required ? "*" : ""}
        </IonLabel>
        <Observer>{() => Field}</Observer>
      </IonItem>
    )}</Observer>;

    return <Observer>{() => <>
      {(title || type === "text") && <IonItem lines="full">
        <IonLabel color="primary" className="textBold">
          {type === "text" ? (title || label || placeholder || value) : title}
        </IonLabel>
      </IonItem>}
      {withLabel(
        (type === "input") ? (
          <Input
            name={name}
            required={required}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            keyboardType={keyboardType}
            clearOnEdit={clearOnEdit}
            cssClass={cssClass}
            label={label}
            placeholder={placeholder}
            errorMessage={errorMessage}
            value={value || undefined}
            multiline={multiLine}
            minLength={minLength}
            maxLength={maxLength}
            autoLineHeight={autoLineHeight}
            disabled={isDisabled}
            // onIonChange={onChange}
            onIonInput={onChange}
            onIonBlur={onBlur}
          />
        ) : (type === "checkbox") ? (
          <Checkbox
            name={name}
            checked={field.value}
            color={checkedColor}
            cssClass={cssClass}
            disabled={isDisabled}
            checkedIcon={checkedIcon}
            uncheckedIcon={uncheckedIcon}
            onIonChange={onChange}
            onIonBlur={onBlur}
          />
        ) : (type === "picker") ? (
          <Picker
            name={name}
            cssClass={cssClass}
            pickerInterface={pickerInterface}
            required={required}
            options={options}
            value={value}
            disabled={isDisabled}
            onIonChange={onChange}
            onIonBlur={onBlur}
          />
        ) : (type === "placesAutocomplete") ? (
          <PlacesAutocomplete
            name={name}
            biasCenter={biasCenter}
            mapOptions={mapOptions}
            required={required}
            clearOnEdit={clearOnEdit}
            placeholder={placeholder}
            value={value || undefined}
            readonly={isDisabled}
            onPositionChange={onChange}
            onIonBlur={onBlur}
          />
        ) : (type === "link") ? (
          <Link
            cssClass={cssClass}
            disabled={isDisabled}
            value={url}
            target={target}
            linkText={linkText}
          />
        ) : null
      )}
    </>}</Observer>
  });

export default FormField;