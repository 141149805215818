import { InputField, PickerField, TypeClassField } from "../../../lib/types/formTypes";
import { RegistrationData } from "../../../lib/types/dataTypes";
import { passwordRegExp } from "../../../utils/helpers";
import { UIText } from "../../../client/lang";

export const regForm = (registerIntents): TypeClassField<InputField | PickerField, RegistrationData>[] => [
  {
    name: "email",
    type: "input",
    required: true,
    unique: "email",
    autoComplete: "off"
  },
  {
    name: "password",
    type: "input",
    keyboardType: "password",
    clearOnEdit: false,
    required: true,
    validationRegex: [
      {
        validationRegex: `/^.{8,}$/g`,
        errorMessage: UIText.registrationPasswordError8
      },
      {
        validationRegex: `${passwordRegExp.lowerNum8}`,
        errorMessage: UIText.registrationPasswordErrorLowerNum
      }
    ],
    autoComplete: "off"
  },
  {
    name: "repeat",
    type: "input",
    keyboardType: "password",
    clearOnEdit: false,
    required: true,
    errorMessage: UIText.registrationPasswordMismatch,
    errorMessageType: "password",
    autoComplete: "off"
  },
  {
    name: "firstName",
    type: "input",
    required: true
  },
  {
    name: "lastName",
    type: "input",
    required: true
  },
  {
    name: "displayName",
    type: "input",
  },
  {
    name: "phone",
    type: "input",
  },
  {
    name: "intent",
    type: "picker",
    cssClass: "formPickerPopoverExWide",
    value: registerIntents[0].groupTypeRoleId.toString(),
    options: registerIntents.map(intent => ({
      name: intent.groupTypeRoleId.toString(),
      placeholder: intent.intentText
    })),
    disabled: registerIntents.length <= 1,
    required: true
  },
];