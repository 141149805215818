import React from "react";
import { observer } from "mobx-react";
import { IonButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { UIText } from "../../client/lang";
import FormTest from "./FormTest";
import ApiTest from "./ApiTest";
import BaseSwitcher from "./BaseSwitcher";
import { observable } from "mobx";
import "./styles.less";
import { Store } from "../../lib/storage";
import { Client, ClientStore } from "../../client/client";
import { api } from "../../client/api";
import { defaultRoute } from "../../config/routes";
import { randomString } from "../../utils/helpers";
import { NukeStorage } from "./NukeStorage";

export const EmbeddedApp: React.FC = observer(props => (
  <iframe
    {...props}
    title="embeddedApp"
    className="ion-margin devToolsApp"
    src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/`}
  />
));

@observer
class DevTools extends React.Component {
  @observable withApp: boolean;
  @observable frameId: string = randomString();

  launchApp = () => {
    this.withApp = true;
    const storage = new Store<ClientStore>("Client");
    storage.dispose();
    setInterval(() => storage.getDriverData().then(data =>
      data && data.oid
        ? api.updateOAuthData(Client.deflavorOAuth2Data(data.oid, data.id))
        : api.resetOAuthData()
    ), 500);
  };

  onBaseSwitch = () => this.frameId = randomString();

  render() {
    return (
      <IonPage className="devTools">
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>
              {UIText.developerSettings}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={this.launchApp}>
                Launch App
              </IonButton>
              <IonButton onClick={() => window.location.href = defaultRoute.path}>
                Go Home
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className={`ion-padding max ${this.withApp ? "withApp" : ""}`}>
          <div className={`flex max ${this.withApp ? "half" : ""}`}>
            <div className="fancyScroll ion-padding-horizontal" style={{ width: this.withApp ? "" : "100%"}}>
              <BaseSwitcher onApply={this.onBaseSwitch} />
              <ApiTest />
              <FormTest />
              <NukeStorage />
            </div>
            {this.withApp && <EmbeddedApp key={this.frameId} />}
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default DevTools;