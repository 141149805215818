import { IonButton, IonButtons, IonCard, IonContent, IonIcon, IonInput, IonPage, withIonLifeCycle } from "@ionic/react";
import { computed, IObservableArray, observable } from "mobx";
import { observer, Observer } from "mobx-react";
import React from "react";
import "./styles.less";
import { StdErr } from "../../../../lib/types/miscTypes";
import { ui } from "../../../../client/ui";
import { ShippingChargeListController } from "./controller";
import { RouteComponentProps } from "react-router";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import CardedContainerHeader from "../../../../components/CardedContainerHeader";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { UIText } from "../../../../client/lang";
import { ShippingCharge } from "../../lib/types/rateTypes";
import Picker from "../../../../components/FormFields/Picker";
import { addOutline, closeOutline, ellipsisHorizontal, funnel, funnelOutline } from "ionicons/icons";
import { asyncPause, getEventRealValue, isEmpty, preventDefaultStopProp, tableViewScrollToBottom } from "../../../../utils/helpers";
import { AutoObserverList } from "../../../../components/ObserverList";
import TwoStageInput from "../../../../components/TwoStageInput";
import SortableTableHead from "../../../../components/SortableTableHead";
import { sortByProperty } from "../../lib/common";
import FilterItemList from "../../../../components/FilterItemList";
import Checkbox from "../../../../components/FormFields/Checkbox";
import { ListController } from "../../../../lib/list-controller";

export interface ShippingChargeListProps extends RouteComponentProps<GroupSubRouteParams<{}>> {}

@observer
class ShippingChargeList extends React.Component<ShippingChargeListProps> {
  url: string;
  title = UIText.rateManagement.systemShippingCharges;

  controller: ShippingChargeListController = {} as ShippingChargeListController;
  listController: ListController<ShippingCharge>;

  @observable loading: boolean = false;

  @computed get shippingCharges(): ShippingCharge[] {
    return this.controller.shippingCharges;
  };
  @computed get sortedCharges(): ShippingCharge[] {
    return [...this.shippingCharges].sort(
      (a, b) => sortByProperty(a, b, this.listController.sortBy, this.listController.sortDir)
    );
  };
  @computed get modeFilters(): IObservableArray<number> {
    return [...this.controller.modeFilters].sort() as IObservableArray;
  };
  @computed get filteredCharges(): ShippingCharge[] {
    if (isEmpty(this.modeFilters)) return this.sortedCharges;
    return this.sortedCharges.filter(charge => this.modeFilters.some(modeId => charge.modeIds.includes(modeId)));
  };

  constructor(props) {
    super(props);
    this.url = props.match.url;
    this.controller = new ShippingChargeListController();
    this.listController = new ListController({
      storeName: "ShippingChargeListFilterSort",
      initialSortBy: "id"
    });
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    if (!this.controller.isSa) return this.props.history.replace("/");
    return this.onRefresh();
  };

  onRefresh = (event?: any) => {
    this.loading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleFieldChange = (event: any) => {
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const id = name.split(".")[0];
    const field = name.split(".")[1];
    const changed = this.controller.onFieldChange(id, field, value);
    return !!changed && setTimeout(() => this.updateFieldAsync(id, field, value));
  };

  updateFieldAsync = async (id: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateField(id, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleAdd = (event: any) => {
    preventDefaultStopProp(event);
    const defaultModeId = this.controller.modes[0].id;
    const modeId = this.modeFilters[0] || defaultModeId;
    if (!modeId) return;
    this.loading = true;
    return this.controller.onAddShippingCharge(modeId)
    .then(() => asyncPause(500))
    .then(tableViewScrollToBottom)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRemove = (event: any, charge: ShippingCharge) => ui.reconfirm({
    header: `${UIText.generalDelete} Shipping Charge`,
    name: `the System Shipping Charge ID ${charge.id}${
      charge.chargeName[UIText.preference] ? ` "${charge.chargeName[UIText.preference]}"` : ""
    }`,
    verb: UIText.generalDelete.toLowerCase(),
    handler: () => this.handleRemoveConfirm(event, charge.id)
  });

  handleRemoveConfirm = (event: any, id: number) => {
    if (!id) return;
    this.loading = true;
    return this.controller.onRemoveShippingCharge(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  handleModeFilterMenu = (event: any) => {
    const { modes, onFilterClear, onFilterSelect } = this.controller;
    const filters = modes.map(mode => ({
      name: mode.id.toString(), placeholder: mode.localeMode[UIText.preference]
    }));
    return ui.popover({
      event,
      component: () => <FilterItemList
        filters={filters}
        selectedFilters={this.modeFilters.map(f => f.toString())}
        onFilterClear={onFilterClear}
        onFilterSelect={onFilterSelect}
      />,
      showBackdrop: false
    });
  };

  render() {
    const { modes } = this.controller;

    const { sortBy, sortDir } = this.listController;

    return (
      <IonPage className="headerOffset shippingChargeList">
        <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
          <IonCard className="flex max column container inboxContainer cardedContainer">
            <Observer>{() => (
              <CardedContainerHeader
                title={this.title}
                titleRight={
                  <IonButtons>
                    <IonButton
                      className="textBold textNoTransform"
                      fill="clear"
                      size="small"
                      onClick={this.handleModeFilterMenu}
                    >
                      <IonIcon icon={isEmpty(this.modeFilters) ? funnelOutline : funnel} slot="start" />
                      {UIText.rateManagement.systemShippingModes}
                    </IonButton>
                    <IonButton color="primary" className="textNoTransform" onClick={this.handleAdd}>
                      <IonIcon slot="icon-only" icon={addOutline}/>
                    </IonButton>
                  </IonButtons>
                }
                backHandler={this.props.history.goBack}
                loading={this.loading}
                onRefresh={this.onRefresh}
              />
            )}</Observer>
            <IonContent className="max fullBackground ion-no-padding" scrollY={false}>
              <TableContainer component={props => <Paper {...props} square />} elevation={0} className="max">
                <Table size="small" stickyHeader>
                  <Observer>{() => (
                    <SortableTableHead
                      columns={[
                        { name: "id", text: "ID *" },
                        { name: "modeIds", text: "Mode *", routerLink: "/ShippingModes", noSort: true },
                        { name: "baseCode", text: "Base code *" },
                        { name: "isSurcharge", text: "Surcharge" },
                        { name: "chargeName", text: `Charge name (${UIText.preference}) *` },
                        { name: "description", text: `Description (${UIText.preference})` },
                        { name: "delete", text: UIText.generalDelete, noSort: true }
                      ]}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      onSort={this.handleColumnSort}
                    />
                  )}</Observer>
                  <Observer>{() => (
                    <TableBody>
                      <AutoObserverList
                        list={this.filteredCharges}
                        getItemKey={charge => charge.id}
                        render={charge => {
                          if (isEmpty(modes)) return [];
                          const baseModeRegExp = new RegExp(`(${modes.map(mode => mode.mode).join("|")})`, "i");
                          if (charge.baseCode.match(baseModeRegExp)) return null;
                          return <TableRow>
                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                {charge.id}
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <Picker
                                  name={`${charge.id}.modeIds`}
                                  className="ion-no-padding"
                                  multiple
                                  placeholder="Modes"
                                  options={modes.map(mode => ({
                                    name: mode.id.toString(), placeholder: mode.localeMode[UIText.preference]
                                  }))}
                                  value={charge.modeIds.map(id => id.toString())}
                                  onIonChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${charge.id}.baseCode`}
                                  placeholder="Short unique identifier"
                                  value={charge.baseCode || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="center" padding="none" className="checkbox">
                                <Checkbox
                                  className="ion-no-padding"
                                  name={`${charge.id}.isSurcharge`}
                                  checked={charge.isSurcharge}
                                  onIonChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${charge.id}.chargeName`}
                                  placeholder="Name of the charge"
                                  value={charge.chargeName[UIText.preference] || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <TwoStageInput
                                  endIcon={ellipsisHorizontal}
                                  className="ion-no-padding"
                                  name={`${charge.id}.description`}
                                  placeholder="Description of the charge"
                                  value={charge.description[UIText.preference] || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>
                            {/*<TableCell align="left" padding="none">*/}
                            {/*  <IonInput*/}
                            {/*    className="ion-no-padding"*/}
                            {/*    name={`${charge.id}.carrierId`}*/}
                            {/*    type="number"*/}
                            {/*    value={charge.carrierId || ""}*/}
                            {/*    onIonChange={this.handleFieldChange}*/}
                            {/*  />*/}
                            {/*</TableCell>*/}
                            {/*<TableCell align="left" padding="none">*/}
                            {/*  <IonInput*/}
                            {/*    className="ion-no-padding"*/}
                            {/*    name={`${charge.id}.carrierCode`}*/}
                            {/*    value={charge.carrierCode || ""}*/}
                            {/*    onIonChange={this.handleFieldChange}*/}
                            {/*  />*/}
                            {/*</TableCell>*/}

                            <Observer>{() => (
                              <TableCell align="center" padding="none">
                                <IonButtons className="flex ion-align-items-center ion-justify-content-center">
                                  <IonButton color="danger" onClick={e => this.handleRemove(e, charge)}>
                                    <IonIcon slot="icon-only" icon={closeOutline}/>
                                  </IonButton>
                                </IonButtons>
                              </TableCell>
                            )}</Observer>
                          </TableRow>
                        }}
                      />
                    </TableBody>
                  )}</Observer>
                </Table>
              </TableContainer>
            </IonContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(ShippingChargeList);