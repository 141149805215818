import { observer } from "mobx-react";
import React from "react";
import { IonInput, IonTextarea } from "@ionic/react";
import { TextFieldTypes } from "@ionic/core";
import { FormFieldCommonProps } from "../../../lib/types/propTypes";
import { InputField } from "../../../lib/types/formTypes";

export interface InputFieldProps extends FormFieldCommonProps {
  autoFocus?: boolean;
  placeholder?: string;
  keyboardType?: TextFieldTypes;
  multiline?: boolean;
  minLength?: number;
  maxLength?: number;
  autoLineHeight?: boolean;
  autoComplete?: InputField["autoComplete"];
}

const Input: React.FC<InputFieldProps> = observer(
  ({
     className,
     name,
     required,
     keyboardType,
     clearOnEdit,
     label,
     placeholder,
     errorMessage,
     value,
     autoFocus,
     multiline,
     maxLength,
     minLength,
     autoLineHeight,
     autoComplete,
     disabled,
     onIonChange,
     onIonBlur,
     onIonInput
   }) => {
    placeholder = (minLength || maxLength)
      ? `${placeholder || ""} (${minLength || 0} ~ ${maxLength || "*"})` // Textarea mode
      : label ? placeholder : undefined; // Input mode

    placeholder = disabled ? "—" : placeholder;

    return multiline ? (
      <IonTextarea
        autofocus={autoFocus}
        name={name}
        className={`editorTextArea ${className || ""}`}
        autoGrow={autoLineHeight}
        minlength={minLength}
        maxlength={maxLength}
        value={value || ""}
        placeholder={placeholder}
        readonly={disabled}
        // onIonChange={onIonChange}
        onIonBlur={onIonBlur}
        onIonInput={onIonInput}
      />
    ) : (
      <IonInput
        className={className || ""}
        // color={errorMessage ? "danger" : undefined}
        autofocus={autoFocus}
        autocomplete={autoComplete}
        name={name}
        required={required}
        type={keyboardType}
        clearOnEdit={clearOnEdit}
        value={value || ""}
        placeholder={placeholder}
        readonly={disabled}
        // onIonChange={onIonChange}
        onIonBlur={onIonBlur}
        onIonInput={onIonInput}
      />
    );
  });

export default Input;