import { observer } from "mobx-react";
import * as React from "react";
import { ui } from "../../client/ui";
import { IonButton, IonList, IonListHeader, IonSpinner } from "@ionic/react";
import { Fade } from "react-reveal";
import "./styles.less";
import { UserLoginData } from "../../lib/types/dataTypes";
import { UIText } from "../../client/lang";
import FormField from "../FormFields";

export interface LoginBoxProps extends React.HTMLProps<HTMLDivElement> {
  loginData: UserLoginData<string>;
  error?: UserLoginData<boolean>;
  handleFieldChange: (event: any) => void;
  handleLogin: (event: any) => void;
  loading?: boolean
}

export const LoginBox: React.FC<LoginBoxProps> = observer((
  { className, loginData, error, handleFieldChange, handleLogin, loading }
) => (
  <div className={className || ""}>
    <Fade duration={ui.defaultDuration}>
      <form
        className="flex column"
        onSubmit={handleLogin}
        {...ui.bindKeyCode("onKeyUp", { 13: handleLogin })}
      >
        <IonList>
          <IonListHeader className="textBold font-s">
            {UIText.welcome}
          </IonListHeader>
          <FormField
            field={{
              type: "input",
              name: "login_email",
              value: loginData.email || loginData.username,
              // required: true,
              _errorMessage: error.email,
              placeholder: UIText.email
            }}
            lines="full"
            autoFocus
            onChange={handleFieldChange}
          />
          <FormField
            field={{
              type: "input",
              name: "login_password",
              keyboardType: "password",
              value: loginData.password,
              clearOnEdit: false,
              // required: true,
              _errorMessage: error.password,
              placeholder: UIText.password
            }}
            lines="full"
            onChange={handleFieldChange}
          />
        </IonList>

        <IonButton
          className="ion-align-self-center ion-margin-vertical"
          size="default"
          type="submit"
          disabled={(!loginData.email && !loginData.username) || !loginData.password}
        >
          {loading ? <IonSpinner /> : UIText.login}
        </IonButton>
      </form>
    </Fade>
  </div>
));