import { observer } from "mobx-react";
import React from "react";
import { Form } from "../../client/form";
import { IonList } from "@ionic/react";
import { getEventRealValue, isEmpty } from "../../utils/helpers";
import FormField from "../FormFields";
import { AnySupportedFieldTypes, SupportedFieldTypes, TypeClassField } from "../../lib/types/formTypes";
import "./styles.less";
import { ui } from "../../client/ui";

@observer
class Field extends React.Component<{
  field: TypeClassField<AnySupportedFieldTypes>;
  disabled: boolean;
  formSet: Form["set"];
  formValidate: Form["validate"];
  validateOnBlur: boolean;
  validateOnChange: boolean;
  onFieldBlur: (fieldName: string, event: any) => void;
  fieldProps: any;
}> {
  onChange = event => {
    const { field, formSet, formValidate, validateOnChange } = this.props;
    const fieldValue =
      field.type === "checkbox"
        ? getEventRealValue(event)
        : field.type !== "input"
        ? event.detail.value
        : event.target.value;
    if (fieldValue === field.value) return;
    formSet(field.name, fieldValue);
    if (validateOnChange) formValidate(field.name).catch(console.warn);
  };
  onBlur = async event => {
    const { field, disabled, validateOnBlur, formValidate, onFieldBlur } = this.props;
    if (validateOnBlur && !disabled) await formValidate(field.name).catch(console.warn);
    if (onFieldBlur) onFieldBlur(field.name, event);
  };
  render() {
    const { field, disabled, fieldProps } = this.props;
    return <FormField
      field={field}
      disabled={disabled}
      onChange={this.onChange}
      onBlur={this.onBlur}
      {...fieldProps}
    />;
  }
}

interface FormViewProps extends React.ComponentProps<typeof IonList> {
  noList?: boolean;
  form: Form;
  disabled?: boolean;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  fieldProps?: Partial<React.ComponentProps<typeof FormField>>;
  onSubmit?: (event: any) => void;
  onFieldBlur?: (name: string, event: any) => void;
}

@observer
class FormView extends React.Component<FormViewProps> {
  render() {
    const {
      noList,
      form,
      className,
      disabled,
      validateOnBlur,
      validateOnChange,
      fieldProps,
      onSubmit,
      onFieldBlur
    } = this.props;

    if (!form || isEmpty(form.renderable)) return null;

    const formView = form.renderable.map((field: TypeClassField<SupportedFieldTypes>) => (
      <Field
        key={field.name}
        field={field}
        disabled={disabled}
        formSet={form.set}
        formValidate={form.validate}
        validateOnBlur={validateOnBlur}
        validateOnChange={validateOnChange}
        onFieldBlur={onFieldBlur}
        fieldProps={fieldProps}
      />
    ));

    return (
      <form
        className={`flex column formView ${className || ""}`}
        onSubmit={onSubmit}
        {...onSubmit && ui.bindKeyCode("onKeyUp", { 13: onSubmit })}
      >
        {noList? formView : <IonList>{formView}</IonList>}
      </form>
    );
  }
}

export default FormView;