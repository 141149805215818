import general from './general';

const app3win = {
  titleLong: "THE GLOBAL LOGISTICS NETWORK",

  shipper: "Shipper",
  lsp: "LSP",
  get lsps() { return `${app3win.lsp}s` },
  lspLong: "Logistics Service Provider (LSP)",

  POL: "POL",
  POD: "POD",
  freight: "Freight",
  get freights() { return `${app3win.freight}s` },
  freightMode: "Mode",

  RFQ: "RFQ",
  RFQs: "RFQs",
  get newRFQ() { return `New ${app3win.submittedRFQ}` },
  get submittedRFQ() { return `Request for ${app3win.quotation}` },
  get myRFQs() { return `My ${app3win.RFQs}` },
  get pendingRFQs() { return `Pending ${app3win.RFQs}` },
  get rfqDescription() { return `${app3win.RFQ} ${general.generalDescription.toLowerCase()}` },
  get rfqPrimaryMode() { return `Primary ${app3win.rfqMode.toLowerCase()}` },
  rfqMode: "Mode",
  get RFQKeyWords() { return `${app3win.RFQ} Keywords...`},

  rfqTraffic: "Traffic",
  get rfqTrafficSegments() { return `${app3win.freight} segments`},

  quotation: "Quotation",
  quotations: "Quotations",
  quotationsForRfq: title => `${app3win.quotations} for ${title}`,
  get allInQuotation() { return `All-in ${app3win.quotation.toLowerCase()}` },
  get notAllInQuotation() { return `Partial ${app3win.quotation.toLowerCase()}` },
  get allInQuotations() { return `All-in ${app3win.quotations.toLowerCase()}` },
  get autoQuotation() { return `Auto generated ${app3win.quotation.toLowerCase()}` },
  get noQuotations() { return `No ${app3win.quotations.toLowerCase()}` },
  get favoriteQuotations() { return `All-in and custom ${app3win.solutions.toLowerCase()}` },
  get quotationName() { return `${app3win.quotation} name` },
  get chooseQuotation() { return `Choose a ${app3win.quotation.toLowerCase()}` },
  get submitQuotation() { return `Submit ${app3win.quotation.toLowerCase()}` },
  get submittedQuotation() { return `Submitted ${app3win.quotation.toLowerCase()}` },
  get submittedQuotations() { return `Submitted ${app3win.quotations.toLowerCase()}` },
  get createNewQuotation() { return `Create a New ${app3win.quotation}` },
  createNewQuotationField: "Use this name or provide your own name for this quotation",
  submission: "Submission",
  get submissions() { return `${app3win.submission}s` },
  inProgressQuotation: "In-progress",

  service: "Service",
  services: "Services",
  serviceType: "Service type",
  get serviceDescription() { return general.generalDescription },
  get servicesByTypes() { return `${app3win.service} ${app3win.quotations.toLowerCase()}` },
  get serviceDatetime() { return `${app3win.service} date` },
  get submittedService() { return `Submitted ${app3win.service.toLowerCase()}` },

  award: "award",
  awards: "Awards",
  awarded: "Awarded",
  closed: "Closed",

  solution: "Solution",
  solutions: "Solutions",
  get allInSolution() { return `All-in ${app3win.solution.toLowerCase()}` },
  get newSolution() { return `New ${app3win.solution}` },
  get multiServiceSolutions() { return `Multi-service ${app3win.solutions.toLowerCase()}` },
  get solutionDetail() { return `View ${app3win.solution} detail` },
  get solutionName() { return `${app3win.solution} name` },
  get chooseSolution() { return `Choose a ${app3win.solution.toLowerCase()} `},
  get addToSolution() { return `${general.generalAdd} to a ${app3win.solution.toLowerCase()} `},
  get removeFromSolution() { return `${general.generalRemove} from ${app3win.solution.toLowerCase()} `},
  get finalizeSolution() { return `Finalize ${app3win.solution.toLowerCase()}` },

  servicesInSolution: solutionName => `the ${app3win.lsps} who provided quotation for the services in solution ${solutionName}`,
  awardBusinessTo: "Award business to",

  provider: "Provider",
  providers: "Providers",
  get unknownProvider() { return `${general.generalUnknown} ${app3win.provider.toLowerCase()}` },

  shortlist: "Shortlist",
  shortlists: "Shortlists",

  requirement: "Requirement",
  requirements: "Requirements",

  inlandServiceDisplay: "Freight Services",
  remarks: "Remarks",
  get noRemarksAvailable() { return `No information` },

  get submitDate() { return `${general.generalSubmit} date` },

  chatWithShipper: "Chat with shipper",
  chatWithLSP: "Chat with LSP",
  chatWithUnavailable: party => `Chat with ${party} is currently unavailable due to technical reasons. Please contact support for more information.`,

  rfqForm: {
    heading: "Describe your cargo",
    headingReadonly: "Shipper RFQ",
    dangerousGoods: "Dangerous?",
    imoClassification: "IMO Classification",
    unNumber: "UN Number",
    shippersReference: "Shipper's own reference to this RFQ",
    shippersReferencePlaceholder: "Provide a name for this RFQ for easy reference",
    pol: "Port of Loading",
    pod: "Port of Discharge",
    origin: "Origin",
    destination: "Destination",
    location: "Location",
    address: "Address",
    to: "To",
    from: "From",
    notes: "Notes",
    instructions: "Instructions",
    type: "Type",
    get description() { return general.generalDescription },
    countryOfExport: "Country of export",
    countryOfImport: "Country of import",
    pickUpAddress: "Pick-up address",
    deliveryAddress: "Delivery address",
    customsInformation: "Additional customs information",
    get addrCityProvStateCountry() { return `Address, ${app3win.rfqForm.cityProvStateCountry}` },
    get cityProvStateCountry() { return `City, Province/State, ${app3win.rfqForm.country}` },
    country: "Country",
    goods: "Goods",
    describeGoods: "Describe these goods",
    metric: "Metric",
    imperial: "Imperial",
    shipmentItems: "What items do you need shipped? Add as many as you need.",
    get shipmentItemsError() { return app3win.rfqForm.shipmentItems + " (at least one required)"},
    shipmentItemsReadonly: "Items",
    numberOfPieces: "Number of pieces",
    numberOfContainers: "Number of containers",
    numberOfTrucks: "Number of trucks",
    pieces: "Pieces",
    pallet: "Pallet",
    container: "Container",
    carton: "Carton",
    bag: "Bag",
    fcl: "Full Container Load (FCL)",
    get lclPallet() { return `LCL ${app3win.rfqForm.pallet}` },
    get lclContainer() { return `LCL ${app3win.rfqForm.container}` },
    // lcl: "LCL",
    lcl: "Less than Container Load (LCL)",
    ftl: "Full Truckload (FTL)",
    ltl: "Less than Truckload (LTL)",
    other: "Other specification",
    item: "Item",
    weight: "Gross weight",
    perPiece: "(per piece)",
    measurementMode: "Measurements",
    measurementHelpSea: "In sea freight, 1 CBM = 1000kg for shipments to main ports, and 1 CBM = 363kg for shipments to inland depots / terminals.",
    measurementHelpAir: "In air freight, airlines will apply DIM./6000 and courier services will apply DIM./5000 to get the volume weight.",
    dimension: "Dimensions",
    dimensionReadonly: "Dimensions (L W H)",
    dimensionAltMode: mode => `Use ${mode}`,
    volume: "Volume",
    length: "L",
    width: "W",
    height: "H",
    containerType: "Container size/type",
    soc: "SOC",
    cargoReadyDate: "Ready for transport",
    arrivalDate: "Desired date of arrival",
    arrivalDatePopup: "Your desired date of arrival lets the logistics service provider (LSP) know when you would like your cargo to arrive at the final destination, however, there are many factors that determine whether a shipment can occur within a certain timeframe. If provided, this date will be used as a guideline.",
    datePlaceholder: "Month DD, YYYY",
    dateTimePlaceholder: "Month DD, YYYY 0:00 AM/PM",
    otherInfo: "Other additional shipment information",
    otherInfoPlaceholder: "Enter additional information to LSPs about your RFQ",
    transportation: "How would you like your cargo transported?",
    air: "Air",
    get airFreight() { return `${app3win.rfqForm.air} ${app3win.freight}` },
    sea: "Sea",
    get seaFreight() { return `${app3win.rfqForm.sea} ${app3win.freight}` },
    rail: "Rail",
    get railFreight() { return `${app3win.rfqForm.rail} ${app3win.freight}` },
    road: "Road",
    get roadFreight() { return `${app3win.rfqForm.road} ${app3win.freight}` },
    freightServicesHeading: "What service do you need?",
    additionalService: "Do you need additional services?",
    additionalServiceReadonly: "Additional services",
    additionalInformation: "Additional information",
    portToPort: "Port-to-port",
    portToDoor: "Port-to-door",
    doorToPort: "Door-to-port",
    doorToDoor: "Door-to-door",
    customs: "Customs clearance",
    storage: "Storage",
    packing: "Packing",
    packingAddressOverride: "Packing address different from pick-up address?",
    inspection: "Inspection",
    otherService: "Other",
    otherInspection: "Other, please specify",
    date: "Date",
    duration: "Duration (days)",
    fumigation: "Fumigation",
    phytosanitary: "Phytosanitary",
    qualityTest: "Quality test",
    get signUpAndSubmit() { return `${general.login} and Submit` },
    visitorLink: "Create an RFQ",
    demoGreeting: "3WIN connects individuals and businesses with a global network of logistics service providers (LSPs) to offer our customers instant, efficient market pricing on their international logistics needs. Customers can access direct, competitive quotes and book their desired pricing easily.",
    showOriginalInquiry: "Show original request",
    unsavedChangesBack: "The not-submitted changes will be discarded after leaving this page.",
    submitted: "Your RFQ has been Submitted!",
    resubmitMsg: "Would you like to create another RFQ based on the RFQ requirements you just submitted?",
    altModes: "Would you like additional quotations for alternative modes of transportation?",
    altModeSpecify: "Specify one or more alternative modes:",
    multimodal: "Multimodal",
    multimodalQuestion: "Are you willing to receive multimodal quotations?",
    multimodalPopup: "Multimodal shipping combines different modes of transportation over multiple segments to transport cargo between the origin and the final destination. Any mode of transportation may be involved to optimize cost, time, or other considerations.",
    fieldsAttention: "RFQ Fields",
    fieldsRequireAttention: fields => `The following ${fields.length > 1 ? "fields require" : "field requires"} your attention:<br/><br/>${fields.map(field => `&bull;&nbsp;${field}<br/><br/>`).join("")}`,
    createSimilar: "Create RFQ like this"
  },

  freightForm: {
    carrier: "Carrier",
    frequency: "Frequency",
    transitTime: "Transit time (days)",
    price: "Rate ($)",
    surcharges: "Surcharges ($)",
    rateValidity: "Rate validity",
    altMode: "Alternative mode",
    get altModes() { return `${app3win.freightForm.altMode}s` },
    totalPrice: "Total rate ($)"
  },

  createShipperProfile: "Create your profile as shipper",
  createLSPProfile: "Create your profile as LSP",

  groupTypes: {
    Shipper: "Shipper",
    LSP: "LSP"
  },

  get rfqSortProperties() {
    return {
      "reference": "Reference name",
      "submissionDate": "Submission date",
      "cargoReadyDate": app3win.rfqForm.cargoReadyDate,
      "cargoArrivalDate": app3win.rfqForm.arrivalDate,
      "quotationCount": "Quotation count",
      "solutionCount": "Solution count",
      "itemCount": "Number of items"
    }
  },
  get rfqFilterProperties() {
    return {
      hasQuotation: `Has ${app3win.quotation.toLowerCase()}`,
      hasSolution: `Has ${app3win.solution.toLowerCase()}`,
      isAwarded: `Awarded`,
      air: `By ${app3win.rfqForm.air}`,
      sea: `By ${app3win.rfqForm.sea}`,
      rail: `By ${app3win.rfqForm.rail}`,
      road: `By ${app3win.rfqForm.road}`,
      portToPort: app3win.rfqForm.portToPort,
      doorToPort: app3win.rfqForm.doorToPort,
      portToDoor: app3win.rfqForm.portToDoor,
      doorToDoor: app3win.rfqForm.doorToDoor,
      packing: app3win.rfqForm.packing,
      storage: app3win.rfqForm.storage,
      inspection: app3win.rfqForm.inspection,
    }
  },

  get shippingPlanSortProperties() {
    return {
      description: "Description",
      createTime: "Created",
      updateTime: "Updated"
    }
  },
  get shippingPlanFilterProperties() {
    return {
      get single() { return app3win.rateManagement.singleMode },
      get simple() { return app3win.rateManagement.multimodal },
      get complex() { return app3win.rateManagement.complexMultimodal },
      air: `By ${app3win.rfqForm.air}`,
      sea: `By ${app3win.rfqForm.sea}`,
      rail: `By ${app3win.rfqForm.rail}`,
      road: `By ${app3win.rfqForm.road}`,
      multimodal:  `By ${app3win.rfqForm.multimodal}`,
    }
  },

  get carrierSortProperties() {
    return {
      id: "ID",
      displayName: "Business name",
      tradeName: "Trade name",
      abbreviation: "Abbr.",
      country: "Country",
      type: "Company Type"
    }
  },

  get shippingPortSortProperties() {
    return {
      iso3166A2Code: "ISO-3166-A2",
      iso3166A3Code: "ISO-3166-A3",
      portCode: "Port code",
      portName: "Port name",
      lat: "Latitude",
      lng: "Longitude"
    }
  },
  shippingPortGooglePlace: "Port place",

  rateManagement: {
    rateManagement: "Rate Management",
    shippingRate: "Shipping Rate",
    get shippingRates() { return `${app3win.rateManagement.shippingRate}s` },
    get selectAShippingRate() { return `${general.generalSelect} a ${app3win.rateManagement.shippingRate}` },

    rateTable: "Rate Table",
    get rateTables(){ return `${app3win.rateManagement.rateTable}s` },

    get shippingRateTable() { return `${app3win.rateManagement.shippingRate} Table` },
    get shippingRateTables() { return `${app3win.rateManagement.shippingRate} Tables` },
    get multimodalShippingRateTable() { return `Multimodal Shipping ${app3win.rateManagement.rateTable}` },
    get complexShippingRateTable() { return `Complex Multimodal Shipping ${app3win.rateManagement.rateTable}` },

    rateDetails: "Details",
    mode: "Mode of Transportation",
    modes: "Modes of Transportation",
    containerType: "Container Type",
    carrier: "Carrier",
    get selectACarrier() { return `${general.generalSelect} a ${app3win.rateManagement.carrier}` },
    get createCarrier() { return `Create new ${app3win.rateManagement.carrier.toLowerCase()}` },
    pieces: "Pieces",
    fcl: "FCL",
    lcl: "LCL",
    ftl: "FTL",
    ltl: "LTL",
    "fcl&lcl": "FCL and LCL",
    "ftl&ltl": "FTL and LTL",

    rate: "Rate",
    get rates() { return `${app3win.rateManagement.rate}s` },
    unit: "Unit",
    get units() { return `${app3win.rateManagement.unit}s` },
    get rateUnit() { return `${app3win.rateManagement.rate} ${app3win.rateManagement.unit.toLowerCase()}` },
    get rateUnits() { return `${app3win.rateManagement.rate} ${app3win.rateManagement.units.toLowerCase()}` },
    validity: "Validity",
    validityStart: "Validity Start",
    validityEnd: "Validity End",
    ETD: "ETD",
    transitTime: "T/T",
    basicFreight: "Basic Freight",
    basicFreightPer: "Per",
    basicFreightPerUoc: uoc => `${app3win.rateManagement.basicFreightPer} ${uoc || "(UOC)"}`,
    amount: "Amount",
    currency: "Currency",
    perUom: "Per UOM",
    perUoc: "Per UOC",
    get addRateUnit() { return `${general.generalAdd} ${app3win.rateManagement.rateUnit.toLowerCase()}` },
    get removeRateUnit() { return `${general.generalRemove} ${app3win.rateManagement.rateUnit.toLowerCase()}` },

    chargeType: "Charge type",
    charge: "Charge",
    get charges() { return `${app3win.rateManagement.charge}s` },
    surcharge: "Surcharge",
    get surcharges() { return `${app3win.rateManagement.surcharge}s` },
    get chargeSurcharge() { return `${app3win.rateManagement.charge} / ${app3win.rateManagement.surcharge}` },
    get chargesSurcharges() { return `${app3win.rateManagement.charges} / ${app3win.rateManagement.surcharges}` },
    get selectChargesSurcharges() { return `${general.generalSelect} ${app3win.rateManagement.chargesSurcharges}` },
    get manageChargesSurcharges() { return `Manage ${app3win.rateManagement.chargesSurcharges}` },
    get addCharges() { return `${general.generalAdd} ${app3win.rateManagement.chargesSurcharges}` },
    get addCharge() { return `${general.generalAdd} ${app3win.rateManagement.chargeSurcharge}`},

    baseCode: "Base code",
    get chargeName() { return `${app3win.rateManagement.charge} name` },
    get carrierChargeSurchargeCode() { return `${app3win.rateManagement.carrier} Code` },
    get chargeSum() { return `${app3win.rateManagement.charges} Total` },
    get surchargeSum() { return `${app3win.rateManagement.surcharges} Total` },

    get carrierShippingCharges() { return `${app3win.rateManagement.carrier} Shipping Charges`},
    get manageCarrierShippingCharges() { return `Manage ${app3win.rateManagement.carrierShippingCharges}` },

    createCarrierChargeOverride: "Create carrier override",
    createCarrierChargeDuplicate: "Duplicate this",
    customShippingCharge: "Custom shipping charge",

    shippingPort: "Shipping Port",
    get selectShippingPort() { return `${general.generalSelect} a ${app3win.rateManagement.shippingPort}` },
    selectPolPodPort: {
      get polPortId() { return `${general.generalSelect} ${app3win.rfqForm.pol}` },
      get podPortId() { return `${general.generalSelect} ${app3win.rfqForm.pod}` },
    },
    selectedForPort: {
      get pol() { return `${general.generalSelected} for POL`},
      get pod() { return `${general.generalSelected} for POD`}
    },

    // allInAmount: "All-in Freight Rate",
    allInAmount: "Basic Freight + Surcharges",
    dayEtd: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Daily"
    ],

    singleMode: "Single-mode",
    multimodal: "Multimodal (Simple)",
    complexMultimodal: "Multimodal (Complex)",

    editComplexMultimodal: "Edit Complex Shipping Rate",

    modeSequences: "Mode Summary",
    modeSequencesPlaceholder: "Click to see modes",

    leg: "Leg",
    get legs() { return `${app3win.rateManagement.leg}s`},

    transitPort: "Transit Port",
    get transitPorts() { return `${app3win.rateManagement.transitPort}s` },
    get transitPortLeg() { return `${app3win.rateManagement.transitPort} Leg` },
    get transitPortLegs() { return `${app3win.rateManagement.transitPort} Legs` },
    get transitPortsPlaceholder() { return `${general.generalSelect} ${app3win.rateManagement.transitPorts}` },
    get complexTransitPortsPlaceholder() { return `No ${app3win.rateManagement.transitPorts}`},
    modeToNextLeg: "Mode to next Port",

    get reconfirmModeChange() {
      return `Changing ${app3win.rateManagement.mode} may reset already selected ${app3win.rateManagement.carrier}, POL/Origin and POD/Destination Shipping Port(s).`
    },
    get lockedModeChange() {
      return `The ${app3win.rateManagement.mode} cannot be changed after setting a port, ${app3win.rateManagement.carrier}, ${app3win.rateManagement.chargesSurcharges}, or rate breakdowns.`
    },

    get useExistingRateForPlan() { return `Use existing single-mode ${app3win.rateManagement.rateTable}` },
    get createNewRateForPlan() { return `Create a new ${app3win.rateManagement.rateTable}` },

    portRouteSummary: "Journey",
    transitPortRouteSummary: "Route Summary",

    systemShippingModes: "Shipping Modes",
    systemShippingCharges: "System Shipping Charges",
    systemShippingUnits: "System Shipping Units",
    systemShippingUocs: "System Shipping UOCs",
    systemShippingUocsLong: "System Shipping Units of Counting",
    systemCarriers: "Shipping Carriers",
    systemShippingPorts: "Shipping Ports",

    useAdvancedSearch: "Search by Mode and Ports",
    searchHelp: "Mode of Transport, Port name/code, Carrier name, Description, or Container type...",

    invalidShippingRateData: "Invalid Rate Table Data"
  },

  adminMenu: "Admin"
};

export default app3win;
