import { observer, Observer } from "mobx-react";
import React from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonModal,
  IonRippleEffect,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { UIText } from "../../../../client/lang";
import { addOutline, closeSharp, ellipsisHorizontal } from "ionicons/icons";
import ListControlToolbar from "../../../../components/ListControlToolbar";
import { getDisplayNameEng, getEventRealValue, preventDefaultStopProp } from "../../../../utils/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@material-ui/core";
import SortableTableHead from "../../../../components/SortableTableHead";
import MdIcon from "../../../../components/MdIcon";
import { mdiDotsVertical } from "@mdi/js";
import { ObserverList } from "../../../../components/ObserverList";
import { Carrier, ShippingCharge, ShippingRate } from "../../lib/types/rateTypes";
import TwoStageInput from "../../../../components/TwoStageInput";
import "./styles.less";
import { ListController } from "../../../../lib/list-controller";
import { sortByProperty } from "../../lib/common";
import { computed, observable } from "mobx";
import { matchShippingCharge } from "../../lib/matchers";
import { ui } from "../../../../client/ui";
import HeaderRefreshButton from "../../../../components/HeaderRefreshButton";
import { PredefinedColors } from "@ionic/core";
import { IonCheckboxWithReadonly } from "../../../../components/FormFields/Checkbox";

export interface CarrierChargeCustomizeModalProps {
  shippingCharges: ShippingCharge[];
  carrier: Carrier;
  rate: ShippingRate;
  loading: boolean;
  isOpen: boolean;
  onDismiss: (event: any) => void;
  onCreateCarrierOverride: (charge: ShippingCharge) => void;
  onRemoveCarrierOverride: (charge: ShippingCharge) => void;
  onRefresh: (event: any) => Promise<void>;
  onFieldChange: (event: any) => void;
  onAddCustomCharge: (event: any) => void;
  onDuplicateCustomCharge: (charge: ShippingCharge) => void;
}

@observer
class CarrierChargeCustomizeModal extends React.Component<CarrierChargeCustomizeModalProps> {
  listController: ListController<ShippingCharge>;

  @observable _loading: boolean;
  @computed get loading(): boolean {
    return this._loading || this.props.loading;
  };


  @computed get searchedShippingCharges(): ShippingCharge[] {
    return this.props.shippingCharges.filter(charge => matchShippingCharge(charge, this.listController.searchValue));
  };
  @computed get sortedShippingCharges(): ShippingCharge[] {
    return [...this.searchedShippingCharges].sort(
      (a, b) => sortByProperty(a, b, this.listController.sortBy, this.listController.sortDir)
    );
  };

  constructor(props) {
    super(props);
    this.listController = new ListController({
      storeName: "carrierChargeCustomizeModalFilterSort",
      initialSortBy: "baseCode"
    });
  }

  onRefresh = (event: any) => {
    preventDefaultStopProp(event);
    if (!this.props.onRefresh) return;
    this._loading = true;
    return this.props.onRefresh(event)
    .finally(() => this._loading = false);
  };

  handleChargeMenu = (event: any, charge: ShippingCharge) => {
    event.persist();
    return ui.popoverMenu({
      event,
      menuItems: [
        !charge.isCustom && {
          text: UIText.rateManagement.createCarrierChargeOverride,
          handler: () => this.props.onCreateCarrierOverride(charge)
        },
        charge.isCustom && {
          text: UIText.rateManagement.createCarrierChargeDuplicate,
          handler: () => this.props.onDuplicateCustomCharge(charge)
        },
        charge.isCustom && {
          text: UIText.generalRemove,
          color: "danger" as PredefinedColors,
          handler: () => this.props.onRemoveCarrierOverride(charge)
        },
      ].filter(Boolean)
    });
  };

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  render() {
    const {
      carrier,
      rate,
      isOpen,
      onDismiss,
      onRefresh,
      onFieldChange,
      onAddCustomCharge
    } = this.props;

    const {
      sortBy,
      sortDir,
      searchValue,
      onSearchValueChange
    } = this.listController;

    return <IonModal
      key={`carrierChargeCustomizeModal ${carrier.id}`}
      cssClass="modal carrierChargeCustomizeModal large"
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <Observer>{() => (
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{UIText.rateManagement.carrierShippingCharges}</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon slot="icon-only" icon={closeSharp} />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <HeaderRefreshButton loading={this.loading} onRefresh={onRefresh} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}</Observer>

      <IonContent fullscreen className="ion-padding" scrollY={false}>
        <div className="flex column max">
          <ListControlToolbar
            searchValue={searchValue}
            onSearchChange={(e: any) => onSearchValueChange(getEventRealValue(e))}
            searchBarPlaceholder={UIText.searchStuff(UIText.rateManagement.carrierShippingCharges)}
          />

          <Observer>{() => (
            <div className="flex ion-wrap ion-justify-content-between ion-align-items-center textBold textLight font-s carrierInfo">
              <IonText className="ion-margin-end">
                {UIText.rateManagement.mode}:&nbsp;{((rate.shippingMode || {}).localeMode || {})[UIText.preference]}
              </IonText>
              <IonText>
                {UIText.rateManagement.carrier}:&nbsp;{getDisplayNameEng(carrier.profile)}
              </IonText>
            </div>
          )}</Observer>

          <IonCard className="max carrierChargeCustomizeTable" style={{ flex: 1 }}>
            <IonCardContent className="max ion-no-padding">
              <TableContainer component={props => <Paper {...props} square />} elevation={0}>
                <Table size="small" stickyHeader>
                  <Observer>{() => (
                    <SortableTableHead
                      columns={[
                        { name: "baseCode", text: UIText.rateManagement.baseCode },
                        { name: "carrierCode", text: UIText.rateManagement.carrierChargeSurchargeCode },
                        { name: "isSurcharge", text: UIText.rateManagement.surcharge },
                        { name: "chargeName", text: `${UIText.rateManagement.chargeName} (${UIText.preference})` },
                        { name: "description", text: `${UIText.generalDescription} (${UIText.preference})` },
                        { name: "add", noSort: true, component: <TableCell className="relative ion-activatable" onClick={onAddCustomCharge}>
                            <Tooltip
                              arrow
                              placement="bottom"
                              title={`${UIText.generalAdd} ${UIText.rateManagement.customShippingCharge.toLowerCase()}`}
                            >
                              <div className="flex ion-align-items-center ion-justify-content-center font-l">
                                <IonIcon icon={addOutline} color="primary" />
                              </div>
                            </Tooltip>
                            <IonRippleEffect />
                          </TableCell>},
                      ]}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      onSort={this.handleColumnSort}
                    />
                  )}</Observer>

                  <TableBody>
                    <ObserverList
                      list={this.sortedShippingCharges}
                      render={(charge: ShippingCharge) => <Observer key={charge.id}>{() => {
                        const readonly = charge.isCustom ? "" : "readonly";
                        return <TableRow>
                          <Observer>{() => (
                            <TableCell align="left" padding="none" className="readonly">
                              <IonInput
                                readonly
                                className="ion-no-padding"
                                name={`${charge.id}.baseCode`}
                                value={(charge.isCustom ? (charge.systemShippingCharge || {}).baseCode : charge.baseCode) || "—"}
                              />
                            </TableCell>
                          )}</Observer>

                          <Observer>{() => (
                            <TableCell align="left" padding="none" className={readonly}>
                              <IonInput
                                readonly={!!readonly}
                                className="ion-no-padding"
                                name={`${charge.id}.baseCode`}
                                value={(charge.isCustom && charge.baseCode) || (readonly && "—")}
                                onIonBlur={onFieldChange}
                              />
                            </TableCell>
                          )}</Observer>

                          <Observer>{() => (
                            <TableCell align="center" padding="none" className={`checkbox ${readonly}`}>
                              {readonly ? (
                                <IonInput
                                  readonly
                                  className="ion-no-padding"
                                  name={`${charge.id}.isSurcharge`}
                                  value={charge.isSurcharge ? UIText.generalYes : UIText.generalNo}
                                />
                              ) : (
                                <IonCheckboxWithReadonly
                                  className="ion-no-padding"
                                  name={`${charge.id}.isSurcharge`}
                                  checked={!!charge.isSurcharge}
                                  onIonChange={onFieldChange}
                                />
                              )}
                            </TableCell>
                          )}</Observer>

                          <Observer>{() => (
                            <TableCell align="left" padding="none" className={readonly}>
                              <TwoStageInput
                                endIcon={ellipsisHorizontal}
                                readonly={!!readonly}
                                className="ion-no-padding"
                                name={`${charge.id}.chargeName`}
                                value={charge.chargeName[UIText.preference] || (readonly && "—")}
                                onIonBlur={onFieldChange}
                              />
                            </TableCell>
                          )}</Observer>

                          <Observer>{() => (
                            <TableCell align="left" padding="none" className={readonly}>
                              <TwoStageInput
                                endIcon={ellipsisHorizontal}
                                readonly={!!readonly}
                                className="ion-no-padding"
                                name={`${charge.id}.description`}
                                value={charge.description[UIText.preference] || (readonly && "—")}
                                onIonBlur={onFieldChange}
                              />
                            </TableCell>
                          )}</Observer>

                          <Observer>{() => (
                            <TableCell
                              className="ion-activatable relative font-m ion-text-center"
                              padding="none"
                              onClick={e => this.handleChargeMenu(e, charge)}
                            >
                              <MdIcon
                                icon={mdiDotsVertical}
                                style={{ verticalAlign: "middle" }}
                              />
                              <IonRippleEffect />
                            </TableCell>
                          )}</Observer>
                        </TableRow>
                      }}</Observer>}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
              {/*<IonButtons className="ion-justify-content-center ion-margin-top">*/}
              {/*  <IonButton color="primary" className="textNoTransform" onClick={onAddCustomCharge}>*/}
              {/*    <IonIcon slot="start" icon={addOutline}/>{UIText.generalAdd}&nbsp;{UIText.rateManagement.customShippingCharge.toLowerCase()}*/}
              {/*  </IonButton>*/}
              {/*</IonButtons>*/}
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonModal>
  }
}

export default CarrierChargeCustomizeModal;