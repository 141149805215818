import { observer } from "mobx-react";
import React from "react";
import { ui } from "../../client/ui";
import { IonIcon, IonItem, IonItemDivider, IonList, IonText } from "@ionic/react";
import { scanOutline } from "ionicons/icons";
import { UIText } from "../../client/lang";
import FormField from "../FormFields";
import { ListControlFilterBarProps } from "../ListControlFilterBar";

@observer
class FilterItemList extends React.Component<Partial<ListControlFilterBarProps>> {
  onFilterClear = (event: any) => {
    this.props.onFilterClear(event);
    ui.dismissPopover();
  };

  render() {
    return <IonList className="ion-no-padding">
      <IonItem button onClick={this.onFilterClear} lines="none" color="primary">
        <IonIcon slot="start" icon={scanOutline} />
        <IonText className="textUpperCase font-xs">{UIText.clearFilters}</IonText>
      </IonItem>
      {this.props.filters.map((item, i) => (
        (item.name === "divider") ? (
          <IonItemDivider key={i} color="light" />
        ) : (
          <FormField
            key={item.name}
            field={{
              type: "checkbox",
              placeholder: item.placeholder,
              name: item.name,
              value: this.props.selectedFilters.includes(item.name)
            }}
            lines={i === this.props.filters.length - 1 ? "none" : undefined}
            onChange={this.props.onFilterSelect}
          />
        )
      ))}
    </IonList>;
  }
}

export default FilterItemList;