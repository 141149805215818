import { Redirect, Route } from "react-router";
import React from "react";
import RootHub from "../pages/RootHub";
import Register from "../pages/Register";
import Login from "../pages/Login";
import Reset from "../pages/Reset";
import RfqList from "../tila/app3win/pages/RfqList";
import Profile from "../pages/Profile";
import RfqSubmission from "../tila/app3win/pages/RfqSubmission";
import RfqDetailShipper from "../tila/app3win/pages/RfqDetailShipper";
import RfqDetailLsp from "../tila/app3win/pages/RfqDetailLsp";
import RfqInbox from "../tila/app3win/pages/RfqInbox";
import RateList from "../tila/app3win/pages/RateList";
import ShippingChargeList from "../tila/app3win/pages/ShippingChargeList";
import ShippingUnitList from "../tila/app3win/pages/ShippingUnitList";
import ShippingUocList from "../tila/app3win/pages/ShippingUocList";
import ShippingModeList from "../tila/app3win/pages/ShippingModeList";
import SystemCarrierList from "../tila/app3win/pages/SystemCarrierList";
import ShippingPortList from "../tila/app3win/pages/ShippingPortList";
import ComplexShippingRate from "../tila/app3win/pages/ComplexShippingRate";

export type RouteConfig = React.ComponentProps<typeof Route> & {
  name: string;
  isDefault?: boolean;
  isAuthDefault?: boolean;
  authRoute?: string | boolean;
}

export const routeConfigs: RouteConfig[] = [
  {
    name: "root",
    exact: true,
    isDefault: true,
    path: "/",
    component: RootHub
  },
  {
    name: "signUpRedirect",
    exact: true,
    path: "/SignUp",
    component: () => <Redirect to="/Register" />
  },
  {
    name: "register",
    exact: true,
    path: "/Register",
    component: Register,
    authRoute: true
  },
  {
    name: "login",
    exact: true,
    isAuthDefault: true,
    path: [
      "/Login",
      "/Logout",
      "/SignOut",
      "/Verification"
    ],
    component: Login,
    authRoute: true
  },
  {
    name: "reset",
    exact: true,
    path: "/Reset",
    component: Reset,
    authRoute: true
  },
  {
    name: "rfqList",
    exact: true,
    path: [
      "/Group",
      "/Group/:groupId",
      "/Group/:groupId/MyRFQ",
      "/Group/:groupId/RFQ"
    ],
    component: RfqList
  },
  {
    name: "profile",
    exact: true,
    path: [
      "/DefaultProfile",
      "/Group/:groupId/Profile/:id",
      "/Profile/:id/type/:setupId"
    ],
    component: Profile
  },
  {
    name: "inbox",
    exact: true,
    path: [
      "/Group/:groupId/Inbox",
      "/Group/:groupId/Inbox/:threadId",
    ],
    component: RfqInbox
  },
  {
    name: "rfqSubmission",
    exact: true,
    path: [
      "/RFQ/new",
      "/Group/:groupId/MyRFQ/new",
      "/Group/:groupId/MyRFQ/:id/Form"
    ],
    component: RfqSubmission,
    authRoute: "/RFQ/New"
  },
  {
    name: "rfqDetailShipper",
    exact: true,
    path: "/Group/:groupId/MyRFQ/:id",
    component: RfqDetailShipper
  },
  {
    name: "rfqDetailLsp",
    exact: true,
    path: "/Group/:groupId/RFQ/:id",
    component: RfqDetailLsp
  },
  {
    name: "rateList",
    exact: true,
    path: [
      "/Group/:groupId/Rates"
    ],
    component: RateList
  },
  {
    name: "complexShippingRate",
    exact: true,
    path: "/Group/:groupId/Rates/:id",
    component: ComplexShippingRate
  },
  {
    name: "shippingModeList",
    exact: true,
    path: "/ShippingModes",
    component: ShippingModeList
  },
  {
    name: "shippingChargeList",
    exact: true,
    path: "/ShippingCharges",
    component: ShippingChargeList
  },
  {
    name: "shippingUnitList",
    exact: true,
    path: "/ShippingUnits",
    component: ShippingUnitList
  },
  {
    name: "shippingUocList",
    exact: true,
    path: "/ShippingUocs",
    component: ShippingUocList
  },
  {
    name: "systemCarrierList",
    exact: true,
    path: "/ShippingCarriers",
    component: SystemCarrierList
  },
  {
    name: "shippingPortList",
    exact: true,
    path: "/ShippingPorts",
    component: ShippingPortList
  }
];

export const getRoutePath = route => Array.isArray(route.path)
  ? typeof route.authRoute === "string"
  ? route.authRoute
  : route.path[0]
  : route.path;

export const defaultRoute = {
  ...(() => {
    const route = routeConfigs.find(route => route.isDefault);
    return {
      ...route,
      path: getRoutePath(route)
    }
  })()
} as RouteConfig & {
  path: string;
};

export const defaultAuthRoute = {
  ...(() => {
    const route = routeConfigs.find(route => route.isAuthDefault);
    return {
      ...route,
      path: getRoutePath(route)
    }
  })()
} as RouteConfig & {
  path: string;
};