import React, { KeyboardEvent, MouseEvent } from "react";
import BaseColumn from "../BaseColumn";
import { UIText } from "../../../../client/lang";
import "./styles.less";
import { FreightService, Quotation, QuotationService, Requirement } from "../../lib/types/dataTypes";
import Accordion from "../../../../components/Accordion";
import { observer, Observer } from "mobx-react";
import { getPriceString, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import QuotationServiceCard from "../QuotationServiceCard";
import { IonButton, IonButtons, IonCard, IonCardHeader, IonIcon, IonInput, IonItem, IonLabel, IonText } from "@ionic/react";
import { addCircleOutline, addOutline, checkmarkOutline, ellipsisHorizontal, trashOutline } from "ionicons/icons";
import { getIonStyleVal } from "../../../../config/styles/style-utils";
import { RfqDetailColumnProps } from "../../lib/types/propTypes";
import { ui } from "../../../../client/ui";
import { getQuotationServicesPrice, sortQuotationServiceFixedOrder, sortQuotationServicesByDate } from "../../lib/common";
import { mdiSeal, mdiTextBoxPlusOutline, mdiTextBoxRemoveOutline } from "@mdi/js";
import MdIcon from "../../../../components/MdIcon";
import TwoStageInput from "../../../../components/TwoStageInput";

export interface QuotationsColumnLspProps extends RfqDetailColumnProps {
  requirements: Requirement[];
  quotationServices: QuotationService[];
  quotations: Quotation[];
  onRequirementCardClick?: (event: MouseEvent, id: Quotation["id"], requirementId: Requirement["id"]) => void;
  onRemoveFromQuotation?: (event: MouseEvent, id: QuotationService["id"]) => void;
  onRemoveQuotation?: (event: MouseEvent, id: Quotation["id"]) => void;
  onQuotationClick?: (event: MouseEvent, id: Quotation["id"]) => void;
  onNewQuotation?: (event: MouseEvent) => void;
  onNewQuotationNameChange?: (event: KeyboardEvent, id: Quotation["id"]) => void;
  onNewQuotationSave?: (event: MouseEvent, id: Quotation["id"]) => void;
  onQuotationSubmit?: (event: MouseEvent, id: Quotation["id"]) => void;
  onQuotationDataChange?: (event: CustomEvent, id: Quotation["id"]) => void;
  onQuotationDataSave?: (event: MouseEvent, id: Quotation["id"]) => void;
}

@observer
class QuotationsColumnLsp extends React.Component<QuotationsColumnLspProps> {
  getTotalPriceStr = (quotation: Quotation, quotationServices: QuotationService[]) => {
    const totalPrice: number = getQuotationServicesPrice(quotationServices);
    return getPriceString(totalPrice, null, true);
  };

  getQuotationTitle = (quotation: Quotation, quotationServices: QuotationService[]) => {
    const priceStr = this.getTotalPriceStr(quotation, quotationServices);
    return `${quotation.description} • ${priceStr}`;
  };

  getExpanded = (id: Quotation["id"]) => {
    const { isAccordionExpanded } = this.props;
    return isAccordionExpanded(id);
  };

  handleAccordionClick = (id: Quotation["id"]) => {
    const { onAccordionFold } = this.props;
    const isExpanded = this.getExpanded(id);
    if (onAccordionFold) return onAccordionFold(id, !isExpanded);
  };

  renderNewQuotationField = (quotation: Quotation, all) => {
    const {
      onRemoveQuotation,
      onNewQuotationNameChange,
      onNewQuotationSave
    } = this.props;
    const handleRemoveQuotation = event => onRemoveQuotation(event, quotation.id);
    const handleNameChange = event => onNewQuotationNameChange(event, quotation.id);
    const handleSave = event => onNewQuotationSave(event, quotation.id);
    return <Observer key="new">{() =>
      <IonCard
        className={`ion-margin quotationsColumnQuotation ${all.length > 1 ? "multi" : ""}`}
        style={{ backgroundColor: getIonStyleVal("--ion-color-light") }}
      >
        <IonCardHeader className="ion-no-padding flex ion-justify-content-between ion-align-items-center">
          <IonInput
            placeholder={UIText.quotationName}
            className="ion-no-padding ion-padding-start textDark font-s"
            style={{ minHeight: 36 }}
            onIonChange={handleNameChange}
            {...ui.bindKeyCode("onKeyUp", {
              13: handleSave
            })}
          />
          <IonButton
            className="ion-no-margin"
            fill="clear"
            // size={ui.isMobile ? "small" : null}
            onClick={handleSave}
          >
            <IonIcon slot="icon-only" color="primary" icon={checkmarkOutline}/>
          </IonButton>
          <IonButton
            className="ion-no-margin"
            fill="clear"
            // size={ui.isMobile ? "small" : null}
            onClick={handleRemoveQuotation}
          >
            <IonIcon slot="icon-only" color="danger" icon={trashOutline}/>
          </IonButton>
        </IonCardHeader>
      </IonCard>
    }</Observer>;
  };

  renderQuotations = (quotation: Quotation, i, all) => {
    const {
      readonly,
      onRemoveFromQuotation,
      onRemoveQuotation,
      onQuotationServiceCardClick,
      onRequirementCardClick,
      onQuotationSubmit,
      onQuotationDataChange,
      onQuotationDataSave,
      quotationServices,
      requirements
    } = this.props;
    if (quotation.new) return this.renderNewQuotationField(quotation, all);
    const handleAccordionClick = (event: MouseEvent<HTMLIonButtonElement>) => {
      preventDefaultStopProp(event);
      return this.handleAccordionClick(quotation.id);
    };
    const handleRemoveQuotation = (event: MouseEvent) => onRemoveQuotation && onRemoveQuotation(event, quotation.id);
    const handleSubmitQuotation = (event: MouseEvent) => onQuotationSubmit && onQuotationSubmit(event, quotation.id);
    let allQuotes = quotationServices.filter(qs => qs.quotationId === quotation.id);
    if (allQuotes.some(qs => qs.serviceType === "Freight" && (qs as FreightService).segments)) {
      allQuotes = allQuotes.filter(qs => qs.serviceType !== "Freight" || (qs as FreightService).segments);
    }
    const pendingRequirements = requirements.filter(rq =>
      !allQuotes.some(qs => qs.requirementId === rq.id) &&
      (rq.serviceType !== "Freight" || !allQuotes.some(qs => qs.serviceType === "Freight"))
    );
    const hasAwarded = allQuotes.some(q => q.isAwarded);
    return <Accordion
      key={quotation.id}
      className={`ion-margin quotationsColumnQuotation ${all.length > 1 ? "multi" : ""}`}
      color={hasAwarded ? "primary" : "light"}
      rightButton={
        <div className="flex ion-align-items-center">
          {!hasAwarded && !readonly && (
            <IonButton
              className="ion-no-margin"
              fill="clear"
              // size={ui.isMobile ? "small" : null}
              onClick={quotation.isSubmitted ? () => ui.comingSoon("Cancel quotation") : handleRemoveQuotation}
            >
              {quotation.isSubmitted
                ? <IonText color="danger" className="textBold">{UIText.submittedAt}</IonText>
                : <IonIcon slot="icon-only" color="danger" icon={trashOutline}/>}
            </IonButton>
          )}
          {!quotation.isSubmitted && allQuotes.length > 0 && (
            <IonButton
              className="ion-no-margin"
              color="primary"
              // size={ui.isMobile ? "small" : null}
              onClick={handleSubmitQuotation}
            >
              {/*<MdIcon icon={mdiEmailCheckOutline} color="light" />*/}
              {UIText.generalSubmit}
            </IonButton>
          )}
        </div>
      }
      backgroundColor={getIonStyleVal("--ion-color-light")}
      // title={this.getQuotationTitle(quotation, allQuotes)}
      title={quotation.description}
      expanded={this.getExpanded(quotation.id)}
      isMobile={ui.isMobile}
      onClick={handleAccordionClick}
    >
      <div className="ion-padding">
        {/*{quotation.isAuto && (*/}
        {/*  <IonButtons className="ion-justify-content-between baseColumnToolbar">*/}
        {/*    <IonText className="font-s" color="primary">{UIText.autoQuotation}</IonText>*/}
        {/*  </IonButtons>*/}
        {/*)}*/}
        <IonButtons className="ion-justify-content-between baseColumnToolbar">
          {!quotation.isSubmitted && <IonText className="font-s" color={quotation.isAllIn ? "primary" : "tooling"}>
            {quotation.isAllIn ? UIText.allInQuotation : UIText.notAllInQuotation}
          </IonText>}
          {quotation.isSubmitted && <IonText className="font-s" color="primary">
            {UIText.submittedQuotation}
          </IonText>}
          <IonButton className="noEvents">
            <IonText className="font-s" color="primary">
              {this.getTotalPriceStr(quotation, allQuotes)}
            </IonText>
          </IonButton>
        </IonButtons>
        {!isEmpty(allQuotes) && (
          allQuotes
          // .sort(sortQuotationServicesByDate)
          .sort(sortQuotationServiceFixedOrder)
          .map(quote => {
            const handleStarClick = event => onRemoveFromQuotation && onRemoveFromQuotation(event, quote.id);
            const handleCardClick = event => onQuotationServiceCardClick(event, quote.id);
            return <QuotationServiceCard
              key={quote.id}
              quote={quote}
              noStar={!quote.isAwarded && (quotation.isSubmitted || readonly)}
              starIcon={quote.isAwarded
                ? <span className="flex ion-align-items-center textNoTransform textBold font-xs textPrimary">
                  <MdIcon icon={mdiSeal} />&nbsp;{UIText.awarded}
                </span>
                : <MdIcon icon={mdiTextBoxRemoveOutline} color="danger" />
              }
              serviceType={quote.displayTitle}
              provider={`${UIText.requirement}: ${quote.serviceType}`}
              handleStarClick={!quotation.isSubmitted && handleStarClick}
              onClick={handleCardClick}
            />;
          })
        )}
        {!isEmpty(pendingRequirements) && !quotation.isSubmitted && !readonly && (
          pendingRequirements
          // .sort(sortRequirementsByDate)
          .sort(sortQuotationServiceFixedOrder)
          .map(requirement => {
            const handleCardClick = event => onRequirementCardClick(event, quotation.id, requirement.id);
            return <QuotationServiceCard
              key={requirement.id}
              serviceType={requirement.displayTitle || requirement.serviceType}
              starIcon={<MdIcon icon={mdiTextBoxPlusOutline} color="primary" />}
              handleStarClick={handleCardClick}
              onClick={handleCardClick}
            >
              <div className="quotationsColumnRequirementInsert flex ion-justify-content-center ion-align-items-center">
                <IonIcon icon={addCircleOutline} color="light" />
              </div>
            </QuotationServiceCard>;
          })
        )}
        <IonItem lines="full" color="light">
          <IonLabel position="floating">
            {UIText.remarks}
          </IonLabel>
          <TwoStageInput
            className="font-xs"
            readonly={quotation.isSubmitted}
            name="remarks"
            heading={`${UIText.remarks} - ${quotation.description}`}
            endIcon={ellipsisHorizontal}
            value={quotation.remarks}
            placeholder={quotation.isSubmitted ? UIText.noRemarksAvailable : undefined}
            onIonChange={e => onQuotationDataChange(e, quotation.id)}
          />
          {!quotation.isSubmitted && <IonButtons slot="end" className="max ion-no-margin">
            <IonButton color="primary" onClick={e => onQuotationDataSave(e, quotation.id)}>
              {UIText.generalSave}
            </IonButton>
          </IonButtons>}
        </IonItem>
      </div>
    </Accordion>;
  };

  render() {
    const {
      readonly,
      quotations,
      onNewQuotation
    } = this.props;

    const Header = <div className="flex ion-justify-content-between ion-align-items-center">
      <IonText className="font-m baseColumnTitle">{UIText.quotations}</IonText>
      {!readonly && <IonButton color="light" size="small" shape="round" onClick={onNewQuotation}>
        <IonIcon slot="icon-only" color="primary" icon={addOutline}/>
      </IonButton>}
    </div>;

    return <BaseColumn className="quotationsColumn max" header={Header}>
      <div className={`flex ion-align-items-start ion-wrap ${quotations.length > 1 ? "" : "ion-justify-content-center"}`}>
        {quotations
        .sort((a, b) => Number(!!a.new) - Number(!!b.new) > 0 ? -1 : 1)
        .sort(sortQuotationServicesByDate)
        .map(this.renderQuotations)}
      </div>
    </BaseColumn>
  }
}

export default QuotationsColumnLsp;