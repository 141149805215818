import { IonButton, IonButtons, IonContent, IonIcon, IonLoading, IonPage, IonText, withIonLifeCycle } from "@ionic/react";
import { computed, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import "./styles.less";
import { KeyValuePairs, StdErr } from "../../../../lib/types/miscTypes";
import { ui } from "../../../../client/ui";
import { UIText } from "../../../../client/lang";
import { RfqListController } from "./controller";
import Refresher from "../../../../components/Refresher";
import { Rfq } from "../../lib/types/dataTypes";
import RfqCard from "../../components/RfqCard";
import HeaderRefreshButton from "../../../../components/HeaderRefreshButton";
import { addOutline } from "ionicons/icons";
import { getEventRealValue, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import { RouteComponentProps } from "react-router";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import { defaultRoute } from "../../../../config/routes";
import { getGroupParty, getRfqDescriptionLsp, getRfqDescriptionShipper, sortRfqByProperty } from "../../lib/common";
import ListControlToolbar from "../../../../components/ListControlToolbar";
import { rfqFilterProperties, rfqSortProperties } from "../../config/constants";
import ListControlFilterBar from "../../../../components/ListControlFilterBar";
import { matchRfq } from "../../lib/matchers";
import { filterRfqBySelectedFilters } from "../../lib/filters";
import { ListController } from "../../../../lib/list-controller";

export interface RfqListProps extends RouteComponentProps<GroupSubRouteParams<{}>> {}

@observer
class RfqList extends React.Component<RfqListProps> {
  url: string;
  controller: RfqListController = {} as RfqListController;
  listController: ListController<KeyValuePairs<string>>;

  @observable loading: boolean = false;
  @observable rfqDraftRedirected: boolean = false;

  @computed get rfqs(): Rfq[] {
    return toJS(this.controller.rfqs) || [];
  };
  @computed get party(): ReturnType<typeof getGroupParty> {
    return this.controller.party;
  };
  get title(): string {
    return this.party === "shipper"
      ? UIText.myRFQs
      : this.party === "lsp"
      ? UIText.pendingRFQs
      : ""
  };
  get rfqListUrl(): string {
    const regex = /(\/MyRFQ|\/RFQ)?(\/$)?/ig;
    return `${this.url.replace(regex, "")}/${this.party === "lsp" ? "RFQ" : "MyRFQ"}`;
  };


  @computed get searchedRfqs(): Rfq[] {
    return this.rfqs.filter(rfq => matchRfq(rfq, this.listController.searchValue));
  };
  @computed get filteredRfqs(): Rfq[] {
    return this.searchedRfqs.filter(
      rfq => filterRfqBySelectedFilters(
        rfq,
        this.listController.selectedFilters,
        this.party === "shipper"
      )
    );
  };
  @computed get sortedRfqs(): Rfq[] {
    return this.filteredRfqs.sort(
      (a, b) => sortRfqByProperty(
        a,
        b,
        this.listController.sortBy as string,
        this.listController.sortDir,
        this.party === "shipper"
      )
    );
  };

  get sortProperties() {
    return rfqSortProperties.map(sort => ({
      name: sort,
      placeholder: UIText.rfqSortProperties[sort]
    }));
  }

  get filterProperties() {
    return rfqFilterProperties.map(filter => ({
      name: typeof filter === "string" && !filter ? "divider" : filter,
      placeholder: UIText.rfqFilterProperties[filter]
    }));
  }

  constructor(props) {
    super(props);
    this.url = props.match.url;
    this.controller = new RfqListController();
    this.listController = new ListController({
      storeName: "rfqListFilterSort",
      initialSortBy: "submissionDate"
    });
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    const { groupId } = this.props.match.params;
    if (isNaN(Number(groupId))) return this.props.history.replace(defaultRoute.path);
    this.controller.setGroupId(Number(groupId));
    return this.onRefresh();
  };

  ionViewWillLeave = () => this.controller.reset();

  onRefresh = (event?: any) => {
    this.loading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleNewRfq = (event: any) => {
    preventDefaultStopProp(event);
    return ui.alert({
      header: UIText.newRFQ,
      inputs: [{
        name: "description",
        placeholder: UIText.rfqDescription,
      }],
      enterKeyHandler: this.handleNewRfqConfirm,
      buttons: [
        {
          text: "See RFQ Form [WIP]",
          handler: this.handleNewRfqForm
        },
        {
          role: "cancel",
          text: UIText.generalCancel
        },
        {
          text: UIText.generalConfirm,
          handler: this.handleNewRfqConfirm
        }
      ]
    });
  };

  handleNewRfqForm = (event: any) => {
    preventDefaultStopProp(event);
    this.props.history.push(`${this.rfqListUrl}/new`);
  };

  handleNewRfqConfirm = (data: { description: string }) => {
    if (isEmpty(data)) return;
    const { description } = data;
    this.loading = true;
    return this.controller.onNewRfq(description)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  renderHeading = () =>
    <div className="flex ion-padding ion-align-items-center font-l textLight">
      <span className="ion-margin-end textBold">{this.title}</span>
      {this.party === "shipper" && (
        <IonButton onClick={this.handleNewRfqForm}>
          <IonText className="textBold">{UIText.generalNew}</IonText>
          <IonIcon slot="end" icon={addOutline} />
        </IonButton>
      )}
    </div>;

  render() {
    const {
      sortBy,
      sortDir,
      searchValue,
      onSearchValueChange,
      onSortByChange,
      onSortDirChange,
      selectedFilters,
      onFilterSelect,
      onFilterClear
    } = this.listController;

    return (
      <IonPage className="headerOffset">
        <IonContent fullscreen className="ion-padding">
          <Refresher customAppearance color="light" onRefresh={this.onRefresh} isMobile={ui.isMobile} />
          <IonLoading translucent isOpen={this.loading} />

          <div className="flex column container">
            <div className="flex ion-align-items-center ion-justify-content-between ion-padding-end ion-margin-bottom">
              {this.renderHeading()}
              {!ui.isMobile && <IonButtons>
                <HeaderRefreshButton
                  color="light"
                  spinnerColor="light"
                  fill="clear"
                  // icon={refreshCircleOutline}
                  loading={this.loading}
                  onRefresh={this.onRefresh}
                />
              </IonButtons>}
            </div>

            <ListControlToolbar
              className="ion-margin-bottom"
              searchValue={searchValue}
              onSearchChange={(e: any) => onSearchValueChange(getEventRealValue(e))}
              searchBarPlaceholder={UIText.searchStuff(UIText.RFQKeyWords)}
            />

            <ListControlFilterBar
              className="ion-margin-vertical ion-padding-horizontal"
              sorts={this.sortProperties}
              sortDir={sortDir}
              sortBy={sortBy as string}
              count={this.sortedRfqs.length}
              onSortByChange={onSortByChange}
              onSortDirChange={onSortDirChange}
              filters={this.filterProperties}
              selectedFilters={selectedFilters}
              onFilterSelect={onFilterSelect}
              onFilterClear={onFilterClear}
            />

            {!this.loading && this.sortedRfqs.map(rfq =>
              <RfqCard
                key={rfq.id}
                party={this.party}
                description={
                  this.party === "lsp"
                  ? getRfqDescriptionLsp(rfq)
                  : getRfqDescriptionShipper(rfq)
                }
                rfq={rfq}
                routerLink={`${this.rfqListUrl}/${rfq.id}`}
              />
            )}
          </div>
        </IonContent>

      </IonPage>
    );
  }
}

export default withIonLifeCycle(RfqList);