import { GroupSetupConfig } from "../../types/setupTypes";
import { groupTypeIds, groupTypeRoleIds, typeClassIds } from "../../../../../config/constants";
import { Form } from "../../../../../client/form";
import { UIException, UIText } from "../../../../../client/lang";
import { client } from "../../../../../client/client";
import { Group, ProfilePrimitive } from "../../../../../lib/types/dataTypes";
import { getDisplayNameEng, isEmpty } from "../../../../../utils/helpers";
import { createGroupProfile, createSelfMember, getGroupRoles, setLastSetupGroupId } from "../../../../../lib/group-utilities";
import { stateCtrl } from "../../../../../client/state";

export const shipperGroupSetup: GroupSetupConfig = {
  id: 2,
  typeClassId: typeClassIds.shipperProfile.v1.id,
  get header() { return `${UIText.shipper} ${UIText.profile}` },
  get subHeader() { return UIText.createShipperProfile },
  metadata: { groupTypeRoleId: groupTypeRoleIds.shipper },
  preSetup: (form: Form) => {
    form.set("firstName", (client.defaultProfile.data || {}).firstName);
    form.set("lastName", (client.defaultProfile.data || {}).lastName);
    form.set("email", (client.defaultProfile.data || {}).email);
    form.set("phone", (client.defaultProfile.data || {}).phone);
  },
  onSubmit: async (form: Form) => {
    const pass = form.validate();
    if (!pass) return;
    if (!form || isEmpty(form.data)) {
      return Promise.reject(new UIException("INVALID_SHIPPER_PROFILE_SUBMISSION"));
    }
    const group: Partial<Group> = {
      owner: client.userId,
      parentId: null,
      groupName: getDisplayNameEng(form.data),
      typeId: groupTypeIds.shipper
    };
    const profile: ProfilePrimitive = {
      type: 3,
      typeClassId: typeClassIds.shipperProfile.v1.id,
      typeClassVersion: typeClassIds.shipperProfile.v1.version,
      data: form.toJSON()
    };
    return createGroupProfile(group, profile, groupTypeRoleIds.shipper)
    .then(setLastSetupGroupId)
    .then(getGroupRoles)
    .then(createSelfMember)
    .then(client.getAndStoreUserGroupsAndMembers);
  },
  get postSetupUrl() { return `/Group/${stateCtrl.lastSetupGroupId}` }
};