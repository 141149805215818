// For future configurable flags.
import { observable } from "mobx";

const flags = observable({
  storageCleanupDate: 1619680930862,
  xBaseSwitch: "",
  renewDisable: false,
  txDisable: false,
  txPollingDisable: true,
  deepRenew: false,
  deepRenewExpiresIn: undefined
});

export default flags;