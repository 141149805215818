import React from "react";
import RateDataViewController from "./controller";
import { computed, observable } from "mobx";
import { PortSelectionPropertyNames } from "../../lib/types/propTypes";
import {
  Carrier,
  ShippingCharge,
  ShippingPlan,
  ShippingRate,
  ShippingRateCharge,
  ShippingRateChargeItem,
  ShippingRateUnit,
} from "../../lib/types/rateTypes";
import { arrayFlat, getEventRealValue, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import { ui } from "../../../../client/ui";
import { UIText } from "../../../../client/lang";
import { observer } from "mobx-react";

@observer
class RateDataView<Props, Controller extends RateDataViewController<any>> extends React.Component<Props> {
  controller: Controller = {} as Controller;

  @observable loading: boolean = false;
  @observable heavyLoading: boolean = false;

  @computed get plans(): ShippingPlan[] {
    return this.controller.plans.filter(Boolean);
  };
  @computed get rates(): ShippingRate[] {
    return arrayFlat(this.plans.map(plan => plan.shippingRates));
  };

  @observable portModalRateId: number;
  @observable portModalPropertyName: PortSelectionPropertyNames;
  @computed get portModalRate(): ShippingRate {
    return this.rates.find(r => r.id === this.portModalRateId) || {} as ShippingRate;
  };
  @computed get portModalOpen(): boolean {
    return !!this.portModalRateId && !!this.portModalPropertyName;
  };

  @observable chargeModalRateId: number;
  @computed get chargeModalRate(): ShippingRate {
    return this.rates.find(r => r.id === this.chargeModalRateId) || {} as ShippingRate;
  };
  @computed get chargeModalOpen(): boolean {
    return !!this.chargeModalRateId;
  };
  @computed get chargeModalAllChargesSelected(): boolean {
    return this.controller.isAllShippingChargesSelected(this.chargeModalRate);
  };
  @computed get chargeModalCarrier(): Carrier {
    return this.chargeModalRate.carrierGroup || {} as Carrier;
  };

  @observable carrierModalRateId: number;
  @computed get carrierModalRate(): ShippingRate {
    return this.rates.find(r => r.id === this.carrierModalRateId) || {} as ShippingRate;
  };
  @computed get carrierModalOpen(): boolean {
    return !!this.carrierModalRateId;
  };

  @observable chargeCustomizeModalRateId: number;
  @computed get chargeCustomizeModalRate(): ShippingRate {
    return this.rates.find(r => r.id === this.chargeCustomizeModalRateId) || {} as ShippingRate;
  };
  @computed get chargeCustomizeModalOpen(): boolean {
    return !!this.chargeCustomizeModalRateId;
  };
  @computed get chargeCustomizeModalCarrier(): Carrier {
    return this.chargeCustomizeModalRate.carrierGroup || {} as Carrier;
  };


  constructor(props, controller: Controller) {
    super(props);
    this.controller = controller;
  }

  public showError: (...args: any[]) => Promise<any>;

  onLoading = (heavy?: boolean) => {
    if (heavy) this.heavyLoading = true;
    this.loading = true;
  };

  doneLoading = () => this.loading = this.heavyLoading = false;

  onRefresh = (event?: any) => {
    this.loading = this.heavyLoading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = false);
  };

  // ionViewWillLeave = () => this.controller.cleanup();


  handlePlanFieldChange = (event: any, plan: ShippingPlan) => {
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const updated = this.controller.onPlanFieldChange(plan, name, value);
    return updated && this.updatePlanFieldAsync(plan.id, name, value);
  };

  updatePlanFieldAsync = async (planId: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdatePlan(planId, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };



  handleRateFieldChange = (event: any, rate: ShippingRate) => {
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const handler = () => {
      this.controller.onRateFieldChange(rate, name, value);
      return this.updateRateFieldAsync(rate.id, name, value);
    };
    if (name === "modeId") {
      if (Number(value) !== Number(rate.modeId)) {
        if (!rate.polPortId && !rate.podPortId && !rate.carrierId) return handler();
        const unchangedModeId = rate.modeId;
        this.controller.onRateFieldChange(rate, name, value);
        this.controller.onRateFieldChange(rate, name, unchangedModeId);
        return ui.reconfirm({
          header: UIText.rateManagement.mode,
          prelude: UIText.rateManagement.reconfirmModeChange,
          verb: UIText.generalChange,
          name: UIText.rateManagement.mode,
          handler
        });
      }
      return;
    }
    return handler();
  };

  updateRateFieldAsync = async (rateId: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateRate(rateId, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };



  handleAddRateUnit = (event: any, rate: ShippingRate, breakdownId: number) => {
    preventDefaultStopProp(event);
    this.loading = this.heavyLoading = true;
    return this.controller.onAddRateUnit(rate.id, breakdownId)
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = false);
  };

  handleRemoveRateUnit = (event: any, id: ShippingRateUnit["id"]) => {
    preventDefaultStopProp(event);
    const rate = this.controller.rates.find(r => r.shippingRateUnits.some(ru => ru.id === id));
    if (!rate) return;
    const rateUnit = rate.shippingRateUnits.find(ru => ru.id === id);
    const localeUnitName =
      (((rateUnit.shippingUnit || {}).shippingRateBreakdown || {}).localeUnitName || {})[UIText.preference];
    const handler = () => this.handleRemoveRateUnitConfirm(event, id);
    if (isEmpty(rateUnit.shippingUnit)) return handler();
    return ui.reconfirm({
      header: `${UIText.generalDelete} ${localeUnitName}`,
      name: `${localeUnitName} ${(rateUnit.shippingUnit.unitName || {})[UIText.preference]}`,
      verb: UIText.generalDelete.toLowerCase(),
      handler
    });
  };

  handleRemoveRateUnitConfirm = (event: any, id: ShippingRateUnit["id"]) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onRemoveRateUnit(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRateUnitFieldChange = (event: any, rateUnit: ShippingRateUnit) => {
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const updated = this.controller.onRateUnitFieldUpdate(rateUnit, name, value);
    return updated && this.updateRateUnitFieldAsync(rateUnit.id, name, value);
  };

  updateRateUnitFieldAsync = async (rateUnitId: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateRateUnit(rateUnitId, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };



  handlePortModalOpen = (event: any, rateId: number, property: PortSelectionPropertyNames) => {
    this.portModalRateId = rateId;
    this.portModalPropertyName = property;
  };

  handlePortModalClose = event => {
    this.portModalRateId = undefined;
    this.portModalPropertyName = undefined;
  };

  handlePortModalSelect = async (shippingPortId: number) => {
    this.loading = true;
    return this.controller.onUpdateRate(
      this.portModalRateId,
      this.portModalPropertyName,
      shippingPortId
    )
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleChargeMenu = (event: any, rateId: number) => {
    event.persist();
    const rate = this.rates.find(r => r.id === rateId) || {} as ShippingRate;
    return ui.popoverMenu({
      event,
      menuItems: [
        {
          text: UIText.rateManagement.selectChargesSurcharges,
          color: "primary",
          fill: "solid",
          handler: () => this.handleChargeModalOpen(null, rateId)
        },
        {
          text: UIText.rateManagement.carrierShippingCharges,
          cssClass: !rate.carrierId && "noEvents item-disabled",
          handler: () => this.handleChargeCustomizeModalOpen(null, rateId)
        },
      ]
    })
  };

  handleChargeModalOpen = (event: any, rateId: number) => {
    this.chargeModalRateId = rateId;
    this.controller.setCurrentCarrierId(this.chargeModalRate.carrierId);
  };

  handleChargeModalClose = event => {
    this.chargeModalRateId = undefined;
    this.controller.setCurrentCarrierId(undefined);
  };

  handleChargeModalCheckboxSelect = (event: any, charge: ShippingCharge) => {
    preventDefaultStopProp(event);
    const { isShippingChargeSelected } = this.controller;
    if (!isShippingChargeSelected(this.chargeModalRate, charge)) {
      return this.handleAddRateCharge(event, charge);
    } else {
      const rateCharge: ShippingRateCharge = this.chargeModalRate.shippingRateCharges.find(
        src => src.shippingChargeId === charge.id
      );
      if (!rateCharge) return;
      return this.handleRemoveRateCharge(event, rateCharge.id);
    }
  };

  handleChargeModalCheckboxSelectAll = (event: any) => {
    preventDefaultStopProp(event);
    this.loading = this.heavyLoading = true;
    return (async () => {
      if (!this.chargeModalAllChargesSelected) {
        return this.controller.onAddAllRateCharges(this.chargeModalRateId);
      } else {
        return this.controller.onRemoveAllRateCharge(this.chargeModalRateId);
      }
    })()
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = false);
  };



  handleCarrierModalOpen = (event: any, rateId: number) => this.carrierModalRateId = rateId;

  handleCarrierModalClose = event => this.carrierModalRateId = undefined;

  handleCarrierModalSelect = async (carrierId: number) => {
    this.loading = true;
    return this.controller.onCarrierSelect(this.carrierModalRateId, carrierId)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };



  handleChargeCustomizeModalOpen = (event: any, rateId: number) => {
    this.chargeCustomizeModalRateId = rateId;
    this.controller.setCurrentCarrierId(this.chargeCustomizeModalRate.carrierId);
  };

  handleChargeCustomizeModalClose = event => {
    this.chargeCustomizeModalRateId = undefined;
    this.controller.setCurrentCarrierId(undefined);
  };

  handleCreateCarrierCustomCharge = async () => {
    this.loading = this.heavyLoading = true;
    return this.controller.onCreateCarrierCustomCharge(this.chargeCustomizeModalRate.id)
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = false);
  };

  handleDuplicateCarrierCustomCharge = async (chargeToDuplicate: ShippingCharge) => {
    this.loading = this.heavyLoading = true;
    return this.controller.onDuplicateCarrierCharge(chargeToDuplicate, this.chargeCustomizeModalRate.id)
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = false);
  };

  handleCreateCarrierChargeOverride = async (systemCharge: ShippingCharge) => {
    this.loading = this.heavyLoading = true;
    return this.controller.onCreateCarrierChargeOverride(systemCharge, this.chargeCustomizeModalRate.id)
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = false);
  };

  handleRemoveCarrierChargeOverride = async (carrierCharge: ShippingCharge) => {
    this.loading = this.heavyLoading = true;
    return this.controller.onRemoveCarrierCharge(carrierCharge)
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = false);
  };

  handleCarrierChargeFieldChange = (event: any) => {
    preventDefaultStopProp(event);
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const id = name.split(".")[0];
    const field = name.split(".")[1];
    // this.controller.onShippingChargeFieldUpdate(id, field, value);
    return this.updateCarrierChargeFieldAsync(id, field, value);
  };

  updateCarrierChargeFieldAsync = async (id: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateCarrierCharge(id, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };



  handleAddRateCharge = (event: any, charge: ShippingCharge) => {
    preventDefaultStopProp(event);
    this.loading = this.heavyLoading = true;
    return this.controller.onAddRateCharge(this.chargeModalRate, charge)
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = false);
  };

  handleRemoveRateCharge = (event: any, rateChargeId: ShippingRateCharge["id"]) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onRemoveRateCharge(rateChargeId)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRateChargeFieldChange = (event: any, rateCharge: ShippingRateCharge) => {
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const updated = this.controller.onRateChargeFieldUpdate(rateCharge, name, value);
    return updated && this.updateRateChargeFieldAsync(rateCharge.id, name, value);
  };

  updateRateChargeFieldAsync = async (rateChargeId: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateRateCharge(rateChargeId, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRateChargeItemFieldChange = (event: any, rateChargeItem: ShippingRateChargeItem) => {
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const updated = this.controller.onRateChargeItemFieldUpdate(rateChargeItem, name, value);
    return updated && this.updateRateChargeItemFieldAsync(rateChargeItem.id, name, value);
  };

  updateRateChargeItemFieldAsync = async (rateChargeItemId: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateRateChargeItem(rateChargeItemId, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };
}

export default RateDataView;