import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { IonContent, IonPage, withIonLifeCycle } from "@ionic/react";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import { RootHubController } from "./controller";
import { SetupConfig } from "../../lib/types/setupTypes";
import { observable } from "mobx";
import { groupTypeIds } from "../../config/constants";
import { isEmpty } from "../../utils/helpers";
import { StdErr } from "../../lib/types/miscTypes";
import { ui } from "../../client/ui";
import { UIText } from "../../client/lang";
import { defaultAuthRoute } from "../../config/routes";

export interface RootHubProps extends RouteComponentProps {}

@observer
class RootHub extends React.Component<RootHubProps> {
  controller: RootHubController = {} as RootHubController;

  @observable rfqDraftRedirected: boolean = false;

  constructor(props) {
    super(props);
    this.controller = new RootHubController();
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || UIText.title
    });

  ionViewWillEnter = async () => {
    if (!this.controller.isLoggedIn) return this.props.history.push(defaultAuthRoute.path);
    const needSetup: [SetupConfig["id"], boolean] = await this.controller.getUserLastPendingStep();
    const setupConfigId = needSetup[0], alreadyPartialSetup = needSetup[1];
    if (setupConfigId && !alreadyPartialSetup) {
      return this.props.history.replace(`/Profile/new/type/${setupConfigId}`);
    }
    return this.navigateUserDefaultHome();
  };

  navigateUserDefaultHome = () =>
    this.controller.getLastGroup()
    .then(async group => {
      if (!group) return;
      if (group.typeId === groupTypeIds.shipper && !this.rfqDraftRedirected) {
        const drafts = await this.controller.getRfqDrafts(group.id).catch(this.showError);
        if (!isEmpty(drafts) && drafts[0]) {
          this.rfqDraftRedirected = true;
          return this.props.history.push(`/Group/${group.id}/MyRFQ/${drafts[0].id}/Form`);
        }
      }
      return this.props.history.replace(`/Group/${group.id}`); // Temporary until we have designated homes.
    });

  render() {
    return <IonPage className="headerOffset">
      <IonContent fullscreen>
        <FullScreenSpinner color="light" />
      </IonContent>
    </IonPage>
  }
}

export default withIonLifeCycle(RootHub);