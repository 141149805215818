import { FreightService, Quotation, QuotationService, Requirement, Rfq } from "../../lib/types/dataTypes";
import { computed, observable, toJS } from "mobx";
import { topicCtrl } from "../../../../client/topic";
import {
  findRfqItemTopics,
  getFreightRequirementTopicTemplate,
  getRfqItemTopics,
  topicToQuotation,
  topicToQuotationService,
  topicToRequirement,
  topicToRfqWithShipperName,
} from "../../lib/common";
import { Group, Member } from "../../../../lib/types/dataTypes";
import { topicTypeIds, typeClassIds } from "../../../../config/constants";
import { api } from "../../../../client/api";
import { endpointConfig } from "../../../../config/api";
import { UIException, UIText } from "../../../../client/lang";
import { arrayFlat, getDisplayNameEng, isEmpty, isNonZeroFalse, randomString } from "../../../../utils/helpers";
import { KeyValuePairs } from "../../../../lib/types/miscTypes";
import { RfqFormDTO } from "../../lib/types/rfqFormTypes";
import { stateCtrl } from "../../../../client/state";
import { client } from "../../../../client/client";
import { Controller } from "../../../../lib/controller";
import { CreateTopicDTO, GroupThread, Thread, Topic, TopicPrimitive } from "../../../../lib/types/topicTypes";
import { msgCtrl } from "../../../../client/msg";
import QuotationServiceFreightForm from "../../components/QuotationServiceFreightForm";

export class RfqDetailLspController extends Controller {
  @observable id: Rfq["id"];
  @observable private _quotations: Quotation[] = [];
  @observable private _temporaryTopics: Partial<Topic<QuotationService>>[] = [];

  // Controller Data
  @computed get group(): Group {
    return stateCtrl.currentGroup;
  };
  @computed get member(): Member {
    return client.findMyMemberByGroupId(this.group.id);
  };

  @computed get groupThread(): GroupThread {
    return msgCtrl.groupThreads.find(gt => gt.groupId === this.group.id) || {} as GroupThread;
  };
  @computed get threads(): Thread[] {
    return (this.groupThread.threads || []).filter(tr => tr.topicId === this.rfqTopic.id);
  };
  @computed get shipperChatThread(): Thread {
    return this.threads.find(tr => tr.topicId === this.id && tr.memberIdList.includes(this.rfqTopic.creatorMemberId));
  };

  @computed get providerName(): string {
     // TODO: This is for quotation services and quotation to show the shipper,
     // but eventually it should not be as part of topic data,
     // rather it should be pulled dynamically from group profile.
    return getDisplayNameEng(this.group.profile) || UIText.unknownProvider;
  };

  @computed get rfqTopic(): Topic {
    return topicCtrl.findTopicById(this.id) as Topic;
  }
  @computed get rfq(): Rfq {
    return topicToRfqWithShipperName(this.rfqTopic as Topic<RfqFormDTO>);
  };
  @computed get rfqItemTopics(): Topic[] {
    const topics = findRfqItemTopics(this.id, this.group.id);
    return topics.concat(topicCtrl.flattenSubTopics(topics));
  };
  @computed get requirementTopics(): Topic<Requirement<{ pod: string; pol: string }>>[] {
    const topics = topicCtrl.findTopics(
      t => t.typeId === topicTypeIds.requirement
        && t.parentId === this.id
    );
    return [
      getFreightRequirementTopicTemplate(this.rfq, -1),
      ...topics
    ].filter(Boolean) as Topic<Requirement<{ pod: string; pol: string }>>[];
  };
  @computed get requirements(): Requirement[] {
    return this.requirementTopics.map(topicToRequirement);
  };
  @computed get quotationTopics(): Topic<Quotation>[] {
    return this.rfqItemTopics.filter(
      t => t.typeId === topicTypeIds.quotation
    ) as Topic<Quotation>[];
  };
  @computed get quotations(): Quotation[] {
    const quotations = [
      ...this.quotationTopics.map(topicToQuotation),
      ...this._quotations
    ];
    const requirements = this.requirements;
    return toJS(quotations).map(q => ({
      ...q,
      get isAllIn() {
        return q.quotationServiceIds.length >= requirements.length
      }
    }))
  };
  @computed get quotationServices(): QuotationService[] {
    return topicCtrl.flattenSubTopics(this.quotationTopics)
    .map(topicToQuotationService);
  };
  @computed get rfqFormDTO(): RfqFormDTO {
    return (this.rfqTopic as Topic<RfqFormDTO>).data;
  };

  // Controller methods
  setId = async (id: Rfq["id"]) => {
    if (!id) return;
    this.id = id;
    if (isEmpty(this.group)) return;
    return this.loadAllData(id);
  };

  loadAllData = async id => Promise.all([
    this.clearLocal(),
    this.getRfq(),
    this.getRfqItems(),
    this.getRfqThreads()
  ]);

  clearLocal = () => {
    this._quotations = [];
  };

  getRfq = async () => topicCtrl.getTopicById(this.id).then(topicCtrl.updateTopic);
  // getRfq = async () => topicCtrl.getTopicById(this.id, this.member.id).then(topicCtrl.updateTopic);

  getRfqItems = async () => getRfqItemTopics({ id: this.id } as Rfq);

  getRfqThreads = async () =>
    msgCtrl.getThreadsAndMessagesByTopicId(this.id, this.member.id)
    .then(threads => msgCtrl.updateGroupThreads(this.group.id, arrayFlat(threads).filter(Boolean)));

  getLastQuotationCount = async () =>
    api.GET(endpointConfig.group_quotation_count(this.group.id))
    .then(response => response.data);

  getShipperChatThread = async () => {
    if (!isEmpty(this.shipperChatThread)) return this.shipperChatThread;
    return api.GET(endpointConfig.check_shipper_lsp_chat_thread(this.id, this.member.id))
    .then(response => {
      const thread = response.data;
      if (!thread) return null;
      this.groupThread.threads.push(thread);
      return this.shipperChatThread;
    });
  };

  // onAddQuotationService = async (id: Requirement["id"], quotationId: Quotation["id"]) => {
  //   const requirementTopic = topicCtrl.findTopicById(id);
  //   if (isEmpty(requirementTopic)) return;
  //   const form = new Form(requirementTopic.typeClassId, requirementTopic.data);
  //   await form.isReady();
  //   const quotationServiceTopic: TopicPrimitive = {
  //     description: `${UIText.service}_${new Date().getTime()}`,
  //     isParentTemplate: 0,
  //     isTemplate: 0,
  //     parentId: quotationId,
  //     typeClassId: requirementTopic.typeClassId,
  //     typeClassVersion: requirementTopic.typeClassVersion,
  //     typeId: topicTypeIds.quotationService,
  //     data: form.toJSON()
  //   };
  //   const data = { topic: quotationServiceTopic };
  //   return api.POST({
  //     endpoint: endpointConfig.create_topic_plain,
  //     data
  //   })
  //   .then(this.getRfqItemTopics);
  // };

  isQuotationServiceSubmitted = (id: QuotationService["id"]): boolean => {
    const quotationService = this.quotationServices.find(qs => qs.id === id);
    if (!quotationService) return false;
    const quotation = this.quotations.find(q => q.id === quotationService.quotationId);
    if (!quotation) return false;
    return quotation.isSubmitted;
  };

  findFreightServiceLegs = (id: QuotationService["id"]): QuotationService<FreightService>[] => {
    const quotationService: QuotationService<FreightService> = this.quotationServices.find(qs => qs.id === id);
    if (!quotationService) return [];
    const { segments } = quotationService;
    if (!segments) return [toJS(quotationService)];
    return segments.map(legId => toJS(this.quotationServices.find(qs => qs.id === legId)));
  };

  onRemoveQuotationService = async (id: QuotationService["id"]) =>
    // topicCtrl.deleteTopicById(id).then(this.getRfqItems);
    topicCtrl.hardDeleteTopicById(id).then(this.getRfqItems);

  onNewQuotation = async () => {
    const last: Quotation = toJS(this.quotations).sort((a, b) => a.id - b.id)[this.quotations.length - 1];
    const lastId: number = (last || { id: 0 }).id;
    const count = await this.getLastQuotationCount().catch(console.warn);
    // const description = count ? `${UIText.quotation} # ${(Number(count) + 1).toString().padStart(7)}` : `${UIText.generalNew} ${UIText.quotation}`;
    const description = isNonZeroFalse(count) ? `Cannot get last quotation count` : `${UIText.quotation} # ${(Number(count) + 1).toString().padStart(8, "0")}`;
    const tempNewQuotation = {
      id: lastId + 1,
      quotationServiceIds: [],
      new: true,
      description
    };
    this._quotations.push(tempNewQuotation);
    return tempNewQuotation;
  };

  onNewQuotationNameChange = async (id: Quotation["id"], value: string) => {
    const quotation: Quotation = this.quotations.find(q => q.id === id);
    if (!quotation) return;
    return quotation.description = value;
  };

  onNewQuotationSave = async (id: Quotation["id"]) => {
    const quotation: Quotation = this.quotations.find(q => q.id === id);
    if (!quotation || !quotation.new || !quotation.description) return;
    const quotationTopic: TopicPrimitive = {
      description: quotation.description,
      isParentTemplate: 0,
      isTemplate: 0,
      parentId: this.rfq.id,
      typeClassId: typeClassIds.quotation.v1.id,
      typeClassVersion: typeClassIds.quotation.v1.version,
      typeId: topicTypeIds.quotation,
      data: JSON.stringify({})
    };
    const data = {
      currentGroupId: this.group.id,
      topic: quotationTopic
    };
    return api.POST({
      endpoint: endpointConfig.create_topic,
      data
    })
    .then(this.getRfqItems)
    .then(() => this.onRemoveNewQuotation(id));
  };

  onNewTempQuotationService = (quotationId: Quotation["id"], requirementId: Requirement["id"]): Partial<Topic<QuotationService>> => {
    const requirementTopic = this.requirementTopics.find(rq => rq.id === requirementId);
    const quotation = this.quotations.find(q => q.id === quotationId);
    if (!quotation) throw new UIException("QUOTATION_NOT_FOUND");
    const tempTopic: TopicPrimitive = {
      id: randomString(),
      description: `${requirementTopic.data.serviceType}_${UIText.service}_${new Date().getTime()}`, // TODO: Temporary
      isParentTemplate: 0,
      isTemplate: 0,
      parentId: quotationId,
      typeClassId: requirementTopic.typeClassId,
      typeClassVersion: requirementTopic.typeClassVersion,
      typeId: topicTypeIds.quotationService,
      startTime: requirementTopic.startTime,
      data: JSON.stringify({
        ...requirementTopic.data,
        requirementTopicId: requirementTopic.id
      }),
    };
    this._temporaryTopics.push(tempTopic);
    return tempTopic;
  };

  onTempTopicDiscard = (id: TopicPrimitive["id"]) => {
    const index = this._temporaryTopics.findIndex(t => t.id === id);
    if (index < 0) return;
    console.log("Discarded", id);
    return this._temporaryTopics.splice(index, 1);
  };

  onTempTopicSave = async (id: TopicPrimitive["id"], inputData: KeyValuePairs) => {
    const tempTopic = this._temporaryTopics.find(t => t.id === id);
    if (!tempTopic) return;
    const data = {
      currentGroupId: this.group.id,
      topic: {
        ...tempTopic,
        data: JSON.stringify({
          ...inputData,
          provider: this.providerName
        })
      }
    };
    delete data.topic.id;
    return api.POST({
      endpoint: endpointConfig.create_topic,
      data
    })
    .then(this.getRfqItems);
  };

  onRemoveQuotation = async (id: Quotation["id"]) => {
    const quotation = this.quotations.find(q => q.id === id);
    if (!quotation) return;
    if (quotation.new) return this.onRemoveNewQuotation(id);
    // return topicCtrl.deleteTopicById(id);
    return topicCtrl.hardDeleteTopicById(id);
  };

  onRemoveNewQuotation = (id: Quotation["id"]) => {
    const index: number = this._quotations.findIndex(s => s.id === id);
    if (index < 0) return;
    return this._quotations.splice(index, 1);
  };

  onQuotationSubmit = (id: Quotation["id"]) => {
    const quotation = this.quotations.find(q => q.id === id);
    if (!quotation) return;
    const data: Partial<Topic> = {
      isDataLocked: 1,
      typeId: topicTypeIds.quotation
    };
    return api.PATCH({
      endpoint: endpointConfig.topic_by_id(id),
      data
    })
    .then(this.getRfqItems);
  };

  onQuotationDataChange = (id: Quotation["id"], name: keyof Quotation, value: string) => {
    const topic = this.quotationTopics.find(t => t.id === id);
    if (!topic || !name) return;
    if (topic.data[name] === value) return;
    return topic.data[name] = value;
  };

  onQuotationDataSave = (id: Quotation["id"]) => {
    const topic = toJS(this.quotationTopics.find(t => t.id === id));
    if (isEmpty(topic)) return;
    return topicCtrl.patchTopic(id, {
      data: JSON.stringify(topic.data)
    });
  };

  onQuotationServiceFormSave = (id: QuotationService["id"], data: KeyValuePairs) => {
    const topic = toJS(this.rfqItemTopics.find(t => t.id === id));
    if (isEmpty(topic) || !data) return;
    const patchData: Partial<TopicPrimitive> = {
      data: JSON.stringify({
        ...data,
        provider: this.providerName
      })
    };
    return topicCtrl.patchTopic(id, patchData);
  };

  onFreightServiceSubmit = async (id: Topic["id"], form: QuotationServiceFreightForm, isUpdate?: boolean) => {
    const { legs, surcharges, price } = form;
    if (isEmpty(legs)) return;
    // Grab the base topic template (parent freight for isUpdate, tempTopic for new)
    const tempTopic = isUpdate
      ? this.rfqItemTopics.find(t => t.id === id)
      : this._temporaryTopics.find(t => t.id === id);
    if (isEmpty(tempTopic)) return;
    // Create and apply updated properties object to the base topic.
    const topic = {
      description: `${UIText.freight}_${legs.length > 1 ? "Parent" : "Single"}_${UIText.service}_${new Date().getTime()}`, // TODO: Temporary
      isParentTemplate: 0,
      isTemplate: 0,
      parentId: tempTopic.parentId,
      typeClassId: tempTopic.typeClassId,
      typeClassVersion: tempTopic.typeClassVersion,
      typeId: tempTopic.typeId,
      startTime: new Date(this.rfq.cargoReadyDate).getTime(),
    } as TopicPrimitive;
    if (isUpdate) topic.id = tempTopic.id;
    // Create endpoint DTO
    const data: CreateTopicDTO = {
      currentGroupId: this.group.id,
      topic,
    };
    // If freight has no segments...
    if (legs.length > 1) {
      const parentFreightData: FreightService = {
        // mode: this.rfq.multimodal ? undefined : legs[0].mode,
        surcharges,
        pol: legs[0].pol,
        pod: legs[legs.length - 1].pod,
        price,
        serviceType: "Freight"
      };
      topic.data = JSON.stringify(parentFreightData);
      data.subTopicList = legs.map(leg => ({
        description: `${UIText.freight}_${UIText.service}_${new Date().getTime()}`, // TODO: Temporary
        isParentTemplate: 0,
        isTemplate: 0,
        parentId: topic.parentId,
        typeClassId: typeClassIds.freight.v1.id,
        typeClassVersion: typeClassIds.freight.v1.version,
        typeId: topicTypeIds.quotationService,
        data: JSON.stringify((() => {
          const freightData = {
            ...leg,
            surcharges: isNonZeroFalse(leg.surcharges) ? 0 : leg.surcharges,
            serviceType: "Freight"
          };
          if (freightData.key) delete freightData.key;
          if (freightData.id) delete freightData.id;
          return freightData;
        })())
      }));
    // If freight has more than one segments...
    } else {
      const freightData = {
        ...legs[0],
        surcharges: isNonZeroFalse(legs[0].surcharges) ? 0 : legs[0].surcharges,
        serviceType: "Freight"
      };
      if (freightData.key) delete freightData.key;
      if (freightData.id) delete freightData.id;
      topic.data = JSON.stringify(freightData);
    }
    // Exec query.
    return api[isUpdate ? "PATCH" : "POST"]({
      endpoint: isUpdate
        ? endpointConfig.update_quotation_service_topic
        : endpointConfig.create_topic,
      data
    })
    .then(this.getRfqItems);
  };
}