import { UIText } from "../client/lang";

export const placeholderAvatarUrl = "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y";

export const groupTypeRoleIds = {
  shipper: 3,
  lsp: 4,
  carrier: 5
};

export const groupTypeIds = {
  shipper: 3,
  lsp: 4,
  carrier: 5
};

export const topicTypeIds = {
  rfq: 1,
  requirement: 2,
  quotation: 3,
  quotationService: 5,
  solution: 6,
  rfqDraft: 7
};

export const typeClassIds = {
  personalProfile: {
    v1: { id: 0, version: 1 }
  },
  shipperProfile: {
    v1: { id: 1, version: 1 }
  },
  lspProfile: {
    v1: { id: 2, version: 1 }
  },
  rfq: {
    v1: { id: 5, version: 1 }
  },
  quotation: {
    v1: { id: 6, version: 1 }
  },
  solution: {
    v1: { id: 7, version: 1 }
  },
  rfqForm: {
    v1: { id: 14, version: 1 }
  },

  inland: {
    v1: { id: 8, version: 1 }
  },
  storage: {
    v1: { id: 9, version: 1 }
  },
  customs: {
    v1: { id: 10, version: 1 }
  },
  inspection: {
    v1: { id: 11, version: 1 }
  },
  packing: {
    v1: { id: 12, version: 1 }
  },
  freight: {
    v1: { id: 13, version: 1 }
  },

  carrierProfile: {
    v1: { id: 15, version: 1 }
  }
};

export const registerIntents = [
  {
    groupTypeRoleId: 3,
    get intentText() { return UIText.shipper }
  },
  {
    groupTypeRoleId: 4,
    get intentText() { return UIText.lspLong }
  }
];