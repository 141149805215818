import { observer } from "mobx-react";
import React from "react";
import { IonText } from "@ionic/react";
import { FormFieldCommonProps } from "../../../lib/types/propTypes";
import { LinkField } from "../../../lib/types/formTypes";

export interface LinkFieldProps extends FormFieldCommonProps {
  target?: LinkField["target"];
  linkText: string;
}

const Link: React.FC<LinkFieldProps> = observer(
  ({
     slot,
     value,
     color,
     target,
     linkText,
     disabled
   }) => (
    // eslint-disable-next-line
    <a
      className="flex ion-wrap ion-margin-vertical"
      href={!disabled ? value : undefined}
      target={target}
      rel="noopener noreferrer"
    >
      <IonText slot={slot} color={color}>
        {linkText}
      </IonText>
    </a>
  ));

export default Link;