import { Rfq } from "./types/dataTypes";
import { Thread, Topic } from "../../../lib/types/topicTypes";
import MdIcon from "../../../components/MdIcon";
import { mdiAccountCircle, mdiInformationOutline } from "@mdi/js";
import { UIText } from "../../../client/lang";
import { groupTypeIds } from "../../../config/constants";
import { Group } from "../../../lib/types/dataTypes";
import { getDisplayNameEng, isEmpty } from "../../../utils/helpers";
import { ui } from "../../../client/ui";
import React from "react";
import { getGroupParty, getRfqDescriptionLsp, getRfqDescriptionShipper, topicToRfqWithShipperName } from "./common";
import { RfqFormDTO } from "./types/rfqFormTypes";
import { msgCtrl } from "../../../client/msg";

export const getThreadDescription = (rfq: Rfq, thread: Thread, party: ReturnType<typeof getGroupParty>) => {
  if (msgCtrl.isInfoThread(thread)) return <span className="flex ion-align-items-center">
  <MdIcon icon={mdiInformationOutline} />&nbsp;&nbsp;{UIText.infoThread}
  </span>;

  if (party === "lsp") return <span className="flex ion-align-items-center">
  <MdIcon icon={mdiAccountCircle} />&nbsp;&nbsp;{rfq.shipperName}
  </span>;

  const lspGroups = (thread.groups && thread.groups.find(
    group => group.typeId === groupTypeIds.lsp
  )) || {} as Group;

  return <span className="flex ion-align-items-center">
  <MdIcon icon={mdiAccountCircle} />&nbsp;&nbsp;{getDisplayNameEng(lspGroups.profile)}
  </span>;
};

export const getThreadModalTitle = (topic: Topic, thread: Thread, party: ReturnType<typeof getGroupParty>) => {
  if (isEmpty(thread) || isEmpty(topic)) return "";
  const rfq = topicToRfqWithShipperName(topic as Topic<RfqFormDTO>);
  const baseTitle = getThreadDescription(rfq, thread, party);
  return <span className={`flex ion-align-items-center ${ui.isMobile ? "font-s" : ""}`}>
  {baseTitle}&nbsp;&nbsp;|&nbsp;&nbsp;{
    party === "lsp" ? getRfqDescriptionLsp(rfq) : getRfqDescriptionShipper(rfq)
  }
  </span>;
};