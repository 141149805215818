import { observer, Observer } from "mobx-react";
import React from "react";
import { UIText } from "../../../../client/lang";
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonReorder,
  IonReorderGroup,
  IonRippleEffect,
  IonText,
} from "@ionic/react";
import "./styles.less";
import { ObserverList } from "../../../../components/ObserverList";
import { ShippingPlan, ShippingRate, ShippingRateLeg } from "../../lib/types/rateTypes";
import PolPodTrafficText from "../PolPodTrafficText";
import { RfqFormDTO } from "../../lib/types/rfqFormTypes";
import { ItemReorderEventDetail } from "@ionic/core";
import { computed } from "mobx";
import { getIonStyleVal } from "../../../../config/styles/style-utils";
import MdIcon from "../../../../components/MdIcon";
import { mdiCircleSmall, mdiDragVertical } from "@mdi/js";
import { addCircleOutline, chevronForward, closeSharp } from "ionicons/icons";
import { isEmpty } from "../../../../utils/helpers";
import MaterialDateTimeItem from "../../../../components/MaterialDateTimeItem";
import InjectIonLabelHelpTip from "../../../../components/InjectIonLabelHelpTip";
import RateTransitPortsIndicator from "../RateTransitPortsIndicator";
import { ui } from "../../../../client/ui";
import { getPortDisplayCode, getPortName } from "../../lib/common";
import { Tooltip } from "@material-ui/core";

export interface ComplexRateLegItemProps extends React.HTMLProps<HTMLDivElement> {
  selected: boolean;
  rate: ShippingRate;
  onRateClick: (event: any) => void;
  onRemoveRate: (event: any) => void;
}

const ComplexRateLegItem: React.FC<ComplexRateLegItemProps> = observer(({ className, selected, rate, onRateClick, onRemoveRate }) => {
  return rate && <div className={`flex rateReorder relative ion-activatable ${className || ""}`} onClick={onRateClick}>
    <IonRippleEffect style={{ zIndex: 10 }}/>
    <IonReorder
      style={{ background: getIonStyleVal("--ion-color-light-tint") }}
      className="flex ion-align-items-center ion-justify-content-center ion-align-self-stretch ion-no-margin"
    >
      <MdIcon icon={mdiDragVertical} color="dark" />
    </IonReorder>
    <div className="flex column leg">
      <IonItemDivider color={selected ? "primary" : "medium"}>
        <IonText className="flex ion-align-items-center">
          {UIText.rateManagement.leg}&nbsp;{rate.shippingPlanLeg.ordinal + 1}:&nbsp;<b>{UIText.rfqForm[(rate.shippingMode || {}).mode]}</b>&nbsp;
        </IonText>
        <IonButton
          fill="clear"
          slot="end"
          className="ion-no-margin max"
          onClick={onRemoveRate}
        >
          <IonIcon color="light" icon={closeSharp} className="font-l" />
        </IonButton>
      </IonItemDivider>
      <div>
        <IonItem lines="none">
          <div className="flex ion-align-items-center polPodText">
            <PolPodTrafficText
              mode={(rate.shippingMode || {}).mode as RfqFormDTO["mode"]}
              pod={getPortDisplayCode(rate.podPort) || "—"}
              pol={getPortDisplayCode(rate.polPort) || "—"}
            />
          </div>
          <IonText slot="end" color="primary" className="flex ion-align-items-center textBold">
            {(((rate.carrierGroup || {}).profile || {}).data || {}).abbreviation || (
              <MdIcon icon={mdiCircleSmall} color="medium" />
            )}&nbsp;
            <IonIcon icon={chevronForward} />
          </IonText>
        </IonItem>
      </div>
    </div>
  </div>
});

export interface ComplexRateLeftColumnProps extends React.HTMLProps<HTMLDivElement> {
  plan: ShippingPlan;
  selectedRateId: number;
  onPlanFieldChange: (event: any, plan: ShippingPlan) => void;
  onPolClick: (event: any) => void;
  onPodClick: (event: any) => void;
  onRateClick: (event: any, rateId: number) => void;
  onAddRate: (event: any, ordinal: number) => void;
  onRemoveRate: (event: any, rate: ShippingRate) => void;
  onLegSelectionModalOpen: (event: any, ordinal: number) => void;
  updatePlanLegFieldAsync: (legId: number, field: keyof ShippingRateLeg, value: any) => void;
}

@observer
class ComplexRateLeftColumn extends React.Component<ComplexRateLeftColumnProps> {
  @computed get legs(): ShippingRate[] {
    return (this.props.plan && this.props.plan.shippingRates) || [] as ShippingRate[];
  };
  @computed get polShippingRate(): ShippingRate {
    return (!isEmpty(this.legs) && this.legs[0]) || {} as ShippingRate;
  };
  @computed get podShippingRate(): ShippingRate {
    return (!isEmpty(this.legs) && this.legs[this.legs.length - 1]) || {} as ShippingRate;
  };

  onLegReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    const { complete } = event.detail;
    complete(this.legs);
    return this.legs.filter(Boolean).forEach((leg, i) => (
      this.props.updatePlanLegFieldAsync(leg.id, "ordinal", i)
    ));
  };

  handleAddLegClick = (event: any, ordinal: number) => ui.popoverMenu({
    event,
    cssClass: "addLegPopover",
    menuItems: [
      {
        text: UIText.rateManagement.useExistingRateForPlan,
        handler: () => this.props.onLegSelectionModalOpen(null, ordinal)
      },
      {
        text: UIText.rateManagement.createNewRateForPlan,
        color: "primary",
        fill: "solid",
        handler: () => this.props.onAddRate(null, ordinal)
      },
    ]
  });

  render() {
    const {
      className,
      plan,
      selectedRateId,
      onPlanFieldChange,
      onPolClick,
      onPodClick,
      onRateClick,
      onRemoveRate
    } = this.props;

    const AddLeg = index =>
      <IonItem
        className="addLegButton ion-margin-horizontal"
        button
        lines="none"
        onClick={e => this.handleAddLegClick(e, index)}
      >
        <IonText className="flex ion-align-items-center ion-justify-content-center font-xl">
          <IonIcon icon={addCircleOutline} color="medium" />
        </IonText>
      </IonItem>;

    return <div className={`max fancyScroll complexRateLeftColumn ${className || ""}`}>
      <IonCard color="light" className="ion-padding-bottom ion-no-margin">
        <Observer>{() => <>
          <IonCardHeader>
            <IonCardSubtitle className="font-s textBold textDarkMedium">
              {UIText.rateManagement.portRouteSummary}
            </IonCardSubtitle>
          </IonCardHeader>
          <div className="flex ion-wrap ion-margin-horizontal half">
            <Tooltip
              arrow
              enterDelay={isEmpty(this.polShippingRate.polPort) ? Number.MAX_SAFE_INTEGER : undefined}
              placement="bottom"
              title={getPortName(this.polShippingRate.polPort)}
            >
              <IonItem lines="full" button onClick={onPolClick} className="complexRateLeftColumnOrig">
                <IonLabel color="primary" position="stacked">
                  {UIText.rfqForm.origin}
                </IonLabel>
                <IonInput
                  readonly
                  className="noEvents textPrimary"
                  value={getPortDisplayCode(this.polShippingRate.polPort)}
                  placeholder={UIText.rfqForm.origin}
                />
              </IonItem>
            </Tooltip>
            <Tooltip
              arrow
              enterDelay={isEmpty(this.podShippingRate.podPort) ? Number.MAX_SAFE_INTEGER : undefined}
              placement="bottom"
              title={getPortName(this.podShippingRate.podPort)}
            >
              <IonItem lines="full" button onClick={onPodClick} className="complexRateLeftColumnDest">
                <IonLabel color="primary" position="stacked">
                  {UIText.rfqForm.destination}
                </IonLabel>
                <IonInput
                  readonly
                  className="noEvents textPrimary"
                  value={getPortDisplayCode(this.podShippingRate.podPort)}
                  placeholder={UIText.rfqForm.destination}
                />
              </IonItem>
            </Tooltip>
          </div>
        </>}</Observer>

        <Observer>{() => <>
          <IonCardHeader>
            <IonCardSubtitle className="font-s textBold textDarkMedium">
              {UIText.rateManagement.validity}
            </IonCardSubtitle>
          </IonCardHeader>
          <div className="flex ion-wrap ion-margin-horizontal half">
            {/*<MaterialDateTimeItem*/}
            {/*  className="relative"*/}
            {/*  useMuiOnly*/}
            {/*  // type="datetime"*/}
            {/*  name="validityStartDate"*/}
            {/*  lines="none"*/}
            {/*  label={UIText.rateManagement.validityStart}*/}
            {/*  color="primary"*/}
            {/*  min={new Date().toISOString()}*/}
            {/*  placeholder={UIText.rateManagement.validityStart}*/}
            {/*  displayFormat="YYYY-MM-DD"*/}
            {/*  value={plan.validityStartDate && new Date(plan.validityStartDate).toISOString()}*/}
            {/*  onIonChange={onPlanFieldChange}*/}
            {/*>*/}
            {/*  <InjectIonLabelHelpTip*/}
            {/*    className="helpTip absolute"*/}
            {/*    help={{*/}
            {/*      header: UIText.generalHelp,*/}
            {/*      message: UIText.generalHelp*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</MaterialDateTimeItem>*/}
            <MaterialDateTimeItem
              className="relative"
              useMuiOnly
              // type="datetime"
              name="validityEndDate"
              lines="none"
              label={UIText.rateManagement.validity}
              color="primary"
              min={new Date().toISOString()}
              placeholder={UIText.rateManagement.validity}
              displayFormat="DDDD YYYY-MM-DD"
              value={plan.validityEndDate && new Date(plan.validityEndDate).toISOString()}
              onIonChange={e => onPlanFieldChange(e, plan)}
            >
              <InjectIonLabelHelpTip
                className="helpTip absolute"
                help={{
                  header: UIText.generalHelp,
                  message: UIText.generalHelp
                }}
              />
            </MaterialDateTimeItem>
          </div>
        </>}</Observer>

        <Observer>{() => <>
          <IonCardHeader>
            <IonCardSubtitle className="font-s textBold textDarkMedium">
              {UIText.rateManagement.transitPortRouteSummary}
            </IonCardSubtitle>
          </IonCardHeader>
          <div className="legSummary ion-margin-horizontal">
            <IonItem lines="none">
              <RateTransitPortsIndicator
                className="ion-margin-vertical"
                showTransshipment
                legs={this.legs}
                onLegClick={(e, leg) => onRateClick(e, leg.id)}
              />
            </IonItem>
          </div>
        </>}</Observer>

        <Observer>{() => <>
          <IonCardHeader>
            <IonCardSubtitle className="font-s textBold textDarkMedium">
              {UIText.rateManagement.legs}
            </IonCardSubtitle>
          </IonCardHeader>
          <div className="flex column complexRateLeftColumnLegs">
            <div className="flex column ion-padding-horizontal ion-align-items-center">
              {AddLeg(0)}
            </div>
            <IonReorderGroup onIonItemReorder={this.onLegReorder} disabled={false}>
              <ObserverList
                list={this.legs}
                render={(rate: ShippingRate, i) => <Observer key={(rate && rate.id) || i}>{() => (
                  <div className="flex column ion-padding-horizontal ion-align-items-center">
                    <ComplexRateLegItem
                      selected={selectedRateId === rate.id}
                      rate={rate}
                      onRateClick={e => onRateClick(e, rate.id)}
                      onRemoveRate={e => onRemoveRate(e, rate)}
                    />
                    {AddLeg(i + 1)}
                  </div>
                )}</Observer>}
              />
            </IonReorderGroup>
          </div>
        </>}</Observer>
      </IonCard>
    </div>;
  }
}

export default ComplexRateLeftColumn;