import { observer } from "mobx-react";
import React from "react";
import { IonCheckbox, IonIcon } from "@ionic/react";
import { FormFieldCommonProps } from "../../../lib/types/propTypes";
import { ComponentRef } from "@ionic/core";
import MdIcon from "../../MdIcon";
import { mdiCheckBold, mdiCheckboxBlankOffOutline } from "@mdi/js";

export interface CheckboxFieldProps extends FormFieldCommonProps {
  checked?: boolean;
  checkedIcon?: string | ComponentRef | React.ReactElement | null;
  uncheckedIcon?: string | ComponentRef | React.ReactElement | null;
}

const Checkbox: React.FC<CheckboxFieldProps> = observer(
  ({ name, checkedIcon, uncheckedIcon, checked, disabled, color, onIonChange, onIonBlur }) => (
    (checkedIcon || uncheckedIcon) ? (
      <>
        {!!checked ? typeof checkedIcon === "string"
          ? <IonIcon slot="start" icon={checkedIcon}/> : checkedIcon
          : typeof uncheckedIcon === "string" ?
            <IonIcon slot="start" icon={uncheckedIcon}/> : uncheckedIcon}
      </>
    ) : (
      <IonCheckbox
        slot="start"
        name={name}
        checked={!!checked}
        color={color}
        disabled={disabled}
        onIonChange={onIonChange}
        onIonBlur={onIonBlur}
      />
    )
  ));

export const IonCheckboxWithReadonly: React.FC<
  { hideOnNoSelect?: boolean; readonly?: boolean; } & React.ComponentProps<typeof IonCheckbox>
  > =
  observer(props => (
    !props.readonly ? <IonCheckbox {...props} />
    : (props.checked
      ? <MdIcon size="18px" className="readonlyIconStyle" icon={mdiCheckBold} color={props.color || "primary"} />
      : props.hideOnNoSelect ? null : <MdIcon size="18px" className="readonlyIconStyle" icon={mdiCheckboxBlankOffOutline} />)
  ));

export default Checkbox;