import { Controller } from "../../../../lib/controller";
import { Carrier, ShippingMode } from "../../lib/types/rateTypes";
import { api } from "../../../../client/api";
import { endpointConfig } from "../../../../config/api";
import { computed, IObservableArray, observable } from "mobx";
import { SortableStore } from "../../../../lib/types/miscTypes";
import { SortableTableHeadColumn } from "../../../../components/SortableTableHead";
import { TypeClass } from "../../../../lib/types/formTypes";
import { formCtrl } from "../../../../client/form";
import { typeClassIds } from "../../../../config/constants";
import { carrierProfileForm, getShippingModes } from "../../lib/common";
import { client } from "../../../../client/client";

export interface SystemCarrierListStore extends SortableStore<Carrier> {
  modeFilters: ShippingMode["mode"][];
}

export class SystemCarrierListController extends Controller<SystemCarrierListStore> {
  @observable modes: IObservableArray<ShippingMode> = [] as IObservableArray<ShippingMode>;
  @observable carriers: IObservableArray<Carrier> = [] as IObservableArray<Carrier>;

  @computed get isSa(): boolean {
    return !!client.user.isSa;
  };
  @computed get carrierProfileTypeClass(): TypeClass {
    return formCtrl.findTypeClassById(typeClassIds.carrierProfile.v1.id);
  };
  @computed get carrierProfileFieldColumns(): SortableTableHeadColumn[] {
    return carrierProfileForm.ready ? carrierProfileForm.blank.map(field => ({
      name: field.name, text: field.placeholder
    })) : [];
  };

  @computed get modeFilters(): IObservableArray<ShippingMode["mode"]> {
    this.storage.initProperty("modeFilters", []);
    return this.store.modeFilters as IObservableArray<ShippingMode["mode"]>;
  };

  loadAllData = () => Promise.all([
    this.getModes(),
    this.getCarriers(),
  ]);

  getModes = async () => getShippingModes()
  .then(modes => this.modes = modes);

  getCarriers = async () => api.GET(endpointConfig.carriers)
  .then(response => this.carriers = (Array.isArray(response.data) && response.data) || []);

  onFilterClear = () => this.modeFilters.clear();

  onFilterSelect = (event: any) => {
    const { name } = event.target;
    if (this.modeFilters.includes(name)) {
      return this.modeFilters.remove(name);
    }
    return this.modeFilters.push(name);
  };

  onRemoveCarrier = async (id: number) => {
    const carrier = this.carriers.find(c => c.id === id);
    if (!carrier) return;
    this.carriers.remove(carrier);
    return api.DELETE(endpointConfig.carrier_by_id(id))
    .finally(this.getCarriers);
  };
}
