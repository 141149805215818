import { observer } from "mobx-react";
import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonLoading, IonTitle, IonToolbar } from "@ionic/react";
import { arrowBack, ellipsisVertical, saveOutline } from "ionicons/icons";
import { UIText } from "../../../../client/lang";
import Refresher from "../../../../components/Refresher";
import { ui } from "../../../../client/ui";
import FormView from "../../../../components/Form";
import React, { MouseEvent } from "react";
import { Form } from "../../../../client/form";
import { QuotationServiceDetailModalProps } from "../../lib/types/propTypes";

export interface QuotationServiceDetailProps extends QuotationServiceDetailModalProps {
  form?: Form;
  onFormEditOrSave?: (event: MouseEvent) => void;
}

const QuotationServiceDetail: React.FC<QuotationServiceDetailProps> = observer(
  ({
     modalItem,
     subHeader,
     isFormLocked,
     form,
     loading,
     menuIcon,
     onFormEditOrSave,
     onMenu,
     onRefresh,
     onDismissModal
   }) => <>
    <IonHeader translucent>
      <IonToolbar>
        <IonTitle color="primary">{modalItem.title}</IonTitle>
        <IonButtons slot="start">
          <IonButton onClick={onDismissModal}>
            <IonIcon slot="icon-only" icon={arrowBack} />
          </IonButton>
        </IonButtons>
        <IonButtons slot="end">
          {!isFormLocked && (
            <IonButton onClick={onFormEditOrSave} className="textBold" color="primary">
              <IonIcon slot="start" icon={saveOutline} />{UIText.generalSave}
            </IonButton>
          )}
          {onMenu && (
            <IonButton onClick={onMenu}>
              {typeof menuIcon !== "string" && menuIcon}
              {(!menuIcon || typeof menuIcon === "string") && <IonIcon slot="icon-only" icon={menuIcon as string || ellipsisVertical} />}
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen className="ion-padding">
      <Refresher customAppearance color="light" onRefresh={onRefresh} isMobile={ui.isMobile} />
      <IonLoading translucent isOpen={loading} />
      <div className="flex column max">
        {subHeader && (
          <div className="flex column ion-padding">
            {subHeader}
          </div>
        )}
        <IonCard className="max" style={{ flex: 1 }}>
          <IonCardContent className="max formView fancyScroll">
            <FormView form={form} disabled={isFormLocked} />
          </IonCardContent>
        </IonCard>
      </div>
    </IonContent>
  </>
);

export default QuotationServiceDetail;