import { IonButton, IonCard, IonCardContent, IonCardHeader, IonContent, IonLoading, IonPage, IonText, withIonLifeCycle } from "@ionic/react";
import { computed, observable, when } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { UIText } from "../../client/lang";
import { LoginController } from "./controller";
import { UserLoginData } from "../../lib/types/dataTypes";
import { capitalize, preventDefaultStopProp } from "../../utils/helpers";
import { StdErr } from "../../lib/types/miscTypes";
import { ui } from "../../client/ui";
import { LoginBox } from "../../components/LoginBox";
import "./styles.less";
import { RouteComponentProps } from "react-router";
import { CompanyLinkLogo } from "../../tila/app3win/components/CompanyLinkLogo";
import { stateCtrl } from "../../client/state";
import { defaultAuthRoute } from "../../config/routes";

export interface LoginProps extends RouteComponentProps {}

@observer
class Login extends React.Component<LoginProps> {
  pathname: string;
  query: string;
  disposer;
  controller: LoginController = {} as LoginController;
  title: string = capitalize(UIText.welcome);

  @observable loading: boolean = false;
  @observable loginProgress: boolean = false;
  @observable errors: UserLoginData<boolean> = {
    email: false,
    password: false
  };
  @observable isVerification: boolean = false;

  @computed get loginData(): UserLoginData<string> {
    return this.controller.loginData;
  };

  constructor(props) {
    super(props);
    this.pathname = props.location.pathname;
    this.query = props.location.search;
    this.controller = new LoginController();
    this.isVerification = !!this.pathname.match(/Verification/ig);
  }

  ionViewWillEnter() {
    if (this.props.location.pathname.match(/signout|logout/ig)) {
      return this.controller.logout()
      .then(() => this.props.history.push(defaultAuthRoute.path));
    }
    when(() => this.controller.isLoggedIn, this.redirectLoggedIn);
    if (this.isVerification && !stateCtrl.verificationLock) return setTimeout(this.handleVerification);
    this.redirectLoggedIn();
  }

  ionViewWillLeave() {
    this.isVerification = false;
    this.controller.reset();
    this.disposer && this.disposer();
  }

  showError = (err: StdErr, actionName?: string) => {
    if (err.name === "ACCOUNT_NOT_VERIFIED") {
      return ui.alert({
        cssClass: "errorAlert",
        header: UIText.loginError,
        subHeader: UIText.loginNeedVerify,
        message: err.message,
        buttons: [UIText.generalConfirm, {
          text: UIText.loginResendVerification,
          handler: this.handleResendVerification
        }]
      });
    }
    if (err.name === "ACCOUNT_NEED_PASSWORD_RESET") {
      return ui.alert({
        cssClass: "errorAlert",
        header: UIText.loginError,
        message: err.message,
        buttons: [UIText.generalConfirm, {
          text: UIText.resetPassword,
          handler: () => this.handleResetPassword(this.controller.loginData.email)
        }]
      });
    }
    return ui.showError({ err, actionName: actionName || UIText.loginError });
  };

  redirectLoggedIn = () => this.controller.isLoggedIn && this.props.history.push(stateCtrl.lastPathname);

  handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    const field: keyof UserLoginData<string> = name.split("_")[1];
    this.controller.onFieldChange(field, value);
    this.errors.email = false;
    this.errors.password = false;
  };

  handleLogin = async (event: any) => {
    preventDefaultStopProp(event);
    if (this.loading) return;
    this.loading = true;
    this.loginProgress = true;
    this.errors = {} as UserLoginData<boolean>;
    const loginResult: UserLoginData<boolean> =
      await this.controller.login()
      .catch((err: StdErr) => {
        this.errors = { email: true, password: true };
        return this.showError(err);
      }) as UserLoginData<boolean>;
    Object.assign(this.errors, loginResult);
    // this.controller.reset();
    this.loginProgress = false;
    this.loading = false;
  };

  handleResendVerification = async (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onResendVerification()
    .then(() => ui.alert({
      header: UIText.title,
      message: UIText.verificationResent,
      buttons: [UIText.generalConfirm]
    }))
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleResetPassword = (email: string) => this.props.history.push(`/Reset?email=${email}`);

  handleVerification = async () => {
    this.loading = true;
    const queryString = this.query;
    return this.controller.execVerification(queryString)
    .then(user => this.controller.setIdentifierField(user.email || user.username))
    .then(() => stateCtrl.verificationLock = true)
    .then(() => ui.alert({
      header: capitalize(UIText.verification),
      message: UIText.verificationSuccess,
      buttons: [UIText.generalConfirm]
    }))
    .catch(e => this.showError(e, UIText.verificationFailure))
    .finally(() => this.loading = false);
  };

  render() {
    const loginLinks = <>
      <IonButton fill="clear" routerLink="/RFQ/New">
        <IonText className="textUnderline">{UIText.rfqForm.visitorLink}</IonText>
      </IonButton>
      <IonButton fill="clear" routerLink="/Register">
        <IonText className="textUnderline">{UIText.signUpLink}</IonText>
      </IonButton>
      <IonButton fill="clear" routerLink="/Reset">
        <IonText className="textUnderline">{UIText.signUpResetLink}</IonText>
      </IonButton>
    </>;

    return (
      <IonPage className="login headerOffset">
        <IonContent fullscreen>
          <IonLoading
            translucent
            isOpen={this.loading}
            message={this.loginProgress ? UIText.loginProgress : ""}
          />
          <div
            className={
              `flex max column container
             ion-align-items-center
             ion-justify-content-center
             ion-text-center
             ${ui.isMobile ? "" : "max"}`
            }
          >
            {ui.isMobile ? (
              <IonCard className="flex max column container fancyScroll ion-padding loginBox">
                <IonCardHeader className="font-l ion-text-left textPrimary">
                  {UIText.login} {UIText.title}
                </IonCardHeader>
                <div className="flex column ion-align-items-center ion-justify-content-center authLinks">
                  <CompanyLinkLogo className="ion-margin-vertical" />
                </div>
                <IonCardContent className="ion-no-padding">
                  <LoginBox
                    className="authForm"
                    loginData={this.loginData}
                    error={this.errors}
                    handleFieldChange={this.handleFieldChange}
                    handleLogin={this.handleLogin}
                    loading={this.loading}
                  />
                </IonCardContent>
                {loginLinks}
              </IonCard>
            ) : (
              <IonCard className="ion-padding loginBox">
                <IonCardHeader className="font-l ion-text-left textPrimary">
                  {UIText.login} {UIText.title}
                </IonCardHeader>
                <IonCardContent className="flex">
                  <LoginBox
                    className="authForm ion-margin-bottom"
                    loginData={this.loginData}
                    error={this.errors}
                    handleFieldChange={this.handleFieldChange}
                    handleLogin={this.handleLogin}
                    loading={this.loading}
                  />
                  <div className="flex column ion-align-items-center ion-justify-content-center authLinks">
                    <CompanyLinkLogo className="ion-margin-bottom" />
                    {loginLinks}
                  </div>
                </IonCardContent>
              </IonCard>
            )}
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Login);
