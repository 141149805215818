import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonPage,
  IonText,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";
import { isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import { StdErr } from "../../../../lib/types/miscTypes";
import { UIText } from "../../../../client/lang";
import { ui } from "../../../../client/ui";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { RfqDetailShipperController } from "./controller";
import "./styles.less";
import { Quotation, QuotationService, Rfq, Solution } from "../../lib/types/dataTypes";
import SolutionsColumn from "../../components/SolutionsColumn";
import AllInColumn from "../../components/AllInColumn";
import QuotesByTypesColumn from "../../components/QuotesByTypesColumn";
import { RfqDetailHeader } from "../../components/RfqDetailHeader";
import MdIcon from "../../../../components/MdIcon";
import { mdiDotsVertical } from "@mdi/js";
import { AccordionController } from "../../../../lib/accordion";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import QuotationServiceDetail, { QuotationServiceDetailProps } from "../../components/QuotationServiceDetail";
import { Form } from "../../../../client/form";
import { Thread, Topic } from "../../../../lib/types/topicTypes";
import { msgCtrl } from "../../../../client/msg";
import { chatbubblesSharp, starSharp } from "ionicons/icons";
import { getRfqDescriptionShipper, quotationServicesToLegs } from "../../lib/common";
import QuotationServiceFreightForm from "../../components/QuotationServiceFreightForm";
import { FreightServiceLeg } from "../../lib/types/propTypes";

interface RfqDetailShipperProps extends RouteComponentProps<GroupSubRouteParams<{
  id: string;
}>> {}

@observer
class RfqDetailShipper extends React.Component<RfqDetailShipperProps> {
  url: string;
  controller: RfqDetailShipperController = {} as RfqDetailShipperController;
  accordionController: AccordionController;

  @observable loading: boolean = false;
  @observable modalLoading: boolean = false;
  @observable isModalOpen: boolean = false;

  @observable modalMode: "service" | "quotation" | "solution";
  @observable modalQuotationId: number;
  @observable modalQuotationServiceId: number;
  @observable modalSolutionId: number;
  @observable modalHidden: boolean = false;
  modalItemForm: Form = {} as Form;

  @computed get id () {
    return this.controller.id;
  };
  @computed get rfq(): Rfq {
    return this.controller.rfq;
  };
  @computed get threads(): Thread[] {
    return this.controller.threads;
  };
  @computed get title(): string {
    return getRfqDescriptionShipper(this.rfq);
  };

  @computed get modalQuotation(): Quotation {
    return this.controller.quotations.find(q => q.id === this.modalQuotationId);
  }
  @computed get modalQuotationService(): QuotationService {
    return this.controller.quotationServices.find(q => q.id === this.modalQuotationServiceId);
  };
  @computed get modalSolution(): Solution {
    return this.controller.solutions.find(s => s.id === this.modalSolutionId);
  };
  @computed get modalItem(): QuotationServiceDetailProps["modalItem"] {
    return this.modalMode === "quotation" ? {
      title: (this.modalQuotation || {}).description,
      type: UIText.quotation as "quotation",
      ...this.modalQuotation
    } : this.modalMode === "service" ? {
      title: (this.modalQuotationService || {}).description,
      type: UIText.service as "service",
      ...this.modalQuotationService
    } : {
      title: (this.modalSolution || {}).description,
      type: UIText.solution as "solution",
      ...this.modalSolution
    };
  };
  @computed get modalFreightServiceLegs(): FreightServiceLeg[] {
    const legs = this.controller.findFreightServiceLegs(this.modalQuotationServiceId);
    if (isEmpty(legs)) return [];
    return quotationServicesToLegs(legs);
  };
  get modalItemIsFreight(): boolean {
    return !isEmpty(this.modalItemForm) && this.modalItemForm.get("serviceType") === "Freight";
  };

  constructor (props) {
    super(props);
    this.controller = new RfqDetailShipperController();
    this.accordionController = new AccordionController("rfqDetailShipper");
  }

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    const { id } = this.props.match.params;
    if (!id || isNaN(Number(id))) return this.backToRfqs(null);
    msgCtrl.addDismissChatHandler(this.onDismissChatModal);
    return this.onLoad(Number(id));
  };

  ionViewWillLeave = () => {
    this.controller.dispose();
    msgCtrl.removeDismissChatHandler(this.onDismissChatModal);
  };

  showError = (err: StdErr, kickOut?: boolean, actionName?: string) =>
    ui.showError({
      err,
      actionName,
      buttons: [{
        text: UIText.generalConfirm,
        handler: () => kickOut && this.backToRfqs(null)
      }]
    });

  onLoad = (id: Rfq["id"]) => {
    this.loading = true;
    return this.controller.setId(id)
    .catch((err: StdErr) => this.showError(err, true))
    .finally(() => this.loading = false);
  };

  onRefresh = (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  onRefreshModal = (event: any) => {
    preventDefaultStopProp(event);
    this.modalLoading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.modalLoading = false);
  };

  onDismissModal = event => {
    this.isModalOpen = false;
    this.modalMode = undefined;
    this.modalItemForm = {} as Form;
    this.modalQuotationServiceId = undefined;
    this.modalQuotationId = undefined;
    this.modalSolutionId = undefined;
  };

  onDismissChatModal = () => this.modalHidden = false;

  backToRfqs = (event?: any) => {
    preventDefaultStopProp(event);
    const urlFrags = this.url.split("/");
    urlFrags.pop();
    this.props.history.push(urlFrags.join("/"));
  };

  handleQuotationClick = (event: any, id: Quotation["id"]) => {
    preventDefaultStopProp(event);
    this.modalMode = "quotation";
    this.modalQuotationId = id;
    return this.isModalOpen = true;
  };

  handleQuotationServiceCardClick = (event: any, id: QuotationService["id"]) => {
    preventDefaultStopProp(event);
    const service = this.controller.quotationServices.find(qs => qs.id === id);
    if (!service) return;
    this.modalMode = "service";
    this.modalQuotationId = service.quotationId;
    this.modalQuotationServiceId = id;
    const topic = this.controller.rfqItemTopics.find(
      t => t.id === this.modalQuotationServiceId
    ) || {} as Topic;
    this.modalItemForm = new Form(
      topic.typeClassId,
      topic.data,
      { quotation: true }
    );
    return this.isModalOpen = true;
  };

  handleSolutionDetailClick = (event: any, id: Solution["id"]) => {
    preventDefaultStopProp(event);
    this.modalMode = "solution";
    this.modalSolutionId = id;
    return this.isModalOpen = true;
  };

  handleCreateSolutionFromQuotation = (event: any, id: Quotation["id"]) => {
    preventDefaultStopProp(event);
    const { isQuotationInSolution, getSolutionsByQuotationId } = this.controller;
    if (isQuotationInSolution(id)) {
      const solutionId: Solution["id"] = (getSolutionsByQuotationId(id)[0] || {}).id;
      return this.handleRemoveSolution(null, solutionId);
    }
    this.loading = true;
    return this.controller.onCreateSolutionFromQuotation(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  // handleStarMenu = (event: any, id: QuotationService["id"]) => {
  //   preventDefaultStopProp(event);
  //   const {
  //     isQuotationServiceInSolution,
  //     isQuotationServiceInEverySolution
  //   } = this.controller;
  //   return ui.popoverMenu({
  //     event,
  //     menuItems: [
  //       !isQuotationServiceInEverySolution(id) && {
  //         text: UIText.addToSolution,
  //         handler: () => {
  //           ui.dismissPopover();
  //           this.handleSolutionMenu(event, id)
  //         }
  //       },
  //       isQuotationServiceInSolution(id) && {
  //         text: UIText.removeFromSolution,
  //         handler: () => {
  //           ui.dismissPopover();
  //           this.handleSolutionMenu(event, id, true)
  //         }
  //       }
  //     ].filter(Boolean)
  //   });
  // };

  handleSolutionMenu = (event: any, id: QuotationService["id"]) => {
    preventDefaultStopProp(event);
    const { solutions } = this.controller;
    const component = () => (
      <IonList className="ion-no-padding">
        <IonListHeader color="tooling">
          {/*{isRemove ? UIText.removeFromSolution : UIText.addToSolution}*/}
          {UIText.addToSolution}
        </IonListHeader>
        {solutions
        // .filter(s => isRemove
        //   ? (s.quotationServiceIds.includes(id))
        //   : (!s.isAllIn && !s.new && !s.quotationServiceIds.includes(id))
        // )
        .map(s => {
          const addToSolution = event => {
            ui.dismissPopover();
            return this.handleAddToSolutionConfirm(event, id, s.id);
          };
          const removeFromSolution = event => {
            ui.dismissPopover();
            return this.handleRemoveFromSolution(event, id, s.id)
          };
          const isRemove = s.quotationServiceIds.includes(id);
          return <IonItem
            key={s.id}
            button
            lines="full"
            onClick={isRemove ? removeFromSolution : addToSolution}
          >
            <IonIcon slot="start" icon={starSharp} color={isRemove ? "warning" : "medium"} />
            <IonText color="dark" className="textBold">
              {s.description}
            </IonText>
          </IonItem>;
        })}
      </IonList>
    );
    return ui.popover({
      event,
      component
    });
  };

  handleAddToSolutionConfirm = (event: any, id: QuotationService["id"], solutionId: Solution["id"]) => {
    preventDefaultStopProp(event);
    ui.dismissPopover(event);
    this.loading = true;
    return this.controller.onAddToSolution(id, solutionId)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRemoveFromSolution = (
    event: any,
    id: QuotationService["id"],
    solutionId: Solution["id"]
  ) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onRemoveFromSolution(id, solutionId)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleNewSolution = (event: any) => {
    preventDefaultStopProp(event);
    return this.controller.onNewSolution()
    .catch(this.showError);
  };

  handleNewSolutionNameChange = (event: any, id: Solution["id"]) => {
    preventDefaultStopProp(event);
    const { value } = event.detail;
    return this.controller.onNewSolutionNameChange(id, value)
    .catch(this.showError);
  };

  handleNewSolutionSave = (event: any, id: Solution["id"]) => {
    preventDefaultStopProp(event);
    const unfoldSolution = solution => (
      setTimeout(() => this.accordionController.onAccordionFold(solution.id, true))
    );
    this.loading = true;
    return this.controller.onNewSolutionSave(id)
    .then(unfoldSolution)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRemoveSolution = (event: any, id: Solution["id"]) => {
    preventDefaultStopProp(event);
    const solution = this.controller.solutions.find(s => s.id === id);
    if (solution.new) return this.controller.onRemoveNewSolution(id);
    const remove = event => this.handleRemoveSolutionConfirm(event, id);
    return ui.reconfirm({
      header: `${UIText.generalDelete} ${UIText.solution.toLowerCase()}`,
      name: `${UIText.solution.toLowerCase()} ${solution.description}`,
      verb: UIText.generalDelete.toLowerCase(),
      handler: remove,
    });
  };

  handleRemoveSolutionConfirm = (event: any, id: Solution["id"]) => {
    this.loading = true;
    return this.controller.onRemoveSolution(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleSolutionAwardBusiness = (event: any, id: Solution["id"]) => {
    preventDefaultStopProp(event);
    const solution = this.controller.solutions.find(s => s.id === id);
    if (!solution) return;
    const award = event => this.handleSolutionAwardBusinessConfirm(event, id);
    return ui.reconfirm({
      header: `${UIText.finalizeSolution}`,
      name: UIText.servicesInSolution(solution.description),
      verb: UIText.awardBusinessTo.toLowerCase(),
      handler: award,
    });
  };

  handleSolutionAwardBusinessConfirm = (event: any, id: Solution["id"]) => {
    this.loading = true;
    return this.controller.onFinalizeSolution(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRfqFormMenu = (event: any) => {
    preventDefaultStopProp(event);
    return ui.popoverMenu({
      event,
      menuItems: [{
        text: UIText.rfqForm.showOriginalInquiry,
        color: "primary",
        fill: "solid",
        handler: () => this.props.history.push(`${this.url}/Form`)
      }]
    })
  };

  handleChatClick = async (event: any, id: QuotationService["id"]) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.getThreadByServiceId(id)
    .then((thread: Thread) => {
      if (!thread) return ui.alert({
        header: UIText.chatWithLSP,
        message: UIText.chatWithUnavailable(UIText.groupTypes.LSP),
        buttons: [UIText.generalConfirm]
      });
      this.modalHidden = true;
      return msgCtrl.openChat(thread.id, this.controller.group.id);
    })
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  render () {
    const {
      allInQuotations,
      quotationServices,
      nonAllInQuotationServices,
      solutions,
      isQuotationInSolution,
      isQuotationServiceInSolution,
    } = this.controller;

    const modalSubHeader = <>
      <IonText className="textBold textLight font-m ion-margin-bottom flex ion-align-items-center ion-justify-content-between ion-wrap">
        {this.modalMode === "service" && <>Provided by {this.modalQuotationService.provider}&nbsp;</>}
        <IonButton
          color="primary"
          className={ui.isMobile ? "ion-margin-vertical" : ""}
          onClick={e => this.handleChatClick(e, this.modalQuotationServiceId)}
        >
          <IonIcon slot="icon-only" icon={chatbubblesSharp} />&nbsp;
          <IonText>{UIText.chatWithLSP}</IonText>
        </IonButton>
      </IonText>
      {!this.modalItemIsFreight && <IonText className="textLight font-s">
        {this.modalMode === "service" && <>
          {UIText.serviceDatetime}: {(this.modalQuotationService.serviceDatetime
          && this.modalQuotationService.serviceDatetime.toLocaleString()) || ""}
        </>}
      </IonText>}
    </>;

    return (
      <IonPage className="headerOffset">
        <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
          <IonLoading translucent isOpen={this.loading}/>

          <IonCard className="flex max column container rfqDetailShipper">
            <RfqDetailHeader
              className={this.rfq.isAwarded ? "awarded" : ""}
              title={this.title}
              loading={this.loading}
              freightMode={this.rfq.mode}
              freightCount={this.rfq.freightCount}
              POL={this.rfq.POL}
              POD={this.rfq.POD}
              titleRight={ui.isMobile ? (
                <IonButtons>
                  <IonButton onClick={this.handleRfqFormMenu}>
                    <MdIcon icon={mdiDotsVertical} color="primary" />
                  </IonButton>
                </IonButtons>
              ) : (
                <IonButton routerLink={`${this.url}/Form`}>
                  {UIText.rfqForm.showOriginalInquiry}
                </IonButton>
              )}
              onRefresh={this.onRefresh}
              backHandler={this.backToRfqs}
            />

            <IonCardContent className="flex max column ion-no-padding">
              <div className="flex max rfqDetailColumnsContainer">
                <div className="flex ion-align-items-start ion-no-wrap rfqDetailColumns">
                  <AllInColumn
                    readonly={!!this.rfq.isAwarded || !!this.rfq.isClosed}
                    quotations={allInQuotations}
                    quotationServices={quotationServices}
                    isQuotationInSolution={isQuotationInSolution}
                    isAccordionExpanded={this.accordionController.isAccordionExpanded}
                    onAccordionFold={this.accordionController.onAccordionFold}
                    onQuotationClick={this.handleQuotationClick}
                    onQuotationServiceCardClick={this.handleQuotationServiceCardClick}
                    onStarClick={this.handleCreateSolutionFromQuotation}
                    onChatClick={this.handleChatClick}
                  />
                  <QuotesByTypesColumn
                    readonly={!!this.rfq.isAwarded || !!this.rfq.isClosed}
                    quotationServices={nonAllInQuotationServices}
                    isInSolution={isQuotationServiceInSolution}
                    isAccordionExpanded={this.accordionController.isAccordionExpanded}
                    onAccordionFold={this.accordionController.onAccordionFold}
                    onQuotationServiceCardClick={this.handleQuotationServiceCardClick}
                    onStarClick={this.handleSolutionMenu}
                    onChatClick={this.handleChatClick}
                  />
                  <SolutionsColumn
                    readonly={!!this.rfq.isAwarded || !!this.rfq.isClosed}
                    quotationServices={quotationServices}
                    solutions={solutions}
                    isAccordionExpanded={this.accordionController.isAccordionExpanded}
                    onAccordionFold={this.accordionController.onAccordionFold}
                    onSolutionClick={this.handleSolutionDetailClick}
                    onQuotationServiceCardClick={this.handleQuotationServiceCardClick}
                    onRemoveFromSolution={this.handleRemoveFromSolution}
                    onNewSolution={this.handleNewSolution}
                    onNewSolutionNameChange={this.handleNewSolutionNameChange}
                    onNewSolutionSave={this.handleNewSolutionSave}
                    onRemoveSolution={this.handleRemoveSolution}
                    onAwardBusiness={this.handleSolutionAwardBusiness}
                    onChatClick={this.handleChatClick}
                  />
                </div>
              </div>
            </IonCardContent>
          </IonCard>

          {/* Item detail modal */}
          <IonModal
            key="rfqDetailShipperModal"
            cssClass={`modal ${this.modalItemIsFreight ? "exLarge" : "large"} ${this.modalHidden ? "ion-hide" : ""}`}
            isOpen={this.isModalOpen}
            onDidDismiss={this.onDismissModal}
          >
            {this.modalItemIsFreight ? (
              <QuotationServiceFreightForm
                rfq={this.rfq}
                modalItem={this.modalItem}
                subHeader={modalSubHeader}
                legs={this.modalFreightServiceLegs}
                loading={this.modalLoading}
                isFormLocked
                descriptionAsTitle
                menuIcon={starSharp}
                onMenu={!this.rfq.isAwarded && (e => this.handleSolutionMenu(e, this.modalQuotationServiceId))}
                onRefresh={this.onRefreshModal}
                onDismissModal={this.onDismissModal}
              />
            ) : (
              <QuotationServiceDetail
                modalItem={this.modalItem}
                subHeader={modalSubHeader}
                isFormLocked
                form={this.modalItemForm}
                loading={this.modalLoading}
                // onFormEditOrSave={this.handleModalFormEditOrSave}
                menuIcon={starSharp}
                onMenu={!this.rfq.isAwarded && (e => this.handleSolutionMenu(e, this.modalQuotationServiceId))}
                onRefresh={this.onRefreshModal}
                onDismissModal={this.onDismissModal}
              />
            )}
          </IonModal>

        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(RfqDetailShipper);