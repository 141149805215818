import { Observer, observer } from "mobx-react";
import React from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonModal,
  IonRippleEffect,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { UIText } from "../../../../client/lang";
import { closeSharp, ellipsisHorizontal } from "ionicons/icons";
import ListControlToolbar from "../../../../components/ListControlToolbar";
import { getEventRealValue, preventDefaultStopProp } from "../../../../utils/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import SortableTableHead from "../../../../components/SortableTableHead";
import MdIcon from "../../../../components/MdIcon";
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from "@mdi/js";
import { ObserverList } from "../../../../components/ObserverList";
import { ShippingCharge, ShippingRate } from "../../lib/types/rateTypes";
import TwoStageInput from "../../../../components/TwoStageInput";
import "./styles.less";
import { ListController } from "../../../../lib/list-controller";
import { computed } from "mobx";
import { sortByProperty } from "../../lib/common";
import { matchShippingCharge } from "../../lib/matchers";

export interface RateChargeSelectionModalProps {
  shippingCharges: ShippingCharge[];
  carrierName: string;
  rate: ShippingRate;
  loading: boolean;
  isOpen: boolean;
  isAllChargesSelected: boolean;
  isShippingChargeSelected: (shippingRate: ShippingRate, shippingCharge: ShippingCharge) => boolean;
  onDismiss: (event: any) => void;
  onCheckboxSelectAll: (event: any) => void;
  onCheckboxSelect: (event: any, shippingCharge: ShippingCharge) => void;
  onChargeCustomizeModalOpen: (event: any, rateId: number) => void;
}

@observer
class RateChargeSelectionModal extends React.Component<RateChargeSelectionModalProps> {
  listController: ListController<ShippingCharge>;

  @computed get rate(): ShippingRate {
    return this.props.rate || {} as ShippingRate;
  };

  @computed get searchedShippingCharges(): ShippingCharge[] {
    return this.props.shippingCharges.filter(charge => matchShippingCharge(charge, this.listController.searchValue));
  };
  @computed get sortedShippingCharges(): ShippingCharge[] {
    return [...this.searchedShippingCharges].sort(
      (a, b) => sortByProperty(a, b, this.listController.sortBy, this.listController.sortDir)
    );
  };

  constructor(props) {
    super(props);
    this.listController = new ListController({
      storeName: "rateChargeSelectionModalFilterSort",
      initialSortBy: "baseCode"
    });
  }

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  handleChargeCustomizeModalOpen = (event: any) => {
    preventDefaultStopProp(event);
    if (this.props.onChargeCustomizeModalOpen) {
      this.props.onChargeCustomizeModalOpen(null, this.rate.id);
    }
  };

  render() {
    const {
      rate,
      carrierName,
      isOpen,
      isAllChargesSelected,
      isShippingChargeSelected,
      loading,
      onDismiss,
      onCheckboxSelectAll,
      onCheckboxSelect
    } = this.props;

    const {
      sortBy,
      sortDir,
      searchValue,
      onSearchValueChange
    } = this.listController;

    return <IonModal
      key={`rateChargeSelectionModal ${rate.id}`}
      cssClass="modal rateChargeSelectionModal large"
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <IonHeader translucent>
        <Observer>{() => (
          <IonToolbar>
            <IonTitle>{UIText.rateManagement.chargesSurcharges} {carrierName && `(${carrierName})`}</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon slot="icon-only" icon={closeSharp} />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end" className="ion-padding-horizontal">
              {loading && <IonSpinner name="crescent" />}
            </IonButtons>
          </IonToolbar>
        )}</Observer>
      </IonHeader>

      <IonContent fullscreen className="ion-padding" scrollY={false}>
        <div className="flex column max">
          <ListControlToolbar
            searchValue={searchValue}
            onSearchChange={(e: any) => onSearchValueChange(getEventRealValue(e))}
            searchBarPlaceholder={UIText.searchStuff(UIText.rateManagement.chargesSurcharges)}
          />

          <Observer>{() => (
            <div className="flex ion-wrap ion-justify-content-between ion-align-items-center textBold textLight font-s carrierInfo">
              <IonText>
                {UIText.rateManagement.mode}:&nbsp;{((rate.shippingMode || {}).localeMode || {})[UIText.preference]}
              </IonText>
              <IonButton
                disabled={!rate.carrierId}
                size="small"
                color="primary"
                className="textBold textNoTransform"
                onClick={rate.carrierId && this.handleChargeCustomizeModalOpen}
              >
                {UIText.rateManagement.manageCarrierShippingCharges}
              </IonButton>
            </div>
          )}</Observer>

          <IonCard className="max rateChargeSelectionTable" style={{ flex: 1 }}>
            <IonCardContent className="max ion-no-padding">
              <TableContainer component={props => <Paper {...props} square />} elevation={0}>
                <Table size="small" stickyHeader>
                  <Observer>{() => (
                    <SortableTableHead
                      columns={[
                        {
                          name: "selectAll",
                          component: <TableCell
                            className="ion-activatable relative font-m ion-text-center"
                            onClick={onCheckboxSelectAll}
                          >
                            <MdIcon
                              icon={isAllChargesSelected ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
                              color={isAllChargesSelected ? "primary" : undefined}
                              style={{ verticalAlign: "middle" }}
                            />
                            <IonRippleEffect />
                          </TableCell>
                        },
                        { name: "baseCode", text: UIText.rateManagement.baseCode },
                        { name: "carrierCode", text: UIText.rateManagement.carrierChargeSurchargeCode },
                        { name: "isSurcharge", text: UIText.rateManagement.surcharge },
                        { name: "chargeName", text: `${UIText.rateManagement.chargeName}\n(${UIText.preference})` },
                        { name: "description", text: `${UIText.generalDescription} (${UIText.preference})` },
                      ]}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      onSort={this.handleColumnSort}
                    />
                  )}</Observer>

                  <TableBody>
                    <ObserverList
                      list={this.sortedShippingCharges}
                      render={(charge: ShippingCharge) => <Observer key={charge.id}>{() => {
                        const isSelected = isShippingChargeSelected(this.rate, charge);
                        return <TableRow>
                          <Observer>{() => (
                            <TableCell
                              className="ion-activatable relative font-m ion-text-center checkbox"
                              padding="none"
                              onClick={e => onCheckboxSelect(e, charge)}
                            >
                              <MdIcon
                                icon={isSelected ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
                                color={isSelected ? "primary" : undefined}
                                style={{ verticalAlign: "middle" }}
                              />
                              <IonRippleEffect />
                            </TableCell>
                          )}</Observer>

                          <Observer>{() => (
                            <TableCell align="left" padding="none">
                              <IonInput
                                readonly
                                className="ion-no-padding"
                                name={`${charge.id}.baseCode`}
                                value={(charge.isCustom ? (charge.systemShippingCharge || {}).baseCode : charge.baseCode) || "—"}
                              />
                            </TableCell>
                          )}</Observer>

                          <Observer>{() => (
                            <TableCell align="left" padding="none">
                              <IonInput
                                readonly
                                className="ion-no-padding"
                                name={`${charge.id}.carrierCode`}
                                value={(charge.isCustom && charge.baseCode) || "—"}
                              />
                            </TableCell>
                          )}</Observer>

                          <Observer>{() => (
                            <TableCell align="center" padding="none" className="checkbox">
                              <IonInput
                                readonly
                                className="ion-no-padding"
                                name={`${charge.id}.isSurcharge`}
                                value={charge.isSurcharge ? UIText.generalYes : UIText.generalNo}
                              />
                            </TableCell>
                          )}</Observer>

                          <Observer>{() => (
                            <TableCell align="left" padding="none">
                              <TwoStageInput
                                endIcon={ellipsisHorizontal}
                                readonly
                                className="ion-no-padding"
                                name={`${charge.id}.chargeName`}
                                value={charge.chargeName[UIText.preference] || "—"}
                              />
                            </TableCell>
                          )}</Observer>

                          <Observer>{() => (
                            <TableCell align="left" padding="none">
                              <TwoStageInput
                                endIcon={ellipsisHorizontal}
                                readonly
                                className="ion-no-padding"
                                name={`${charge.id}.description`}
                                value={charge.description[UIText.preference] || "—"}
                              />
                            </TableCell>
                          )}</Observer>
                        </TableRow>
                      }}</Observer>}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonModal>
  }
}

export default RateChargeSelectionModal;