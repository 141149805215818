import { GroupSetupConfig } from "../../types/setupTypes";
import { groupTypeIds, groupTypeRoleIds, typeClassIds } from "../../../../../config/constants";
import { Form } from "../../../../../client/form";
import { UIException, UIText } from "../../../../../client/lang";
import { client } from "../../../../../client/client";
import { Group, ProfilePrimitive } from "../../../../../lib/types/dataTypes";
import { getDisplayNameEng, isEmpty } from "../../../../../utils/helpers";
import { createGroupProfile } from "../../../../../lib/group-utilities";

export const carrierGroupSetup: GroupSetupConfig = {
  id: 3,
  typeClassId: typeClassIds.carrierProfile.v1.id,
  get header() { return `${UIText.rateManagement.carrier} ${UIText.profile}` },
  get subHeader() { return UIText.rateManagement.createCarrier },
  metadata: { groupTypeRoleId: groupTypeRoleIds.carrier },
  allowBackButton: true,
  preSetup: (form: Form) => {},
  onSubmit: async (form: Form) => {
    const pass = form.validate();
    if (!pass) return;
    if (!form || isEmpty(form.data)) {
      return Promise.reject(new UIException("INVALID_CARRIER_PROFILE_SUBMISSION"));
    }
    const group: Partial<Group> = {
      owner: client.userId,
      parentId: null,
      groupName: getDisplayNameEng(form.data),
      typeId: groupTypeIds.carrier
    };
    const profile: ProfilePrimitive = {
      type: 1,
      typeClassId: typeClassIds.carrierProfile.v1.id,
      typeClassVersion: typeClassIds.carrierProfile.v1.version,
      data: form.toJSON()
    };
    return createGroupProfile(group, profile, groupTypeRoleIds.carrier)
    .then(client.getAndStoreUserGroupsAndMembers);
  },
  get postSetupUrl() { return `/ShippingCarriers` }
};