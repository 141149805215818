import { observer } from "mobx-react";
import React from "react";
import "./styles.less";
import { ui } from "../../../../client/ui";
import { UIText } from "../../../../client/lang";
import CardedContainerHeader from "../../../../components/CardedContainerHeader";
import MdIcon from "../../../../components/MdIcon";
import { mdiCancel, mdiSeal } from "@mdi/js";
import PolPodTrafficText from "../PolPodTrafficText";
import { Rfq } from "../../lib/types/dataTypes";

export interface RfqDetailHeaderProps extends React.ComponentProps<typeof CardedContainerHeader> {
  freightMode?: Rfq["mode"];
  freightCount?: number;
  POL?: string;
  POD?: string;
}

export const RfqDetailHeader: React.FC<RfqDetailHeaderProps> = observer(
  ({
     className,
     title,
     titleRight,
     loading,
     backHandler,
     onRefresh,
     freightMode,
     freightCount,
     POL,
     POD,
     children
  }) => (
    <CardedContainerHeader
      className={`rfqDetailHeader ${className || ""}`}
      title={title}
      titleRight={titleRight}
      loading={loading}
      onRefresh={onRefresh}
      backHandler={backHandler}
    >
      <div className="flex ion-margin-start ion-align-items-center ion-wrap">
        <span className={`closedText flex ion-align-items-center textBold textPrimary ${ui.isMobile ? "font-xs" : "font-s"}`}>
          <MdIcon icon={mdiCancel} color="primary" />&nbsp;{UIText.closed}
        </span>
        <span className={`awardedText flex ion-align-items-center textBold textPrimary ${ui.isMobile ? "font-xs" : "font-s"}`}>
          <MdIcon icon={mdiSeal} color="warning" />&nbsp;{UIText.awarded}
        </span>
        {freightMode && (
          <div className="flex ion-align-items-center ion-text-center textPrimary ion-margin-end">
            <div className={ui.isMobile ? "font-xs" : "font-s"}>
              <span>{UIText.freightMode + ": "}</span>
              <span className="ion-text-nowrap">{UIText.rfqForm[freightMode]}</span>
            </div>
          </div>
        )}
        {POD && POL && (
          <div className="flex ion-align-items-center ion-text-center textDarkMedium">
            <PolPodTrafficText mode={freightMode} pod={POD} pol={POL} />
            {/*{freightCount > 1 && <div className={`ion-margin-start ${ui.isMobile ? "font-xs" : "font-s"}`}>*/}
            {/*  + {freightCount - 1} {UIText.generalMore}*/}
            {/*</div>}*/}
          </div>
        )}
      </div>
      {children && children}
    </CardedContainerHeader>
));