// import { Controller } from "../../../../lib/controller";
// import { Rfq } from "../../lib/types/dataTypes";
// import { computed, observable, toJS } from "mobx";
// import { findRfqTopics, getGroupParty, getRfqTopics, topicToRfqWithShipperName } from "../../lib/common";
// import { GroupThread, Message, Thread, Topic } from "../../../../lib/types/topicTypes";
// import { RfqFormDTO } from "../../lib/types/rfqFormTypes";
// import { Group, Member } from "../../../../lib/types/dataTypes";
// import { stateCtrl } from "../../../../client/state";
// import { msgCtrl } from "../../../../client/msg";
// import { client } from "../../../../client/client";
// import { arrayFlat, contextReject, isEmpty, randomString } from "../../../../utils/helpers";
// import { topicTypeIds } from "../../lib/constants";
//
// export class RfqInboxController extends Controller {
//   @computed get group(): Group {
//     return stateCtrl.currentGroup;
//   };
//   @computed get party(): ReturnType<typeof getGroupParty> {
//     return getGroupParty(this.group);
//   };
//   @computed get member(): Member {
//     return client.findMyMemberByGroupId(this.group.id);
//   };
//   @computed get groupThread(): GroupThread {
//     return msgCtrl.groupThreads.find(gt => gt.groupId === this.group.id) || {} as GroupThread;
//   }
//   @computed get threads(): Thread[] {
//     return (this.groupThread || {}).threads || [];
//   };
//   @computed get totalUnreadCount(): number {
//     return this.groupThread.unreadCount || 0;
//   };
//   @computed get rfqTopics(): Topic<RfqFormDTO>[] {
//     if (!this.party) return [];
//     return findRfqTopics(this.party === "shipper" && this.group.id);
//   };
//   @computed get rfqs(): Rfq[] {
//     return toJS(this.rfqTopics).map(topicToRfqWithShipperName);
//   };
//   @computed get rfqMembers(): Member[] {
//     return arrayFlat(this.rfqTopics.map(topic => topic.members));
//   };
//   @computed get allMessages(): Message[] {
//     return arrayFlat(this.threads.map(thread => thread.messages));
//   };
//   @computed get unreadMessages(): Message[] {
//     return this.allMessages.filter(msg => msgCtrl.isUnread(msg, this.member.id));
//   };
//
//   loadAllData = async () => this.getRfqThreads();
//
//   clearThreads = () => {
//     const groupThreads = this.groupThread;
//     if (groupThreads) {
//       groupThreads.threads = [];
//       groupThreads.unreadCount = 0;
//     }
//   };
//
//   getRfqs = async () => this.party && getRfqTopics(this.group.id, this.party);
//
//   getRfqThreads = async () => {
//     const promises = [];
//     if (isEmpty(this.rfqTopics)) promises.push(this.getRfqs());
//     const groupId = Number(this.group.id), memberId = Number(this.member.id);
//     if (!this.party) return;
//     stateCtrl.unreadRefreshing[groupId] = true;
//     promises.push(
//       msgCtrl.getInboxUnreads(topicTypeIds.rfq, groupId, memberId, true)
//       .then(msgCtrl.flattenTopicThreads)
//       .then(threads => msgCtrl.updateGroupThreads(groupId, threads))
//       .finally(() => stateCtrl.unreadRefreshing[groupId] = false)
//     );
//     return Promise.all(promises);
//   };
//
//   findRfqThreads = (id: Rfq["id"]) => this.threads.filter(thread => thread.topicId === id);
//
//   onMessageRead = async (message: Message) => {
//     if (!msgCtrl.isUnread(message, this.member.id)) return;
//     if (message.senderMemberId === this.member.id) return;
//     return msgCtrl.readMessage(message.id, this.member.id)
//     .then(this.getRfqThreads);
//   };
// }

import { Controller } from "../../../../lib/controller";
import { Rfq } from "../../lib/types/dataTypes";
import { computed, observable, toJS } from "mobx";
import { findRfqTopics, getGroupParty, getRfqTopics, topicToRfqWithShipperName } from "../../lib/common";
import { Message, Thread, Topic } from "../../../../lib/types/topicTypes";
import { RfqFormDTO } from "../../lib/types/rfqFormTypes";
import { Group, Member } from "../../../../lib/types/dataTypes";
import { stateCtrl } from "../../../../client/state";
import { msgCtrl } from "../../../../client/msg";
import { client } from "../../../../client/client";
import { arrayFlat, contextReject, isEmpty, randomString } from "../../../../utils/helpers";

export class RfqInboxController extends Controller {
  @observable threads: Thread[] = [];
  @observable totalUnreadCount: number = 0;

  @computed get group(): Group {
    return stateCtrl.currentGroup;
  };
  @computed get party(): ReturnType<typeof getGroupParty> {
    return getGroupParty(this.group);
  };
  @computed get member(): Member {
    return client.findMyMemberByGroupId(this.group.id);
  };
  @computed get rfqTopics(): Topic<RfqFormDTO>[] {
    if (!this.party) return [];
    return findRfqTopics(this.party === "shipper" && this.group.id);
  };
  @computed get rfqs(): Rfq[] {
    return toJS(this.rfqTopics).map(topicToRfqWithShipperName);
  };
  @computed get rfqMembers(): Member[] {
    return arrayFlat(this.rfqTopics.map(topic => topic.members));
  };
  @computed get allMessages(): Message[] {
    return arrayFlat(this.threads.map(thread => thread.messages));
  };
  @computed get unreadMessages(): Message[] {
    return this.allMessages.filter(msg => msgCtrl.isUnread(msg, this.member.id));
  };

  loadAllData = async () => this.getRfqs().then(this.getRfqThreads);

  clearThreads = () => this.threads = [];

  getRfqs = async () => this.party && isEmpty(this.rfqTopics) && getRfqTopics(this.group.id, this.party);

  getRfqThreads = async () => {
    const groupId = Number(this.group.id), memberId = Number(this.member.id);
    // TODO: Raise this part to msgCtrl to support generic messaging thread logic.
    if (!this.party) return;
    stateCtrl.unreadRefreshing[groupId] = true;
    return Promise.all(this.rfqs.map(rfq =>
      msgCtrl.getThreadsAndMessagesByTopicId(rfq.id, memberId)
    ))
    .then(threads => this.threads = arrayFlat(threads).filter(Boolean))
    .then(() => this.updateUnreadCount(groupId, memberId))
    .finally(() => stateCtrl.unreadRefreshing[groupId] = false);
  };

  updateRfqThreads = async (id: Rfq["id"]) => {
    const groupId = Number(this.group.id), memberId = Number(this.member.id);
    const rfq = this.rfqs.find(rfq => rfq.id === id);
    if (!rfq) return;
    const threads = await msgCtrl.getThreadsAndMessagesByTopicId(rfq.id, this.member.id).catch(contextReject);
    for (const thread of threads) {
      const index = this.threads.findIndex(t => t.id === thread.id);
      if (index < 0) continue;
      // if (isEqual(toJS(this.threads[index]), thread)) return;
      this.threads[index] = thread;
    }
    this.updateUnreadCount(groupId, memberId);
  };

  updateUnreadCount = (groupId, memberId) => {
    this.totalUnreadCount = this.threads
    .filter(Boolean)
    .reduce(
      (a, b) => a += (b.messages && b.messages.filter(msg => msgCtrl.isUnread(msg, memberId)).length) || 0, 0
    );
    stateCtrl.updateHeaderUnreadCount(this.totalUnreadCount, groupId);
  };

  findRfqThreads = (id: Rfq["id"]) => this.threads.filter(thread => thread.topicId === id);

  findMessageById = (id: Message["id"]) =>
    arrayFlat(this.threads.map(thread => thread.messages)).find(msg => msg.id === id);

  findSenderMember = (msg: Message | Message["id"]) => {
    let message = msg as Message;
    if (typeof message === "number") {
      message = this.findMessageById(message);
    }
    return this.rfqMembers.find(m => m.id === message.senderMemberId);
  };

  onMessageSend = async (topicId, threadId, text) => {
    const rfq = this.rfqs.find(rfq => rfq.id === topicId);
    const thread = this.threads.find(thread => thread.id === threadId);
    if (!rfq || !thread) return;
    const watermark = randomString();
    const tempMessage: Message = {
      senderMemberId: this.member.id,
      text,
      threadId,
      topicId,
      watermark
    };
    if (!thread.messages) thread.messages = [];
    thread.messages.push(tempMessage);
    return msgCtrl.sendMessage(topicId, threadId, this.member.id, text, watermark)
    .then(() => this.updateRfqThreads(topicId));
  };

  onMessageRead = async (message: Message) => {
    if (!msgCtrl.isUnread(message, this.member.id)) return;
    if (message.senderMemberId === this.member.id) return;
    const thread = this.threads.find(thread => thread.id === message.threadId);
    return msgCtrl.readMessage(message.id, this.member.id)
    .then(() => this.updateRfqThreads(thread.topicId));
  };
}