/**
 * Boot file to ensure all CDM controllers are whacked onto the memory in order.
 */

import { api, Api, initApi } from "./api";
import { client, Client, initClient } from "./client";
import { initMsgCtrl, Messaging, msgCtrl } from "./msg";
import { initStateCtrl, State, stateCtrl } from "./state";
import { initTx, tx, Tx } from "./tx";
import { initUi, ui, UI } from "./ui";
import { observable, when } from "mobx";
import { env } from "../config/env";
import { FormController, formCtrl, initFormCtrl } from "./form";
import { initPlacesApi, Places, placesApi } from "./places";
import { initTopicCtrl, TopicController, topicCtrl } from "./topic";
import { UIText } from "./lang";

export class BootLoader {
  @observable ready: boolean = false;
  controllers: {
    api: Api;
    client: Client;
    formCtrl: FormController;
    msgCtrl: Messaging;
    placesApi: Places;
    stateCtrl: State;
    topicCtrl: TopicController;
    tx: Tx;
    ui: UI;
  };

  async boot() {
    this.controllers = {} as BootLoader["controllers"];
    this.controllers.api = initApi(new Api());
    this.controllers.client = initClient(new Client());
    this.controllers.formCtrl = initFormCtrl(new FormController());
    this.controllers.msgCtrl = initMsgCtrl(new Messaging());
    this.controllers.stateCtrl = initStateCtrl(new State());
    this.controllers.topicCtrl = initTopicCtrl(new TopicController());
    this.controllers.placesApi = initPlacesApi(new Places());
    this.controllers.tx = initTx(new Tx());
    this.controllers.ui = initUi(new UI());
    this._assignGlobal();
    return this._waitReady();
  }

  private readonly _assignGlobal = () => {
    if (env === "prod") return;
    for (const controller in this.controllers) {
      (window as any)[controller] = this.controllers[controller];
    }
  };

  private readonly _waitReady = () => api.storage.isReady()
  .then(client.storage.isReady)
  .then(formCtrl.storage.isReady)
  .then(msgCtrl.storage.isReady)
  .then(stateCtrl.storage.isReady)
  .then(topicCtrl.storage.isReady)
  .then(placesApi.storage.isReady)
  .then(tx.storage.isReady)
  .then(ui.storage.isReady)
  .then(() => when(() => UIText.ready))
  .then(() => this.ready = true);
}