import React, { KeyboardEvent, MouseEvent } from "react";
import BaseColumn from "../BaseColumn";
import { UIText } from "../../../../client/lang";
import "./styles.less";
import { QuotationService, Solution } from "../../lib/types/dataTypes";
import Accordion from "../../../../components/Accordion";
import { observer, Observer } from "mobx-react";
import { getPriceString, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import QuotationServiceCard from "../QuotationServiceCard";
import { IonButton, IonButtons, IonCard, IonCardHeader, IonIcon, IonInput, IonText } from "@ionic/react";
import { addOutline, chatbubblesSharp, checkmarkOutline, trashOutline } from "ionicons/icons";
import { getIonStyleVal } from "../../../../config/styles/style-utils";
import { RfqDetailColumnProps } from "../../lib/types/propTypes";
import { ui } from "../../../../client/ui";
import { getQuotationServicesPrice, sortQuotationServicesByDate } from "../../lib/common";
import MdIcon from "../../../../components/MdIcon";
import { mdiSeal, mdiStarOff } from "@mdi/js";

export interface SolutionsColumnProps extends RfqDetailColumnProps {
  quotationServices: QuotationService[];
  solutions: Solution[];
  onRemoveFromSolution?: (event: MouseEvent, id: QuotationService["id"], solutionId: Solution["id"]) => void;
  onRemoveSolution?: (event: MouseEvent, id: Solution["id"]) => void;
  onSolutionClick?: (event: MouseEvent, id: Solution["id"]) => void;
  onNewSolution?: (event: MouseEvent) => void;
  onNewSolutionNameChange?: (event: KeyboardEvent, id: Solution["id"]) => void;
  onNewSolutionSave?: (event: MouseEvent, id: Solution["id"]) => void;
  onAwardBusiness?: (event: MouseEvent, id: Solution["id"]) => void;
}

@observer
class SolutionsColumn extends React.Component<SolutionsColumnProps> {
  getSolutionTitle = (solution: Solution, quotationServices: QuotationService[]) => {
    const totalPrice: number = getQuotationServicesPrice(quotationServices);
    const priceStr: string = getPriceString(totalPrice, null, true, true);
    return `${solution.description} • ${priceStr}`;
  };

  getExpanded = (id: Solution["id"]) => {
    const { isAccordionExpanded } = this.props;
    return isAccordionExpanded(id);
  };

  handleAccordionClick = (id: Solution["id"]) => {
    const { onAccordionFold } = this.props;
    const isExpanded = this.getExpanded(id);
    if (onAccordionFold) return onAccordionFold(id, !isExpanded);
  };

  renderNewSolutionField = (solution: Solution) => {
    const handleRemoveSolution = event => this.props.onRemoveSolution(event, solution.id);
    const handleNameChange = event => this.props.onNewSolutionNameChange(event, solution.id);
    const handleSave = event => this.props.onNewSolutionSave(event, solution.id);
    return <Observer key={solution.id}>{() =>
      <IonCard className="ion-margin" style={{ backgroundColor: getIonStyleVal("--ion-color-light") }}>
        <IonCardHeader className="ion-no-padding flex ion-justify-content-between ion-align-items-center">
          <IonInput
            placeholder={UIText.solutionName}
            className="ion-no-padding ion-padding-start textDark font-s"
            style={{ minHeight: 36 }}
            onIonChange={handleNameChange}
            {...ui.bindKeyCode("onKeyUp", {
              13: handleSave
            })}
          />
          <IonButton
            className="ion-no-margin"
            fill="clear"
            // size={ui.isMobile ? "small" : null}
            onClick={handleSave}
          >
            <IonIcon slot="icon-only" color="tooling" icon={checkmarkOutline}/>
          </IonButton>
          <IonButton
            className="ion-no-margin"
            fill="clear"
            // size={ui.isMobile ? "small" : null}
            onClick={handleRemoveSolution}
          >
            <IonIcon slot="icon-only" color="tooling" icon={trashOutline}/>
          </IonButton>
        </IonCardHeader>
      </IonCard>
    }</Observer>;
  };

  renderSolutions = (solution: Solution) => {
    const {
      onRemoveFromSolution,
      onRemoveSolution,
      // onSolutionClick,
      onQuotationServiceCardClick,
      onChatClick,
      onAwardBusiness
    } = this.props;
    if (solution.new) return this.renderNewSolutionField(solution);
    const allQuotes = this.props.quotationServices;
    const handleAccordionClick = (event: MouseEvent<HTMLIonButtonElement>) => {
      preventDefaultStopProp(event);
      return this.handleAccordionClick(solution.id);
    };
    // const handleSolutionClick = (event: MouseEvent) => onSolutionClick(event, solution.id);
    const handleRemoveSolution = (event: MouseEvent) => onRemoveSolution(event, solution.id);
    const handleSolutionAwardBusiness = (event: MouseEvent) => onAwardBusiness(event, solution.id);
    const quotationServiceIds = solution.quotationServiceIds || [];
    const quotationServices = allQuotes.filter(q => quotationServiceIds.includes(q.id));
    return <Accordion
      className="ion-margin"
      key={solution.id}
      color={solution.isAwarded ? "primary" : "light"}
      rightButton={
        <div className="flex ion-align-items-center">
          {((!isEmpty(quotationServices) && !this.props.readonly) || solution.isAwarded) && (
            <IonButton
              className="ion-no-margin"
              fill={solution.isAwarded ? "solid" : "clear"}
              style={solution.isAwarded ? { "--box-shadow": "none" } : undefined}
              onClick={!solution.isAwarded && handleSolutionAwardBusiness}
            >
              <MdIcon icon={mdiSeal} color={solution.isAwarded ? "warning" : "primary"} />
            </IonButton>
          )}
          {/*<IonButton*/}
          {/*  className="ion-no-margin"*/}
          {/*  fill="clear"*/}
          {/*  onClick={handleSolutionClick}*/}
          {/*>*/}
          {/*  <IonIcon color="tooling" icon={receiptOutline} slot="icon-only" />*/}
          {/*</IonButton>*/}
          {!solution.isAwarded && !this.props.readonly && (
            <IonButton
              className="ion-no-margin"
              fill="clear"
              onClick={handleRemoveSolution}
            >
              <IonIcon slot="icon-only" color="tooling" icon={trashOutline}/>
            </IonButton>
          )}
        </div>
      }
      backgroundColor={getIonStyleVal("--ion-color-light")}
      title={<span className={`textBold ${solution.isAwarded ? "textLight" : "textDark"}`}>
        {this.getSolutionTitle(solution, quotationServices)}
      </span>}
      expanded={this.getExpanded(solution.id)}
      isMobile={ui.isMobile}
      onClick={handleAccordionClick}
    >
      <div className="ion-padding">
        {solution.isAllIn && (
          <IonButtons className="ion-justify-content-between baseColumnToolbar">
            <span>
              <IonText color="primary" className="font-xs">{UIText.allInSolution}</IonText><br />
              <IonText className="font-s" color="tooling">{(quotationServices[0] || {}).provider}</IonText>
            </span>
            <IonButton
              color="primary"
              onClick={e => onChatClick(e, (quotationServices[0] || {}).id)}
            >
              <IonIcon slot="start" icon={chatbubblesSharp} />
              {UIText.chatWithLSP}
            </IonButton>
          </IonButtons>
        )}
        {solution.isAwarded && (
          <IonButtons
            className={`baseColumnToolbar ${
              solution.isAllIn ? "ion-justify-content-center" : "ion-justify-content-between extraPadding"
            }`}
          >
            <IonText className="font-s flex ion-align-items-center" color="primary">
              <MdIcon icon={mdiSeal} />&nbsp;{UIText.awarded}
            </IonText>
          </IonButtons>
        )}
        {quotationServices.length === 0 ? (
          <div className="ion-text-center ion-padding">{UIText.noQuotations}</div>
        ) : (
          quotationServices
          .sort(sortQuotationServicesByDate)
          .map(quote => {
            const handleStarClick = event => {
              preventDefaultStopProp(event);
              return onRemoveFromSolution(null, quote.id, solution.id);
            };
            const handleChatClick = event => onChatClick(event, quote.id);
            const handleCardClick = event => {
              preventDefaultStopProp(event);
              return onQuotationServiceCardClick(null, quote.id)
            };
            return <QuotationServiceCard
              key={quote.id}
              quote={quote}
              noStar={solution.isAllIn || solution.isAwarded || this.props.readonly}
              // starColor={this.isInSolution(quote.id) ? "warning" : "medium"}
              starIcon={<MdIcon icon={mdiStarOff} color="tooling" />}
              handleStarClick={handleStarClick}
              handleChatClick={!solution.isAllIn && handleChatClick}
              onClick={handleCardClick}
            />;
          })
        )}
        <div className="quotationRemarks ion-padding">
          <IonText color="tooling" className="font-s textBold">
            {UIText.remarks}:
          </IonText>
          <div className={`ion-padding quotationRemarksContent ${solution.remarks ? "ion-text-left" : "ion-text-center"}`}>
            {!solution.remarks ? (
              <IonText>{UIText.noRemarksAvailable}</IonText>
            ) : (
              <IonText color="dark" className="textPre">{solution.remarks}</IonText>
            )}
          </div>
        </div>
      </div>
    </Accordion>;
  };

  render() {
    const {
      readonly,
      solutions,
      onNewSolution
    } = this.props;

    const Header = <div className="flex ion-justify-content-between ion-align-items-center">
      <IonText className="font-m baseColumnTitle">{UIText.favoriteQuotations}</IonText>
      {!readonly && <IonButton color="light" size="small" shape="round" onClick={onNewSolution}>
        <IonIcon slot="icon-only" color="tooling" icon={addOutline}/>
      </IonButton>}
    </div>;

    return <BaseColumn className="solutionsColumn" header={Header}>
      {solutions.map(this.renderSolutions)}
    </BaseColumn>
  }
}

export default SolutionsColumn;