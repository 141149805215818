import React, { MouseEvent } from "react";
import { IonBadge, IonButton, IonButtons, IonHeader, IonIcon, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import { observer } from "mobx-react";
import "./styles.less";
import { caretDownSharp, languageOutline, pricetagsSharp, readerSharp } from "ionicons/icons";
import { mdiCog, mdiEmail, mdiLogoutVariant } from "@mdi/js";
import UserGroupMenu from "../UserGroupMenu";
import { RouteComponentProps } from "react-router";
import { computed } from "mobx";
import HeaderAdminMenu, { showAdminMenu } from "../HeaderAdminMenu";
import { getDisplayNameEng, preventDefaultStopProp } from "../../../../utils/helpers";
import { stateCtrl } from "../../../../client/state";
import { client } from "../../../../client/client";
import { ui } from "../../../../client/ui";
import { defaultRoute } from "../../../../config/routes";
import { Group } from "../../../../lib/types/dataTypes";
import { groupTypeIds } from "../../../../config/constants";
import { UIText } from "../../../../client/lang";
import MdIcon from "../../../../components/MdIcon";

export interface HeaderNavProps {
  history: RouteComponentProps["history"];
  onLangClick?: (event: MouseEvent) => void;
}

// const currentRouteStyles = {
//   color: getIonStyleVal("--ion-color-primary-tint"),
//   fontWeight: "600",
//   borderBottom: `1px solid ${getIonStyleVal("--ion-color-primary")}`
// };

// const isCurrentRouteStyle = (routeName: string, currentRoute: string) =>
//   currentRoute.match(routeName)
//   ? currentRouteStyles
//   : null;

@observer
class HeaderNav extends React.Component<HeaderNavProps> {
  disposer;

  // @observable inboxLoading: boolean = false;
  inboxLoadingTimer;

  @computed get name(): string {
    return getDisplayNameEng(stateCtrl.currentGroup.profile || client.defaultProfile);
  };
  @computed get inboxLoading(): boolean {
    return stateCtrl.unreadRefreshing[stateCtrl.currentGroupId];
  }

  componentWillUnmount(): void {
    this.disposer && this.disposer();
  }

  handleSetupClick = e => {
    preventDefaultStopProp(e);
    ui.dismissPopover();
    return this.props.history.push(defaultRoute.path);
  };

  handleLogoutClick = e => {
    preventDefaultStopProp(e);
    ui.dismissPopover();
    return stateCtrl.logoutResetPathname(e);
  };

  handleGroupClick = (e, groupId) => {
    preventDefaultStopProp(e);
    ui.dismissPopover();
    return this.props.history.push(`/Group/${groupId}`)
  };

  handleProfileClick = (e, groupId) => {
    preventDefaultStopProp(e);
    ui.dismissPopover();
    const profileId = (client.nonDefaultGroups.find(g => g.id === groupId) || {} as Group).profileId;
    if (!profileId) return;
    return this.props.history.push(`/Group/${groupId}/Profile/${profileId}`);
  };

  handleInboxClick = e => {
    preventDefaultStopProp(e);
    ui.dismissPopover();
    return this.props.history.push(`/Group/${stateCtrl.currentGroupId}/Inbox`);
  };

  handleSettingsClick = e => {
    preventDefaultStopProp(e);
    ui.dismissPopover();
    return this.props.history.push(`/Group/${client.defaultGroup.id}/Profile/${client.defaultProfile.id}`);
  };

  handleRateManagementClick = e => {
    preventDefaultStopProp(e);
    ui.dismissPopover();
    return this.props.history.push(`/Group/${stateCtrl.currentGroupId}/Rates`);
  };

  handleShippingChargesClick = e => {
    preventDefaultStopProp(e);
    ui.dismissPopover();
    return this.props.history.push(`/Charges`);
  };

  handleRfqListClick = e => {
    preventDefaultStopProp(e);
    ui.dismissPopover();
    return this.props.history.push(`/Group/${stateCtrl.currentGroupId}`);
  };

  handleAdminMenuClick = e => {
    e.persist();
    if (!client.user.isSa) return;
    return showAdminMenu(this.props.history)(e);
  };

  showGroupDropdown = (event: any) =>
    ui.popover({
      event,
      component: () => <UserGroupMenu
        originalEvent={event}
        groups={client.nonDefaultGroups}
        onSetup={this.handleSetupClick}
        onLogout={this.handleLogoutClick}
        onGroup={this.handleGroupClick}
        onProfile={this.handleProfileClick}
        onInbox={this.handleInboxClick}
        onSettings={this.handleSettingsClick}
        onRates={stateCtrl.currentGroup.typeId === groupTypeIds.lsp && this.handleRateManagementClick}
        onRfqs={stateCtrl.currentGroup.typeId === groupTypeIds.lsp && this.handleRfqListClick}
        onAdminMenu={client.user.isSa && stateCtrl.currentGroup.typeId !== groupTypeIds.shipper && this.handleAdminMenuClick}
        onLang={this.props.onLangClick}
      />,
      cssClass: "userGroupMenuContainer",
      showBackdrop: false
    });

  render() {
    const { onLangClick, history } = this.props;
    const unreadCount = stateCtrl.unreadCounters[stateCtrl.currentGroupId];

    return <IonHeader className="headerNav" translucent>
      <IonToolbar>
        <IonButton fill="clear" routerLink={defaultRoute.path} className="ion-no-margin ion-no-padding textNoTransform noHover headerLogoButton">
          <img src={require("../../../../assets/logo_cropped.png")} slot="start" alt={UIText.titleLong} />
          {!ui.isViewMax && <IonTitle className="ion-no-padding textBold font-m ion-margin-start">
            {UIText.titleLong}
          </IonTitle>}
        </IonButton>
        <IonButtons slot="primary">
          {/*LSP RFQ Entry*/}
          {stateCtrl.currentGroup.typeId === groupTypeIds.lsp && !ui.isMobileIntermediate && <>
            <IonButton
              className="textBold textNoTransform textUnderline"
              color="primary"
              routerLink={`/Group/${stateCtrl.currentGroupId}`}
            >
              <IonIcon slot="start" icon={readerSharp} />
              {UIText.RFQs}
            </IonButton>
          </>}
          {/*Shipping Rate Entry*/}
          {stateCtrl.currentGroup.typeId === groupTypeIds.lsp && !ui.isMobileIntermediate && <>
            <IonButton
              className="textBold textNoTransform textUnderline"
              color="primary"
              onClick={this.handleRateManagementClick}
            >
              <IonIcon slot="start" icon={pricetagsSharp} />
              {UIText.rateManagement.shippingRates}
            </IonButton>
          </>}
          {/*Admin Menu Entry*/}
          {client.user.isSa && !ui.isMobileIntermediate && stateCtrl.currentGroup.typeId !== groupTypeIds.shipper && (
            <HeaderAdminMenu history={history} />
          )}

          {client.isLoggedIn && <>
            <IonButton
              className="userGroupMenuButton textBold"
              fill="clear"
              onClick={this.showGroupDropdown}
            >
              {/*{UIText.helloUser(getDisplayNameEng(client.defaultProfile))}*/}
              {/*{UIText.helloUser(name)}*/}
              <span>{this.name}</span>
              <IonIcon icon={caretDownSharp} color="dark" className="font-xs ion-margin-start"/>
            </IonButton>
          </>}
        </IonButtons>
        <IonButtons slot="end" className="ion-margin-horizontal">
          {/*<PopoverHoverButton title="language" color="primary" popoverContent={<div>Testing</div>} />*/}
          {!ui.isMobile && client.isLoggedIn && <>
            <IonButton
              color="primary"
              fill="clear"
              className="textBold textNoTransform"
              onClick={this.handleInboxClick}
            >
              {this.inboxLoading ? <IonSpinner style={{ maxWidth: 24 }} /> : <MdIcon icon={mdiEmail} color="primary"/>}
              {unreadCount > 0 && !this.inboxLoading && <IonBadge color="tooling">{unreadCount}</IonBadge>}
            </IonButton>
            <IonButton
              color="primary"
              fill="clear"
              className="textBold textNoTransform"
              onClick={this.handleSettingsClick}
            >
              <MdIcon icon={mdiCog} color="primary"/>
            </IonButton>
            <IonButton
              color="primary"
              fill="clear"
              className="textBold textNoTransform"
              onClick={stateCtrl.logoutResetPathname}
            >
              <MdIcon icon={mdiLogoutVariant} color="primary"/>
            </IonButton>
          </>}
          {!client.isLoggedIn && (
            <IonButton
              color="primary"
              fill="clear"
              className="textBold textNoTransform"
              routerLink="/Login"
            >
              {UIText.login}
            </IonButton>
          )}
          {!client.isLoggedIn && (
            <IonButton
              color="primary"
              fill="clear"
              className="textBold textNoTransform"
              routerLink="/Register"
            >
              {UIText.signUp}
            </IonButton>
          )}
          <IonButton slot="icon-only" fill="clear" onClick={onLangClick}>
            <IonIcon icon={languageOutline}/>
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>;
  }
}

export default HeaderNav;