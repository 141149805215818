// TODO: Hey, https://fusejs.io/
import { RfqFormDTO, RfqFormItemDTO } from "./types/rfqFormTypes";
import { Rfq } from "./types/dataTypes";
import { arrayFlat, getDisplayNameEng, isEmpty } from "../../../utils/helpers";
import { Carrier, ShippingCharge, ShippingPlan, ShippingPort, ShippingRate, ShippingRateLeg, ShippingRateUnit } from "./types/rateTypes";
import { UIText } from "../../../client/lang";
import { getPortDisplayCode, getPortName } from "./common";


// TODO: Complete the new properties
export const matchRfq = (rfq: RfqFormDTO | Rfq, match: string | number) => {
  match = match && match.toString().trim();
  const regExp = new RegExp(match, "i");
  const {
    mode,
    altModes,
    POL,
    POD,
    items,
    doorToDoor,
    doorToPort,
    portToDoor,
    packing,
    storage,
    // inspection,
    description,
    shipperName
  } = rfq as Rfq;
  const {
    cargoReadyDate,
    cargoArrivalDate,
    otherInformation,
    reference
  } = rfq as RfqFormDTO;
  const goodsList = items && (items as RfqFormItemDTO[]).map(item => item.goods);
  const addressList = arrayFlat([
    (doorToDoor || {}).deliveryAddress,
    (doorToDoor || {}).pickUpAddress,
    (doorToPort || {}).pickUpAddress,
    (portToDoor || {}).deliveryAddress,
    (packing || {}).location,
    (storage || {}).location,
  ]).filter(Boolean);
  const modeList = [mode, ...(altModes || [])].filter(Boolean);
  return (
    (POL && POL.match(regExp)) ||
    (POD && POD.match(regExp)) ||
    (description && description.toString().match(regExp)) ||
    (shipperName && shipperName.toString().match(regExp)) ||
    (goodsList && goodsList.some(g => typeof g === "string" && g.match(regExp))) ||
    (cargoReadyDate && new Date(cargoReadyDate).toDateString().match(regExp)) ||
    (cargoArrivalDate && new Date(cargoArrivalDate).toDateString().match(regExp)) ||
    (otherInformation && otherInformation.match(regExp)) ||
    (reference && reference.match(regExp)) ||
    (addressList.some(a => typeof a === "string" && a.match(regExp))) ||
    (modeList.some(m => typeof m === "string" && m.match(regExp)))
  );
};


export const matchPlanByPolPod = (plan: ShippingPlan, matchPol: string, matchPod: string) => {
  matchPol = matchPol && matchPol.toString().trim();
  matchPod = matchPod && matchPod.toString().trim();
  if (isEmpty(matchPol) && isEmpty(matchPod)) return true;
  const { shippingRates } = plan;
  return shippingRates.some(rate => matchRateByPolPod(rate, matchPol, matchPod));
};


export const matchRateByPolPod = (rate: (ShippingRate | ShippingRateLeg), matchPol: string, matchPod: string) => {
  matchPol = matchPol && matchPol.toString().trim();
  matchPod = matchPod && matchPod.toString().trim();
  if (isEmpty(matchPol) && isEmpty(matchPod)) return true;
  const polRegExp = new RegExp(matchPol, "i");
  const podRegExp = new RegExp(matchPod, "i");
  const {
    polPort,
    podPort,
    shippingRateLegs
  } = rate as ShippingRate;
  const matchedPol = (
    (polPort && getPortDisplayCode(polPort) && getPortDisplayCode(polPort).match(polRegExp)) ||
    (polPort && getPortName(polPort) && getPortName(polPort).match(polRegExp)) ||
    (shippingRateLegs && shippingRateLegs.some((leg: ShippingRateLeg) => matchRateByPolPod(leg, matchPol, null)))
  );
  const matchedPod = (
    (podPort && getPortDisplayCode(podPort) && getPortDisplayCode(podPort).match(podRegExp)) ||
    (podPort && getPortName(podPort) && getPortName(podPort).match(podRegExp)) ||
    (shippingRateLegs && shippingRateLegs.some((leg: ShippingRateLeg) => matchRateByPolPod(leg, null, matchPod)))
  );
  return (!!matchPol && !!matchPod && matchedPol && matchedPod) ||
    (!matchPol && !!matchPod && matchedPod) ||
    (!!matchPol && !matchPod && matchedPol);
};

export const matchPlan = (plan: ShippingPlan, match: string | number) => {
  if (isEmpty(match)) return true;
  match = match && match.toString().trim();
  const {
    description,
    shippingRates,
    lspGroup
  } = plan;
  const regExp = new RegExp(match, "i");
  return (
    (description && description.toString().match(regExp)) ||
    (shippingRates.some(rate => matchRate(rate, match))) ||
    (lspGroup && lspGroup.profile && (() => {
      const displayName = getDisplayNameEng(lspGroup.profile);
      if (displayName) return displayName.match(regExp);
    })())
  );
};


export const matchRate = (rate: ShippingRate, match: string | number) => {
  match = match && match.toString().trim();
  const regExp = new RegExp(match, "i");
  const {
    shippingMode,
    polPort,
    podPort,
    carrierGroup,
    // shippingRateUnits,
    container,
  } = rate;
  return (
    (shippingMode && shippingMode.mode && shippingMode.mode.toString().match(regExp)) ||
    (shippingMode && shippingMode.mode && ((shippingMode.localeMode || {})[UIText.preference] || "").toString().match(regExp)) ||
    (polPort && getPortDisplayCode(polPort) && getPortDisplayCode(polPort).match(regExp)) ||
    (podPort && getPortDisplayCode(podPort) && getPortDisplayCode(podPort).match(regExp)) ||
    (polPort && getPortName(polPort) && getPortName(polPort).match(regExp)) ||
    (podPort && getPortName(podPort) && getPortName(podPort).match(regExp)) ||
    (container && container.toString().match(regExp)) ||
    // Temporary disable for performance reason
    // (shippingRateUnits && shippingRateUnits.some(shippingRateUnit => matchShippingRateUnit(shippingRateUnit, match))) ||
    (carrierGroup && carrierGroup.profile && (() => {
      const displayName = getDisplayNameEng(carrierGroup.profile);
      if (displayName) return displayName.match(regExp);
    })())
  );
};


export const matchShippingRateUnit = (rateUnit: ShippingRateUnit, match: string | number) => {
  match = match && match.toString().trim();
  const regExp = new RegExp(match, "i");
  const {
    shippingUnit
  } = rateUnit;
  return shippingUnit && ((shippingUnit.unitName || {})[UIText.preference] || "").toString().match(regExp);
};


export const matchShippingCharge = (charge: ShippingCharge, match: string | number) => {
  match = match && match.toString().trim();
  const regExp = new RegExp(match, "i");
  const {
    baseCode,
    chargeName,
    description
  } = charge;
  return (
    (baseCode && baseCode.toString().match(regExp)) ||
    (!isEmpty(chargeName) && chargeName[ UIText.preference ] && chargeName[ UIText.preference ].toString().match(regExp)) ||
    (!isEmpty(description) && chargeName[ UIText.preference ] && description[ UIText.preference ].toString().match(regExp))
  );
};


export const matchCarrier = (carrier: Carrier, match: string | number) => {
  match = match && match.toString().trim();
  const regExp = new RegExp(match, "i");
  const profileData = (carrier.profile || {}).data;
  const {
    displayName,
    tradeName,
    abbreviation,
    country,
    type
  } = profileData;
  const { id } = carrier;
  return (
    (displayName && displayName.toString().match(regExp)) ||
    (tradeName && tradeName.toString().match(regExp)) ||
    (abbreviation && abbreviation.toString().match(regExp)) ||
    (country && country.toString().match(regExp)) ||
    (type && type.toString().match(regExp)) ||
    (id && id.toString().match(regExp))
  );
};

export const matchShippingPort = (port: ShippingPort, match: string | number) => {
  match = match && match.toString().trim();
  const regExp = new RegExp(match, "i");
  const {
    iso3166A2Code,
    iso3166A3Code,
    portCode,
    portName
  } = port;
  return (
    (iso3166A2Code && iso3166A2Code.toString().match(regExp)) ||
    (iso3166A3Code && iso3166A3Code.toString().match(regExp)) ||
    (portCode && portCode.toString().match(regExp)) ||
    (!isEmpty(portName) && portName[ UIText.preference ] && portName[ UIText.preference ].toString().match(regExp))
  );
};