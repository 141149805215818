import * as React from "react";
import { MouseEvent, ReactNode } from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonIcon, IonText } from "@ionic/react";
import { caretDown, caretUp } from "ionicons/icons";
import "./styles.less";
import { observable, reaction } from "mobx";
import { observer } from "mobx-react";
import { asyncPause } from "../../utils/helpers";
import { ComponentRef } from "@ionic/core";

export interface AccordionProps extends Omit<React.ComponentProps<typeof IonButton>, "title"> {
  title?: string | ReactNode;
  headerComponent?: ReactNode | Element | ComponentRef | React.ReactElement | null;
  useDiv?: boolean;
  expanded?: boolean;
  animated?: boolean;
  leftButton?: ComponentRef | React.ReactElement | null;
  rightButton?: ComponentRef | React.ReactElement | null;
  backgroundColor?: string;
  duration?: number;
  isMobile?: boolean;
}

@observer
class Accordion extends React.Component<AccordionProps> {
  disposer;
  contentHeightDebouncer;

  @observable elm;
  @observable targetHeight: number = 0;
  @observable contentHeight: number = 0;
  @observable _expanded: boolean = true;

  get expanded(): boolean {
    return this.props.expanded === undefined ? this._expanded : this.props.expanded;
  };

  componentDidUpdate() {
    if (this.expanded) return this.ensureHeight();
  }

  componentDidMount() {
    this.disposer = reaction(() => this.targetHeight, this.setContentHeightWithDebounce);
    return this.componentDidUpdate();
  }

  componentWillUnmount(): void {
    this.disposer && this.disposer();
  }

  regElm = ref => this.elm = ref;

  setHeight = () => {
    if (this.elm && this.elm.scrollHeight) this.targetHeight = this.elm.scrollHeight;
  };

  setContentHeightWithDebounce = () => {
    clearTimeout(this.contentHeightDebouncer);
    this.contentHeightDebouncer = setTimeout(() => (
      this.contentHeight = this.targetHeight
    ), 100);
  };

  ensureHeight = () => {
    let counter = 0;
    const getHeight = async () => {
      if (!this.elm) return setTimeout(getHeight);
      if (this.targetHeight !== this.elm.scrollHeight) {
        counter = 0;
        this.setHeight();
      }
      await asyncPause(20);
      counter++;
      return counter < 50 && getHeight();
    };
    return getHeight();
  };

  // recursiveSetHeight = async () => {
  //   await asyncPause(20);
  //   if (this.elm && this.elm.scrollHeight) {
  //     this.setHeight();
  //   }
  //   return this.recursiveSetHeight();
  // };

  toggleExpand = (event: any) => this._expanded = !this._expanded;

  handleClick = (event: MouseEvent<HTMLIonButtonElement & HTMLDivElement>) => {
    this.setHeight();
    this.props.onClick
      ? this.props.onClick(event)
      : this.toggleExpand(event);
  };

  render() {
    const {
      title,
      headerComponent,
      useDiv,
      color,
      animated,
      children,
      className,
      backgroundColor,
      duration,
      leftButton,
      rightButton,
      isMobile
    } = this.props;

    const Header = headerComponent ? (
      <div onClick={this.handleClick}>{headerComponent}</div>
    ) : (
      <IonCardHeader translucent className="ion-no-padding flex ion-justify-content-between">
        {leftButton || null}
        <IonButton
          expand="full"
          fill="solid"
          color={color}
          className="accordionHeaderButton ion-no-margin"
          onClick={this.handleClick}
        >
          <IonText className={`accordionHeaderTitle textNoTransform ion-text-left  ${isMobile? "font-xs" : "font-s"}`}>
            {title}
          </IonText>
          <IonIcon slot="end" icon={this.expanded ? caretUp : caretDown} />
        </IonButton>
        {rightButton || null}
      </IonCardHeader>
    );

    const maxHeightSetter = {
      maxHeight: this.expanded ? this.contentHeight : 0,
      ...(duration && {
        transitionDuration: `${duration / 1000}s`
      })
    };

    return useDiv ? (
      <div
        className={`accordion ${animated === false ? "" : "animated"} ${this.expanded ? "expanded" : ""} ${className || ""}`}
        style={{ backgroundColor }}
      >
        {Header}
        <div
          ref={this.regElm}
          className="flex column ion-no-padding accordionContent"
          style={maxHeightSetter}
        >
          {children && <div>{children}</div>}
        </div>
      </div>
    ) : (
      <IonCard
        className={`accordion ${animated === false ? "" : "animated"} ${className || ""}`}
        style={{ backgroundColor }}
      >
        {Header}
        <IonCardContent
          ref={this.regElm}
          className="flex column ion-no-padding accordionContent"
          style={maxHeightSetter}
        >
          {children && <div>{children}</div>}
        </IonCardContent>
      </IonCard>
    );
  }
}

export default Accordion;