import { observer } from "mobx-react";
import { IonCard, IonItem, IonLabel, IonList } from "@ionic/react";
import React, { MouseEvent } from "react";
import { Message, Thread } from "../../lib/types/topicTypes";
import { ui } from "../../client/ui";
import Accordion from "../Accordion";
import "./styles.less";
import MdIcon from "../MdIcon";
import { mdiEmail } from "@mdi/js";
import { isEmpty } from "../../utils/helpers";

export interface InboxItemProps extends React.ComponentProps<typeof IonCard>{
  header: string;
  threads?: Thread[];
  expanded?: boolean;
  onClick?: (event: MouseEvent) => void;
  onThreadClick?: (event: MouseEvent, id: Thread["id"]) => void;
  getThreadDescription: (thread: Thread) => string | React.ReactNode;
  getThreadUnreads: (thread: Thread) => number;
  hideEmptyThread?: boolean;
}

const InboxItem: React.FC<InboxItemProps> = observer(
  ({
     className,
     header,
     threads,
     expanded,
     onClick,
     onThreadClick,
     getThreadDescription,
     getThreadUnreads,
     hideEmptyThread
  }) => {
    const hasUnread = threads && threads.some(getThreadUnreads);
    return <Accordion
      className={`inboxItem ${className || ""}`}
      color={hasUnread ? "primary" : "light"}
      // backgroundColor={getIonStyleVal("--ion-color-light")}
      title={
        <span className="flex ion-align-items-center">
          {hasUnread && <MdIcon icon={mdiEmail} className="ion-margin-end"/>}
          {header}
        </span>
      }
      expanded={expanded}
      isMobile={ui.isMobile}
      onClick={onClick}
    >
      {threads && <IonList className="ion-no-padding">
        {threads
        .filter(threads => !isEmpty(threads.messages))
        .map(thread => {
          const lastMessage = (thread.messages && thread.messages[thread.messages.length - 1]) || {} as Message;
          const hasUnread = getThreadUnreads(thread) > 0;
          return <IonItem
            button
            key={thread.id}
            color={hasUnread ? "tooling" : undefined}
            onClick={e => onThreadClick(e, thread.id)}
          >
            <IonLabel className="flex column">
              <span className={`font-s threadName ${hasUnread ? "textLight" : "textPrimary"}`}>
                {getThreadDescription(thread)}
              </span>
              <span className={`font-xs messagePreview ${hasUnread ? "textLight" : "textMedium"}`}>
                {lastMessage.text || ""}
              </span>
            </IonLabel>
          </IonItem>;
        })}
      </IonList>}
    </Accordion>;
  });

export default InboxItem;