import * as React from "react";
import { observer } from "mobx-react";
import { IonPopover } from "@ionic/react";
import { ui } from "../../client/ui";

const PopoverContainer: React.FC = observer(props => (
  <IonPopover
    cssClass="global-popover-container"
    isOpen={ui.popoverVisible}
    onDidDismiss={ui.onDismissPopover}
    {...ui.popoverProps}
  >
    {ui.popoverComponent && <ui.popoverComponent />}
  </IonPopover>
));

export default PopoverContainer;