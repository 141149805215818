import { Controller } from "../../../../lib/controller";
import { ShippingMode, ShippingRateBreakdown } from "../../lib/types/rateTypes";
import { api } from "../../../../client/api";
import { endpointConfig } from "../../../../config/api";
import { computed, IObservableArray, observable } from "mobx";
import { getShippingModes, getShippingRateBreakdowns } from "../../lib/common";
import { UIText } from "../../../../client/lang";
import { SortableStore } from "../../../../lib/types/miscTypes";
import { client } from "../../../../client/client";
import { isEqual } from "../../../../utils/helpers";

export interface ShippingModeListStore extends SortableStore<ShippingMode> {}

export class ShippingModeListController extends Controller<ShippingModeListStore> {
  @observable shippingModes: IObservableArray<ShippingMode> = [] as IObservableArray<ShippingMode>;
  @observable shippingRateBreakdowns: IObservableArray<ShippingRateBreakdown> = [] as IObservableArray<ShippingRateBreakdown>;

  @computed get isSa(): boolean {
    return !!client.user.isSa;
  };

  loadAllData = () => Promise.all([
    this.getShippingModes(),
    this.getShippingRateBreakdowns()
  ]);

  getShippingModes = async () => getShippingModes()
  .then(shippingModes => this.shippingModes = shippingModes);

  getShippingRateBreakdowns = async () => getShippingRateBreakdowns()
  .then(shippingRateBreakdowns => this.shippingRateBreakdowns = shippingRateBreakdowns);

  onFieldChange = (id: number, _field: string, _value) => {
    const mode = this.shippingModes.find(c => c.id === Number(id));
    if (!mode) return;
    const { field, value } = this.normalizeFieldChange(mode[_field], _field, _value);
    if (isEqual(mode[field], value)) return;
    mode[field] = value;
    return true;
  };

  onUpdateField = async (id: number, _field: string, _value) => {
    const mode = this.shippingModes.find(c => c.id === Number(id));
    if (!mode) return;
    const { field, value } = this.normalizeFieldChange(mode[_field], _field, _value);
    return api.PATCH({
      endpoint: endpointConfig.shipping_mode_by_id(id),
      data: { [field]: value }
    });
  };

  onAddShippingMode = async (mode: string) => api.POST({
    endpoint: endpointConfig.new_shipping_mode,
    data: { mode }
  })
  .then(this.getShippingModes);

  onRemoveShippingMode = async (id: number) => {
    const shippingMode = this.shippingModes.find(c => c.id === id);
    if (!shippingMode) return;
    this.shippingModes.remove(shippingMode);
    return api.DELETE(endpointConfig.shipping_mode_by_id(id))
    .finally(this.getShippingModes);
  };

  normalizeFieldChange = (originalValue, field: string, value) => {
    if (field === "breakdownIds") {
      value = (value as string[]).map(id => Number(id));
    }
    if (field === "localeMode") {
      value = {
        ...originalValue,
        [UIText.preference]: value
      };
    }
    return { field, value };
  }
}
