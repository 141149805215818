import { Controller } from "../lib/controller";
import { computed, observable, reaction, toJS, when } from "mobx";
import { asyncPause, flipCoin, isEmpty, isEqual, isNonZeroFalse, randomString, safeParseJSON } from "../utils/helpers";
import {
  CheckUniqueResult,
  EntityProfileDuoDTO,
  Group,
  GroupType,
  Member,
  OAuth2Data,
  PersonalProfile,
  Profile,
  User,
  UserLoginData,
} from "../lib/types/dataTypes";
import { api } from "./api";
import { endpointConfig } from "../config/api";
import { AxiosResponse } from "axios";
import { UIException, UIText } from "./lang";
import { oauthClientId, serverConfig } from "../config/api/base";
import { DataParser, DualDTOParser } from "../lib/parser";
import { ui } from "./ui";
import { Topic } from "../lib/types/topicTypes";
import { ClientUniqueIdentifiers } from "../lib/types/formTypes";
import { ApiOptions, StdErr } from "../lib/types/miscTypes";

// ClientStore
// Persistent storage model for Client (User) Service
export interface ClientStore {
  id: string;
  oid: string;
  user: User;
  groupTypes: GroupType[];
  groups: Group[];
}

type InternalTopicController = Controller<{ topics: Topic[] }>;

// Client.
// Main class instance for Client (User/Group/Member/Profile) Service
// persistent data management and store.
export class Client extends Controller<ClientStore> {
  @observable initialized: boolean = false;
  @observable hasError: boolean = false;

  topicCtrl: InternalTopicController;

  loginHandlers: Array<(param?: any) => any> = [];
  logoutHandlers: Array<(param?: any) => any> = [];

  protected parser: DataParser<any> = new DataParser<any>();
  groupParser: DataParser<EntityProfileDuoDTO<Group>> =
    new DataParser<EntityProfileDuoDTO<Group>>(new DualDTOParser("profile").parse);
  memberParser: DataParser<EntityProfileDuoDTO<Member>> =
    new DataParser<EntityProfileDuoDTO<Member>>(new DualDTOParser("profile").parse);

  @computed get id(): string {
    return this.store.id;
  };
  @computed get user(): User {
    return this.store.user || {} as User;
  };
  @computed get oauth(): OAuth2Data {
    return Client.deflavorOAuth2Data(this.store.oid, this.id);
  };
  @computed get groupTypes(): GroupType[] {
    this.storage.initProperty("groupTypes", []);
    return this.store.groupTypes;
  };
  @computed get groups(): Group[] {
    this.storage.initProperty("groups", []);
    return this.store.groups;
  };
  @computed get nonDefaultGroups(): Group[] {
    return this.groups.filter(group => group.id !== this.defaultGroup.id);
  };
  @computed get members(): Member[] {
    return this.groups.map(group => this.findMyMemberInGroup(group));
  };

  @computed get clientId(): string {
    return this.id;
  };
  @computed get userId(): User["id"] {
    return this.user.id;
  };
  @computed get defaultMember(): Member {
    return this.user.defaultMember || {} as Member;
  };
  @computed get defaultGroup(): Group {
    return this.user.defaultGroup || {} as Group;
  };
  @computed get defaultProfile(): Profile<PersonalProfile> {
    return (this.defaultGroup.profile || this.defaultMember.profile || {}) as Profile<PersonalProfile>;
  };

  @computed get allEmailAddresses(): string[] {
    return this.members.map(m => m.email);
  };

  @computed get credentialReady(): boolean {
    return !isEmpty(this.oauth) && !isEmpty(this.user);
  };
  @computed get isLoggedIn(): boolean {
    return !isEmpty(this.user) &&
      !isEmpty(this.oauth) &&
      !isEmpty(this.defaultGroup) &&
      !isEmpty(this.defaultGroup.profile) &&
      !isEmpty(this.defaultMember) &&
      !isEmpty(this.defaultMember.profile) &&
      !isEmpty(this.groups) &&
      !isEmpty(this.members);
  };

  constructor() {
    super();
    reaction(() => this.credentialReady, this.initialize);
    reaction(
      () => this.oauth,
      () => isEmpty(this.oauth) ? api.resetOAuthData() : api.updateOAuthData(toJS(this.oauth))
    );
    api.registerLogoutHandler(() =>  this.logout());
    api.registerRenewHandler((refreshToken) => this.renewOAuth2Data(refreshToken));
    this.checkOAuthValidity().catch(console.warn);
  };

  initialize = () =>
    this.loadInitialData()
    .then(this.runLoginHandlers)
    .catch(err => (this.hasError = true) && ui.showError({
      err,
      buttons: [{
        text: UIText.generalConfirm,
        handler: this.logout
      }]
    }))
    .finally(() => this.initialized = true);

  isLoggedInAndReady = async () => when(
    () => !!client.initialized && !!this.isLoggedIn,
  );

  loadInitialData = async () => {
    if (!this.credentialReady) return;
    await when(() => !!api.OAuth2Data);
    return Promise.all([
      this.getAndStoreGroupTypes(),
      this.getAndStoreDefaultGroupMember(),
      this.getAndStoreUserGroupsAndMembers()
    ]);
  };


  /**
   * Client control
   */
  isMaintenance = async () => {};

  loginAndStoreUser = async (input: UserLoginData<string>): Promise<User> => {
    this.logout();
    api.hardResetPending();

    const clientId = this.store.id = randomString();
    console.log("ClientId", clientId);

    return this.isMaintenance()
    .then(() => api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.login,
      data: {
        client_id: oauthClientId,
        grant_type: "password",
        username: (input.username || input.email).toLowerCase(),
        password: input.password
      },
    }))
    .then((response: AxiosResponse<OAuth2Data>) => this.updateOAuth2Data(response.data))
    .then(this.getAndStoreUser);
  };

  runLoginHandlers = () => {
    for (const handler of this.loginHandlers) {
      if (typeof handler === "function") handler();
    }
  };

  logout = async (): Promise<void> => {
    this.storage.clearStore();
    for (const handler of this.logoutHandlers) {
      if (typeof handler === "function") handler();
    }
    this.hasError = false;
  };

  logoutReload = async () => this.logout().then(() => window.location.reload());

  updateOAuth2Data = (oauth: OAuth2Data) => this.store.oid = Client.flavorOAuth2Data(oauth, this.id);

  renewOAuth2Data = async (refreshToken?: OAuth2Data["refresh_token"]) => {
    if (!refreshToken && !this.isLoggedIn) return;

    return api.POST({
      renewingOAuth2Data: true,
      endpoint: endpointConfig.login,
      headers: serverConfig.defaultHeaders,
      data: {
        client_id: oauthClientId,
        grant_type: "refresh_token",
        refresh_token: refreshToken || this.oauth.refresh_token
      }
    })
    .then((response: AxiosResponse<OAuth2Data>) => this.updateOAuth2Data(response.data));
  };

  static flavorOAuth2Data = (oauth: OAuth2Data, id: string) => {
    if (isEmpty(oauth)) return "";
    oauth.timestamp = new Date().getTime();
    const raw = JSON.stringify(oauth);
    const salted = flipCoin()
      ? `${raw}${id}`
      : `${id}${raw}`;
    return btoa(salted);
  };

  static deflavorOAuth2Data = (cooked: string, id: string): OAuth2Data => {
    cooked = cooked || "";
    try {
      const result = safeParseJSON(atob(cooked).replace(id, ""), true);
      return result as OAuth2Data;
    }
    catch (e) {
      return {} as OAuth2Data;
    }
  };

  setLastTxId = txId => {
    if (isNonZeroFalse(txId)) return;
    if (this.user && this.user.txId) this.user.txId = txId;
  };


  /**
   * Api data getter and storers
   */
  getAndStoreUser = async (): Promise<User> =>
    api.POST({
      endpoint: endpointConfig.post_login,
      data: {
        clientId: this.clientId,
        userAgent: navigator.userAgent
      }
    })
    .then((response: AxiosResponse<User>) => {
      const user = response.data || {} as User;
      if (isEmpty(user)) {
        this.logout();
        throw new UIException("RECEIVED_INVALID_CREDENTIALS");
      }
      if (user.password) delete user.password;
      return this.store.user = user;
    })
    .catch((err: StdErr<User>) => {
      const user = err.response && err.response.status < 400 && err.response.data as User;
      if (isEmpty(user)) throw err;
      if (Number(user.status) === 2) throw new UIException("ACCOUNT_NOT_VERIFIED");
      if (user.needPasswordReset) throw new UIException("ACCOUNT_NEED_PASSWORD_RESET");
      if (!user.termsAgreed) throw new UIException("ACCOUNT_TERMS_NOT_AGREED");
      throw err;
    });

  getAndStoreGroupTypes = async (): Promise<GroupType[]> =>
    api.GET(endpointConfig.group_types(null))
    .then(this.parser.parseResponseArray)
    .then(groupTypes => this.store.groupTypes = groupTypes);

  getAndStoreDefaultGroupMember = async () =>
    Promise.all([
      api.GET(endpointConfig.get_default_group)
      .then(this.groupParser.parseResponseObject)
      .then(group => this.user.defaultGroup = group),
      api.GET(endpointConfig.get_default_member)
      .then(this.memberParser.parseResponseObject)
      .then(member => this.user.defaultMember = member)
    ]);

  getAndStoreUserGroupsAndMembers = async () =>
    Promise.all([
      api.GET(endpointConfig.get_my_groups)
      .then(this.groupParser.parseResponseArray)
      .then(groups => this.store.groups = groups),
      // api.GET(endpointConfig.get_my_members)
      // .then(this.memberParser.parseResponseArray)
      // .then(members => this.store.members = members)
    ]);


  /**
   * Api data getters
   */
  getMe = async (options?: Partial<ApiOptions>) =>
    api.GET({
      endpoint: endpointConfig.user,
      ...options || {}
    })
    .then(response => response.data);

  getGroupById = async (id: Group["id"]) =>
    api.GET(endpointConfig.group_by_id(id))
    .then(this.groupParser.parseResponseObject);

  getGroupsByTypeId = async (typeId: Group["typeId"]) =>
    api.GET(endpointConfig.groups_by_type_id(typeId))
    .then(this.groupParser.parseResponseArray);

  // getGroupTypeRoles
  //
  // getGroupRolesByGroupId

  getMemberById = async (id: Member["id"]) =>
    api.GET(endpointConfig.member_by_id(id))
    .then(this.memberParser.parseResponseObject);

  getMembersByGroupId = async (groupId: Group["id"]) =>
    api.GET(endpointConfig.members_by_group_id(groupId))
    .then(this.memberParser.parseResponseArray);

  getProfileById = async (id: Profile["id"]) =>
    api.GET(endpointConfig.profile_by_id(id))
    .then(this.parser.parseResponseObject);

  checkUniqueIdentifier = async (type: ClientUniqueIdentifiers, value: string): Promise<CheckUniqueResult> => {
    if (!type || !value) return {} as CheckUniqueResult;
    let result = { exists: null, error: null };
    await api.GET({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.exists(type, value.toLowerCase())
    })
    .then(response => result = response.data)
    .catch(err => {
      console.error(err);
      result.error = (err.response && err.response.data) || err.message;
    });
    return result;
  };

  getPendingEmailChange = async () =>
    api.GET(endpointConfig.get_email_change).then(response => response.data || {});

  getRevertEmailChangeDeadline = async (id: number) =>
    api.GET({
      endpoint: endpointConfig.get_revert_email_expiry(id),
      headers: serverConfig.defaultHeaders,
      noKickout: true
    })
    .then(response => response.data);


  /**
   * Local data updaters
   */
  updateMember = (data: Member) => {
    const memberId = data && data.id;
    if (!memberId || !data) return data;
    const members = this.findMembers(m => m.id === memberId);
    for (let member of members) {
      if (isEqual(toJS(member), data)) continue;
      member = Object.assign(member, data);
    }
    return data;
  };

  updateProfile = (data: Profile) => {
    const profileId = data && data.id;
    if (!profileId || !data) return data;
    const groups = this.findGroups(g => g.profileId === profileId);
    const members = this.findMembers(m => m.profileId === profileId);
    for (let group of groups) group.profile = data;
    for (let member of members) member.profile = data;
    if (this.defaultMember.profileId === profileId) {
      this.defaultMember.profile = data;
    }
    if (this.defaultGroup.profileId === profileId) {
      this.defaultMember.profile = data;
    }
    // Return final data;
    return data;
  };


  /**
   * Local data finders
   */
  findGroupById = groupId => this.groups.find(group => group.id === groupId) || {} as Group;

  findGroupTypeById = groupTypeId => this.groupTypes.find(gt => gt.id === groupTypeId) || {} as GroupType;

  findGroups = query => this.groups.filter(query);

  findMembers = (query, limit?: "group" | "topic") => {
    const members: Member[] = [];
    if (!isEmpty(this.groups) && limit !== "topic") {
      for (let group of this.groups) {
        const m = Array.isArray(group.members) && group.members.filter(query);
        if (m) members.push(...m);
      }
    }
    if (!isEmpty(this.topicCtrl.store.topics) && limit !== "group") {
      for (let topic of this.topicCtrl.store.topics) {
        const m = Array.isArray(topic.members) && topic.members.filter(query);
        if (m) members.push(...m);
      }
    }
    return members;
  };

  findMyMemberByGroupId = groupId => {
    const group = this.findGroupById(groupId);
    return this.findMyMemberInGroup(group);
  };

  findMyMemberInGroup = (group: Group) => {
    if (isEmpty(group)) return {} as Member;
    return (group.members || []).find(member => member.userId === this.userId) || {} as Member;
  };


  /**
   * Login logout event registers
   */
  onLogin = (handler: (param?: any) => any) =>
    !this.loginHandlers.includes(handler) && this.loginHandlers.push(handler);

  onLogout = (handler: (param?: any) => any) =>
    !this.logoutHandlers.includes(handler) && this.logoutHandlers.push(handler);

  /**
   * OAuth validity polling with popup
   */
  checkOAuthValidity = async () => {
    if (api.deepRenew) return;
    if (this.initialized && this.isLoggedIn && !this.hasError && ui.hasFocus) {
      await this.getMe({ noRenew: true, noKickOut: true })
      .catch(err => new Promise((resolve, reject) => {
        if (!err.response || err.response.status !== 401) return resolve();
        const continueSession = () =>
          this.renewOAuth2Data(this.oauth.refresh_token)
          .then(resolve)
          .catch(reject);
        return ui.alert({
          header: UIText.idleLogout,
          message: UIText.idleLogoutMessage,
          buttons: [
            {
              cssClass: "textDanger",
              text: UIText.logout,
              handler: this.logoutReload
            },
            {
              text: UIText.idleLogoutContinueSession,
              handler: continueSession
            }
          ]
        })
      }).catch(err => {
        this.hasError = true;
        return ui.showError({
          err,
          buttons: [{
            text: UIText.generalConfirm,
            handler: this.logoutReload
          }]
        });
      }));
    }
    await asyncPause(10000);
    return this.checkOAuthValidity();
  };

  /**
   * Other controller registers
   */
  setTopicCtrl = (controller: InternalTopicController) => this.topicCtrl = controller;
}

export let client = {} as Client;
export const initClient = constructor => client = constructor;
