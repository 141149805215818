import { observer } from "mobx-react";
import { IonButton, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonReorder, IonText } from "@ionic/react";
import { getIonStyleVal } from "../../../../../config/styles/style-utils";
import MdIcon from "../../../../../components/MdIcon";
import { mdiDragVertical } from "@mdi/js";
import { UIText } from "../../../../../client/lang";
import { caretDownSharp, closeSharp } from "ionicons/icons";
import React, { MouseEvent } from "react";
import { Form } from "../../../../../client/form";
import PlacesAutocomplete from "../../../../../components/FormFields/PlaceAutocomplete";
import { Rfq } from "../../../lib/types/dataTypes";
import "./styles.less";
import { preventDefaultStopProp } from "../../../../../utils/helpers";
import { ui } from "../../../../../client/ui";
import { FreightServiceLeg } from "../../../lib/types/propTypes";

export interface FreightLegItemProps extends React.HTMLProps<HTMLDivElement> {
  readonly?: boolean;
  leg: FreightServiceLeg;
  index?: number;
  error: { [key: string]: boolean };
  availableModes: Rfq["mode"][];
  onModeChangeSelect?: (event: MouseEvent) => void;
  onModeChange?: (mode: Rfq["mode"]) => void;
  onRemoveLeg?: (event: MouseEvent) => void;
  onStringFieldChange?: (event: CustomEvent) => void;
}

const PolPodLet = observer(
  ({ readonly, mode, polError, podError, pol, pod, onChange }) => <div className="half">
    <IonItem className="polPodLetItem">
      <IonLabel position="floating" color={polError && "danger"}>
        {Form.mandatoryFieldLabel(mode === "air" ? UIText.rfqForm.origin : UIText.rfqForm.pol)}
      </IonLabel>
      <PlacesAutocomplete
        readonly={readonly}
        name="pol"
        biasCenter
        autocompleteOptions={{
          types: ["(cities)"]
        }}
        heading={mode === "air" ? UIText.rfqForm.origin : UIText.rfqForm.pol}
        value={pol}
        limitTo="city"
        placeholder={UIText.rfqForm.cityProvStateCountry}
        onPositionChange={onChange}
      />
    </IonItem>
    <IonItem className="polPodLetItem">
      <IonLabel position="floating" color={podError && "danger"}>
        {Form.mandatoryFieldLabel(mode === "air" ? UIText.rfqForm.destination : UIText.rfqForm.pod)}
      </IonLabel>
      <PlacesAutocomplete
        readonly={readonly}
        name="pod"
        autocompleteOptions={{
          types: ["(cities)"]
        }}
        heading={mode === "air" ? UIText.rfqForm.destination : UIText.rfqForm.pod}
        value={pod}
        limitTo="city"
        placeholder={UIText.rfqForm.cityProvStateCountry}
        onPositionChange={onChange}
      />
    </IonItem>
  </div>
);

const InfoLet = observer(({ readonly, error, carrier, frequency, transitTime, onChange }) => <div className="half">
    <IonItem lines={ui.isMobile ? "full" : "none"}>
      <IonLabel position="floating" color={error.carrier && "danger"}>
        {Form.mandatoryFieldLabel(UIText.freightForm.carrier)}
      </IonLabel>
      <IonInput
        readonly={readonly}
        name="carrier"
        value={carrier}
        onIonChange={onChange}
      />
    </IonItem>
    <IonItem lines={ui.isMobile ? "full" : "none"}>
      <IonLabel position="floating" color={error.frequency && "danger"}>
        {Form.mandatoryFieldLabel(UIText.freightForm.frequency)}
      </IonLabel>
      <IonInput
        readonly={readonly}
        name="frequency"
        value={frequency}
        onIonChange={onChange}
      />
    </IonItem>
    <IonItem lines="none">
      <IonLabel position="floating" color={error.transitTime && "danger"}>
        {Form.mandatoryFieldLabel(UIText.freightForm.transitTime)}
      </IonLabel>
      <IonInput
        readonly={readonly}
        name="transitTime"
        value={transitTime}
        onIonChange={onChange}
      />
    </IonItem>
  </div>
);

const PriceLet = observer(({ readonly, priceError, rateValidityError, price, surcharges, rateValidity, onChange }) =>
  <div className="half">
    <IonItem>
      <IonLabel position="floating" color={priceError && "danger"}>
        {Form.mandatoryFieldLabel(UIText.freightForm.price)}
      </IonLabel>
      <IonInput
        readonly={readonly}
        name="price"
        inputMode="numeric"
        type="number"
        placeholder="0"
        min="0"
        value={price}
        onIonChange={onChange}
      />
    </IonItem>
    <IonItem>
      <IonLabel position="floating">
        {UIText.freightForm.surcharges}
      </IonLabel>
      <IonInput
        readonly={readonly}
        name="surcharges"
        inputMode="numeric"
        type="number"
        placeholder="0"
        min="0"
        value={surcharges}
        onIonChange={onChange}
      />
    </IonItem>
    <IonItem>
      <IonLabel position="floating" color={rateValidityError && "danger"}>
        {Form.mandatoryFieldLabel(UIText.freightForm.rateValidity)}
      </IonLabel>
      <IonInput
        readonly={readonly}
        name="rateValidity"
        value={rateValidity}
        onIonChange={onChange}
      />
    </IonItem>
  </div>
);

const FreightLegItem: React.FC<FreightLegItemProps> = observer(
  ({
     className,
     readonly,
     leg,
     index,
     error,
     availableModes,
     onModeChange,
     onRemoveLeg,
     onStringFieldChange
   }) => {
    const onModeChangeSelect = (event: any) => {
      if (availableModes.length === 1) return;
      preventDefaultStopProp(event);
      return ui.popoverMenu({
        event,
        menuItems: availableModes.map(mode => ({
          text: UIText.rfqForm[mode],
          handler: () => onModeChange(mode)
        }))
      });
    };
    const modesAvailable = availableModes.length > 1 && !readonly;
    return <div className={`flex legReorder ${readonly ? "readonly" : ""} ${className || ""}`}>
      {!readonly && <IonReorder
        style={{ background: getIonStyleVal("--ion-color-light-tint") }}
        className="flex ion-align-items-center ion-justify-content-center ion-align-self-stretch ion-no-margin"
      >
        <MdIcon icon={mdiDragVertical} color="dark" />
      </IonReorder>}
      <div className="flex column leg">
        <IonItemDivider color="primary">
          <IonText
            className={`flex ion-align-items-center ${modesAvailable ? "ion-activatable" : ""}`}
            onClick={modesAvailable ? onModeChangeSelect : undefined}
          >
            {index + 1}.&nbsp;{UIText.freightMode}:&nbsp;{UIText.rfqForm[leg.mode]}&nbsp;
            {modesAvailable && <IonIcon icon={caretDownSharp} />}
          </IonText>
          {!readonly && <IonButton
            fill="clear"
            slot="end"
            className="ion-no-margin max"
            onClick={onRemoveLeg}
          >
            <IonIcon color="light" icon={closeSharp} className="font-l" />
          </IonButton>}
        </IonItemDivider>
        <PolPodLet
          readonly={readonly}
          polError={error.pol}
          podError={error.pod}
          pol={leg.pol}
          pod={leg.pod}
          mode={leg.mode}
          onChange={onStringFieldChange}
        />
        <PriceLet
          readonly={readonly}
          priceError={error.price}
          surchargeError={error.surcharges}
          rateValidityError={error.rateValidity}
          price={leg.price}
          surcharges={leg.surcharges}
          rateValidity={leg.rateValidity}
          onChange={onStringFieldChange}
        />
        <InfoLet
          readonly={readonly}
          error={error}
          carrier={leg.carrier}
          frequency={leg.frequency}
          transitTime={leg.transitTime}
          onChange={onStringFieldChange}
        />
      </div>
    </div>;
  });

export default FreightLegItem;