import { IonButton, IonButtons, IonCard, IonContent, IonIcon, IonInput, IonPage, withIonLifeCycle } from "@ionic/react";
import { computed, IObservableArray, observable } from "mobx";
import { observer, Observer } from "mobx-react";
import React from "react";
import "./styles.less";
import { StdErr } from "../../../../lib/types/miscTypes";
import { ui } from "../../../../client/ui";
import { RouteComponentProps } from "react-router";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import CardedContainerHeader from "../../../../components/CardedContainerHeader";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { UIText } from "../../../../client/lang";
import { ShippingPort } from "../../lib/types/rateTypes";
import Picker from "../../../../components/FormFields/Picker";
import { addOutline, closeOutline, ellipsisHorizontal, funnel, funnelOutline } from "ionicons/icons";
import { asyncPause, getEventRealValue, isEmpty, preventDefaultStopProp, tableViewScrollToBottom } from "../../../../utils/helpers";
import { AutoObserverList } from "../../../../components/ObserverList";
import TwoStageInput from "../../../../components/TwoStageInput";
import { ShippingPortListController } from "./controller";
import SortableTableHead from "../../../../components/SortableTableHead";
import { getPortDisplayCode, sortByProperty } from "../../lib/common";
import FilterItemList from "../../../../components/FilterItemList";
import PlacesAutocomplete from "../../../../components/FormFields/PlaceAutocomplete";
import countries from "iso-3166-1/dist/iso-3166";
import { shippingPortSortProperties } from "../../config/constants";
import { ListController } from "../../../../lib/list-controller";

export interface ShippingPortListProps extends RouteComponentProps<GroupSubRouteParams<{}>> {}

@observer
class ShippingPortList extends React.Component<ShippingPortListProps> {
  url: string;
  title = UIText.rateManagement.systemShippingPorts;

  controller: ShippingPortListController = {} as ShippingPortListController;
  listController: ListController<ShippingPort>;

  @observable loading: boolean = false;

  @computed get shippingPorts(): ShippingPort[] {
    return this.controller.shippingPorts;
  };
  @computed get sortedPorts(): ShippingPort[] {
    return [...this.shippingPorts].sort(
      (a, b) => sortByProperty(a, b, this.listController.sortBy, this.listController.sortDir)
    );
  };
  @computed get modeFilters(): IObservableArray<number> {
    return [...this.controller.modeFilters].sort() as IObservableArray;
  };
  @computed get filteredPorts(): ShippingPort[] {
    if (isEmpty(this.modeFilters)) return this.sortedPorts;
    return this.sortedPorts.filter(port => this.modeFilters.some(modeId => port.modeIds.includes(modeId)));
  };

  constructor(props) {
    super(props);
    this.url = props.match.url;
    this.controller = new ShippingPortListController();
    this.listController = new ListController({
      storeName: "ShippingPortListFilterSort",
      initialSortBy: "id"
    });
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    if (!this.controller.isSa) return this.props.history.replace("/");
    return this.onRefresh();
  };

  onRefresh = (event?: any) => {
    this.loading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleFieldChange = (event: any) => {
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const id = name.split(".")[0];
    const field = name.split(".")[1];
    const changed = this.controller.onFieldChange(id, field, value);
    return !!changed && setTimeout(() => this.updateFieldAsync(id, field, value));
  };

  updateFieldAsync = async (id: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateField(id, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleAdd = (event: any) => {
    preventDefaultStopProp(event);
    const defaultModeId = this.controller.modes[0].id;
    const modeId = this.modeFilters[0] || defaultModeId;
    if (!modeId) return;
    this.loading = true;
    return this.controller.onAddShippingPort(modeId, countries[0].alpha2)
    .then(() => asyncPause(500))
    .then(tableViewScrollToBottom)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRemove = (event: any, port: ShippingPort) => ui.reconfirm({
    header: `${UIText.generalDelete} Shipping Port`,
    name: `the System Shipping Port ID ${port.id} "${getPortDisplayCode(port)}"`,
    verb: UIText.generalDelete.toLowerCase(),
    handler: () => this.handleRemoveConfirm(event, port.id)
  });

  handleRemoveConfirm = (event: any, id: number) => {
    if (!id) return;
    this.loading = true;
    return this.controller.onRemoveShippingPort(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  handleModeFilterMenu = (event: any) => {
    const { modes, onFilterClear, onFilterSelect } = this.controller;
    const filters = modes.map(mode => ({
      name: mode.id.toString(), placeholder: mode.localeMode[UIText.preference]
    }));
    return ui.popover({
      event,
      component: () => <FilterItemList
        filters={filters}
        selectedFilters={this.modeFilters.map(f => f.toString())}
        onFilterClear={onFilterClear}
        onFilterSelect={onFilterSelect}
      />,
      showBackdrop: false
    });
  };

  render() {
    const { modes } = this.controller;

    const { sortBy, sortDir } = this.listController;

    return (
      <IonPage className="headerOffset shippingPortList">
        <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
          <IonCard className="flex max column container inboxContainer cardedContainer">
            <Observer>{() => (
              <CardedContainerHeader
                title={this.title}
                titleRight={
                  <IonButtons>
                    <IonButton
                      className="textBold textNoTransform"
                      fill="clear"
                      size="small"
                      onClick={this.handleModeFilterMenu}
                    >
                      <IonIcon icon={isEmpty(this.modeFilters) ? funnelOutline : funnel} slot="start" />
                      {UIText.rateManagement.systemShippingModes}
                    </IonButton>
                    <IonButton color="primary" className="textNoTransform" onClick={this.handleAdd}>
                      <IonIcon slot="icon-only" icon={addOutline}/>
                    </IonButton>
                  </IonButtons>
                }
                backHandler={this.props.history.goBack}
                loading={this.loading}
                onRefresh={this.onRefresh}
              />
            )}</Observer>
            <IonContent className="max fullBackground ion-no-padding" scrollY={false}>
              <TableContainer component={props => <Paper {...props} square />} elevation={0} className="max">
                <Table size="small" stickyHeader>
                  <Observer>{() => (
                    <SortableTableHead
                      columns={[
                        { name: "id", text: "ID *" },
                        { name: "modeIds", text: "Modes *", routerLink: "/ShippingModes", noSort: true },
                        ...shippingPortSortProperties
                        .filter(sort => !sort.match(/portName|lat|lng/g))
                        .map(sort => ({
                          name: sort,
                          text: UIText.shippingPortSortProperties[sort]
                        })),
                        { name: "portName", text: `Port name (${UIText.preference})` },
                        { name: "placeData", text: "Google Place", noSort: true },
                        { name: "lat", text: "Latitude" },
                        { name: "lng", text: "Longitude" },
                        { name: "delete", text: UIText.generalDelete, noSort: true }
                      ]}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      onSort={this.handleColumnSort}
                    />
                  )}</Observer>
                  <Observer>{() => (
                    <TableBody>
                      <AutoObserverList
                        list={this.filteredPorts}
                        getItemKey={port => port.id}
                        render={(port: ShippingPort) => {
                          return <TableRow>
                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                {port.id}
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <Picker
                                  name={`${port.id}.modeIds`}
                                  className="ion-no-padding"
                                  multiple
                                  placeholder="Modes"
                                  options={modes.map(mode => ({
                                    name: mode.id.toString(), placeholder: mode.localeMode[UIText.preference]
                                  }))}
                                  value={port.modeIds.map(id => id.toString())}
                                  onIonChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <Picker
                                  className="ion-no-padding"
                                  name={`${port.id}.iso3166A2Code`}
                                  options={countries.map(country => ({
                                    name: country.alpha2, placeholder: country.alpha2
                                  }))}
                                  value={port.iso3166A2Code}
                                  onIonChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <Picker
                                  className="ion-no-padding"
                                  name={`${port.id}.iso3166A3Code`}
                                  options={countries.map(country => ({
                                    name: country.alpha3, placeholder: country.alpha3
                                  }))}
                                  value={port.iso3166A3Code}
                                  onIonChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${port.id}.portCode`}
                                  placeholder="Port code *"
                                  value={port.portCode || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <TwoStageInput
                                  endIcon={ellipsisHorizontal}
                                  className="ion-no-padding"
                                  name={`${port.id}.portName`}
                                  placeholder={`Port name (${UIText.preference})`}
                                  value={port.portName[UIText.preference] || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <PlacesAutocomplete
                                  name={`${port.id}.placeData`}
                                  className="placesField"
                                  biasCenter
                                  heading="Google Place"
                                  value={port.placeData}
                                  placeholder="Google Place"
                                  onPositionChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${port.id}.lat`}
                                  placeholder="Latitude"
                                  value={port.lat || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${port.id}.lng`}
                                  placeholder="Longitude"
                                  value={port.lng || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="center" padding="none">
                                <IonButtons className="flex ion-align-items-center ion-justify-content-center">
                                  <IonButton color="danger" onClick={e => this.handleRemove(e, port)}>
                                    <IonIcon slot="icon-only" icon={closeOutline}/>
                                  </IonButton>
                                </IonButtons>
                              </TableCell>
                            )}</Observer>
                          </TableRow>
                        }}
                      />
                    </TableBody>
                  )}</Observer>
                </Table>
              </TableContainer>
            </IonContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(ShippingPortList);