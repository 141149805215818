import { computed } from "mobx";
import { endpointConfig } from "../../../../config/api";
import { api } from "../../../../client/api";
import RateDataViewController from "../../components/RateDataView/controller";

export interface RateListStore {
  advancedSearch: { isEnabled: boolean; matchPol: string; matchPod: string; savedFilters: string[] }
}

export class RateListController extends RateDataViewController<RateListStore> {
  @computed get advancedSearch(): RateListStore["advancedSearch"] {
    this.storage.initProperty("advancedSearch", {
      isEnabled: false,
      matchPol: "",
      matchPod: "",
      savedFilters: []
    });
    return this.store.advancedSearch;
  };

  constructor() {
    super("RateListController");
    super.setGetPlansMethod(this.getPlans);
  }

  getPlans = () => this.isSa ? this.getAllPlans() : this.getLspPlans();

  getAllPlans = async () => api.GET(endpointConfig.shipping_plans())
  .then(response => this.plans = (response.data || []));

  getLspPlans = async () => api.GET(endpointConfig.shipping_plans_by_group_id(this.group.id, null))
  .then(response => this.plans = (response.data || []));
}