import { Controller } from "../../lib/controller";
import { InputField, PickerField, TypeClassField } from "../../lib/types/formTypes";
import { UIText } from "../../client/lang";
import { registerIntents } from "../../config/constants";
import { Form } from "../../client/form";
import { computed, toJS } from "mobx";
import { isEmpty } from "../../utils/helpers";
import { endpointConfig } from "../../config/api";
import { api } from "../../client/api";
import { client } from "../../client/client";
import { RegistrationData } from "../../lib/types/dataTypes";
import { stateCtrl } from "../../client/state";
import { regForm } from "../../tila/app3win/lib/registrationForm";

export class RegisterController extends Controller {
  registerIntents = registerIntents.filter(intent =>
    isEmpty(stateCtrl.registrationIntentLock) ||
    stateCtrl.registrationIntentLock.includes(intent.groupTypeRoleId)
  );

  form: Form<RegistrationData>;
  fields: TypeClassField<InputField | PickerField, RegistrationData>[] = regForm(this.registerIntents);

  @computed get formValid(): boolean {
    return this.form.form.every(field =>
      (field.required ? !!field.value : true) && !field._errorMessage
    );
  };

  constructor() {
    super();
    this.fields = this.fields.filter(Boolean).map(field => ({
      ...field,
      label: UIText.registrationFields[field.name],
      placeholder: UIText.registrationFields[field.name]
    }));
    this.form = new Form<RegistrationData>(this.fields);
  };

  reset = () => this.form.reset();

  initialize = async () => {
    return client.isLoggedIn && client.logout();
  };

  onRegister = async () => {
    const data = toJS(this.form.data);
    data.intent = [Number(
      this.registerIntents.length > 1
        ? data.intent
        : this.registerIntents[0].groupTypeRoleId
    )];
    console.log(data);
    return api.POST({
      endpoint: endpointConfig.registerShipperOrLsp,
      noKickOut: true,
      data: {
        ...data,
        ...stateCtrl.additionalRegistrationData
      }
    });
  };
}