import { Controller } from "../../lib/controller";
import { SetupConfig } from "../../lib/types/setupTypes";
import { computed, observable } from "mobx";
import { ProfilePageModes } from "../../lib/types/propTypes";
import { EmailChangeRequest, Group, Member, Profile, User } from "../../lib/types/dataTypes";
import { UIException } from "../../client/lang";
import { setupConfigs } from "../../tila/app3win/lib/setups";
import { Form } from "../../client/form";
import { endpointConfig } from "../../config/api";
import { DataParser } from "../../lib/parser";
import { asyncPause, isEmpty } from "../../utils/helpers";
import { api } from "../../client/api";
import { stateCtrl } from "../../client/state";
import { Topic } from "../../lib/types/topicTypes";
import { topicCtrl } from "../../client/topic";
import { client } from "../../client/client";
import { oauthClientId, serverConfig } from "../../config/api/base";

export class ProfileController extends Controller {
  @observable mode: ProfilePageModes;
  @observable setupConfig: SetupConfig;

  @observable profileId: Profile["id"];
  @observable profileData: Profile["data"] = {};

  @observable topic: Topic = {} as Topic;
  @observable topicId: Topic["id"];
  @observable topicData: Topic["data"] = {};

  @observable form: Form;

  @observable emailChangeRequest: EmailChangeRequest = {} as EmailChangeRequest;
  @observable revertEmailChangeDeadline: number;

  @computed get isDirty(): boolean {
    return this.mode === "setup" || (this.form && this.form.isDirty);
  };
  @computed get user(): User {
    return client.user || {} as User;
  };
  @computed get group(): Group {
    return stateCtrl.currentGroup;
  };
  @computed get member(): Member {
    return this.groupMembers.find(member => member.userId === client.userId) || {} as Member;
  };
  @computed get groupMembers(): Member[] {
    return (this.group && this.group.members) || [];
  };
  @computed get defaultGroup(): Group {
    return client.defaultGroup;
  };
  @computed get defaultProfile(): Profile {
    return client.defaultProfile;
  };
  @computed get isDefaultProfile(): boolean {
    return this.mode === "profile" &&
      (this.profileId === stateCtrl.delegateDefaultProfileId
      || this.profileId === this.defaultProfile.id);
  };
  @computed get isForeign(): boolean {
    if (stateCtrl.profilePageExplicitEditable) return false;
    return this.mode === "profile" &&
      this.group.profileId !== this.profileId &&
      !this.groupMembers.some(member => member.profileId === this.profileId);
  };

  @computed get hasPendingChange(): boolean {
    return !isEmpty(this.emailChangeRequest) &&
      !this.emailChangeRequest.isCompleted &&
      !this.emailChangeRequest.isCancelled &&
      !this.emailChangeRequest.isReverted;
  };
  @computed get hasRecentChange(): boolean {
    return !isEmpty(this.emailChangeRequest) &&
      this.emailChangeRequest.isCompleted &&
      !this.emailChangeRequest.isCancelled &&
      !this.emailChangeRequest.isReverted;
  };
  @computed get canRevertEmailChange(): boolean {
    return this.hasRecentChange && (new Date().getTime() < this.revertEmailChangeDeadline);
  };


  reset = () => {
    this.mode = undefined;
    this.setupConfig = undefined;
    this.form = undefined;
    this.profileId = undefined;
    this.profileData = {};
    this.topic = {} as Topic;
    this.topicId = undefined;
    this.topicData = {};
  };

  initSetup = async (setupId: SetupConfig["id"]) => {
    this.mode = "setup";
    const setupConfig = setupConfigs.find(setup => setup.id === setupId);
    if (!setupConfig) throw new UIException("INVALID_SETUP_URL");
    this.setupConfig = setupConfig;
    await asyncPause(200);
    return this.loadSetup();
  };

  loadSetup = () => {
    this.form = new Form(this.setupConfig.typeClassId, {}, { setup: true });
    if (this.setupConfig.preSetup) this.setupConfig.preSetup(this.form);
  };

  loadProfile = async (id: Profile["id"]) => {
    this.mode = "profile";
    this.profileId = id;
    await asyncPause(200);
    return this.getProfile(id)
    .then(this.loadProfileForm);
  };

  getProfile = async (id: Profile["id"]) =>
    api.GET(endpointConfig.profile_by_id(id))
    .then(new DataParser<Profile>().parseResponseObject)
    .then(client.updateProfile);

  loadProfileForm = async (profile: Profile) => {
    this.profileData = { ...profile.data };
    this.form = new Form(profile.typeClassId, profile.data);
    const emailField = this.form.getField("email");
    const disableEmail = () => this.form.setField("email", { disabled: true });
    if (!isEmpty(emailField) && emailField.unique) {
      if (this.isDefaultProfile) {
        await client.getPendingEmailChange()
        .then(changeRequest => this.emailChangeRequest = changeRequest)
        .catch(err => {
          console.warn(err);
          return disableEmail();
        });
        if (this.hasPendingChange) return disableEmail();
        if (this.hasRecentChange) {
          await client.getRevertEmailChangeDeadline(this.emailChangeRequest.id)
          .then(deadline => this.revertEmailChangeDeadline = deadline);
          if (this.canRevertEmailChange) return disableEmail();
        }
        this.form.setField("email", { uniquePermitOwned: true });
      } else {
        disableEmail();
      }
    }
  };

  loadTopic = async (id: Topic["id"]) => {
    this.mode = "topic";
    this.topicId = id;
    await asyncPause(200);
    return this.getTopic(id)
    .then(this.loadTopicForm);
  };

  getTopic = async (id: Topic["id"]) =>
    topicCtrl.getTopicById(id, this.member.id)
    .then(topicCtrl.updateTopic);

  loadTopicForm = (topic: Topic) => {
    this.topic = topic;
    this.topicData = { ...topic.data };
    this.form = new Form(topic.typeClassId, topic.data);
  };

  onSubmit = async () => this.mode === "setup"
    ? this.onSetupSubmit()
    : this.mode === "topic"
    ? this.onTopicSave()
    : this.onProfileSave();

  onSetupSubmit = async () => {
    if (this.mode !== "setup") throw new UIException("PROFILE_SETUP_INVALID_STATE");
    if (typeof this.setupConfig.onSubmit !== "function") {
      throw new UIException("PROFILE_SETUP_INVALID_SUBMIT_HANDLER");
    }
    return this.setupConfig.onSubmit(this.form);
  };

  onProfileSave = async () => {
    if (!this.isDirty) return;
    return api.PATCH({
      endpoint: endpointConfig.profile_by_id(this.profileId),
      data: { data: this.form.json }
    });
  };

  onTopicSave = async () => {
    if (!this.isDirty) return;
    return topicCtrl.patchTopic(this.topicId, {
      data: this.form.toJSON()
    });
  };

  onPasswordResetRequest = async () =>
    api.POST({
      endpoint: endpointConfig.reset_pass_request,
      headers: serverConfig.defaultHeaders,
      noKickout: true,
      data: { identifier: client.user.email }
    });

  verifyPassword = (password: string) =>
    api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.login,
      noKickOut: true,
      data: {
        client_id: oauthClientId,
        grant_type: "password",
        username: client.user.email.toLowerCase(),
        password
      },
    })
    .then(() => true)
    .catch(err => {
      if (err.response && err.response.status === 400) return false;
      throw err;
    });

  onChangeEmailRequest = async (password: string, email: string) => {
    const data = {
      username: client.user.userName,
      password,
      email,
      // TODO: For now, we are defaulting email change to propagate all groups to simplify the process.
      includedMembers: client.members.map(m => m.id)
    };
    return api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.reset_email_options,
      data
    });
  };

  onChangeEmailCancel = async () => {
    const data = {
      authToken: client.oauth.access_token,
      email: this.emailChangeRequest.newEmail
    };
    return api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.cancel_email_change,
      data
    });
  };

  onChangeEmailRevert = async () => {
    const data = {
      authToken: client.oauth.access_token
    };
    return api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.revert_email_change_request,
      data
    });
  };

  onRefresh = () => this.mode === "topic" ? this.loadTopic(this.topicId) : this.loadProfile(this.profileId);
}