import { observer } from "mobx-react";
import React from "react";
import { IonIcon, IonInput } from "@ionic/react";
import "./styles.less";
import { ShippingMode } from "../../lib/types/rateTypes";
import Picker from "../../../../components/FormFields/Picker";
import { UIText } from "../../../../client/lang";
import { arrowForwardSharp } from "ionicons/icons";

export interface RateListAdvancedSearchBarProps extends React.HTMLProps<HTMLDivElement> {
  modes: ShippingMode[];
  selectedModes: ShippingMode["mode"][];
  matchPol: string;
  matchPod: string;
  onFieldChange: (event: any) => void;
}

export const RateListAdvancedSearchBar: React.FC<RateListAdvancedSearchBarProps> = observer(
  ({
     className,
     modes,
     selectedModes,
     matchPol,
     matchPod,
     onFieldChange
   }) => (
    <div className={`flex ion-wrap ion-align-items-center rateListAdvancedSearchBar ${className || ""}`}>
      <Picker
        name="modes"
        multiple
        className="ion-padding-horizontal"
        options={modes.map(mode => ({
          name: mode.mode, placeholder: mode.localeMode[UIText.preference]
        }))}
        placeholder={UIText.rateManagement.mode}
        value={selectedModes}
        onIonChange={onFieldChange}
      />
      <div className="flex ion-align-items-center ports" style={{ flexGrow: 1 }}>
        <IonInput
          name="matchPol"
          className="ion-padding-horizontal"
          placeholder={UIText.rfqForm.origin}
          value={matchPol}
          onIonChange={onFieldChange}
        />
        <IonIcon className="ion-margin-end" icon={arrowForwardSharp} size="small" color="primary" />
        <IonInput
          name="matchPod"
          className="ion-padding-end"
          placeholder={UIText.rfqForm.destination}
          value={matchPod}
          onIonChange={onFieldChange}
        />
      </div>
    </div>
  ));