import { observer } from "mobx-react";
import React from "react";
import { observable, reaction } from "mobx";
import { ui } from "../../../client/ui";
import { baseURL, devServer, prodServer, testServer } from "../../../config/api/base";
import { api } from "../../../client/api";
import { tx } from "../../../client/tx";
import { IonButton, IonCard, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { caretDownSharp } from "ionicons/icons";
import { UIText } from "../../../client/lang";
import { IonCheckboxWithReadonly } from "../../../components/FormFields/Checkbox";

@observer
class BaseSwitcher extends React.Component<{ onApply: Function }> {
  @observable baseUrl: string = "";
  @observable renewDisable: boolean;
  @observable txDisable: boolean;
  @observable txPollingDisable: boolean;
  @observable xBaseSwitch: boolean;
  @observable deepRenew: boolean;

  constructor(props) {
    super(props);
    api.storage.isReady()
    .then(() => {
      this.renewDisable = api.noAutoRenew;
      this.xBaseSwitch = !!api.xBaseSwitch;
      this.baseUrl = api.xBaseSwitch || api.store.baseUrl || baseURL;
      this.deepRenew = api.deepRenew;
    });
    tx.storage.isReady()
    .then(() => {
      this.txDisable = tx.disabled;
      this.txPollingDisable = tx.pollingDisabled;
    });
    reaction(() => this.xBaseSwitch, () => (this.baseUrl = this.xBaseSwitch ? "" : api.baseUrl));
  }

  showServerList = event =>
    ui.popoverMenu({
      event,
      menuItems: [devServer, testServer, prodServer].map((server, i) => ({
        key: i,
        text: `https://${server}`,
        handler: () => this.onBaseChange({ detail: { value: `https://${server}` }})
      }))
    });

  resetBaseUrl = () => {
    api.store.baseUrl = undefined;
    api.store.xBaseSwitch = undefined;
    tx.store.disable = undefined;
    tx.store.pollingDisable = undefined;
    api.store.noRenew = undefined;
    api.store.deepRenew = undefined;
    return window.location.reload();
  };

  onBaseChange = event => {
    const { value } = event.detail;
    return this.baseUrl = value;
  };

  onBaseApply = () => {
    api.store.noRenew = this.renewDisable;
    api.store.deepRenew = this.deepRenew;
    tx.store.disable = this.txDisable;
    tx.store.pollingDisable = this.txPollingDisable;
    this.baseUrl && (
      this.xBaseSwitch
        ? (api.store.xBaseSwitch = this.baseUrl)
        : this.baseUrl !== api.baseUrl
        && (api.store.baseUrl = this.baseUrl) && api.baseSwitch(this.baseUrl)
    );
    if (this.props.onApply) this.props.onApply();
    return ui.toast({ header: "Applied" });
  };

  render() {
    return <IonCard className="flex column container cardedContainer" style={{ margin: "16px auto" }}>
      <IonList className="ion-no-padding">
        <IonListHeader className="textPrimary textBold">API</IonListHeader>

        <IonItem>
          <IonLabel color="primary">
            {this.xBaseSwitch ? "Spring Boot URL" : "Base URL"}
          </IonLabel>
          <IonInput
            value={this.baseUrl}
            onIonChange={this.onBaseChange}
          />
          <IonButton fill="clear" onClick={this.showServerList}>
            <IonIcon icon={caretDownSharp} color="dark" slot="icon-only"/>
          </IonButton>
          <IonButton onClick={this.onBaseApply}>
            {UIText.generalApply}
          </IonButton>
          <IonButton onClick={this.resetBaseUrl}>
            {UIText.generalReset}
          </IonButton>
        </IonItem>
        <IonItem lines="full">
          <IonLabel>
            Only switch Spring Boot server
          </IonLabel>
          <IonCheckboxWithReadonly
            slot="start"
            checked={this.xBaseSwitch}
            onIonChange={e => this.xBaseSwitch = e.detail.checked}
          />
        </IonItem>
        <IonItem lines="full">
          <IonLabel>
            Disable Auto-renew
          </IonLabel>
          <IonCheckboxWithReadonly
            slot="start"
            checked={this.renewDisable}
            onIonChange={e => this.renewDisable = e.detail.checked}
          />
        </IonItem>
        <IonItem lines="full">
          <IonLabel>
            Disable SSE Tx Log
          </IonLabel>
          <IonCheckboxWithReadonly
            slot="start"
            checked={this.txDisable}
            onIonChange={e => this.txDisable = e.detail.checked}
          />
        </IonItem>
        <IonItem lines="full">
          <IonLabel>
            Disable Tx Log Polling
          </IonLabel>
          <IonCheckboxWithReadonly
            slot="start"
            checked={this.txPollingDisable}
            onIonChange={e => this.txPollingDisable = e.detail.checked}
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel>
            Deep-renew
          </IonLabel>
          <IonCheckboxWithReadonly
            slot="start"
            checked={this.deepRenew}
            onIonChange={e => this.deepRenew = e.detail.checked}
          />
        </IonItem>
        {/*<IonItem lines="none">*/}
        {/*  <IonLabel>*/}
        {/*    Deep-renew refresh token expires in (ms)*/}
        {/*  </IonLabel>*/}
        {/*  <IonInput*/}
        {/*    slot="start"*/}
        {/*    value={this.deepRenewExpiresIn}*/}
        {/*    onIonChange={e => this.deepRenewExpiresIn = e.detail.value}*/}
        {/*  />*/}
        {/*</IonItem>*/}
      </IonList>
    </IonCard>
  }
}

export default BaseSwitcher;