import { observer } from "mobx-react";
import React from "react";
import { ui } from "../../../../client/ui";
import { IonButton, IonIcon, IonText } from "@ionic/react";
import { UIText } from "../../../../client/lang";
import { airplaneSharp, boatSharp, businessSharp, cashSharp, locationSharp, shapesSharp, statsChartSharp } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import "./styles.less";

export interface HeaderAdminMenuProps extends React.HTMLProps<HTMLDivElement> {
  history: RouteComponentProps["history"];
}

export const showAdminMenu = (history: RouteComponentProps["history"]) => event => ui.popoverMenu({
  event,
  cssClass: "headerAdminMenu",
  menuItems: [
    {
      color: "primary",
      icon: airplaneSharp,
      text: UIText.rateManagement.systemShippingModes,
      routerLink: "/ShippingModes"
    },
    {
      color: "primary",
      icon: locationSharp,
      text: UIText.rateManagement.systemShippingPorts,
      routerLink: "/ShippingPorts"
    },
    {
      color: "primary",
      icon: cashSharp,
      text: UIText.rateManagement.systemShippingCharges,
      routerLink: "/ShippingCharges"
    },
    {
      color: "primary",
      icon: statsChartSharp,
      text: UIText.rateManagement.systemShippingUnits,
      routerLink: "/ShippingUnits"
    },
    {
      color: "primary",
      icon: shapesSharp,
      text: UIText.rateManagement.systemShippingUocs,
      routerLink: "/ShippingUocs"
    },
    {
      color: "primary",
      icon: boatSharp,
      text: UIText.rateManagement.systemCarriers,
      routerLink: "/ShippingCarriers"
    }
  ]
});

const HeaderAdminMenu: React.FC<HeaderAdminMenuProps> = observer(({ history }) => {
  return <IonButton onClick={showAdminMenu(history)} color="primary">
    <IonIcon slot="start" icon={businessSharp} />
    <IonText className="textBold textNoTransform textUnderline">{UIText.adminMenu}</IonText>
  </IonButton>
});

export default HeaderAdminMenu;