import { Group, Member, PlacesAutocompleteResult } from "../../../lib/types/dataTypes";
import {
  FreightService,
  InlandService,
  PackingStorageService,
  Quotation,
  QuotationService,
  Requirement,
  Rfq,
  RfqExtraConditions,
  Solution,
} from "./types/dataTypes";
import { arrayFlat, contextReject, dirSort, getDisplayNameEng, isEmpty, randomString, safeParseJSON } from "../../../utils/helpers";
import { Form, formCtrl } from "../../../client/form";
import { topicCtrl } from "../../../client/topic";
import { containerNameBreakdownMap, defaultDimensionUnit, defaultWeightUnit, rfqSortProperties } from "../config/constants";
import * as convert from "convert-units";
import { RfqFormDTO, RfqFormItemDTO } from "./types/rfqFormTypes";
import { observable, when } from "mobx";
import { client } from "../../../client/client";
import { Topic } from "../../../lib/types/topicTypes";
import { groupTypeIds, topicTypeIds, typeClassIds } from "../../../config/constants";
import { placesApi } from "../../../client/places";
import { UIText } from "../../../client/lang";
import { ElementType } from "../../../lib/types/miscTypes";
import { SortDirection } from "@material-ui/core";
import { api } from "../../../client/api";
import { endpointConfig } from "../../../config/api";
import {
  Carrier,
  ShippingCharge,
  ShippingMode,
  ShippingPort,
  ShippingRate,
  ShippingRateBreakdown,
  ShippingRateChargeItem,
  ShippingRateUnit,
  ShippingUnit,
  ShippingUoc,
  ShippingUom,
} from "./types/rateTypes";
import { PickerField } from "../../../lib/types/formTypes";
import { AxiosResponse } from "axios";

export const findRfqTopics = (groupId?: number) =>
  topicCtrl.findTopics(
    t => t.typeId === topicTypeIds.rfq &&
      (!groupId || (t.groupIdList && t.groupIdList.includes(groupId)))
  ) as Topic<RfqFormDTO>[];

export const getRfqTopics = async (groupId: number, party: ReturnType<typeof getGroupParty>) => {
  const rfqsTopics = findRfqTopics(groupId);
  return (party === "shipper"
    ? topicCtrl.getGroupTopicsByTypeId(groupId, topicTypeIds.rfq)
    : topicCtrl.getTopicsByTypeId(topicTypeIds.rfq, false, false, groupId))
  .then(topics => topicCtrl.existsOrRemove(rfqsTopics, topics))
  .then(topics => topics.map(topicCtrl.updateTopic));
};

export const findRfqItemTopics = (rfqId: number, groupId?: number) =>
  topicCtrl.findTopics(
    t => t.parentId === rfqId &&
      (!groupId || t.groupIdList.includes(groupId))
  ) as Topic[];

export const getRfqItemTopics = async (rfq: Rfq) =>
  // TODO: Leaking quotation service and quotation access LSP
  topicCtrl.getSubTopicsByParentId(rfq.id, true)
  .then(topics => topicCtrl.existsOrRemove(topicCtrl.findSubTopicsByParentId(rfq.id), topics))
  .then(topics => topics.map(topicCtrl.updateTopic));

export const getRfqPodPol = (rfqFormDTO: Partial<RfqFormDTO>, key: "pol" | "pod") => {
  const { city } = rfqFormDTO[key.toLowerCase()] || {};
  return city || "";
};

export const topicToEntity = (topic: Topic) => {
  const entity = {} as Topic & { createDatetime: Date };
  if (isEmpty(topic)) return entity;
  entity.id = topic.id;
  entity.description = topic.description;
  entity.createDatetime = topic.createTime && new Date(topic.createTime);
  entity.groupIdList = topic.groupIdList || [];
  return entity;
};

export const topicToRfq = (topic: Topic<RfqFormDTO, RfqExtraConditions>): Rfq => {
  const rfq = topicToEntity(topic) as Partial<Rfq>;
  const data = topic.data || {} as Partial<RfqFormDTO>;
  rfq.mode = data.mode;
  rfq.pol = data.pol;
  rfq.pod = data.pod;
  rfq.isClosed = topic.isClosed;
  rfq.isAwarded = topic.isAwarded;
  rfq.hasAwarded = !!topic.awardedQuotation;
  rfq.submittedQuotationCount = topic.submittedQuotationCount;
  rfq.shipperQuotationCount = topic.shipperQuotationCount;
  rfq.lspQuotationCount = topic.lspQuotationCount;
  rfq.solutionCount = topic.solutionCount;
  rfq.items = data.items && Array.isArray(data.items) ? data.items : safeParseJSON(data.items, 1);
  const returnRfq = {
    ...data,
    ...rfq,
    shipperName: "",
    get POD() { return getRfqPodPol(data, "pod") },
    get POL() { return getRfqPodPol(data, "pol") },
  };
  return returnRfq as Required<Rfq>;
};

export const topicToRfqWithShipperName = (topic: Topic<RfqFormDTO>): Rfq => {
  const shipperMember = (topic.members && topic.members.find(
    m => m.profile && formCtrl.matchTypeClassId(m.profile.typeClassId, "shipperProfile")
  )) || {} as Member;
  const returnRfq = observable({
    ...topicToRfq(topic),
    shipperName: getDisplayNameEng(shipperMember.profile)
  });
  if (!returnRfq.shipperName && !isEmpty(topic.groupIdList)) (async () => {
    const shipperGroup: Group = (await Promise.all(
      [...new Set(topic.groupIdList)].map(client.getGroupById)
    )
    .catch(contextReject))
    .filter(group => group.typeId === groupTypeIds.shipper)[0];
    if (shipperGroup && !isEmpty(shipperGroup.profile)) {
      returnRfq.shipperName = getDisplayNameEng(shipperGroup.profile);
    }
  })();
  return returnRfq;
};

export const topicToQuotation = (topic: Topic<Quotation>): Quotation => {
  const quotation = topicToEntity(topic) as Partial<Quotation>;
  const lspMember = (topic.members && topic.members.find(
    m => m.profile && formCtrl.matchTypeClassId(m.profile.typeClassId, "lspProfile")
  )) || {} as Member;
  const data = topic.data || {} as Partial<Quotation>;
  quotation.rfqId = topic.parentId;
  quotation.isAllIn = data.isAllIn;
  quotation.isSubmitted = !!topic.isDataLocked;
  quotation.isCancelled = !!topic.isLocked;
  quotation.quotationServiceIds = (topic.subTopics && topic.subTopics.map(t => t.id)) || [];
  quotation.lspGroupId = lspMember.groupId;
  quotation.remarks = data.remarks;
  return quotation as Required<Quotation>;
};

export const topicToQuotationService = (topic: Topic<QuotationService>): QuotationService => {
  let qs = topicToEntity(topic) as Partial<QuotationService>;
  const data = topic.data || {} as Partial<QuotationService>;
  qs.quotationId = topic.parentId;
  qs.provider = topic.creatorMember ? getDisplayNameEng(topic.creatorMember.profile) : "";
  qs.serviceType = data.serviceType;
  qs.price = data.price;
  qs.requirementId = data.requirementId;
  qs.serviceDatetime = topic.startTime && new Date(topic.startTime);
  qs.requirementId = data.requirementTopicId;
  qs.isAwarded = !!topic.isDataLocked;
  qs = getQuotationServiceExtraProperties(
    qs as Required<QuotationService>,
    topic.data,
    topic.typeClassId
  );
  return qs as Required<QuotationService>;
};

export const topicToSolution = (topic: Topic<Solution>): Solution => {
  const solution = topicToEntity(topic) as Partial<Solution>;
  const data = topic.data || {} as Partial<Solution>;
  solution.rfqId = topic.parentId;
  solution.isAllIn = data.isAllIn;
  solution.isAwarded = !!topic.isDataLocked;
  solution.selections = data.selections;
  solution.quotationServiceIds = data.selections
    ? arrayFlat(data.selections.map(sl => sl.services && sl.services.map(sv => sv.topicId)))
    : [];
  return solution as Required<Solution>;
};

export const topicToRequirement = (topic: Topic<Requirement>): Requirement => {
  let requirement = topicToEntity(topic) as Partial<Requirement>;
  const data = topic.data || {} as Partial<Requirement>;
  requirement.rfqId = topic.parentId;
  requirement.serviceType = data.serviceType;
  if (!requirement.serviceType) {
    const form = new Form(topic.typeClassId, {});
    when(
      () => form.ready,
      () => {
        requirement.serviceType = form.getRenderedValue("serviceType")
      }
    );
  }
  requirement.serviceDatetime = topic.startTime && new Date(topic.startTime);
  requirement = getQuotationServiceExtraProperties(
    requirement as QuotationService<any>,
    topic.data,
    topic.typeClassId
  ) as Partial<Requirement>;
  return requirement as Required<Requirement>;
};

export const quotationServicesToLegs = (quotationServices: QuotationService<FreightService>[]) =>
  quotationServices.filter(Boolean).map(leg => ({
    key: randomString(),
    pol: leg.pol,
    pod: leg.pod,
    mode: leg.mode,
    carrier: leg.carrier,
    frequency: leg.frequency,
    surcharges: leg.surcharges,
    price: leg.price,
    rateValidity: leg.rateValidity,
    transitTime: leg.transitTime
  }));

export const sortRequirementsByDate = (a: QuotationService, b: QuotationService) => {
  if (a.serviceType.match(/freight/ig) && !b.serviceType.match(/freight/ig)) return -1;
  if (!a.serviceType.match(/freight/ig) && b.serviceType.match(/freight/ig)) return 1;
  return sortQuotationServicesByDate(a, b);
};

export const sortQuotationServicesByDate = (a: QuotationService, b: QuotationService) => {
  const aTime = a.serviceDatetime, bTime = b.serviceDatetime;
  const aName = a.description, bName = b.description;
  if (aTime && bTime) return aTime > bTime ? 1 : -1;
  if (aTime && !bTime) return -1;
  if (bTime && !aTime) return 1;
  return ("" + aName).localeCompare(bName);
};

export const sortQuotationServiceFixedOrder = (a: QuotationService, b: QuotationService) => {
  const map = {
    Freight: 0,
    "Customs Brokerage": 1,
    Inland: 2,
    Packing: 3,
    Inspection: 4,
    Storage: 5
  };
  return map[a.serviceType] > map[b.serviceType] ? 1 : -1;
};

export const sortRfqByProperty = (
  a: Rfq,
  b: Rfq,
  property: ElementType<typeof rfqSortProperties>,
  direction: SortDirection,
  isShipper?: boolean
) => {
  switch (property) {
    case "submissionDate": {
      const aTime = a.createDatetime, bTime = b.createDatetime;
      return dirSort(aTime, bTime, direction);
    }
    case "cargoReadyDate": {
      const aTime = a.cargoReadyDate, bTime = b.cargoReadyDate;
      return dirSort(aTime, bTime, direction);
    }
    case "cargoArrivalDate": {
      const aTime = a.cargoArrivalDate, bTime = b.cargoArrivalDate;
      return dirSort(aTime, bTime, direction);
    }
    case "quotationCount": {
      const aCount = isShipper ? a.shipperQuotationCount : a.lspQuotationCount,
        bCount = isShipper ? b.shipperQuotationCount : b.lspQuotationCount;
      return dirSort(aCount, bCount, direction);
    }
    case "solutionCount": {
      const aCount = a.solutionCount, bCount = b.solutionCount;
      return dirSort(aCount, bCount, direction);
    }
    case "itemCount": {
      const aCount = (a.items || []).length, bCount = (b.items || []).length;
      return dirSort(aCount, bCount, direction);
    }
    default: {
      return 0;
    }
  }
};

export const getQuotationServiceExtraProperties = (
  qs: QuotationService<
    FreightService
    & InlandService
    & PackingStorageService
    >,
  data: Topic<QuotationService<FreightService & InlandService & PackingStorageService> | Requirement>["data"],
  typeClassId: Topic["typeClassId"]
): Partial<QuotationService> => {
  const serviceType: string = qs.serviceType;
  const form = new Form(typeClassId, data);
  if (serviceType.match(/Freight/ig)) {
    qs = {
      ...qs,
      pol: (data as QuotationService<FreightService>).pol,
      pod: (data as QuotationService<FreightService>).pod,
      mode: (data as QuotationService<FreightService>).mode,
      segments: (data as QuotationService<FreightService>).segments,
      surcharges: (data as QuotationService<FreightService>).surcharges,
      carrier: (data as QuotationService<FreightService>).carrier,
      frequency: (data as QuotationService<FreightService>).frequency,
      transitTime: (data as QuotationService<FreightService>).transitTime,
      rateValidity: (data as QuotationService<FreightService>).rateValidity,
      get displayTitle() {
        return getFreightServiceTitle(form.getRenderedValue("serviceType"), qs.mode, qs.pol, qs.pod)
      },
    };
  }
  if (serviceType.match(/Inland/ig)) {
    qs = {
      ...qs,
      get inlandServiceType() { return form.getRenderedValue("inlandServiceType"); },
      get pickUpAddress() { return form.getRenderedValue("pol"); },
      get deliveryAddress() { return form.getRenderedValue("pod"); },
      get displayTitle() { return `${UIText.inlandServiceDisplay}: ${qs.inlandServiceType}` }
    };
  }
  if (serviceType.match(/Storage|Packing/ig)) {
    qs = {
      ...qs,
      get location() { return form.getRenderedValue("location"); },
      get notes() { return form.getRenderedValue("notes") as string; },
      get duration() { return form.getRenderedValue("duration") as number; },
      get date() { return form.getRenderedValue("date") as Date; },
      get displayTitle() {
        return `${form.getRenderedValue("serviceType")}: ${
          (qs.location || {} as PlacesAutocompleteResult).city ||
          (qs.location || {} as PlacesAutocompleteResult).provState ||
          (qs.location || {} as PlacesAutocompleteResult).country || 
          ""}`
      }
    };
  }
  return qs;
};

export const getFreightServiceTitle = (serviceType, mode, pol, pod) => `${UIText.freight}: ${
  placesApi.getFormattedAddress(pol, "city")
} 🡆 ${
  placesApi.getFormattedAddress(pod, "city")
}`;

export const getQuotationServicesPrice = (quotationServices: QuotationService[]) =>
  quotationServices.reduce((a, b) => a + Number(b.price), 0);

export const rfqRoundWeight = (value, from, to) =>
  Math.round(convert(value)
  .from(from || defaultWeightUnit)
  .to(to || defaultWeightUnit) * 1000) / 1000;

export const rfqRoundDimension = (value, from, to) =>
  Math.round(convert(value)
  .from(from || defaultDimensionUnit)
  .to(to || defaultDimensionUnit) * 100) / 100;

export const getRfqDescriptionLsp = rfq => `${rfq.shipperName} ${rfq.description}`.trim();
export const getRfqDescriptionShipper = rfq => rfq.reference ? `${rfq.reference} (${rfq.description})` : rfq.description;
export const getGroupParty = group => !isEmpty(group)
  ? group.typeId === groupTypeIds.lsp
  ? "lsp"
  : group.typeId === groupTypeIds.shipper
  ? "shipper"
  : undefined
  : undefined;

export const getFreightItemTypes = (mode: RfqFormDTO["mode"]): RfqFormItemDTO["type"][] =>
  (mode === "sea" || mode === "rail")
    ? ["fcl", "lcl"]
    : mode === "road"
    ? ["ftl", "ltl"]
    : ["pieces"];

export const getFreightRequirementTopicTemplate = (rfq: Rfq, id: number) => ({
  id,
  typeId: topicTypeIds.requirement,
  description: "",
  isParentTemplate: 0,
  isTemplate: 0,
  parentId: rfq.id,
  typeClassId: typeClassIds.freight.v1.id,
  typeClassVersion: typeClassIds.freight.v1.version,
  data: {
    id,
    serviceType: "Freight",
    pol: rfq.POL,
    pod: rfq.POD,
    serviceDatetime: rfq.cargoReadyDate
  },
  status: 1,
  actors: [],
  createTime: 0,
  creatorMemberId: undefined,
  defaultThreadId: undefined,
  groupIdList: [],
  memberNames: [],
  performer: [],
  roleNames: [],
  subTopics: [],
  updateTime: 0
});


/**
 * Rate Managements
 */
export const getShippingModes = () => api.GET(endpointConfig.shipping_modes)
.then((response: AxiosResponse<ShippingMode[]>) => (Array.isArray(response.data) && response.data) || []);

export const getShippingRateBreakdowns = () => api.GET(endpointConfig.shipping_rate_breakdowns)
.then((response: AxiosResponse<ShippingRateBreakdown[]>) => (Array.isArray(response.data) && response.data) || []);

export const getPorts = async () => api.GET(endpointConfig.shipping_ports)
.then((response: AxiosResponse<ShippingPort[]>) => (Array.isArray(response.data) && response.data) || []);

export const getUnits = async () => api.GET(
  client.credentialReady
    ? endpointConfig.shipping_units
    : endpointConfig.shipping_units_public)
.then((response: AxiosResponse<ShippingUnit[]>) => (Array.isArray(response.data) && response.data) || []);

export const getCharges = async () => api.GET(endpointConfig.system_shipping_charges)
.then((response: AxiosResponse<ShippingCharge[]>) => (Array.isArray(response.data) && response.data) || []);

export const getPerUocs = async () => api.GET(endpointConfig.shipping_uocs)
.then((response: AxiosResponse<ShippingUoc[]>) => (Array.isArray(response.data) && response.data) || []);

export const getPerUoms = async () => api.GET(endpointConfig.shipping_uoms)
.then((response: AxiosResponse<ShippingUom[]>) => (Array.isArray(response.data) && response.data) || []);

export const getCarriers = async () => api.GET(endpointConfig.carriers)
.then((response: AxiosResponse<Carrier[]>) => (Array.isArray(response.data) && response.data) || []);

export function sortByProperty<T = any> (
  a: T,
  b: T,
  property: keyof T,
  direction: SortDirection
): 1 | -1 | 0 {
  let aSort, bSort;
  if (typeof a[property] === "object" &&
    typeof b[property] === "object" &&
    (a[property] || {}).hasOwnProperty(UIText.preference) &&
    (b[property] || {}).hasOwnProperty(UIText.preference)
  ) {
    aSort = a[property][UIText.preference];
    bSort = b[property][UIText.preference];
    const indicator = (aSort || "").localeCompare(bSort);
    if (!indicator) return dirSort(aSort, bSort, direction);
    return direction === "asc" ? indicator : -indicator;
  } else {
    aSort = a[property];
    bSort = b[property];
    return dirSort(aSort, bSort, direction);
  }
}

export const getChargeItemsSum = (entity: ShippingRateUnit | ShippingRate) => {
  if (isEmpty(entity.shippingRateChargeItems)) return 0;
  return entity.shippingRateChargeItems
  .filter((shippingRateChargeItem: ShippingRateChargeItem) => !shippingRateChargeItem.shippingRateCharge.isBasic)
  .reduce((total, c) => total + Number(c.amount), 0);
};


/**
 * Carrier
 */
export const carrierProfileForm = new Form(typeClassIds.carrierProfile.v1.id);
export const getCarrierTypePlaceholder = (carrier: Carrier): string => {
  const typeField: PickerField = carrierProfileForm.getField("type");
  if (isEmpty(typeField)) return "";
  const carrierType = carrier.profile.data.type;
  const type = typeField.options.find(option => option.name === carrierType);
  return type && type.placeholder;
};

export const getCarrierModes = (modes: ShippingMode[], carrier: Carrier): string => {
  const carrierModes = carrier.profile.data.modes;
  modes = modes.filter(mode => carrierModes.includes(mode.mode));
  return !isEmpty(modes) && modes.map(mode => mode.localeMode[UIText.preference]).join(", ");
};


/**
 * ShippingPort
 */
// export const getPortDisplayCode = (port: ShippingPort) => port ? `${port.iso3166A2Code || ""}${port.portCode || ""}` : "";
export const getPortDisplayCode = (port: ShippingPort) => port ? `${port.portCode || ""}` : "";

export const getPortName = (port: ShippingPort) => port ? (port.portName || {})[UIText.preference] : "";

/**
 * Container and units
 */
export const getContainerPrefixForBreakdown = (rate: ShippingRate, breakdown: ShippingRateBreakdown): string => {
  if (isEmpty(rate) || isEmpty(breakdown)) return "";
  const { shippingMode } = rate;
  if (isEmpty(shippingMode)) return "";
  const { mode } = shippingMode;
  if (!mode || mode === "air") return "";
  const containerTypes = (rate.container || "").split("&");
  const possibleContainerTypes = containerNameBreakdownMap[breakdown.unitType];

  if (isEmpty(possibleContainerTypes) || isEmpty(containerTypes)) return "";
  if (!containerTypes.find(containerType => possibleContainerTypes.includes(containerType))) return "";
  const container = possibleContainerTypes.filter(containerType => containerTypes.includes(containerType));
  return `${UIText.rateManagement[container]} - `;
};