// TODO: "pac-container" leaking

import React from "react";
import { observer } from "mobx-react";
import { AlertInput, IonInput } from "@ionic/react";
import { observable, when } from "mobx";
import { isEmpty, preventDefaultStopProp, safeParseJSON } from "../../../utils/helpers";
import { placesApi } from "../../../client/places";
import { JSONOfPlacesAutocompleteResult, PlacesAutocompleteResultLimiter } from "../../../lib/types/formTypes";
import TwoStageInput from "../../TwoStageInput";
import { createOutline, ellipsisHorizontal } from "ionicons/icons";
import { ui } from "../../../client/ui";
import { UIText } from "../../../client/lang";
import { PlacesAutocompleteResult } from "../../../lib/types/dataTypes";

export interface PlacesAutocompleteProps extends Omit<React.ComponentProps<typeof IonInput>, "value"> {
  heading?: string;
  mapOptions?: google.maps.MapOptions;
  autocompleteOptions?: google.maps.places.AutocompleteOptions;
  biasCenter?: boolean;
  limitTo?: PlacesAutocompleteResultLimiter;
  value?: JSONOfPlacesAutocompleteResult | PlacesAutocompleteResult;
  onPositionChange?: (event: any) => void;
}

@observer
class PlacesAutocomplete extends React.Component<PlacesAutocompleteProps> {
  ionInput: HTMLIonInputElement;
  initialized: boolean;
  @observable value: string;
  @observable private _rawValue: string | PlacesAutocompleteResult;

  constructor(props) {
    super(props);
    const { value } = this.props;
    this.value = placesApi.getFormattedAddress(value);
  }

  componentDidUpdate() {
    this.value = placesApi.getFormattedAddress(this.props.value);
    // if (!this.props.readonly && !this.initialized) this.initGooglePlaces();
  }

  inputRef = (ref: HTMLIonInputElement) => {
    if (ref) this.ionInput = ref;
    return this.ionInput.getInputElement().then(this.initGooglePlaces);
  };

  setMapCircleCenter = (autocomplete: google.maps.places.Autocomplete, options: google.maps.CircleOptions) => {
    const circle = new google.maps.Circle(options);
    return autocomplete.setBounds(circle.getBounds());
  };

  setCenterUsingLocation = async (autocomplete: google.maps.places.Autocomplete) => {
    const position = await placesApi.getGeolocation(true).catch(console.warn);
    if (!position) return;
    const circle: google.maps.CircleOptions = {
      center: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      },
      radius: position.coords.accuracy
    };
    return this.setMapCircleCenter(autocomplete, circle);
  };

  setCenterUsingOptions = async (autocomplete: google.maps.places.Autocomplete) =>
    this.setMapCircleCenter(autocomplete, this.props.mapOptions);

  initGooglePlaces = async (inputElm: HTMLInputElement) => {
    if (this.props.readonly) return;
    if (this.initialized) return;
    if (!inputElm) return;
    await when(() => placesApi.initialized);
    const autocomplete = new google.maps.places.Autocomplete(inputElm, this.props.autocompleteOptions);
    if (this.props.biasCenter) this.setCenterUsingLocation(autocomplete).catch(console.warn);
    if (this.props.mapOptions) this.setCenterUsingOptions(autocomplete).catch(console.warn);
    autocomplete.addListener("place_changed", e => {
      const place = autocomplete.getPlace();
      if (!place.place_id) return; // Prevent enter-key clearing places data.
      const value = placesApi.parseAutocompleteResult(place, this.props.limitTo);
      this._rawValue = value;
      return this.props.onPositionChange && this.props.onPositionChange(this.buildEvent(value));
    });
    return this.initialized = true;
  };

  buildEvent = (value: JSONOfPlacesAutocompleteResult | PlacesAutocompleteResult) => ({
    target: { name: this.props.name },
    detail: { value }
  });

  handleManualModeClick = (event: any) => {
    preventDefaultStopProp(event);
    const existingVal = this.props.value || this._rawValue;
    const existing: PlacesAutocompleteResult =
      typeof existingVal === "string"
        ? safeParseJSON(existingVal, true)
        : existingVal
      || {} as PlacesAutocompleteResult;
    const onSubmit = data => {
      const value = placesApi.parseManualResult(data, this.props.limitTo);
      this._rawValue = value;
      return this.props.onPositionChange && this.props.onPositionChange(this.buildEvent(value));
    };
    return ui.alert({
      header: "Manual address entry",
      inputs: [
        !this.props.limitTo && {
          name: "street1",
          placeholder: UIText.autocompleteFields.street1,
          value: existing.street1
        },
        !this.props.limitTo && {
          name: "street2",
          placeholder: UIText.autocompleteFields.street2,
          value: existing.street2
        },
        (!this.props.limitTo || this.props.limitTo === "city") && {
          name: "city",
          placeholder: UIText.autocompleteFields.city,
          value: existing.city
        },
        (!this.props.limitTo || this.props.limitTo.match(/city|provState/g)) && {
          name: "provState",
          placeholder: UIText.autocompleteFields.provState,
          value: existing.provState
        },
        (!this.props.limitTo || this.props.limitTo.match(/city|provState|country/g)) && {
          name: "country",
          placeholder: UIText.autocompleteFields.country,
          value: existing.country
        },
        (!this.props.limitTo || this.props.limitTo === "postalCode") && {
          name: "postalCode",
          placeholder: UIText.autocompleteFields.postalCode,
          value: existing.postalCode
        }
      ]
      .filter(Boolean)
      .map((input: AlertInput) => (input.label = input.placeholder) && input),
      buttons: [
        {
          role: "cancel",
          text: UIText.generalCancel
        },
        {
          text: UIText.generalConfirm,
          handler: onSubmit
        }
      ]
    });
  };

  handleClear = (event: any) => {
    const { value } = event.detail;
    if (!isEmpty(value)) return;
    const empty = undefined;
    this._rawValue = empty;
    return this.props.onPositionChange && this.props.onPositionChange(this.buildEvent(empty));
  };

  render() {
    const {
      className,
      autofocus,
      // name,
      heading,
      required,
      clearOnEdit,
      placeholder,
      readonly,
      disabled,
      onIonInput
    } = this.props;

    return <TwoStageInput
      type="search"
      useIonInput
      forwardedRef={this.inputRef}
      className={className}
      autofocus={autofocus}
      // name={name}
      heading={heading || placeholder}
      required={required}
      clearOnEdit={clearOnEdit}
      readonly={readonly}
      disabled={disabled}
      placeholder={placeholder}
      value={this.value}
      endIcon={readonly ? ellipsisHorizontal : createOutline}
      onEndIconClick={!readonly && this.handleManualModeClick}
      onIonChange={this.handleClear}
      onIonInput={onIonInput}
      onIonBlur={() => this.forceUpdate()}
    />;
  }
}

export default PlacesAutocomplete;