import { FreightService, Quotation, QuotationService, Requirement, Rfq, Solution } from "../../lib/types/dataTypes";
import { computed, observable, toJS } from "mobx";
import { UIException } from "../../../../client/lang";
import { topicCtrl } from "../../../../client/topic";
import {
  getFreightRequirementTopicTemplate,
  getRfqItemTopics,
  topicToQuotation,
  topicToQuotationService,
  topicToRequirement,
  topicToRfq,
  topicToSolution,
} from "../../lib/common";
import { Group, Member } from "../../../../lib/types/dataTypes";
import { topicTypeIds, typeClassIds } from "../../../../config/constants";
import { arrayFlat, isEmpty } from "../../../../utils/helpers";
import { RfqFormDTO } from "../../lib/types/rfqFormTypes";
import { client } from "../../../../client/client";
import { stateCtrl } from "../../../../client/state";
import { Controller } from "../../../../lib/controller";
import { GroupThread, Thread, Topic, TopicPrimitive } from "../../../../lib/types/topicTypes";
import { api } from "../../../../client/api";
import { endpointConfig } from "../../../../config/api";
import { msgCtrl } from "../../../../client/msg";
import { formCtrl } from "../../../../client/form";

export interface RfqDetailShipperStore {
  shortlist: QuotationService["id"][];
}

export class RfqDetailShipperController extends Controller<RfqDetailShipperStore> {
  @observable id: Rfq["id"];
  @observable private _solutions: Solution[] = [];

  // Controller Data
  @computed get group(): Group {
    return stateCtrl.currentGroup;
  };
  @computed get member(): Member {
    return client.findMyMemberByGroupId(this.group.id);
  };

  @computed get groupThread(): GroupThread {
    return msgCtrl.groupThreads.find(gt => gt.groupId === this.group.id) || {} as GroupThread;
  };
  @computed get threads(): Thread[] {
    return (this.groupThread.threads || []).filter(tr => tr.topicId === this.rfqTopic.id);
  };

  @computed get rfqTopic(): Topic<RfqFormDTO> {
    return topicCtrl.findTopicById(this.id) as Topic<RfqFormDTO>;
  };
  @computed get rfq(): Rfq {
    return topicToRfq(this.rfqTopic);
  };
  @computed get rfqMembers(): Member[] {
    return this.rfqTopic.members;
  };
  @computed get providerMembers(): Member[] {
    return this.rfqMembers.filter(m => formCtrl.matchTypeClassId(
      (m.profile && m.profile.typeClassId) || 0, "lspProfile"
      )
    );
  };
  @computed get rfqItemTopics(): Topic[] {
    const topics = topicCtrl.findSubTopicsByParentId(this.id);
    return topics.concat(topicCtrl.flattenSubTopics(topics));
  };
  @computed get requirementTopics(): Topic<Requirement<{ pod: string; pol: string }>>[] {
    const topics = topicCtrl.findTopics(
      t => t.typeId === topicTypeIds.requirement
        && t.parentId === this.id
    );
    return [
      getFreightRequirementTopicTemplate(this.rfq, -1),
      ...topics
    ].filter(Boolean) as Topic<Requirement<{ pod: string; pol: string }>>[];
  };
  @computed get requirements(): Requirement[] {
    return this.requirementTopics.map(topicToRequirement);
  };
  @computed get quotationTopics(): Topic<Quotation>[] {
    return topicCtrl.findTopics(t =>
      t.typeId === topicTypeIds.quotation &&
      t.parentId === this.id &&
      t.isDataLocked
      // To hide non-submitted quotations
    ) as Topic<Quotation>[];
  };
  @computed get quotations(): Quotation[] {
    const requirements = this.requirements;
    const quotations = this.quotationTopics.map(topicToQuotation);
    const quotationServices = this.quotationServices;
    return toJS(quotations).map(q => ({
      ...q,
      get isAllIn() {
        return requirements.every(rq => {
          const qss: QuotationService[] =
            q.quotationServiceIds.map(id => quotationServices.find(qs => qs.id === id));
          return qss.some(qs => qs.serviceType === rq.serviceType);
        });
      }
    }));
  };
  @computed get allInQuotations(): Quotation[] {
    return this.quotations.filter(q => q.isAllIn);
  };
  @computed get quotationServices(): QuotationService[] {
    return topicCtrl.flattenSubTopics(this.quotationTopics)
    .map(topicToQuotationService);
  };
  @computed get nonAllInQuotationServices(): QuotationService[] {
    return this.quotationServices.filter(qs => (
      !this.allInQuotations.some(q => q.id === qs.quotationId)
    ));
  };
  @computed get solutionTopics(): Topic<Solution>[] {
    return this.rfqItemTopics.filter(t => t.typeId === topicTypeIds.solution) as Topic<Solution>[];
  };
  @computed get solutions(): Solution[] {
    return [
      ...this.solutionTopics.map(topicToSolution),
      ...this._solutions
    ];
  };
  @computed get shortlist(): QuotationService["id"][] {
    this.storage.initProperty("shortlist", []);
    return this.store.shortlist;
  };

  // Controller methods
  setId = async (id: Rfq["id"]) => {
    if (!id) return;
    this.id = id;
    if (isEmpty(this.group)) return;
    return this.loadAllData();
  };

  loadAllData = async () => this.getRfq()
  .then(() => Promise.all([
    this.getRfqItems(),
    this.getRfqThreads()
  ]));

  getRfq = async () => topicCtrl.getTopicById(this.id).then(topicCtrl.updateTopic);
  // getRfq = async () => topicCtrl.getTopicById(this.id, this.member.id).then(topicCtrl.updateTopic);

  getRfqItems = async () => getRfqItemTopics(this.rfq);

  getRfqThreads = async () =>
    msgCtrl.getThreadsAndMessagesByTopicId(this.id, this.member.id)
    .then(threads => msgCtrl.updateGroupThreads(this.group.id, arrayFlat(threads).filter(Boolean)));

  getThreadByServiceId = async (id: QuotationService["id"]) => {
    const topic = this.rfqItemTopics.find(t => t.id === id);
    if (isEmpty(topic)) return null;

    let topicMemberIds = topic.members && topic.members.map(m => m.id);
    if (isEmpty(topicMemberIds)) {
      // Could be clicking on a chat button of all-in quotation
      const quotationTopic = this.rfqItemTopics.find(t => t.id === topic.parentId);
      topicMemberIds = quotationTopic.members && quotationTopic.members.map(m => m.id);
    }
    if (isEmpty(topicMemberIds)) return null;

    const thread: Thread = this.threads.find(th => (
      th.memberIdList &&
      th.memberIdList.includes(this.member.id) &&
      topicMemberIds.every(id => th.memberIdList.includes(id)) &&
      !msgCtrl.isInfoThread(th)
    ));

    if (!thread) return api.GET(endpointConfig.check_shipper_lsp_chat_thread(this.id, topic.creatorMemberId))
    .then(response => {
      const thread = response.data;
      if (!thread) return null;
      this.groupThread.threads.push(thread);
      return thread;
    });

    return thread;
  };

  getSolutionsByQuotationId = (id: Quotation["id"]): Solution[] =>
    this.solutions.filter(s => s.selections && s.selections.some(sl => sl.quotation && sl.quotation.topicId === id));

  getSolutionByQuotationServiceId = (id: QuotationService["id"]) =>
    this.solutions.filter(s => s.selections && s.selections.some(
      sl => sl.services && sl.services.some(sv => sv.topicId === id)
    ));

  isQuotationInSolution = (id: Quotation["id"]): boolean =>
    !isEmpty(this.getSolutionsByQuotationId(id));

  isQuotationServiceInSolution = (id: QuotationService["id"]): boolean =>
    !isEmpty(this.getSolutionByQuotationServiceId(id));

  isQuotationServiceInEverySolution = (id: QuotationService["id"]): boolean =>
    this.solutions.every(s => s.quotationServiceIds.includes(id));

  isQuotationAwarded = (id: Quotation["id"]): boolean => {
    const solutions = this.getSolutionsByQuotationId(id);
    if (isEmpty(solutions)) return false;
    return solutions.some(sl => sl.isSubmitted);
  };

  isQuotationServiceAwarded = (id: QuotationService["id"]): boolean => {
    const solutions = this.getSolutionByQuotationServiceId(id);
    if (isEmpty(solutions)) return false;
    return solutions.some(sl => sl.isSubmitted);
  };

  findFreightServiceLegs = (id: QuotationService["id"]): QuotationService<FreightService>[] => {
    const quotationService: QuotationService<FreightService> = this.quotationServices.find(qs => qs.id === id);
    if (!quotationService) return [];
    const { segments } = quotationService;
    if (!segments) return [toJS(quotationService)];
    return segments.map(legId => toJS(this.quotationServices.find(qs => qs.id === legId)));
  };

  onAddToShortlist = async (id: QuotationService["id"]) => {
    const quotationService = this.quotationServices.find(q => q.id === id);
    if (!quotationService) return Promise.reject(new UIException("QUOTATION_SERVICE_NOT_FOUND"));
    const inShortlist: QuotationService["id"] = this.shortlist.find(qid => qid === id);
    if (inShortlist) return this.onRemoveFromShortlist(id);
    return this.shortlist.push(quotationService.id);
  };

  onCreateSolutionFromQuotation = async (id: Quotation["id"]) => {
    const quotation: Quotation = this.quotations.find(q => q.id === id);
    if (!quotation) return Promise.reject(new UIException("QUOTATION_NOT_FOUND"));
    const newSolution: Solution = {
      id: (this.solutions[this.solutions.length - 1] || { id: 0 }).id + 1,
      description: quotation.description,
      selections: [{
        quotation: { topicId: quotation.id, groupId: quotation.lspGroupId },
        services: quotation.quotationServiceIds.map(id => ({ topicId: id }))
      }],
      createDatetime: new Date(),
      isAllIn: true,
      remarks: quotation.remarks,
      new: true
    };
    return this._solutions.push(newSolution);
  };

  onNewSolution = async () => {
    const last: Solution = toJS(this.solutions).sort((a, b) => a.id - b.id)[this.solutions.length - 1];
    const lastId: number = (last || { id: 0 }).id;
    return this._solutions.push({
      id: lastId + 1,
      new: true
    });
  };

  onNewSolutionNameChange = async (id: Solution["id"], value: string) => {
    const solution: Solution = this.solutions.find(s => s.id === id);
    if (!solution) return;
    return solution.description = value;
  };

  onNewSolutionSave = async (id: Solution["id"]) => {
    const solution: Solution = this.solutions.find(s => s.id === id);
    if (!solution || !solution.new || !solution.description) return;
    const topicData: Partial<Solution> = {
      isAllIn: solution.isAllIn,
      selections: solution.selections
    };
    let newSolution: Topic<Solution>;
    const topic: TopicPrimitive = {
      description: solution.description,
      isParentTemplate: 0,
      isTemplate: 0,
      parentId: this.rfq.id,
      typeClassId: typeClassIds.solution.v1.id,
      typeClassVersion: typeClassIds.solution.v1.version,
      typeId: topicTypeIds.solution,
      data: JSON.stringify(topicData)
    };
    const data = {
      currentGroupId: this.group.id,
      topic
    };
    return api.POST({
      endpoint: endpointConfig.create_topic,
      data
    })
    .then(topicCtrl.parser.parseResponseObject)
    .then(topic => newSolution = topic)
    .then(this.getRfqItems)
    .then(() => this.onRemoveNewSolution(id))
    .then(() => newSolution);
  };

  onRemoveSolution = async (id: Quotation["id"]) => {
    const solution = this.solutions.find(s => s.id === id);
    if (!solution) return;
    if (solution.new) return this.onRemoveNewSolution(id);
    // return topicCtrl.deleteTopicById(id);
    return topicCtrl.hardDeleteTopicById(id);
  };

  onRemoveNewSolution = async (id: Solution["id"]) => {
    const solutionIndex: number = this._solutions.findIndex(s => s.id === id);
    if (solutionIndex < 0) return;
    return this._solutions.splice(solutionIndex, 1);
  };

  onRemoveFromShortlist = async (id: QuotationService["id"]) => {
    const index: number = this.shortlist.findIndex(qid => qid === id);
    if (index < 0) return;
    return this.shortlist.splice(index, 1);
  };

  onAddToSolution = async (id: QuotationService["id"], solutionId: Solution["id"]) => {
    const quotationService = this.quotationServices.find(qs => qs.id === id);
    if (!quotationService) return Promise.reject(new UIException("QUOTATION_SERVICE_NOT_FOUND"));
    const quotation = this.quotations.find(q => q.id === quotationService.quotationId);
    if (!quotation) return Promise.reject(new UIException("QUOTATION_NOT_FOUND"));
    const solutionTopic = this.solutionTopics.find(s => s.id === solutionId);
    if (!solutionTopic) return Promise.reject(new UIException("SOLUTION_NOT_FOUND"));
    const selections: Solution["selections"] = toJS(solutionTopic.data.selections) || [];
    const selectionQuotation = selections.find(sl => sl.quotation.topicId === quotationService.quotationId);
    if (!selectionQuotation) {
      selections.push({
        quotation: { topicId: quotation.id, groupId: quotation.lspGroupId },
        services: [{ topicId: quotationService.id }]
      });
    } else {
      if (selectionQuotation.services.find(sv => sv.topicId === id)) return;
      selectionQuotation.services.push({ topicId: id });
    }
    return this.updateSolutionSelections(selections, solutionTopic);
  };

  onRemoveFromSolution = async (id: QuotationService["id"], solutionId: Solution["id"]) => {
    const solutionTopic = this.solutionTopics.find(s => s.id === solutionId);
    if (!solutionTopic) return Promise.reject(new UIException("SOLUTION_NOT_FOUND"));
    const selections: Solution["selections"] = toJS(solutionTopic.data.selections);
    const quotationService = this.quotationServices.find(qs => qs.id === id);
    if (isEmpty(selections) || !quotationService) return Promise.reject(new UIException("QUOTATION_SERVICE_NOT_FOUND"));
    const selectionQuotation = selections.find(sl => sl.services.some(sv => sv.topicId === id));
    if (!selectionQuotation) return;
    selectionQuotation.services = selectionQuotation.services.filter(s => s.topicId !== id);
    if (isEmpty(selectionQuotation.services)) selections.splice(selections.indexOf(selectionQuotation), 1);
    return this.updateSolutionSelections(selections, solutionTopic);
  };

  onFinalizeSolution = async (id: Solution["id"]) => {
    const solutionTopic = this.solutionTopics.find(s => s.id === id);
    if (!solutionTopic) return Promise.reject(new UIException("SOLUTION_NOT_FOUND"));
    const data: Partial<Topic> = {
      typeId: topicTypeIds.solution,
      isDataLocked: 1
    };
    return api.PATCH({
      endpoint: endpointConfig.topic_by_id(id),
      data
    })
    .then(this.getRfqItems);
  };

  updateSolutionSelections = (selections: Solution["selections"], solutionTopic: Topic<Solution>) => {
    const data: Partial<TopicPrimitive> = {
      data: JSON.stringify({
        ...solutionTopic.data,
        selections
      })
    };
    return api.PATCH({
      endpoint: endpointConfig.topic_by_id(solutionTopic.id),
      data
    })
    .then(this.getRfqItems);
  };
}