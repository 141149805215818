import { observer } from "mobx-react";
import HelpTip, { HelpTipProps } from "../HelpTip";
import React from "react";
import ReactDOM from "react-dom";
import { observable } from "mobx";
import { isEmpty } from "../../utils/helpers";

export interface InjectIonLabelHelpTipProps extends HelpTipProps {}

@observer
class InjectIonLabelHelpTip extends React.Component<InjectIonLabelHelpTipProps> {
  labelEquivalentFontSize = 12;

  @observable labelContent = "";

  componentDidMount() {
    this.getIonLabelElm();
  }

  getIonLabelElm = () => {
    const ionItem = ReactDOM.findDOMNode(this).parentElement;
    if (!ionItem) return;
    const ionLabel = ionItem.querySelector("ion-label");
    if (!ionLabel) return;
    const { textContent } = ionLabel;
    if (isEmpty(textContent)) return;
    this.labelContent = textContent;
  };

  render() {
    return <span className="absolute">
      <span
        className="noEvents"
        style={{
          fontSize: this.labelEquivalentFontSize,
          visibility: "hidden"
        }}
      >
        {this.labelContent}
      </span>
      <HelpTip
        {...this.props}
        style={{
          position: "relative",
          top: "3px",
          zIndex: 10,
          display: "inline-block"
        }}
      />
    </span>;
  }
}

export default InjectIonLabelHelpTip;