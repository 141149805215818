const general = {
  generalConfirm: "Ok",
  generalCancel: "Cancel",
  generalYes: "Yes",
  generalNo: "No",
  generalAccept: "Accept",
  generalDecline: "Decline",
  generalRefuse: "Refuse",
  generalReject: "Reject",
  generalIgnore: "Ignore",
  generalChange: "Change",
  generalReset: "Reset",
  generalApply: "Apply",
  generalSend: "Send",
  generalSubmit: "Submit",
  generalNext: "Next",
  generalBack: "Back",
  generalEdit: "Edit",
  generalSave: "Save",
  generalCreate: "Create",
  generalView: "View",
  generalDiscard: "Discard",
  generalUndo: "Undo",
  generalMinimize: "Minimize",
  generalClose: "Close",
  generalAdd: "Add",
  generalRemove: "Remove",
  generalDelete: "Delete",
  generalNew: "New",
  generalSelect: "Select",
  generalSelected: "Selected",
  generalSelectAll: "Select all",
  generalDeselectAll: "Deselect all",
  generalExpandAll: "Expand all",
  generalCollapseAll: "Collapse all",
  generalWarning: "Warning",
  generalError: "Error",
  generalSuccess: "Success",
  generalFailure: "Failure",
  generalRefresh: "Refresh",
  generalUnknown: "Unknown",
  generalDone: "Done",
  generalFinish: "Finish",
  generalHelp: "Help",
  generalMore: "More",
  generalAnd: "And",
  generalOr: "Or",
  generalChoose: name => `Choose ${name}`,
  generalReconfirm: (verb, item) => `Are you sure you want to ${verb} ${item}?`,
  generalMe: "Me",
  generalDescription: "Description",
  generalUnavailable: "Unavailable",
  generalReadonly: "Read only",
  
  title: "3WIN",
  helloUser: displayName => `Hello ${displayName}!`,

  id: "ID",
  menu: "Menu",
  selectLanguage: "Select Language",

  email: "Email",
  user: "User",
  username: "Username",
  password: "Password",
  currentPassword: "Current password",
  myPage: "My page",

  session: "Session",
  login: "Sign in",
  get loginTo() { return `${general.login} to`},
  loginProgress: "Signing in...",
  postLoginProgress: "Loading profile...",
  loginError: "Sign in unsuccessful",
  loginNeedVerify: "Email not verified",
  loginResendVerification: "Resend verification link",
  logout: "Sign out",

  idleLogout: "Inactivity",
  idleLogoutMessage: "Your session has been signed out due to inactivity.<br><br>Do you wish to continue your session?",
  idleLogoutContinueSession: "Continue",

  signUp: "Sign up",
  signUpLink: "Sign up for new account",
  signUpSuccessful: "Sign up complete!",
  signUpFormHeading: "Some basic information",
  signUpLoginLink: "Sign in with existing account",
  signUpResetLink: "Trouble signing in",
  signUpSuccessfulMessage: "Thank you, your account has been created. Please check your email for further instructions.",
  registrationUniqueExists: "already exists.",
  registrationFields: {
    password: "Password",
    repeat: "Confirm Password",
    email: "Email address",
    phone: "Phone",
    firstName: "First Name",
    lastName: "Last Name",
    displayName: "Business name",
    intent: "I'm a ..."
  },
  registrationPasswordMismatch: "Password does not match!",
  registrationPasswordError8: "Password must be at least 8 character",
  registrationPasswordErrorLowerNum: "Password must contain numbers and letters",
  invalidEmail: "Invalid email",
  invalidPostalCode: "Invalid postal code",

  verification: "verification",
  verificationSuccess:
    "You have successfully verified your email! Please proceed to sign in.",
  verificationFailure: "Verification unsuccessful",
  verificationResent:
    "Your verification link has been sent. Please check your email for further instructions.",

  resetPassword: "Reset password",
  resetNewPassword: "New password",
  returnToLogin: "Return to sign in",
  resetFormHeading: "Enter your user account's verified email address and we will send you a password reset link.",
  resetFormExecHeading: name => `Change password for ${name}`,
  resetRequestSent:
    "If you have entered a correct validated email address, you should receive an email containing password reset instructions within a few minutes.",
  resetRequestSentKnownUser: "You should receive an email containing password reset instructions within a few minutes.",
  resetSuccess: "Your password has been updated.",

  changeEmail: "Change email",
  changeEmailPasswordAlert: newEmail => `Please enter your current password for email address change.<br><br>You are changing your email address to:<br><b>${newEmail}</b>`,
  changeEmailIncorrectPassword: "Incorrect current password",
  changeEmailRequestSent: "Your requested change will not made until you verify your new email address. You will receive the verification email shortly. You may also cancel the request on this page.",
  changeEmailPending: value => `Changing to: ${value}`,
  changeEmailCancel: "Cancel email change",
  changeEmailPendingRequest: "Pending email change",
  changeEmailCancelled: "Your requested email change has been cancelled.",
  changeEmailRevert: "Undo email change",
  changeEmailRecentChange: "Recent email change",
  changeEmailRecentChangeSummary: (changedFrom, changedTo, revertDeadline) => `You recently changed email address from <b>${changedFrom}</b> to <b>${changedTo}</b>.<br><br>You can undo this change before <b>${revertDeadline}</b>.`,
  changeEmailYourRecentChange: "your recent email change",
  changeEmailRevertRequestSent: "Your email change undo will not take effect until you verify your previous email address. You will receive the verification email shortly.",

  welcome: "Welcome",

  inbox: "Inbox",
  inboxFor: groupName => `${general.inbox} for ${groupName}`,
  inboxUnread: count => `${count || 0} unread messages`,
  infoThread: "General information",
  inboxMarkAllAsRead: "Mark all as read",
  inboxMarkedAllAsRead: "Marked all as read",

  chat: "Chat",
  chatEmoji: "Emoji",
  chatEmojiWin10: `If you are running Windows 10 version 1809 or later, please press <br><span class="textBold">"WIN+(period)"</span> or<br><span class="textBold">"WIN+(semicolon)"</span><br>to access the emoji panel.`,
  chatEmojiOSX: `If you are running OS X Mavericks or later, please press <br><span class="textBold">"Control–Command–Space"</span> to access the emoji panel.`,
  chatEmojiNotSupported: "Sorry, emoji is not supported on your operating system.",
  chatBottomBarPlaceholder: "Enter message here...",
  chatBottomBarEnterNotes: "Enter notes here...",

  autocompleteFields: {
    street1: "Street 1",
    street2: "Street 2",
    city: "City",
    provState: "State/Province",
    country: "Country",
    postalCode: "Postal code",
  },

  characters: "Characters",

  settings: "Settings",
  account: "Account",
  get showUsername() { return `Show ${general.username}` },

  profile: "Profile",
  finishSetupProfile: "Finish setting up profile",

  service: "Service",
  get services() { return `${general.service}s` },

  copiedToClipboard: name => `${name || "data"} copied to clipboard!`,
  clearSidebarRecentEntries: "Clear recent entries",
  comingSoon: "Coming soon!",
  pleaseWait: "Please wait",
  get pleaseWaitLong() { return `${general.pleaseWait}, this may take a while` },

  createdAt: "Created",
  updatedAt: "Updated",
  submittedAt: "Submitted",

  applyToAll: "Apply to all",

  search: "Search",
  searchStuff: name => `${general.search} ${name}`,

  sortBy: "Sort by",
  orderBy: "Order",

  filter: "Filter",
  get filters() { return `${general.filter}s` },
  get clearFilters() { return `Clear all ${general.filters}` },

  swipeRevealHintLeft: "Swipe left to see more options",
  swipeRevealHintRight: "Swipe right to see more options",

  developerSettings: "Developer Tools"
};

export default general;