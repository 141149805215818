import { observer } from "mobx-react";
import React, { MouseEvent } from "react";
import { Group } from "../../../../lib/types/dataTypes";
import { IonAvatar, IonBadge, IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { getDisplayNameEng, isEmpty } from "../../../../utils/helpers";
import { UIText } from "../../../../client/lang";
import { Client } from "../../../../client/client";
import { mdiAccountEdit, mdiCog, mdiEmail, mdiLogoutVariant } from "@mdi/js";
import MdIcon from "../../../../components/MdIcon";
import { ui } from "../../../../client/ui";
import { getGroupTypeNameByGroup } from "../../../../lib/group-utilities";
import { State, stateCtrl } from "../../../../client/state";
import "./styles.less";
import { businessSharp, pricetagsSharp, readerSharp } from "ionicons/icons";
import { placeholderAvatarUrl } from "../../../../config/constants";

export interface UserGroupMenuProps {
  originalEvent?: MouseEvent;
  groups: Group[];
  onSetup: (event: MouseEvent) => void;
  onInbox: (event: MouseEvent) => void;
  onSettings: (event: MouseEvent) => void;
  onGroup: (event: MouseEvent, groupId: Group["id"]) => void;
  onProfile: (event: MouseEvent, groupId: Group["id"]) => void;
  onLang: (event: MouseEvent) => void;
  onRates: (event: MouseEvent) => void;
  onRfqs: (event: MouseEvent) => void;
  onAdminMenu: (event: MouseEvent) => void;
  onLogout: Client["logout"] | State["logoutResetPathname"];
}

const UserGroupMenu: React.FC<UserGroupMenuProps> = observer(
  ({ groups, onSetup, onInbox, onSettings, onGroup, onProfile, onRates, onRfqs, onAdminMenu, onLogout }) => {
    const unreadCount = stateCtrl.unreadCounters[ stateCtrl.currentGroupId ];
    return <IonList className="userGroupMenu ion-no-padding">
      {isEmpty(groups) ? (
        <IonItem button lines="full" onClick={onSetup}>
          <IonLabel className="textPrimary ion-text-center">
            {UIText.finishSetupProfile}
          </IonLabel>
        </IonItem>
      ) : (
        groups.map((group, i) => (
          <div
            key={group.id}
            className={`flex ion-align-items-center ${ui.isMobileIntermediate && i === groups.length - 1 ? "underline" : ""}`}
          >
            <IonItem
              key={group.id}
              className="group"
              lines="none"
              button
              onClick={e => onGroup(e, group.id)}
            >
              <IonAvatar slot="start">
                <img alt="avatar" src={stateCtrl.currentAvatarUrl || placeholderAvatarUrl} />
              </IonAvatar>
              <IonLabel className="flex column">
                <span className="font-s textPrimary groupName">{getDisplayNameEng(group.profile)}</span>
                <span className="font-xs textMedium groupType">{getGroupTypeNameByGroup(group)}</span>
              </IonLabel>
            </IonItem>
            <IonButtons className="ion-align-self-stretch">
              <IonButton
                className="ion-no-padding profileEditButton"
                onClick={e => onProfile(e, group.id)}
              >
                <MdIcon icon={mdiAccountEdit} color="medium"/>
              </IonButton>
            </IonButtons>
          </div>
        ))
      )}
      {/*LSP RFQ Entry*/}
      {ui.isMobileIntermediate && onRfqs && (
        <IonItem button onClick={onRfqs} lines="full">
          <IonIcon icon={readerSharp} color="primary" />
          <IonLabel className="textPrimary ion-text-center">
            {UIText.RFQs}
          </IonLabel>
        </IonItem>
      )}
      {/*Shipping Rate Entry*/}
      {ui.isMobileIntermediate && onRates && (
        <IonItem button onClick={onRates} lines="full">
          <IonIcon icon={pricetagsSharp} color="primary" />
          <IonLabel className="textPrimary ion-text-center">
            {UIText.rateManagement.rateManagement}
          </IonLabel>
        </IonItem>
      )}
      {/*Admin Menu Entry*/}
      {ui.isMobileIntermediate && onAdminMenu && (
        <IonItem button onClick={onAdminMenu} lines="full">
          <IonIcon icon={businessSharp} color="primary" />
          <IonLabel className="textPrimary ion-text-center">
            {UIText.adminMenu}
          </IonLabel>
        </IonItem>
      )}
      {ui.isMobile && (
        <IonItem button onClick={onInbox} lines="full">
          <MdIcon icon={mdiEmail} color="primary"/>
          <IonLabel className="textPrimary ion-text-center">
            {UIText.inbox}
          </IonLabel>
          {unreadCount > 0 && <IonBadge color="tooling">{unreadCount}</IonBadge>}
        </IonItem>
      )}
      {ui.isMobile && (
        <IonItem button onClick={onSettings} lines="full">
          <MdIcon icon={mdiCog} color="primary"/>
          <IonLabel className="textPrimary ion-text-center">
            {UIText.settings}
          </IonLabel>
        </IonItem>
      )}
      {ui.isMobile && (
        <IonItem button onClick={onLogout} lines="none">
          <MdIcon icon={mdiLogoutVariant} color="primary"/>
          <IonLabel className="textPrimary ion-text-center">
            {UIText.logout}
          </IonLabel>
        </IonItem>
      )}
    </IonList>;
  });

export default UserGroupMenu;

