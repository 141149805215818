// import { observer } from "mobx-react";
// import React from "react";
// import { RouteComponentProps } from "react-router";
// import {
//   IonButton,
//   IonButtons,
//   IonCard,
//   IonCardContent,
//   IonContent,
//   IonHeader,
//   IonIcon,
//   IonLoading,
//   IonModal,
//   IonPage,
//   IonTitle,
//   IonToolbar,
//   withIonLifeCycle
// } from "@ionic/react";
// import { ui } from "../../../../client/ui";
// import Refresher from "../../../../components/Refresher";
// import { UIText } from "../../../../client/lang";
// import { computed, observable } from "mobx";
// import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
// import { RfqInboxController } from "./controller";
// import { StdErr } from "../../../../lib/types/miscTypes";
// import { CardedContainerHeader } from "../../../../components/CardedContainerHeader";
// import { getDisplayNameEng, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
// import { stateCtrl } from "../../../../client/state";
// import MdIcon from "../../../../components/MdIcon";
// import { mdiAccountCircle, mdiDotsVertical, mdiEmail, mdiEmailOpen, mdiInformationOutline } from "@mdi/js";
// import "./styles.less";
// import InboxItem from "../../../../components/InboxItem";
// import { getGroupParty, getRfqDescriptionLsp } from "../../lib/common";
// import { AccordionController } from "../../../../lib/accordion";
// import { Rfq } from "../../lib/types/dataTypes";
// import { Message, Thread } from "../../../../lib/types/topicTypes";
// import { arrowBack } from "ionicons/icons";
// import ChatModalContent from "../../../../components/ChatModalContent";
// import { Group, Member, Profile } from "../../../../lib/types/dataTypes";
// import { groupTypeIds } from "../../lib/constants";
// import { msgCtrl } from "../../../../client/msg";
// import HeaderRefreshButton from "../../../../components/HeaderRefreshButton";
// import ChatModal from "../../../../components/ChatModal";
// import { getThreadDescription } from "../../lib/chat-utilities";
//
// export interface RfqInboxProps extends RouteComponentProps<GroupSubRouteParams<{
//   threadId: string;
// }>> {}
//
// @observer
// class RfqInbox extends React.Component<RfqInboxProps> {
//   url: string;
//   controller: RfqInboxController = {} as RfqInboxController;
//   accordionController: AccordionController;
//   title: string = UIText.inbox;
//
//   @observable loading: boolean = false;
//   @observable reading: boolean = false;
//   @observable isModalOpen: boolean = false;
//   @observable modalThreadId: Thread["id"];
//   @observable modalInputValues: { [key: number]: string } = {};
//   @observable modalLoading: boolean = false;
//   @observable modalHidden: boolean = false;
//
//   @computed get group(): Group {
//     return this.controller.group;
//   };
//   @computed get party(): ReturnType<typeof getGroupParty> {
//     return this.controller.party;
//   };
//   @computed get groupName(): string {
//     return getDisplayNameEng(stateCtrl.currentGroup.profile);
//   };
//   @computed get unreadCount(): number {
//     return this.controller.totalUnreadCount || 0;
//   };
//   @computed get hasUnread(): boolean {
//     return this.unreadCount > 0;
//   };
//   @computed get unreadMessages(): Message[] {
//     return this.controller.unreadMessages;
//   };
//   @computed get inboxItems(): Rfq[] {
//     return this.controller.rfqs
//     // .sort(rfq => {
//     //   const threads = this.controller.findRfqThreads(rfq.id);
//     //   const hasUnread = threads && threads.some(this.getThreadUnreads);
//     //   return hasUnread ? -1 : 1;
//     // })
//     .filter(rfq => !isEmpty(this.controller.findRfqThreads(rfq.id)));
//   };
//   @computed get modalThread(): Thread {
//     return this.controller.threads.find(thread => thread.id === this.modalThreadId) || {} as Thread;
//   };
//
//   constructor(props) {
//     super(props);
//     this.controller = new RfqInboxController();
//     this.accordionController = new AccordionController("RfqInbox", true);
//   }
//
//   showError = (err: StdErr, actionName?: string) =>
//     ui.showError({
//       err,
//       actionName: actionName || this.title
//     });
//
//   ionViewWillEnter = () => {
//     this.url = this.props.match.url;
//     this.modalHidden = false;
//     const { groupId, threadId } = this.props.match.params;
//     if (!groupId) return this.props.history.goBack();
//     msgCtrl.addDismissChatHandler(this.onDismissModal);
//     return this.onRefresh()
//     .then(() => {
//       if (threadId && this.party) this.handleThreadClick(null, Number(threadId));
//     });
//   };
//
//   ionViewWillLeave = () => {
//     this.controller.clearThreads();
//     this.modalThreadId = undefined;
//     msgCtrl.removeDismissChatHandler(this.onDismissModal);
//   };
//
//   onRefresh = (event?: any) => {
//     preventDefaultStopProp(event);
//     this.loading = true;
//     return this.controller.loadAllData()
//     .catch(this.showError)
//     .finally(() => this.loading = false);
//   };
//
//   onDismissModal = event => this.handleModalChange(event, "close");
//
//   handleAccordionClick = (event: any, id: Rfq["id"]) => {
//     const { onAccordionFold, isAccordionExpanded } = this.accordionController;
//     const isExpanded = isAccordionExpanded(id);
//     if (onAccordionFold) return onAccordionFold(id, !isExpanded);
//   };
//
//   handleThreadClick = (event: any, id: Thread["id"]) => {
//     preventDefaultStopProp(event);
//     if (!id) return;
//     return this.handleModalChange(event, "open", id);
//   };
//
//   handleModalChange = (
//     event: any,
//     action?: "open" | "close",
//     threadId?: number
//   ) => {
//     preventDefaultStopProp(event);
//     const isModalOpen =
//       action
//         ? action === "open"
//         ? true
//         : action === "close"
//         : false;
//     isModalOpen ? msgCtrl.openChat(threadId, this.group.id) : msgCtrl.dismissChat();
//     const url: string = action === "open"
//       ? `/Group/${this.group.id}/Inbox/${threadId}`
//       : `/Group/${this.group.id}/Inbox`;
//     this.props.history.replace(url);
//   };
//
//   handleInboxMenu = (event: any) => {
//     preventDefaultStopProp(event);
//     const expandCollapse = (expand?: boolean) => {
//       const { isAccordionExpanded } = this.accordionController;
//       for (const item of this.inboxItems) {
//         const isExpanded = isAccordionExpanded(item.id);
//         if (expand && isExpanded) continue;
//         if (!expand && !isExpanded) continue;
//         this.handleAccordionClick(null, item.id);
//       }
//     };
//     return ui.popoverMenu({
//       event,
//       menuItems: [
//         {
//           text: UIText.inboxMarkAllAsRead,
//           handler: this.markAllAsRead
//         },
//         {
//           text: UIText.generalExpandAll,
//           handler: () => expandCollapse(true)
//         },
//         {
//           text: UIText.generalCollapseAll,
//           handler: () => expandCollapse()
//         }
//       ]
//     })
//   };
//
//   markAllAsRead = async () => {
//     const { onMessageRead } = this.controller;
//     this.reading = true;
//     return Promise.all(this.unreadMessages.map(onMessageRead))
//     .then(() => ui.toast({ header: UIText.inboxMarkedAllAsRead }))
//     .catch(this.showError)
//     .finally(() => this.reading = false);
//   };
//
//   getThreadUnreads = (thread: Thread) => {
//     const { member } = this.controller;
//     if (isEmpty(thread) || isEmpty(thread.messages)) return 0;
//     return thread.messages.filter(msg => msgCtrl.isUnread(msg, member.id)).length;
//   };
//
//   render() {
//     const { findRfqThreads } = this.controller;
//     const { isAccordionExpanded } = this.accordionController;
//
//     return <IonPage className="headerOffset rfqInbox">
//       <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
//         <IonLoading translucent isOpen={this.loading} />
//         <IonCard className="flex max column container inboxContainer cardedContainer">
//           <CardedContainerHeader
//             loading={this.loading || this.reading}
//             title={UIText.inboxFor(this.groupName)}
//             titleRight={
//               <IonButtons>
//                 <IonButton onClick={this.handleInboxMenu}>
//                   <MdIcon icon={mdiDotsVertical} color="primary" />
//                 </IonButton>
//               </IonButtons>
//             }
//             onRefresh={!ui.isMobile && this.onRefresh}
//             backHandler={this.props.history.goBack}
//           >
//             <div
//               className={`${
//                 ui.isMobile ? "font-xs" : "font-s"
//               } ${
//                 this.hasUnread ? "textPrimary" : ""} cardedContainerSubHeader`
//               }
//             >
//               <MdIcon
//                 icon={this.hasUnread ? mdiEmail : mdiEmailOpen}
//                 color={this.hasUnread ? "tooling" : ""}
//                 className="ion-margin-end"
//               />
//               <span className="textBold">{UIText.inboxUnread(!this.loading && this.unreadCount)}</span>
//             </div>
//           </CardedContainerHeader>
//           <IonCardContent className="flex max column ion-no-padding">
//             <IonContent className={`max fullBackground ${ui.isMobile ? "" : ""}`}>
//               <Refresher customAppearance appearTop color="primary" onRefresh={this.onRefresh} isMobile={ui.isMobile} />
//               {!this.loading && this.inboxItems
//               .map(rfq => (
//                 <InboxItem
//                   className={ui.isMobile ? "ion-margin" : ""}
//                   key={rfq.id}
//                   header={
//                     this.party === "lsp"
//                       ? getRfqDescriptionLsp(rfq)
//                       : rfq.description
//                   }
//                   expanded={isAccordionExpanded(rfq.id)}
//                   threads={findRfqThreads(rfq.id)}
//                   onClick={e => this.handleAccordionClick(e, rfq.id)}
//                   onThreadClick={this.handleThreadClick}
//                   getThreadDescription={thread => getThreadDescription(rfq, thread, this.party)}
//                   getThreadUnreads={this.getThreadUnreads}
//                   hideEmptyThread={this.party === "shipper"}
//                 />
//               ))}
//             </IonContent>
//           </IonCardContent>
//         </IonCard>
//       </IonContent>
//     </IonPage>
//   }
// }
//
// export default withIonLifeCycle(RfqInbox);

import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import { ui } from "../../../../client/ui";
import Refresher from "../../../../components/Refresher";
import { UIText } from "../../../../client/lang";
import { computed, observable } from "mobx";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import { RfqInboxController } from "./controller";
import { StdErr } from "../../../../lib/types/miscTypes";
import CardedContainerHeader from "../../../../components/CardedContainerHeader";
import { getDisplayNameEng, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import { stateCtrl } from "../../../../client/state";
import MdIcon from "../../../../components/MdIcon";
import { mdiDotsVertical, mdiEmail, mdiEmailOpen } from "@mdi/js";
import "./styles.less";
import InboxItem from "../../../../components/InboxItem";
import { getGroupParty, getRfqDescriptionLsp, getRfqDescriptionShipper } from "../../lib/common";
import { AccordionController } from "../../../../lib/accordion";
import { Rfq } from "../../lib/types/dataTypes";
import { Message, Thread } from "../../../../lib/types/topicTypes";
import { arrowBack } from "ionicons/icons";
import ChatModalContent from "../../../../components/ChatModalContent";
import { Group, Member, Profile } from "../../../../lib/types/dataTypes";
import { msgCtrl } from "../../../../client/msg";
import HeaderRefreshButton from "../../../../components/HeaderRefreshButton";
import { getThreadDescription, getThreadModalTitle } from "../../lib/chat-utilities";

export interface RfqInboxProps extends RouteComponentProps<GroupSubRouteParams<{
  threadId: string;
}>> {}

@observer
class RfqInbox extends React.Component<RfqInboxProps> {
  url: string;
  controller: RfqInboxController = {} as RfqInboxController;
  accordionController: AccordionController;
  title: string = UIText.inbox;

  @observable loading: boolean = false;
  @observable reading: boolean = false;
  @observable isModalOpen: boolean = false;
  @observable modalThreadId: Thread["id"];
  @observable modalInputValues: { [key: number]: string } = {};
  @observable modalLoading: boolean = false;
  @observable modalHidden: boolean = false;

  @computed get group(): Group {
    return this.controller.group;
  };
  @computed get party(): ReturnType<typeof getGroupParty> {
    return this.controller.party;
  };
  @computed get groupName(): string {
    return getDisplayNameEng(stateCtrl.currentGroup.profile);
  };
  @computed get unreadCount(): number {
    return this.controller.totalUnreadCount || 0;
  };
  @computed get hasUnread(): boolean {
    return this.unreadCount > 0;
  };
  @computed get unreadMessages(): Message[] {
    return this.controller.unreadMessages;
  };
  @computed get inboxItems(): Rfq[] {
    return this.controller.rfqs
    .sort(rfq => {
      const threads = this.controller.findRfqThreads(rfq.id);
      const hasUnread = threads && threads.some(this.getThreadUnreads);
      return hasUnread ? -1 : 1;
    })
    .filter(rfq => !isEmpty(this.controller.findRfqThreads(rfq.id)));
  };
  @computed get modalThread(): Thread {
    return this.controller.threads.find(thread => thread.id === this.modalThreadId) || {} as Thread;
  };

  constructor(props) {
    super(props);
    this.controller = new RfqInboxController();
    this.accordionController = new AccordionController("RfqInbox", true);
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    this.modalHidden = false;
    const { groupId, threadId } = this.props.match.params;
    if (isNaN(Number(groupId))) return this.props.history.goBack();
    return this.onRefresh()
    .then(() => {
      if (threadId && this.party) this.handleThreadClick(null, Number(threadId));
    });
  };

  ionViewWillLeave = () => {
    this.controller.clearThreads();
    this.modalThreadId = undefined;
  };

  onRefresh = (event?: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  onDismissModal = event => this.handleModalChange(event, "close");

  handleAccordionClick = (event: any, id: Rfq["id"]) => {
    const { onAccordionFold, isAccordionExpanded } = this.accordionController;
    const isExpanded = isAccordionExpanded(id);
    if (onAccordionFold) return onAccordionFold(id, !isExpanded);
  };

  handleThreadClick = (event: any, id: Thread["id"]) => {
    preventDefaultStopProp(event);
    if (!id) return;
    return this.handleModalChange(event, "open", id);
  };

  handleModalChange = (
    event: any,
    action?: "open" | "close",
    threadId?: number
  ) => {
    preventDefaultStopProp(event);
    this.isModalOpen =
      action
        ? action === "open"
        ? true
        : action === "close"
          ? false
          : !this.isModalOpen
        : !this.isModalOpen;
    this.modalThreadId = action === "open" ? Number(threadId) : undefined;
    const url: string = action === "open"
      ? `/Group/${this.group.id}/Inbox/${threadId}`
      : `/Group/${this.group.id}/Inbox`;
    this.props.history.replace(url);
  };

  handleModalInputChange = (value: string) => this.modalInputValues[this.modalThreadId] = value;

  handleChatModalSendClick = (event: any) => {
    preventDefaultStopProp(event);
    const value = this.modalInputValues[this.modalThreadId];
    this.modalInputValues[this.modalThreadId] = "";
    return this.controller.onMessageSend(
      this.modalThread.topicId,
      this.modalThreadId,
      value
    );
  };

  handleModalRefresh = (event: any) => {
    preventDefaultStopProp(event);
    const topicId = this.modalThread.topicId;
    this.modalLoading = true;
    return this.controller.updateRfqThreads(topicId)
    .catch(this.showError)
    .finally(() => this.modalLoading = false);
  };

  handleMessageAvatarClick = (event: any, id: Message["id"]) => {
    preventDefaultStopProp(event);
    const message = this.controller.findMessageById(id);
    if (!message) return;
    const profile = this.getMessageSenderProfile(message);
    if (isEmpty(profile)) return;
    this.modalHidden = true;
    return this.props.history.push(`/Group/${this.group.id}/Profile/${profile.id}`)
  };

  handleInboxMenu = (event: any) => {
    preventDefaultStopProp(event);
    const expandCollapse = (expand?: boolean) => {
      const { isAccordionExpanded } = this.accordionController;
      for (const item of this.inboxItems) {
        const isExpanded = isAccordionExpanded(item.id);
        if (expand && isExpanded) continue;
        if (!expand && !isExpanded) continue;
        this.handleAccordionClick(null, item.id);
      }
    };
    return ui.popoverMenu({
      event,
      menuItems: [
        {
          text: UIText.inboxMarkAllAsRead,
          handler: this.markAllAsRead
        },
        {
          text: UIText.generalExpandAll,
          handler: () => expandCollapse(true)
        },
        {
          text: UIText.generalCollapseAll,
          handler: () => expandCollapse()
        }
      ]
    })
  };

  markAllAsRead = async () => {
    const { onMessageRead } = this.controller;
    this.reading = true;
    return Promise.all(this.unreadMessages.map(onMessageRead))
    .then(() => ui.toast({ header: UIText.inboxMarkedAllAsRead }))
    .catch(this.showError)
    .finally(() => this.reading = false);
  };

  getThreadDescription = (rfq: Rfq, thread: Thread) => getThreadDescription(rfq, thread, this.party);

  getThreadModalTitle = (rfqId: Rfq["id"], threadId: Thread["id"]) => {
    const rfqTopic = this.controller.rfqTopics.find(rfq => rfq.id === rfqId);
    const thread = this.controller.threads.find(thread => thread.id === threadId);
    return getThreadModalTitle(rfqTopic, thread, this.party);
  };

  getThreadUnreads = (thread: Thread) => {
    const { member } = this.controller;
    if (isEmpty(thread) || isEmpty(thread.messages)) return 0;
    return thread.messages.filter(msg => msgCtrl.isUnread(msg, member.id)).length;
  };

  getMessageSenderProfile = (message: Message) => {
    if (isEmpty(message)) return {} as Profile;
    const sender: Member = this.controller.findSenderMember(message);
    return (sender || {}).profile || {} as Profile;
  };

  isInfoThread = thread => thread.memberIdList && thread.memberIdList.length === 2;

  render() {
    const { findRfqThreads, member, onMessageRead } = this.controller;
    const { isAccordionExpanded } = this.accordionController;

    return <IonPage className="headerOffset rfqInbox">
      <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
        <IonLoading translucent isOpen={this.loading} />
        <IonCard className="flex max column container inboxContainer cardedContainer">
          <CardedContainerHeader
            loading={this.loading || this.reading}
            title={UIText.inboxFor(this.groupName)}
            titleRight={
              <IonButtons>
                <IonButton onClick={this.handleInboxMenu}>
                  <MdIcon icon={mdiDotsVertical} color="primary" />
                </IonButton>
              </IonButtons>
            }
            onRefresh={!ui.isMobile && this.onRefresh}
            backHandler={this.props.history.goBack}
          >
            <div
              className={`${
                ui.isMobile ? "font-xs" : "font-s"
              } ${
                this.hasUnread ? "textPrimary" : ""} cardedContainerSubHeader`
              }
            >
              <MdIcon
                icon={this.hasUnread ? mdiEmail : mdiEmailOpen}
                color={this.hasUnread ? "tooling" : ""}
                className="ion-margin-end"
              />
              <span className="textBold">{UIText.inboxUnread(!this.loading && this.unreadCount)}</span>
            </div>
          </CardedContainerHeader>
          <IonCardContent className="flex max column ion-no-padding">
            <IonContent className="max fullBackground">
              <Refresher customAppearance appearTop color="primary" onRefresh={this.onRefresh} isMobile={ui.isMobile} />
              {!this.loading && this.inboxItems
              .map(rfq => (
                <InboxItem
                  className={ui.isMobile ? "ion-margin" : ""}
                  key={rfq.id}
                  header={
                    this.party === "lsp"
                      ? getRfqDescriptionLsp(rfq)
                      : getRfqDescriptionShipper(rfq)
                  }
                  expanded={isAccordionExpanded(rfq.id)}
                  threads={findRfqThreads(rfq.id)}
                  onClick={e => this.handleAccordionClick(e, rfq.id)}
                  onThreadClick={this.handleThreadClick}
                  getThreadDescription={thread => this.getThreadDescription(rfq, thread)}
                  getThreadUnreads={this.getThreadUnreads}
                  hideEmptyThread={this.party === "shipper"}
                />
              ))}
            </IonContent>
          </IonCardContent>
        </IonCard>

        <IonModal
          keyboardClose={false}
          cssClass={`rfqInboxChatModal modal large ${this.modalHidden ? "ion-hide" : ""}`}
          isOpen={this.isModalOpen}
          onDidDismiss={this.onDismissModal}
        >
          <IonHeader translucent>
            <IonToolbar>
              <IonTitle className="textSelectable">
                {this.getThreadModalTitle(this.modalThread.topicId, this.modalThreadId)}
              </IonTitle>
              <IonButtons slot="start">
                <IonButton onClick={this.onDismissModal}>
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <HeaderRefreshButton loading={this.modalLoading} onRefresh={this.handleModalRefresh} />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <ChatModalContent
            inputValue={this.modalInputValues[this.modalThreadId]}
            onInputValueChange={this.handleModalInputChange}
            onSendClick={this.handleChatModalSendClick}
            onMessageRead={onMessageRead}
            onMessageAvatarClick={this.handleMessageAvatarClick}
            messages={this.modalThread.messages}
            getMessageIsSystem={msgCtrl.isSystem}
            getMessageSenderName={m => getDisplayNameEng(this.getMessageSenderProfile(m))}
            getMessageSelf={m => msgCtrl.isSelf(m, member.id)}
            getMessageUnread={m => msgCtrl.isUnread(m, member.id)}
            inputPlaceholderText={this.isInfoThread(this.modalThread) && UIText.chatBottomBarEnterNotes}
          />
        </IonModal>
      </IonContent>
    </IonPage>
  }
}

export default withIonLifeCycle(RfqInbox);