import { Controller } from "../../../../lib/controller";
import { ShippingUoc, ShippingUom } from "../../lib/types/rateTypes";
import { api } from "../../../../client/api";
import { endpointConfig } from "../../../../config/api";
import { computed, IObservableArray, observable } from "mobx";
import { UIText } from "../../../../client/lang";
import { SortableStore } from "../../../../lib/types/miscTypes";
import { client } from "../../../../client/client";
import { isEqual } from "../../../../utils/helpers";

export interface ShippingUocListStore extends SortableStore<ShippingUoc> {
  
}

export class ShippingUocListController extends Controller<ShippingUocListStore> {
  @observable shippingUocs: IObservableArray<ShippingUoc> = [] as IObservableArray<ShippingUoc>;
  @observable _shippingUoms: IObservableArray<ShippingUom> = [] as IObservableArray<ShippingUom>;

  @computed get shippingUoms(): IObservableArray<ShippingUom> {
    return [
      { id: 0, localeUom: { [UIText.preference]: "—" } },
      ...this._shippingUoms
    ] as IObservableArray<ShippingUom>;
  };
  @computed get isSa(): boolean {
    return !!client.user.isSa;
  };

  loadAllData = () => Promise.all([
    this.getShippingUocs(),
    this.getShippingUoms()
  ]);

  getShippingUocs = async () => api.GET(endpointConfig.shipping_uocs)
  .then(response => this.shippingUocs = (Array.isArray(response.data) && response.data) || []);

  getShippingUoms = async () => api.GET(endpointConfig.shipping_uoms)
  .then(response => this._shippingUoms = (Array.isArray(response.data) && response.data) || []);

  onFieldChange = (id: number, _field: keyof ShippingUoc, _value: ShippingUoc[keyof ShippingUoc]) => {
    const uoc = this.shippingUocs.find(c => c.id === Number(id));
    if (!uoc) return;
    const { field, value } = this.normalizeFieldChange(uoc[_field], _field, _value);
    if (isEqual(uoc[field], value)) return;
    (uoc[field] as ShippingUoc[keyof ShippingUoc]) = value;
    return true;
  };

  onUpdateField = async (id: number, _field: keyof ShippingUoc, _value: ShippingUoc[keyof ShippingUoc]) => {
    const uoc = this.shippingUocs.find(c => c.id === Number(id));
    if (!uoc) return;
    const { field, value } = this.normalizeFieldChange(uoc[_field], _field, _value);
    return api.PATCH({
      endpoint: endpointConfig.shipping_uoc_by_id(id),
      data: { [field]: value }
    });
  };

  onAddShippingUoc = async () => api.POST(endpointConfig.shipping_uocs)
  .then(this.getShippingUocs);

  onRemoveShippingUoc = async (id: number) => {
    const shippingUoc = this.shippingUocs.find(c => c.id === id);
    if (!shippingUoc) return;
    this.shippingUocs.remove(shippingUoc);
    return api.DELETE(endpointConfig.shipping_uoc_by_id(id))
    .finally(this.getShippingUocs);
  };

  normalizeFieldChange = (originalValue, field: keyof ShippingUoc, value: ShippingUoc[keyof ShippingUoc]) => {
    if (field === "perUomId" || field === "perUomRatio") {
      value = Number(value) || 0;
    }
    if (field === "localeUoc" || field === "description") {
      value = {
        ...originalValue,
        [UIText.preference]: value
      };
    }
    return { field, value };
  }
}
