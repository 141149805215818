import { VerificationParams, VerificationResult } from "./types/dataTypes";
import { UIException } from "../client/lang";
import { api } from "../client/api";
import { endpointConfig } from "../config/api";
import { AxiosResponse } from "axios";
import { StdErr } from "./types/miscTypes";

// VerificationController.
// Utility Class for various user verification services.
export class VerificationController {
  hash: VerificationParams["hash"];
  identifier: VerificationParams["user" | "email"];
  type: VerificationParams["type"];

  get verificationIsResetPassword(): boolean {
    return this.type === "reset";
  };
  get verificationIsChangeEmail(): boolean {
    return this.type === "changeEmail";
  };
  get verificationIsChangeEmailRevert(): boolean {
    return this.type === "changeEmailRevert";
  };

  verify = (type: VerificationParams["type"]) => {
    this.setVerificationType(type);
    return {
      identify: id => this.setIdentifier(id)
    }
  };

  setVerificationType = (type: VerificationParams["type"]) => {
    if (type && !type.match(/changeEmail|changeEmailRever|reset/g)) {
      throw new UIException("INVALID_VERIFICATION_TYPE");
    }
    this.type = type;
  };

  setIdentifier = (id: VerificationController["identifier"]) => {
    if (!id) throw new UIException("VERIFICATION_UNKNOWN_IDENTIFIER");
    this.identifier = id;
    return {
      post: hashOrToken => this.execVerification(hashOrToken),
    };
  };

  execVerification = async (hashOrToken: string): Promise<VerificationResult["user"] | string> =>
    api.storage.isReady()
    .then(() => api.POST({
      endpoint:
        endpointConfig[
          this.verificationIsResetPassword
            ? "reset_pass_verify"
            : this.verificationIsChangeEmail
            ? "verify_email_change"
            : this.verificationIsChangeEmailRevert
            ? "revert_email_change_exec"
            : "verify"
          ],
      data: {
        token: hashOrToken,
        identifier: this.identifier
      }
    }))
    .then((response: AxiosResponse<VerificationResult | string>) => {
      const result = response.data;
      if (typeof result !== "string" && !(result as VerificationResult).pass) {
        return this.reject();
      }
      return this.verificationIsResetPassword
        ? result as string
        : (result as VerificationResult).user;
    })
    .catch(err => this.reject(err));

  reject = (err?: StdErr) => Promise.reject(new UIException(this.verificationIsResetPassword
    ? "PASSWORD_RESET_EXPIRE_INVALID"
    : "VERIFICATION_EXPIRE_INVALID", err));
}