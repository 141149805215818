import general from './general';
import app3win from './app3win';
import exceptions from './exceptions';

const enCA = {
  ...general,
  ...app3win,
  exceptions
};

export default enCA;