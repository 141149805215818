import { observer } from "mobx-react";
import React from "react";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { UIText } from "../../../../client/lang";
import InjectIonLabelHelpTip from "../../../../components/InjectIonLabelHelpTip";
import { ShippingRateBreakdown, ShippingRateUnit, ShippingUoc } from "../../lib/types/rateTypes";
import "./styles.less";

export interface RateBasicFreightItemProps {
  readonly: boolean;
  breakdown: ShippingRateBreakdown;
  unit: ShippingRateUnit;
  uocs: ShippingUoc[];
  handleFieldChange: (event: any) => void;
}

@observer
class RateBasicFreightItem extends React.Component<RateBasicFreightItemProps> {
  // @computed get availableUocs(): ShippingUoc[] {
  //   return this.props.breakdown.unitType === "w/m"
  //     ? this.props.uocs.filter(uoc => (uoc.perUom || {}).id === kgPerUomId)
  //     : this.props.uocs;
  // };

  // handlePerUocMenu = (event: any) => {
  //   const menuItems = isEmpty(this.availableUocs)
  //     ? [{ text: UIText.generalUnavailable, cssClass: "noEvents item-disabled", handler: () => {} }]
  //     : this.availableUocs.map(uoc => ({
  //       text: (uoc.localeUoc || {})[UIText.preference] || uoc.id.toString(),
  //       handler: () => this.props.handleFieldChange({
  //         target: {
  //           name: "basicAmountPerUocId",
  //           value: uoc.id
  //         }
  //       })
  //     }));
  //   return ui.popoverMenu({
  //     event,
  //     menuItems
  //   });
  // };

  render() {
    const {
      readonly,
      // breakdown,
      unit,
      // uocs,
      handleFieldChange
    } = this.props;

    // const basicAmountPerUoc = uocs.find(uoc => uoc.id === unit.basicAmountPerUocId) || {} as ShippingUoc;

    return <IonItem className="rateBasicFreightItem relative" lines="full">
      <IonLabel color="primary" position="floating">
        {UIText.rateManagement.basicFreight}
      </IonLabel>
      <IonInput
        readonly={readonly}
        name="basicAmount"
        placeholder={UIText.rateManagement.basicFreight}
        value={unit.basicAmount}
        onIonBlur={!readonly && handleFieldChange}
      />
      {/*{breakdown.unitType !== "unit" && (*/}
      {/*  <IonButtons className="perUocSelector">*/}
      {/*    <IonButton size="small" color="primary" onClick={this.handlePerUocMenu}>*/}
      {/*      <IonIcon slot="start" icon={caretDownSharp} />*/}
      {/*      <IonText>*/}
      {/*        {UIText.rateManagement.basicFreightPerUoc((basicAmountPerUoc.localeUoc || {})[UIText.preference])}*/}
      {/*      </IonText>*/}
      {/*    </IonButton>*/}
      {/*  </IonButtons>*/}
      {/*)}*/}
      <InjectIonLabelHelpTip
        className="helpTip absolute"
        help={{
          header: UIText.generalHelp,
          message: UIText.generalHelp
        }}
      />
    </IonItem>;
  }
}

export default RateBasicFreightItem;
