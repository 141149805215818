import { observer } from "mobx-react";
import React, { MouseEvent } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonLoading,
  IonReorderGroup,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addCircleOutline, arrowBack, arrowDownCircleOutline, arrowForwardSharp, ellipsisVertical, saveOutline } from "ionicons/icons";
import { UIText } from "../../../../client/lang";
import { ui } from "../../../../client/ui";
import { FreightServiceLeg, QuotationServiceDetailModalProps } from "../../lib/types/propTypes";
import { Rfq } from "../../lib/types/dataTypes";
import { computed, IObservableArray, observable, reaction, toJS } from "mobx";
import { getEventRealValue, getPriceString, isEmpty, isEqual, preventDefaultStopProp, randomString } from "../../../../utils/helpers";
import PolPodTrafficText from "../PolPodTrafficText";
import MdIcon from "../../../../components/MdIcon";
import { mdiCheckBold } from "@mdi/js";
import { ItemReorderEventDetail } from "@ionic/core";
import FreightLegItem from "./FreightLegItem";
import "./styles.less";
import { Breadcrumbs } from "@material-ui/core";
import { RfqFormDTO } from "../../lib/types/rfqFormTypes";

const AddLegComponent = observer(({ onAddLeg }) => (
  <IonItem
    className="addLegButton ion-margin-bottom"
    button
    lines="none"
    onClick={onAddLeg}
  >
    <IonText className="flex ion-align-items-center ion-justify-content-center font-xl">
      <IonIcon icon={addCircleOutline} color="medium" />
    </IonText>
  </IonItem>
));

const LegArrowComponent = observer(() => (
  <IonItem
    className="addLegButton ion-margin-bottom noEvents"
    lines="none"
  >
    <IonText className="flex ion-align-items-center ion-justify-content-center font-xl">
      <IonIcon icon={arrowDownCircleOutline} color="medium" />
    </IonText>
  </IonItem>
));

interface QuotationServiceFreightFormProps extends QuotationServiceDetailModalProps {
  rfq: Rfq;
  legs: FreightServiceLeg[];
  descriptionAsTitle?: boolean;
  onSubmit?: (event: MouseEvent) => void;
}

@observer
class QuotationServiceFreightForm extends React.Component<QuotationServiceFreightFormProps> {
  disposer;
  @observable legs: FreightServiceLeg[] = [];
  @observable emptyError: boolean;
  itemErrors = [
    "pol",
    "pod",
    "mode",
    "price",
    "carrier",
    "frequency",
    "rateValidity",
    "transitTime"
  ];
  @observable itemErrorItems = {};

  @computed get availableModes(): Rfq["mode"][] {
    const { mode, altModes, multimodal } = this.props.rfq;
    if (isEmpty(altModes) && !multimodal) return [mode];
    if (multimodal) return ["air", "sea", "rail", "road"];
    return [
      mode,
      ...altModes.filter(m => m !== mode)
    ];
  };

  @computed get altModes(): Rfq["mode"][] {
    return this.props.rfq.altModes.map(
      mode => mode !== this.props.rfq.mode && UIText.rfqForm[mode]
    ).filter(Boolean) as RfqFormDTO["mode"][];
  };

  @computed get surcharges(): number {
    return this.legs.reduce((a, b) => a + Number(b.surcharges), 0);
  };
  @computed get price(): number {
    return this.legs.reduce((a, b) => a + Number(b.price), 0);
  };
  @computed get totalPrice(): number {
    return this.surcharges + this.price;
  };

  constructor(props) {
    super(props);
    if (!isEmpty(props.legs) && isEmpty(this.legs)) this.legs = toJS(this.props.legs);
    this.disposer = reaction(() => toJS(this.legs), this.clearErrors);
  }

  componentDidUpdate(prevProps: Readonly<QuotationServiceFreightFormProps>): void {
    if (isEqual(prevProps.legs, this.props.legs) && isEqual(this.legs, this.props.legs)) return;
    if (isEmpty(this.legs)) {
      this.legs = toJS(this.props.legs);
    }
  }

  reset = () => (this.legs as IObservableArray).clear();

  // TODO: Fine-control the POL and POD in-between segments to form a seamless route.
  onAddLeg = (event: any, index: number) => {
    preventDefaultStopProp(event);
    const {
      isFormLocked,
      rfq
    } = this.props;
    if (isFormLocked) return;
    const newLeg: FreightServiceLeg = {
      key: randomString(),
      pol: isEmpty(this.legs) ? rfq.pol : index === -1 ? rfq.pol : this.legs[index].pod,
      pod: isEmpty(this.legs) ? rfq.pod : undefined,
      carrier: "",
      mode: this.props.rfq.mode,
      surcharges: 0,
      price: 0,
      rateValidity: "",
      transitTime: 0
    };
    if (this.legs.length === 0) return this.legs.push(newLeg);
    return this.legs.splice(index + 1, 0, newLeg);
  };

  onRemoveLeg = (event: any, id) => {
    preventDefaultStopProp(event);
    if (this.props.isFormLocked) return;
    const leg = this.legs.find(leg => leg.key === id || leg.id === id);
    if (!leg) return;
    return (this.legs as IObservableArray<FreightServiceLeg>).remove(leg);
  };

  onLegReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    if (this.props.isFormLocked) return false;
    const { complete } = event.detail;
    return this.legs = complete(this.legs);
  };

  onLegModeChange = (mode: Rfq["mode"], id) => {
    if (this.props.isFormLocked) return;
    const leg = this.legs.find(leg => leg.key === id || leg.id === id);
    if (!leg) return;
    if (!this.availableModes.includes(mode)) return;
    if (!this.props.rfq.multimodal) return this.legs.forEach(leg => leg.mode = mode);
    if (leg.mode === mode) return;
    return leg.mode = mode;
  };

  onLegFieldChange = (event: any, id) => {
    preventDefaultStopProp(event);
    if (this.props.isFormLocked) return;
    const leg = this.legs.find(leg => leg.key === id || leg.id === id);
    if (!leg) return;
    const name = event.target.name;
    const realValue = getEventRealValue(event);
    if (realValue === leg[name]) return;
    return leg[name] = realValue;
  };

  validate = () => {
    let pass = true;
    if (this.legs.length === 0) {
      this.emptyError = true;
      return false;
    }
    for (const property of this.itemErrors) {
      for (const item of this.legs) {
        if (!this.itemErrorItems[item.key || item.id]) this.itemErrorItems[item.key || item.id] = {};
        if (!item[property]) {
          this.itemErrorItems[item.key || item.id][property] = true;
          pass = false;
        }
      }
    }
    return pass;
  };

  clearErrors = () => {
    this.emptyError = false;
    this.itemErrorItems = {};
  };

  render() {
    const {
      rfq,
      legs,
      modalItem,
      subHeader,
      loading,
      menuIcon,
      isFormLocked,
      descriptionAsTitle,
      onDismissModal,
      onMenu,
      // onRefresh,
      onSubmit
    } = this.props;
    const TrafficComponent = <PolPodTrafficText mode={rfq.mode} pod={rfq.pod} pol={rfq.pol} />;
    return <>
      <IonHeader translucent>
        <IonToolbar>
          {!descriptionAsTitle && (
            <IonTitle color="primary">
              {isEmpty(legs) ? UIText.generalAdd : UIText.generalEdit}&nbsp;{UIText.freight}
            </IonTitle>
          )}
          {descriptionAsTitle && <IonTitle color="primary">{modalItem.description}</IonTitle>}
          <IonButtons slot="start">
            <IonButton onClick={onDismissModal}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            {!isFormLocked && (
              <IonButton onClick={onSubmit} className="textBold" color="primary">
                <IonIcon slot="start" icon={saveOutline} />{UIText.generalSave}
              </IonButton>
            )}
            {onMenu && (
              <IonButton onClick={onMenu}>
                {typeof menuIcon !== "string" && menuIcon}
                {(!menuIcon || typeof menuIcon === "string") && <IonIcon slot="icon-only" icon={menuIcon as string || ellipsisVertical} />}
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className={!ui.isMobile ? "ion-padding" : ""} scrollY={false}>
        {/*<Refresher customAppearance color="light" onRefresh={onRefresh} isMobile={ui.isMobile} />*/}
        <IonLoading translucent isOpen={loading} />
        <div className="flex column max">
          {subHeader && (
            <div className="flex column ion-padding-horizontal">
              {subHeader}
            </div>
          )}
          <div>
            <div className={`flex ion-justify-content-between ion-align-items-center ion-wrap ${subHeader ? "ion-padding-horizontal ion-padding-bottom" : "ion-padding"}`}>
              <IonText className="textBold textLight font-m flex ion-align-items-center">
                {UIText.rfqPrimaryMode}:&nbsp;{UIText.rfqForm[rfq.mode]}
              </IonText>
              <IonText className="textBold textLight font-m flex ion-align-items-center">
                {TrafficComponent}
              </IonText>
            </div>
            <div className="flex ion-justify-content-between ion-align-items-center ion-wrap ion-margin-bottom">
              <div className="flex ion-padding-horizontal ion-align-items-center textLight textBold font-s">
                {rfq.multimodal && <IonText className="flex ion-align-items-end">
                  {UIText.rfqForm.multimodal}&nbsp;<MdIcon icon={mdiCheckBold} />
                </IonText>}
                {!rfq.multimodal && !isEmpty(rfq.altModes) && <div className="flex ion-wrap">
                  <IonText>
                    {this.altModes.length === 1 ? UIText.freightForm.altMode : UIText.freightForm.altModes}:&nbsp;
                  </IonText>
                  <IonText>
                    {this.altModes.join(", ")}
                  </IonText>
                </div>}
              </div>
              <div className="flex ion-padding-horizontal ion-align-items-center textLight textBold font-xs">
                <IonText>
                  {UIText.freightForm.totalPrice}:&nbsp;{getPriceString(this.totalPrice, "$", true)}
                </IonText>
              </div>
            </div>
            <div className="flex ion-padding-horizontal ion-align-items-center ion-wrap ion-margin-bottom freightSegmentIndicator">
              <Breadcrumbs separator={<IonIcon icon={arrowForwardSharp} size="small"/>} className="textLight textBold font-xs">
                {this.legs
                .filter(leg => !!leg.pol)
                .map(leg => (
                  <IonText key={leg.key || leg.id}>
                    {leg.pol.city}
                  </IonText>
                ))}
                <IonText>
                  {((this.legs[this.legs.length - 1] || {}).pod || {}).city || ""}
                </IonText>
              </Breadcrumbs>
            </div>
          </div>
          <IonCard className="max" style={{ flex: 1 }}>
            <IonCardContent className="max formView fancyScroll ion-no-padding ion-padding-horizontal">
              <IonList className="ion-no-padding">
                <IonListHeader className={`ion-no-padding textBold font-s ${this.emptyError ? "textDanger" : "textPrimary"}`}>
                  {UIText.rfqTrafficSegments}
                </IonListHeader>
              </IonList>
              {!isFormLocked && <AddLegComponent onAddLeg={e => this.onAddLeg(e, -1)} />}
              <IonReorderGroup disabled={isFormLocked} onIonItemReorder={this.onLegReorder}>
                {this.legs.map((leg, i) => <div className="flex column" key={leg.key || leg.id}>
                  <FreightLegItem
                    className="ion-margin-bottom"
                    leg={leg}
                    index={i}
                    error={this.itemErrorItems[leg.key || leg.id] || {}}
                    readonly={isFormLocked}
                    availableModes={this.availableModes}
                    onRemoveLeg={e => this.onRemoveLeg(e, leg.key || leg.id)}
                    onModeChange={mode => this.onLegModeChange(mode, leg.key || leg.id)}
                    onStringFieldChange={e => this.onLegFieldChange(e, leg.key || leg.id)}
                  />
                  {!isFormLocked && <AddLegComponent onAddLeg={e => this.onAddLeg(e, i)} />}
                  {isFormLocked && i !== this.legs.length - 1 && <LegArrowComponent />}
                </div>)}
              </IonReorderGroup>
              {/*<FormView />*/}
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </>;
  }
}

export default QuotationServiceFreightForm;