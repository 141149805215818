import { IonButton, IonButtons, IonCard, IonContent, IonIcon, IonInput, IonPage, IonRippleEffect, withIonLifeCycle } from "@ionic/react";
import { computed, IObservableArray, observable } from "mobx";
import { observer, Observer } from "mobx-react";
import React from "react";
import "./styles.less";
import { StdErr } from "../../../../lib/types/miscTypes";
import { ui } from "../../../../client/ui";
import { RouteComponentProps } from "react-router";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import CardedContainerHeader from "../../../../components/CardedContainerHeader";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { UIText } from "../../../../client/lang";
import { ShippingUnit } from "../../lib/types/rateTypes";
import Picker from "../../../../components/FormFields/Picker";
import { addOutline, caretDownSharp, closeOutline, ellipsisHorizontal, funnel, funnelOutline } from "ionicons/icons";
import { asyncPause, getEventRealValue, isEmpty, preventDefaultStopProp, tableViewScrollToBottom } from "../../../../utils/helpers";
import { AutoObserverList } from "../../../../components/ObserverList";
import TwoStageInput from "../../../../components/TwoStageInput";
import { ShippingUnitListController } from "./controller";
import SortableTableHead from "../../../../components/SortableTableHead";
import { sortByProperty } from "../../lib/common";
import FilterItemList from "../../../../components/FilterItemList";
import { ListController } from "../../../../lib/list-controller";
import { defaultDimensionUnit, dimensionOptions } from "../../config/constants";

export interface ShippingUnitListProps extends RouteComponentProps<GroupSubRouteParams<{}>> {}

const DimensionLet = observer(
  ({
     readonly,
     l,
     w,
     h,
     unit,
     roundDimension,
     onDimensionChange
   }) => (
    <div className="flex">
      <IonInput
        readonly={readonly}
        name="length"
        inputMode="numeric"
        type="number"
        min="0"
        step={unit === "m" ? "0.001" : "0.1"}
        placeholder={UIText.rfqForm.length}
        value={roundDimension(l, defaultDimensionUnit, unit)}
        // onIonChange={onDimensionChange}
        onIonBlur={onDimensionChange}
      />
      <IonInput
        readonly={readonly}
        name="width"
        inputMode="numeric"
        type="number"
        min="0"
        step={unit === "m" ? "0.001" : "0.1"}
        placeholder={UIText.rfqForm.width}
        value={roundDimension(w, defaultDimensionUnit, unit)}
        // onIonChange={onDimensionChange}
        onIonBlur={onDimensionChange}
      />
      <IonInput
        readonly={readonly}
        name="height"
        inputMode="numeric"
        type="number"
        min="0"
        step={unit === "m" ? "0.001" : "0.1"}
        placeholder={UIText.rfqForm.height}
        value={roundDimension(h, defaultDimensionUnit, unit)}
        // onIonChange={onDimensionChange}
        onIonBlur={onDimensionChange}
      />
    </div>
  ));

@observer
class ShippingUnitList extends React.Component<ShippingUnitListProps> {
  url: string;
  title = UIText.rateManagement.systemShippingUnits;

  controller: ShippingUnitListController = {} as ShippingUnitListController;
  listController: ListController<ShippingUnit>;

  @observable loading: boolean = false;

  @computed get dimensionUnit() {
    return this.controller.dimensionUnit;
  };
  @computed get shippingUnits(): ShippingUnit[] {
    return this.controller.shippingUnits;
  };

  @computed get sortedUnits(): ShippingUnit[] {
    return [...this.shippingUnits].sort(
      (a, b) => sortByProperty(a, b, this.listController.sortBy, this.listController.sortDir)
    );
  };
  @computed get modeFilters(): IObservableArray<number> {
    return [...this.controller.modeFilters].sort() as IObservableArray;
  };
  @computed get filteredUnits(): ShippingUnit[] {
    if (isEmpty(this.modeFilters)) return this.sortedUnits;
    return this.sortedUnits.filter(unit => this.modeFilters.some(modeId => unit.modeIds.includes(modeId)));
  };

  constructor(props) {
    super(props);
    this.url = props.match.url;
    this.controller = new ShippingUnitListController();
    this.listController = new ListController({
      storeName: "ShippingUnitListFilterSort",
      initialSortBy: "id"
    });
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    if (!this.controller.isSa) return this.props.history.replace("/");
    return this.onRefresh();
  };

  onRefresh = (event?: any) => {
    this.loading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleFieldChange = (event: any) => {
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const id = name.split(".")[0];
    const field = name.split(".")[1];
    const changed = this.controller.onFieldChange(id, field, value);
    return !!changed && setTimeout(() => this.updateFieldAsync(id, field, value));
  };

  handleDimensionFieldChange = (event: any, unit: ShippingUnit) => {
    const { name, value } = event.target;
    const changed = this.controller.onFieldChange(unit.id, name, value);
    return !!changed && setTimeout(() => this.updateFieldAsync(unit.id, name, value));
  };

  handleDimensionUnitMenu = (event: any) => ui.popoverMenu({
    event,
    menuItems: dimensionOptions.map(unit => ({
      text: unit,
      cssClass: this.dimensionUnit === unit ? "textBold" : "",
      handler: () => this.controller.setDimensionUnit(unit)
    }))
  });

  updateFieldAsync = async (id: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateField(id, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleAdd = (event: any) => {
    preventDefaultStopProp(event);
    const defaultModeId = this.controller.modes[0].id;
    const modeId = this.modeFilters[0] || defaultModeId;
    if (!modeId) return;
    this.loading = true;
    return this.controller.onAddShippingUnit(modeId)
    .then(() => asyncPause(500))
    .then(tableViewScrollToBottom)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRemove = (event: any, unit: ShippingUnit) => ui.reconfirm({
    header: `${UIText.generalDelete} Shipping Unit`,
    name: `the System Shipping Unit ID ${unit.id}${
      unit.unitName[UIText.preference] ? ` "${unit.unitName[UIText.preference]}"` : ""
    }`,
    verb: UIText.generalDelete.toLowerCase(),
    handler: () => this.handleRemoveConfirm(event, unit.id)
  });

  handleRemoveConfirm = (event: any, id: number) => {
    if (!id) return;
    this.loading = true;
    return this.controller.onRemoveShippingUnit(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  handleModeFilterMenu = (event: any) => {
    const { modes, onFilterClear, onFilterSelect } = this.controller;
    const filters = modes.map(mode => ({
      name: mode.id.toString(), placeholder: mode.localeMode[UIText.preference]
    }));
    return ui.popover({
      event,
      component: () => <FilterItemList
        filters={filters}
        selectedFilters={this.modeFilters.map(f => f.toString())}
        onFilterClear={onFilterClear}
        onFilterSelect={onFilterSelect}
      />,
      showBackdrop: false
    });
  };

  render() {
    const {
      modes,
      isModeAvailable,
      roundDimension,
      // isShippingUnitLikeWeightBreak,
      shippingRateBreakdowns
    } = this.controller;

    const { sortBy, sortDir } = this.listController;

    return (
      <IonPage className="headerOffset shippingUnitList">
        <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
          <IonCard className="flex max column container inboxContainer cardedContainer">
            <Observer>{() => (
              <CardedContainerHeader
                title={this.title}
                titleRight={
                  <IonButtons>
                    <IonButton
                      className="textBold textNoTransform"
                      fill="clear"
                      size="small"
                      onClick={this.handleModeFilterMenu}
                    >
                      <IonIcon icon={isEmpty(this.modeFilters) ? funnelOutline : funnel} slot="start" />
                      {UIText.rateManagement.systemShippingModes}
                    </IonButton>
                    <IonButton color="primary" className="textNoTransform" onClick={this.handleAdd}>
                      <IonIcon slot="icon-only" icon={addOutline}/>
                    </IonButton>
                  </IonButtons>
                }
                backHandler={this.props.history.goBack}
                loading={this.loading}
                onRefresh={this.onRefresh}
              />
            )}</Observer>
            <IonContent className="max fullBackground ion-no-padding" scrollY={false}>
              <TableContainer component={props => <Paper {...props} square />} elevation={0} className="max">
                <Table size="small" stickyHeader>
                  <Observer>{() => (
                    <SortableTableHead
                      columns={[
                        { name: "id", text: "ID *" },
                        { name: "breakdownId", text: "Rate Breakdown *", },
                        { name: "modeIds", text: "Modes *", routerLink: "/ShippingModes", noSort: true },
                        { name: "unitName", text: "Unit name *" },
                        { name: "dimensions", component: <TableCell className="relative ion-activatable" onClick={this.handleDimensionUnitMenu}>
                            <div className="flex ion-align-items-center ion-justify-content-center textBold textDark">
                              Dimensions L / W / H ({this.dimensionUnit})&nbsp;
                              <IonIcon icon={caretDownSharp} color="dark"/>
                            </div>
                            <IonRippleEffect />
                          </TableCell> },
                        { name: "volume", text: "Capacity (CBM)" },
                        { name: "min", text: "Min (kg)" },
                        { name: "max", text: "Max (kg)" },
                        // { name: "uomId", text: "UOM *", routerLink: "/ShippingUoms" },
                        { name: "description", text: `Description (${UIText.preference})` },
                        { name: "delete", text: UIText.generalDelete, noSort: true }
                      ]}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      onSort={this.handleColumnSort}
                    />
                  )}</Observer>
                  <Observer>{() => (
                    <TableBody>
                      <AutoObserverList
                        list={this.filteredUnits}
                        getItemKey={unit => unit.id}
                        render={(unit: ShippingUnit) => {
                          return <TableRow>
                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                {unit.id}
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <Picker
                                  name={`${unit.id}.breakdownId`}
                                  className="ion-no-padding"
                                  options={shippingRateBreakdowns.map(breakdown => ({
                                    name: breakdown.id.toString(), placeholder: breakdown.localeUnitName[UIText.preference]
                                  }))}
                                  value={unit.breakdownId.toString()}
                                  onIonChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <Picker
                                  name={`${unit.id}.modeIds`}
                                  className="ion-no-padding"
                                  multiple
                                  placeholder="Modes"
                                  options={modes.map(mode => ({
                                    name: mode.id.toString(),
                                    placeholder: mode.localeMode[UIText.preference],
                                    disabled: !isModeAvailable(mode.id, unit)
                                  }))}
                                  value={unit.modeIds.map(id => isModeAvailable(id, unit) && id.toString()).filter(Boolean)}
                                  onIonChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${unit.id}.unitName`}
                                  placeholder="Name of the unit"
                                  value={unit.unitName[UIText.preference] || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            {/*<TableCell align="left" padding="none" className={isShippingUnitLikeWeightBreak(unit) ? "readonly" : ""}>*/}
                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                {/*{!isShippingUnitLikeWeightBreak(unit) && <DimensionLet*/}
                                {<DimensionLet
                                  l={unit.length}
                                  w={unit.width}
                                  h={unit.height}
                                  unit={this.dimensionUnit}
                                  roundDimension={roundDimension}
                                  onDimensionChange={e => this.handleDimensionFieldChange(e, unit)}
                                />}
                              </TableCell>
                            )}</Observer>

                            {/*<TableCell align="left" padding="none" className={isShippingUnitLikeWeightBreak(unit) ? "readonly" : ""}>*/}
                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  // readonly={isShippingUnitLikeWeightBreak(unit)}
                                  className="ion-no-padding"
                                  type="number"
                                  min="0"
                                  name={`${unit.id}.volume`}
                                  value={unit.volume || 0}
                                  // value={!isShippingUnitLikeWeightBreak(unit) ? (unit.volume || 0) : undefined}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  type="number"
                                  min="0"
                                  name={`${unit.id}.min`}
                                  value={unit.min || 0}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  type="number"
                                  min="0"
                                  name={`${unit.id}.max`}
                                  value={unit.max || 0}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            {/*<TableCell align="left" padding="none">*/}
                            {/*  <Picker*/}
                            {/*    name={`${unit.id}.uomId`}*/}
                            {/*    className="ion-no-padding"*/}
                            {/*    options={uoms.map(uom => ({*/}
                            {/*      name: uom.id.toString(), placeholder: uom.localeUom[UIText.preference]*/}
                            {/*    }))}*/}
                            {/*    value={unit.uomId.toString()}*/}
                            {/*    onIonChange={this.handleFieldChange}*/}
                            {/*  />*/}
                            {/*</TableCell>*/}

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <TwoStageInput
                                  endIcon={ellipsisHorizontal}
                                  className="ion-no-padding"
                                  name={`${unit.id}.description`}
                                  placeholder="Description of the unit"
                                  value={unit.description[UIText.preference] || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="center" padding="none">
                                <IonButtons className="flex ion-align-items-center ion-justify-content-center">
                                  <IonButton color="danger" onClick={e => this.handleRemove(e, unit)}>
                                    <IonIcon slot="icon-only" icon={closeOutline}/>
                                  </IonButton>
                                </IonButtons>
                              </TableCell>
                            )}</Observer>
                          </TableRow>
                        }}
                      />
                    </TableBody>
                  )}</Observer>
                </Table>
              </TableContainer>
            </IonContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(ShippingUnitList);