import React from "react";
import { Observer, observer } from "mobx-react";
import "./styles.less";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
} from "@ionic/react";
import { createOutline, layersSharp, trashOutline } from "ionicons/icons";
import { UIText } from "../../../../client/lang";
import { ShippingPlan } from "../../lib/types/rateTypes";
import { observable } from "mobx";
import { asyncPause, getDisplayNameEng, isEmpty, preventDefaultStopProp, whenFulfill } from "../../../../utils/helpers";
import RateTransitPortsIndicator from "../RateTransitPortsIndicator";
import { ui } from "../../../../client/ui";
import MaterialDateTimeItem from "../../../../components/MaterialDateTimeItem";
import InjectIonLabelHelpTip from "../../../../components/InjectIonLabelHelpTip";
import { stateCtrl } from "../../../../client/state";
import { getPortDisplayCode } from "../../lib/common";
import { Tooltip } from "@material-ui/core";
import { RouteComponentProps } from "react-router";

export interface ComplexRateEntryCardProps extends React.ComponentProps<typeof IonCard> {
  plan: ShippingPlan;
  isSa?: boolean;
  getEntryLink: () => string;
  onLoading?: (heavy?: boolean) => void;
  history: RouteComponentProps["history"];
  handlePlanFieldChange?: (event: any) => void;
  handleRemove?: (event: any) => void;
}

@observer
class ComplexRateEntryCard extends React.Component<ComplexRateEntryCardProps> {
  descriptionField: HTMLIonInputElement;
  descriptionFieldRef = elm => this.descriptionField = elm;

  entryButton: HTMLIonButtonElement;
  entryButtonRef = elm => this.entryButton = elm;

  @observable editingDescription: boolean = false;

  handleEditDescription = async (event: any) => {
    preventDefaultStopProp(event);
    this.editingDescription = true;
    await whenFulfill(() => !!this.descriptionField);
    const input = await this.descriptionField.getInputElement();
    if (!input) return;
    input.addEventListener("focusout", () => this.editingDescription = false);
    return input && input.focus();
  };

  onEnter = (event?: any) => {
    preventDefaultStopProp(event);
    const { onLoading, history, getEntryLink } = this.props;
    if (onLoading) onLoading(true);
    return asyncPause().then(() => history.push(getEntryLink()));
  };

  handleOrigPortClick = async (event: any) => {
    await this.onEnter();
    const getNextButton = () => document.getElementsByClassName("complexRateLeftColumnOrig");
    await whenFulfill(() => !!getNextButton());
    (getNextButton()[0] as HTMLIonButtonElement).click();
  };

  handleDestPortClick = async (event: any) => {
    await this.onEnter();
    const getNextButton = () => document.getElementsByClassName("complexRateLeftColumnDest");
    await whenFulfill(() => !!getNextButton());
    (getNextButton()[0] as HTMLIonButtonElement).click();
  };

  render() {
    const {
      className,
      plan,
      isSa,
      getEntryLink,
      handlePlanFieldChange,
      handleRemove
    } = this.props;

    return <>
      <IonCard className={`complexRateEntryCard ${className || ""}`} color="light">
        <Observer>{() => (
          <IonCardHeader className={`flex ion-justify-content-between ${ui.isMobileIntermediate ? "ion-align-items-start" : "ion-align-items-center"}`}>
            <IonCardTitle color="primary" className="textPrimary textBold" style={{ flex: 1 }}>
              {this.editingDescription ? (
                <IonInput
                  ref={this.descriptionFieldRef}
                  name="description"
                  color="primary"
                  className="textPrimary textBold"
                  placeholder={UIText.generalDescription}
                  value={plan.description}
                  onIonBlur={handlePlanFieldChange}
                />
              ) : (
                <IonText
                  className="flex ion-align-items-center ion-activatable editDescription"
                  onClick={this.handleEditDescription}
                >
                  {plan.description || UIText.rateManagement.complexShippingRateTable}&nbsp;
                  <IonIcon className="editDescriptionIcon" icon={createOutline} />
                </IonText>
              )}
              {isSa && plan.lspGroup && plan.lspGroup.id !== stateCtrl.currentGroupId && (
                <IonText className="font-xs textNormal lspName">
                  {UIText.lsp}:&nbsp;{getDisplayNameEng((plan.lspGroup || {}).profile)}
                </IonText>
              )}
              {ui.isMobileIntermediate && (
                <div className="entryButton">
                  <IonButton
                    ref={this.entryButtonRef}
                    size="small"
                    routerLink={getEntryLink()}
                    onClick={this.onEnter}
                    className="textNoTransform textBold"
                  >
                    <IonIcon slot="start" icon={layersSharp} />
                    {UIText.rateManagement.editComplexMultimodal}
                  </IonButton>
                </div>
              )}
            </IonCardTitle>
            <IonButtons className={!ui.isMobileIntermediate && "ion-margin-end"}>
              <IonButton onClick={handleRemove}>
                <IonIcon icon={trashOutline} slot="icon-only" color="danger" />
              </IonButton>
            </IonButtons>
            {!ui.isMobileIntermediate && (
              <IonButton
                ref={this.entryButtonRef}
                routerLink={getEntryLink()}
                onClick={this.onEnter}
                className="textNoTransform textBold"
              >
                <IonIcon slot="start" icon={layersSharp} />
                {UIText.rateManagement.editComplexMultimodal}
              </IonButton>
            )}
          </IonCardHeader>
        )}</Observer>

        <IonCardContent>
          <IonCardHeader>
            <IonCardSubtitle className="font-s textBold textDarkMedium">
              {UIText.rateManagement.portRouteSummary}
            </IonCardSubtitle>
          </IonCardHeader>
         <Observer>{() => (
           <div className="flex column firstGroup itemGroups">
             <div className="flex ion-wrap ion-align-items-start">
               <div className="flex ion-wrap firstGroupSubWrap">
                 <IonItem lines="none" onClick={this.handleOrigPortClick} className="relative ion-activatable">
                   <IonLabel color="primary" position="stacked">
                     {UIText.rfqForm.origin}
                   </IonLabel>
                   <Tooltip
                     arrow
                     enterDelay={isEmpty((plan.origRate || {}).polPort) ? Number.MAX_SAFE_INTEGER : undefined}
                     placement="bottom"
                     title={(((plan.origRate || {}).polPort || {}).portName || {})[UIText.preference] || ""}
                   >
                     <div className="linkButton">
                       <IonInput
                         readonly
                         className="noEvents textPrimary"
                         value={getPortDisplayCode((plan.origRate || {}).polPort)}
                         placeholder={UIText.rfqForm.origin}
                       />
                     </div>
                   </Tooltip>
                 </IonItem>
                 <IonItem lines="none" onClick={this.handleDestPortClick} className="relative ion-activatable">
                   <IonLabel color="primary" position="stacked">
                     {UIText.rfqForm.destination}
                   </IonLabel>
                   <Tooltip
                     arrow
                     enterDelay={isEmpty((plan.destRate || {}).podPort) ? Number.MAX_SAFE_INTEGER : undefined}
                     placement="bottom"
                     title={(((plan.destRate || {}).podPort || {}).portName || {})[UIText.preference] || ""}
                   >
                     <div className="linkButton">
                       <IonInput
                         readonly
                         className="noEvents textPrimary"
                         value={getPortDisplayCode((plan.destRate || {}).podPort)}
                         placeholder={UIText.rfqForm.destination}
                       />
                     </div>
                   </Tooltip>
                 </IonItem>
               </div>
             </div>
           </div>
         )}</Observer>


          <IonCardHeader>
            <IonCardSubtitle className="font-s textBold textDarkMedium">
              {UIText.rateManagement.rateDetails}
            </IonCardSubtitle>
          </IonCardHeader>
          <Observer>{() => (
            <div className="flex column firstGroup itemGroups">
              <div className="flex ion-wrap ion-align-items-start">
                <div className="flex ion-wrap firstGroupSubWrap">
                  {/*<MaterialDateTimeItem*/}
                  {/*  className="relative"*/}
                  {/*  useMuiOnly*/}
                  {/*  // type="datetime"*/}
                  {/*  name="validityStartDate"*/}
                  {/*  lines="none"*/}
                  {/*  label={UIText.rateManagement.validityStart}*/}
                  {/*  color="primary"*/}
                  {/*  min={new Date().toISOString()}*/}
                  {/*  placeholder={UIText.rateManagement.validityStart}*/}
                  {/*  displayFormat="YYYY-MM-DD"*/}
                  {/*  value={plan.validityStartDate && new Date(plan.validityStartDate).toISOString()}*/}
                  {/*  onIonChange={handlePlanFieldChange}*/}
                  {/*>*/}
                  {/*  <InjectIonLabelHelpTip*/}
                  {/*    className="helpTip absolute"*/}
                  {/*    help={{*/}
                  {/*      header: UIText.generalHelp,*/}
                  {/*      message: UIText.generalHelp*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</MaterialDateTimeItem>*/}
                  <MaterialDateTimeItem
                    className="relative"
                    useMuiOnly
                    // type="datetime"
                    name="validityEndDate"
                    lines="none"
                    label={UIText.rateManagement.validity}
                    color="primary"
                    min={new Date().toISOString()}
                    placeholder={UIText.rateManagement.validity}
                    displayFormat="DDDD YYYY-MM-DD"
                    value={plan.validityEndDate && new Date(plan.validityEndDate).toISOString()}
                    onIonChange={handlePlanFieldChange}
                  >
                    <InjectIonLabelHelpTip
                      className="helpTip absolute"
                      help={{
                        header: UIText.generalHelp,
                        message: UIText.generalHelp
                      }}
                    />
                  </MaterialDateTimeItem>
                </div>
              </div>
            </div>
          )}</Observer>

          <IonCardHeader>
            <IonCardSubtitle className="font-s textBold textDarkMedium">
              {UIText.rateManagement.transitPortRouteSummary}
            </IonCardSubtitle>
          </IonCardHeader>
          <Observer>{() => (
            <div className="flex column transitPortGroup itemGroups ion-margin-bottom">
              <div className="flex ion-wrap ion-align-items-start">
                <IonItem lines="none" style={{ flex: 1 }} routerLink={getEntryLink()} onClick={this.onEnter}>
                  <IonLabel color="primary" position="stacked">
                    {UIText.rateManagement.transitPorts}
                  </IonLabel>
                  <div className="relative ion-activatable linkButton">
                    {!isEmpty(plan.shippingRates && plan.shippingRates.map(rate => rate.polPort || rate.podPort).filter(Boolean)) ? (
                      <div className="ion-padding-vertical">
                        <RateTransitPortsIndicator
                          showTransshipment
                          legs={plan.shippingRates}
                        />
                      </div>
                    ) : (
                      <IonInput
                        readonly
                        className="noEvents textPrimary textUnderline"
                        placeholder={UIText.rateManagement.complexTransitPortsPlaceholder}
                      />
                    )}
                  </div>
                </IonItem>
              </div>
            </div>
          )}</Observer>

          {/*<IonCardHeader>*/}
          {/*  <IonCardSubtitle className="font-s textBold textDarkMedium">*/}
          {/*    {UIText.rateManagement.charges}*/}
          {/*  </IonCardSubtitle>*/}
          {/*</IonCardHeader>*/}
          {/*<div className="flex column secondGroup itemGroups">*/}
          {/*  <RateCardChargeItemTable*/}
          {/*    isChargesOnly*/}
          {/*    parentEntity={rate}*/}
          {/*    uoms={uoms}*/}
          {/*    shippingRateCharges={filterChargesOnly(rate.shippingRateCharges)}*/}
          {/*    handleParentEntityFieldChange={handleDetailFieldChange}*/}
          {/*    handleChargeItemFieldChange={handleChargeItemFieldChange}*/}
          {/*  />*/}
          {/*</div>*/}
        </IonCardContent>
      </IonCard>
    </>;
  }
}

export default ComplexRateEntryCard;
