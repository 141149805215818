import { Controller } from "../../lib/controller";
import { Form } from "../../client/form";
import { UIText } from "../../client/lang";
import { computed, observable } from "mobx";
import { endpointConfig } from "../../config/api";
import { api } from "../../client/api";
import { client } from "../../client/client";
import { passwordRegExp } from "../../utils/helpers";
import { VerificationController } from "../../lib/verification";

export class ResetController extends Controller {
  requestForm: Form = new Form([{
    type: "input",
    name: "reset_email",
    required: true,
    validationRegex: `/^[a-zA-Z0-9.!#$%&’*+/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$/g`,
    errorMessage: UIText.invalidEmail,
    placeholder: UIText.registrationFields.email
  }]);

  execForm: Form = new Form([
    {
      name: "password",
      type: "input",
      keyboardType: "password",
      clearOnEdit: false,
      required: true,
      validationRegex: [
        {
          validationRegex: `/^.{8,}$/g`,
          errorMessage: UIText.registrationPasswordError8
        },
        {
          validationRegex: `${passwordRegExp.lowerNum8}`,
          errorMessage: UIText.registrationPasswordErrorLowerNum
        }
      ],
      autoComplete: "off",
      placeholder: UIText.resetNewPassword
    },
    {
      name: "repeat",
      type: "input",
      keyboardType: "password",
      clearOnEdit: false,
      required: true,
      errorMessage: UIText.registrationPasswordMismatch,
      errorMessageType: "password",
      autoComplete: "off",
      placeholder: UIText.registrationFields.repeat
    },
  ]);

  @observable execToken: string;
  @observable execUser: string;

  @observable resetHash: string;

  @computed get requestFormValid(): boolean {
    return this.requestForm.form.every(field =>
      (field.required ? !!field.value : true) && !field._errorMessage
    );
  };

  @computed get execFormValid(): boolean {
    return this.execForm.form.every(field =>
      (field.required ? !!field.value : true) && !field._errorMessage
    );
  };

  getExecVerification = async (token: string, user: string) => {
    this.execToken = token;
    this.execUser = user;
    if (!token || !user) return false;
    await client.logout();
    return new VerificationController()
    .verify("reset")
    .identify(user)
    .post(token)
    .then((hash: string) => this.resetHash = hash);
  };

  onPasswordResetRequest = async () => {
    if (!this.requestFormValid) return;
    client.isLoggedIn && await client.logout();
    return api.POST({
      endpoint: endpointConfig.reset_pass_request,
      data: { identifier: this.requestForm.get("reset_email") }
    })
    .catch(err => {
      console.warn(err);
      return Promise.resolve(); // No error regardless.
    });
  };

  onPasswordResetExec = async () => {
    if (!this.execFormValid) return;
    client.isLoggedIn && await client.logout();
    return api.POST({
      endpoint: endpointConfig.reset_pass_exec,
      data: {
        hash: this.resetHash,
        newPass: this.execForm.get("repeat")
      }
    });
  };
}