import { observer } from "mobx-react";
import { api } from "../../client/api";
import { IonText } from "@ionic/react";
import React from "react";
import "./styles.less";
import { env } from "../../config/env";

export const BaseUrlIndicator: React.FC = observer(props => {
  let counter = 0, clicker;
  const toDevSettings = () => {
    if (env.match(/test|prod/g)) return;
    clearTimeout(clicker);
    clicker = setTimeout(() => counter = 0, 250);
    counter++;
    if (counter >= 2) return window.location.href = "/DevTools";
  };
  return (api.baseChanged || env === "local") && (
    <IonText className="baseUrlIndicator ion-activatable textNoSelect font-s" color="medium" onClick={toDevSettings}>
      {api.xBaseSwitch
        ? `Spring Boot: ${api.xBaseSwitch}`
        : api.baseUrl}
    </IonText>
  );
});