import { observer } from "mobx-react";
import React from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { SelectInterface } from "@ionic/core";
import { responsive } from "../../../config/styles/responsive";
import { ui } from "../../../client/ui";
import { UIText } from "../../../client/lang";
import { PickerOption } from "../../../lib/types/formTypes";
import { FormFieldCommonProps } from "../../../lib/types/propTypes";

export interface PickerFieldProps extends FormFieldCommonProps {
  pickerInterface?: SelectInterface;
  options: PickerOption[];
}

const Picker: React.FC<PickerFieldProps> = observer(
  ({
     className,
     name,
     slot,
     pickerInterface,
     value,
     placeholder,
     cssClass,
     options,
     multiple,
     disabled,
     onIonChange,
     onIonBlur
   }) => (
    <IonSelect
      className={className || ""}
      name={name}
      slot={slot || ""}
      interface={pickerInterface || (multiple ? undefined : responsive.pickerInterface)}
      interfaceOptions={{
        ...ui.withTranslucent,
        cssClass: `formPickerPopover ${cssClass}`
      }}
      multiple={multiple}
      value={value}
      placeholder={placeholder}
      cancelText={UIText.generalCancel}
      okText={UIText.generalConfirm}
      disabled={disabled}
      onIonChange={onIonChange}
      onIonBlur={onIonBlur}
    >
      {options
      .filter(o => !o.hidden)
      .map((o: PickerOption) =>
        <IonSelectOption
          key={o.name}
          value={o.name}
          disabled={o.disabled}
        >
          {o.placeholder}
        </IonSelectOption>
      )}
    </IonSelect>
  ));

export default Picker;