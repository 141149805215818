import { Controller } from "../../../../lib/controller";
import { ShippingCharge, ShippingMode } from "../../lib/types/rateTypes";
import { api } from "../../../../client/api";
import { endpointConfig } from "../../../../config/api";
import { computed, IObservableArray, observable } from "mobx";
import { getShippingModes } from "../../lib/common";
import { UIText } from "../../../../client/lang";
import { SortableStore } from "../../../../lib/types/miscTypes";
import { client } from "../../../../client/client";
import { isEqual } from "../../../../utils/helpers";

export interface ShippingChargeListStore extends SortableStore<ShippingCharge> {
  modeFilters: number[];
}

export class ShippingChargeListController extends Controller<ShippingChargeListStore> {
  @observable modes: IObservableArray<ShippingMode> = [] as IObservableArray<ShippingMode>;
  @observable shippingCharges: IObservableArray<ShippingCharge> = [] as IObservableArray<ShippingCharge>;

  @computed get isSa(): boolean {
    return !!client.user.isSa;
  };
  @computed get modeFilters(): IObservableArray<number> {
    this.storage.initProperty("modeFilters", []);
    return this.store.modeFilters as IObservableArray<number>;
  };

  loadAllData = () => Promise.all([
    this.getModes(),
    this.getShippingCharges()
  ]);

  getModes = async () => getShippingModes()
  .then(modes => this.modes = modes);

  getShippingCharges = async () => api.GET(endpointConfig.system_shipping_charges)
  .then(response => this.shippingCharges = (Array.isArray(response.data) && response.data) || []);

  onFieldChange = (id: number, _field: string, _value) => {
    const charge = this.shippingCharges.find(c => c.id === Number(id));
    if (!charge) return;
    const { field, value } = this.normalizeFieldChange(charge[_field], _field, _value);
    if (isEqual(charge[field], value)) return;
    charge[field] = value;
    return true;
  };

  onUpdateField = async (id: number, _field: string, _value) => {
    const charge = this.shippingCharges.find(c => c.id === Number(id));
    if (!charge) return;
    const { field, value } = this.normalizeFieldChange(charge[_field], _field, _value);
    return api.PATCH({
      endpoint: endpointConfig.shipping_charge_by_id(id),
      data: { [field]: value }
    });
  };

  onAddShippingCharge = async (modeId: number) => api.POST({
    endpoint: endpointConfig.new_shipping_charge,
    data: { modeIds: [modeId] }
  })
  .then(this.getShippingCharges);

  onRemoveShippingCharge = async (id: number) => {
    const shippingCharge = this.shippingCharges.find(c => c.id === id);
    if (!shippingCharge) return;
    this.shippingCharges.remove(shippingCharge);
    return api.DELETE(endpointConfig.shipping_charge_by_id(id))
    .finally(this.getShippingCharges);
  };

  onFilterClear = () => this.modeFilters.clear();

  onFilterSelect = (event: any) => {
    const { name } = event.target;
    if (this.modeFilters.includes(Number(name))) {
      return this.modeFilters.remove(Number(name));
    }
    return this.modeFilters.push(Number(name));
  };

  normalizeFieldChange = (originalValue, field: string, value) => {
    if (field === "modeIds") {
      (value as any[]) = Array.isArray(value)
        ? value.map(v => isNaN(Number(v)) ? null : Number(v)).filter(Boolean)
        : [value].filter(Boolean);
    }
    if (field === "chargeName") {
      value = {
        ...originalValue,
        [UIText.preference]: value
      };
    }
    if (field === "description") {
      value = {
        ...originalValue,
        [UIText.preference]: value
      };
    }
    if (field === "isSurcharge") {
      value = value ? 1 : 0
    }
    return { field, value };
  }
}
