import { observer } from "mobx-react";
import { ui } from "../../client/ui";
import { IonDatetime, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { observable } from "mobx";
import { getIonStyleVal } from "../../config/styles/style-utils";

export type ResponsiveDatetimeProps = Omit<(React.ComponentProps<typeof IonDatetime> & React.ComponentProps<typeof IonItem>), "type"> & {
  label: string;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  type?: "datetime" | "date";
  useMuiOnly?: boolean;
}

@observer
class MaterialDateTimeItem extends React.Component<ResponsiveDatetimeProps> {
  @observable isMuiPickerOpen: boolean = false;
  get controlledOpen(): boolean {
    return this.props.hasOwnProperty("isOpen");
  };
  render() {
    const {
      className,
      name,
      label,
      color,
      displayFormat,
      isOpen,
      type,
      min,
      max,
      placeholder,
      value,
      readonly,
      lines,
      useMuiOnly,
      onClick,
      onOpen,
      onClose,
      onIonChange,
      children
    } = this.props;
    const wrapMuiEvent = val => {
      const value = new Date(val).toISOString();
      const evt = {
        target: {
          name,
          value
        },
        detail: {
          value
        }
      };
      return onIonChange && onIonChange(evt as any);
    };
    return (ui.isMobile || readonly) && !useMuiOnly ? (
      <IonItem className={className} lines={lines} onClick={onClick}>
        <IonLabel position="floating" color={color || undefined}>
          {label}
        </IonLabel>
        <IonDatetime
          {...this.props}
        />
        {children}
      </IonItem>
    ) : <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <IonItem
        className={className}
        lines={lines}
        onClick={e => {
          if (readonly) return;
          if (!this.controlledOpen) this.isMuiPickerOpen = true;
          if (onClick) onClick(e);
        }}
      >
        <IonLabel position="floating" color={color || undefined}>
          {label}
        </IonLabel>
        <style>{`
          .MuiPickerDTTabs-tabs,
          .MuiPickersToolbar-toolbar,
          .MuiPickersDay-daySelected {
            background-color: ${getIonStyleVal("--ion-color-primary")}; 
          }
          .MuiButton-textPrimary {
            color: ${getIonStyleVal("--ion-color-primary")};
          }
        `}</style>
        <IonDatetime
          readonly
          placeholder={placeholder}
          displayFormat={displayFormat}
          value={value}
        />
        {children}
      </IonItem>
      {type === "datetime" ? (
        <DateTimePicker
          name={name}
          value={new Date(value)}
          minDate={min}
          maxDate={max}
          open={this.controlledOpen ? isOpen : this.isMuiPickerOpen}
          onChange={wrapMuiEvent}
          onOpen={this.controlledOpen ? onOpen : (() => this.isMuiPickerOpen = true)}
          onClose={this.controlledOpen ? onClose : (() => this.isMuiPickerOpen = false)}
          TextFieldComponent={() => null}
        />
      ) : (
        <DatePicker
          name={name}
          value={new Date(value)}
          minDate={min}
          maxDate={max}
          open={this.controlledOpen ? isOpen : this.isMuiPickerOpen}
          onChange={wrapMuiEvent}
          onOpen={this.controlledOpen ? onOpen : (() => this.isMuiPickerOpen = true)}
          onClose={this.controlledOpen ? onClose : (() => this.isMuiPickerOpen = false)}
          TextFieldComponent={() => null}
        />
      )}
    </MuiPickersUtilsProvider>;
  }
}

export default MaterialDateTimeItem;