import { observer } from "mobx-react";
import React, { MouseEvent } from "react";
import { IonContent } from "@ionic/react";
import ChatBottomBar from "../ChatBottomBar";
import { Message } from "../../lib/types/topicTypes";
import ChatMessage from "../ChatMessage";
import { ui } from "../../client/ui";

export interface ChatModalContentProps extends React.ComponentProps<typeof IonContent>{
  inputValue?: string;
  onInputValueChange?: (value: string) => void;
  onSendClick: (event: MouseEvent) => void;
  onMessageRead: (message: Message) => void;
  messages: Message[];
  getMessageIsSystem: (message: Message) => boolean;
  getMessageSenderName: (message: Message) => string;
  getMessageSelf: (message: Message) => boolean;
  getMessageUnread: (message: Message) => boolean;
  inputPlaceholderText?: string;
  onMessageAvatarClick?: (event: MouseEvent, id: Message["id"]) => void;
}

@observer
class ChatModalContent extends React.Component<ChatModalContentProps> {
  messagesContainer: HTMLDivElement;
  lastMessageLength: number = 0;

  componentDidUpdate(prevProps: Readonly<ChatModalContentProps>): void {
    const currentLength = this.props.messages && this.props.messages.length;
    if (this.lastMessageLength === currentLength || !currentLength) return;
    this.scrollToBottom();
    this.lastMessageLength = currentLength;
  }

  scrollToBottom = () => {
    this.messagesContainer &&
    setTimeout(() => this.messagesContainer.scrollTop = Number.MAX_SAFE_INTEGER, ui.defaultDuration);
  };

  render() {
    const {
      messages,
      inputValue,
      onInputValueChange,
      onMessageRead,
      onSendClick,
      getMessageIsSystem,
      getMessageSenderName,
      getMessageSelf,
      getMessageUnread,
      inputPlaceholderText,
      onMessageAvatarClick
    } = this.props;

    return <IonContent fullscreen className="chatModalContent fullBackground">
      <div className="flex column max">
        <div ref={elm => this.messagesContainer = elm} className="max chatModalContentMessages fancyScroll">
          {messages && messages.map(message => (
            <ChatMessage
              key={message.id || message.watermark}
              senderName={getMessageSenderName(message)}
              text={message.text}
              html={message.html}
              isSelf={getMessageSelf(message)}
              isSystem={getMessageIsSystem(message)}
              isUnread={getMessageUnread(message)}
              isSending={!message.id}
              timestamp={message.sentTime ? new Date(message.sentTime).toLocaleString() : ""}
              readMessage={() => onMessageRead(message)}
              onAvatarClick={e => onMessageAvatarClick(e, message.id)}
            />
          ))}
        </div>
        <ChatBottomBar
          placeholderText={inputPlaceholderText}
          inputValue={inputValue}
          onMessageChange={onInputValueChange}
          onMessageSend={onSendClick}
        />
      </div>
    </IonContent>
  }
}

export default ChatModalContent;