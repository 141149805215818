import { Controller } from "../../../../lib/controller";
import { computed, observable, toJS } from "mobx";
import { RfqFormDTO } from "../../lib/types/rfqFormTypes";
import { contextReject, isEmpty } from "../../../../utils/helpers";
import { Group, Member } from "../../../../lib/types/dataTypes";
import { endpointConfig } from "../../../../config/api";
import { api } from "../../../../client/api";
import { client } from "../../../../client/client";
import { UIText } from "../../../../client/lang";
import { findRfqTopics, getGroupParty, getRfqTopics } from "../../lib/common";
import { stateCtrl } from "../../../../client/state";
import { Rfq } from "../../lib/types/dataTypes";
import { topicCtrl } from "../../../../client/topic";
import { Topic, TopicPrimitive } from "../../../../lib/types/topicTypes";
import { groupTypeIds, topicTypeIds, typeClassIds } from "../../../../config/constants";

export class RfqSubmissionController extends Controller {
  @observable preLoginRfqForm: RfqFormDTO;
  @observable rfqTopic: Topic<RfqFormDTO>;

  @computed get isLoggedIn(): boolean {
    return client.isLoggedIn;
  };
  @computed get group(): Group {
    return stateCtrl.currentGroup;
  };
  @computed get party(): ReturnType<typeof getGroupParty> {
    return getGroupParty(this.group);
  };
  @computed get myShipperGroup(): Group {
    return client.findGroups(group => group.typeId === groupTypeIds.shipper)[0] || {} as Group;
  };
  @computed get member(): Member {
    return client.findMyMemberByGroupId(this.group.id);
  };

  getRfqForm = async (id: Rfq["id"]) =>
    topicCtrl.getTopicById(id).then((topic: Topic<RfqFormDTO>) => {
      this.rfqTopic = topic;
      return topic.data
    });

  setPreLoginForm = (data: RfqFormDTO) => this.preLoginRfqForm = data;

  clearPreLoginRfqForm = () => this.preLoginRfqForm = undefined;

  getRFQNumber = () => `${UIText.RFQ} # ${(findRfqTopics(this.group.id).length + 1).toString().padStart(8, "0")}`;

  onSubmit = async (rfq: RfqFormDTO) => {
    if (isEmpty(rfq)) return;
    const rfqFormDTO: RfqFormDTO = toJS(rfq);
    // Sanitize post data.
    if (!!rfqFormDTO.altModes && isEmpty(rfqFormDTO.altModes)) rfqFormDTO.altModes = undefined;
    if (rfqFormDTO[`_${rfqFormDTO.mode}_items`]) {
      rfqFormDTO.items = JSON.stringify(
        rfqFormDTO[`_${rfqFormDTO.mode}_items`].map(item => {
          if (item.key !== undefined) delete item.key;
          return item;
        })
      );
    }
    if (rfqFormDTO.inspection && rfqFormDTO.inspection._otherChecked) {
      delete rfqFormDTO.inspection._otherChecked;
    }
    if (rfqFormDTO._air_items) delete rfqFormDTO._air_items;
    if (rfqFormDTO._sea_items) delete rfqFormDTO._sea_items;
    if (rfqFormDTO._rail_items) delete rfqFormDTO._rail_items;
    if (rfqFormDTO._road_items) delete rfqFormDTO._road_items;
    await getRfqTopics(this.group.id, "shipper").catch(contextReject);
    const topic: TopicPrimitive = {
      isParentTemplate: 0,
      isTemplate: 0,
      data: JSON.stringify(rfqFormDTO),
      description: this.getRFQNumber(),
      typeClassId: typeClassIds.rfq.v1.id,
      typeClassVersion: typeClassIds.rfq.v1.version,
      typeId: topicTypeIds.rfq
    };
    return api.POST({
      endpoint: endpointConfig.create_topic,
      data: {
        currentGroupId: this.group.id,
        topic
      }
    });
  };
}