import React from "react";
import { observer } from "mobx-react";
import { IonText } from "@ionic/react";
import "./styles.less";

export interface BaseColumnProps extends React.HTMLProps<HTMLDivElement> {
  header?: string | React.ReactNode;
  cards?: React.Component[];
}

const BaseColumn: React.FC<BaseColumnProps> = observer(
  ({ header, className, cards, children }) => {
    return <div className={`flex column baseColumn ${className || ""}`}>
      {header && <div className="flex column ion-padding">
        {typeof header === "string" ? (
          <IonText className="font-m baseColumnTitle">
            {header}
          </IonText>
        ) : (
          header
        )}
      </div>}
      <div className="baseColumnCards fancyScroll">
        {cards || children}
      </div>
    </div>
  });

export default BaseColumn;