export const apiVersion = "v1";

export const endpointConfig = {
  // Registration
  register: `/registration/signup`,
  registerShipperOrLsp: `/registration/signup/create_group_profile`,
  exists: (type, value) => `/registration/signup/exists/${type}?value=${value}`,
  verify: `/registration/signup/verify`,
  resend_verification: `/registration/signup/resend_verify`,

  // Login Sequence
  login: "/auth/oauth/token",
  post_login: "/user/post_login",
  user: "/user/me",

  // Reset password
  reset_pass_request: `/registration/reset`,
  reset_pass_verify: `/registration/reset/verify`,
  reset_pass_exec: `/registration/reset/exec`,

  // Change email
  reset_email_all: `/registration/reset/email`,
  reset_email_options: `/registration/reset/email/options`,
  verify_email_change: `/registration/reset/email/verify`,
  cancel_email_change: `/registration/reset/email/cancel`,
  revert_email_change_request: `/registration/reset/email/revert_request`,
  revert_email_change_exec: `/registration/reset/email/revert`,
  get_revert_email_expiry: changeRequestId =>
    `/registration/reset/email/revert_expiry/${changeRequestId}`,
  get_email_change: `/userEmail/me`,

  // Group
  group_types: typeId => `/groupType/${typeId ? typeId : ""}`,
  get_default_group: "/group/me/default",
  get_my_groups: "/group/me",
  group_by_id: (groupId, memberId) => `/group/${groupId}?memberId=${memberId ? memberId : ""}`,
  create_group_profile: "/group/profile",
  groups_by_type_id: typeId => `/group/typeId/${typeId}`,
  archive_group: (groupId, inform, refund) =>
    `/group/archive/${groupId}/inform/${inform ? 1 : 0}/refund/${refund ? 1 : 0}`,

  // Member
  get_default_member: "/member/me/default",
  get_my_members: "/member/me",
  member_by_id: memberId => `/member/${memberId}`,
  members_by_group_id: groupId => `/member/groupId/${groupId}`,
  create_member: "/member",
  create_member_profile: "/member/profile",
  archive_member: (memberId, inform) => `/member/archive/${memberId}/inform/${inform ? 1 : 0}`,

  // Roles
  roles_by_group_id: (groupId, typeRoleId) =>
    `/role/groupId/${groupId}${typeRoleId ? "/groupTypeRoleId/" + typeRoleId : ""}`,
  group_type_roles_by_group_id: groupTypeId => `/groupTypeRole/groupTypeId/${groupTypeId}`,

  // Profiles
  profile_by_id: profileId => `/profile/${profileId}`,
  profile_field_in_use: "/formExt/profile/field",

  // Topic
  topic_by_id: (topicId, memberId) =>
    `/topic/${topicId}?memberId=${memberId ? memberId : ""}`,
  topic_by_id_w_o_member_id: topicId => `/topic/${topicId}`,
  topic_member_actor: (groupId, topicId, actorName) =>
    `/topic/topicId/${topicId}/groupId/${groupId}/actorName/${actorName}`,
  create_topic: `/topic`,
  create_topic_plain: `/topic/save`,
  topics_by_group_id: (groupId, topicOnly) =>
    `/topic/groupId/${groupId}?topicOnly=${topicOnly ? 1 : 0}`,
  topics_by_type_id: (typeId, isT, isPT, groupId) =>
    `/topic/typeId/${typeId}?isTemplate=${isT ? 1 : ""}&isParentTemplate=${isPT ? 1 : ""}&groupId=${groupId || ""}`,
  topics_by_group_and_type_id: (groupId, typeId, isT, isPT) =>
    `/topic/groupId/${groupId}/typeId/${typeId}?isTemplate=${isT ? 1 : ""}&isParentTemplate=${isPT ? 1 : ""}`,
  sub_topics_by_parent_id: (parentId, recursive) => `/topic/parentId/${parentId}?recursive=${recursive ? 1 : 0}`,
  topic_type_by_id: topicTypeId => `/topic/topicType/${topicTypeId}`,
  topic_by_template_topic_id: templateTopicId =>
    `/topic/templateTopicId/${templateTopicId}`,
  lock_topic: topicId => `/topic/lock/topicId/${topicId}`,
  delete_topic_hard: topicId => `/topic/remove/${topicId}`,

  // Messaging
  create_default_thread: `/thread/default`,
  threads_by_topic_id: (topicId, memberId) => `/thread/allThread/topicId/${topicId}/memberId/${memberId}`,
  create_message: "/messaging",
  get_messages: `/messaging/getMessageHistory`,
  read_message: (messageId, memberId) => `/messageMember/readMessage/memberId/${memberId}/messageId/${messageId}`,
  get_inbox_messages: "/messaging/getInboxMessage",

  // Form Engine
  type_classes: "/form",
  type_class_by_id: typeClassId => `/form/formTypeClassId/${typeClassId}`,
  // type_class_by_id: typeClassId => `http://localhost:8080/form-ext/typeClass/${typeClassId}`,
  update_type_class_byId: typeClassId =>
    `/formExt/form/typeClassId/${typeClassId}`,

  // Billing
  get_plans: groupId => `/plan/plans/groupId/${groupId}`,
  get_group_subscription: groupId =>
    `/planSubscription/groupId/${groupId}`,
  get_plan_price_breakdown: (planId, provState, isTrial, promo) =>
    `/plan/priceBreakDown/planId/${planId}/provState/${provState}/isTrial/${isTrial ? 1 : 0}/promo/${promo ? promo : 0}`,
  get_user_stored_payment: userId => `/payment/userId/${userId}`,
  get_promo_code_validity: (groupId, planId, code) =>
    `/promo/check/groupId/${groupId ? groupId : 0}/planId/${planId}/code/${code}`,
  create_payment_plan: "/planSubscription",
  update_payment: "/planSubscription/updateCustomerPayment",

  // TX
  transaction_sse: clientId => `/sse/push?clientId=${clientId}`,
  logs_after_tx_id: txId => `/log/afterTxId/${txId}`,

  // App3WIN
  update_quotation_service_topic: "/topic/updateQuotationService",
  group_quotation_count: groupId => `/topic/quotationCount/groupId/${groupId}`,
  check_shipper_lsp_chat_thread: (topicId, memberId) => `/thread/rfqLspShipperChatThread/topicId/${topicId}/lspMemberId/${memberId}`,

  // Shipping rates
  shipping_modes: "/shippingMode",
  new_shipping_mode: "/shippingMode/new",
  shipping_mode_by_id: id => `/shippingMode/${id}`,

  shipping_ports: "/shippingPort",
  shipping_port_by_id: id => `/shippingPort/${id}`,

  shipping_units: "/shippingUnit",
  shipping_units_public: "/shippingUnit/public",
  new_shipping_unit: "/shippingUnit/new",
  shipping_unit_by_id: id => `/shippingUnit/${id}`,

  shipping_rate_breakdowns: "/shippingRateBreakdown",
  shipping_rate_breakdown_by_id: id => `/shippingRateBreakdown/${id}`,

  shipping_plans: type => `/shippingPlan${type ? `?type=${type}` : ""}`,
  new_shipping_plan: "/shippingPlan/new",
  shipping_plan_by_id: id => `/shippingPlan/${id}`,
  shipping_plans_by_group_id: (id, type) => `/shippingPlan/groupId/${id}${type ? `?type=${type}` : ""}`,
  new_shipping_rate_for_plan: id => `/shippingPlan/${id}/newShippingRate`,
  shipping_rate_By_id_for_plan: (id, rateId) => `/shippingPlan/${id}/shippingRate/${rateId}`,

  shipping_plan_leg_by_id: id => `/shippingPlanLeg/${id}`,

  shipping_rates: "/shippingRate",
  new_shipping_rate: "/shippingRate/new",
  shipping_rate_by_id: id => `/shippingRate/${id}`,
  rates_by_group_id: id => `/shippingRate/groupId/${id}`,

  shipping_rate_units: "/shippingRateUnit",
  new_shipping_rate_unit: "/shippingRateUnit/new",
  shipping_rate_unit_by_id: id => `/shippingRateUnit/${id}`,

  shipping_rate_legs: "/shippingRateLeg",
  new_shipping_rate_leg: "/shippingRateLeg/new",
  shipping_rate_leg_by_id: id => `/shippingRateLeg/${id}`,

  shipping_charges: "/shippingCharge",
  system_shipping_charges: "/shippingCharge?system=1",
  carrier_shipping_charges: (lspGroupId, carrierGroupId) => `/shippingCharge?lspGroupId=${lspGroupId}&carrierGroupId=${carrierGroupId}`,
  new_shipping_charge: "/shippingCharge/new",
  shipping_charge_by_id: id => `/shippingCharge/${id}`,

  shipping_rate_charges: "/shippingRateCharge",
  new_shipping_rate_charge: "/shippingRateCharge/new",
  shipping_rate_charge_by_id: id => `/shippingRateCharge/${id}`,

  shipping_rate_charge_items: "/shippingRateChargeItem",
  new_shipping_rate_charge_item: "/shippingRateChargeItem/new",
  shipping_rate_charge_item_by_id: id => `/shippingRateChargeItem/${id}`,

  shipping_uocs: "/shippingUoc",
  shipping_uoc_by_id: id => `/shippingUoc/${id}`,

  shipping_uoms: "/shippingUom",
  shipping_uom_by_id: id => `/shippingUom/${id}`,

  carriers: "/shippingCarrier",
  carrier_by_id: id => `/shippingCarrier/${id}`
};