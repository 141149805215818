import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";
import { isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import { StdErr } from "../../../../lib/types/miscTypes";
import { UIText } from "../../../../client/lang";
import { ui } from "../../../../client/ui";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { RfqDetailLspController } from "./controller";
import { Quotation, QuotationService, Requirement, Rfq } from "../../lib/types/dataTypes";
import { RfqDetailHeader } from "../../components/RfqDetailHeader";
import "./styles.less";
import QuotationsColumnLsp from "../../components/QuotationsColunmLsp";
import { Form } from "../../../../client/form";
import { topicTypeIds } from "../../../../config/constants";
import RfqForm from "../../components/RfqForm";
import { RfqFormDTO } from "../../lib/types/rfqFormTypes";
import { AccordionController } from "../../../../lib/accordion";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import { Thread, Topic } from "../../../../lib/types/topicTypes";
import { getRfqDescriptionLsp, quotationServicesToLegs } from "../../lib/common";
import QuotationServiceDetail, { QuotationServiceDetailProps } from "../../components/QuotationServiceDetail";
import QuotationSummary from "../../components/QuotationSummary";
import { msgCtrl } from "../../../../client/msg";
import { arrowBack, chatbubblesSharp } from "ionicons/icons";
import QuotationServiceFreightForm from "../../components/QuotationServiceFreightForm";
import { FreightServiceLeg } from "../../lib/types/propTypes";

interface RfqDetailLspProps extends RouteComponentProps<GroupSubRouteParams<{
  id: string;
}>> {}

@observer
class RfqDetailLsp extends React.Component<RfqDetailLspProps> {
  url: string;
  controller: RfqDetailLspController = {} as RfqDetailLspController;
  accordionController: AccordionController;

  freightForm: QuotationServiceFreightForm;
  freightFormRef = ref => this.freightForm = ref;

  @observable loading: boolean = false;
  @observable showSegment: "rfq" | "quotations" = "rfq";

  @observable modalMode: "submission" | "qs";
  @observable modalLoading: boolean = false;
  @observable isModalOpen: boolean = false;
  @observable isModalFormLocked: boolean = true;
  @observable isModalItemTemp: boolean = false;
  @observable modalItemId: number;
  @observable modalQuotationId: number;
  @observable modalHidden: boolean = false;
  modalItemForm: Form = {} as Form;
  modalDismissCallback;

  @observable isAddingQuotation: boolean = false;
  @observable modalNewQuotationId: number;
  @computed get modalNewQuotation(): Quotation {
    return this.controller.quotations.find(q => q.id === this.modalNewQuotationId) || {} as Quotation;
  };

  @computed get id () {
    return this.controller.id;
  };
  @computed get rfq(): Rfq {
    return this.controller.rfq;
  };
  @computed get rfqFormDTO(): RfqFormDTO {
    return this.controller.rfqFormDTO;
  };
  @computed get threads(): Thread[] {
    return this.controller.threads;
  };
  @computed get title(): string {
    return getRfqDescriptionLsp(this.rfq);
  };
  @computed get modalItem(): QuotationServiceDetailProps["modalItem"] {
    const quotationService = this.controller.quotationServices.find(qs => qs.id === this.modalItemId);
    const requirement = this.controller.requirements.find(r => r.id === this.modalItemId);
    const entity = quotationService || requirement || {} as any;
    return {
      ...entity,
      type: quotationService ? "service" : "requirement",
      title: this.isModalItemTemp ? `${UIText.generalAdd} ${UIText.service}` : entity.description
    };
  };
  @computed get modalQuotation(): Quotation {
    return this.controller.quotations.find(q => q.id === this.modalQuotationId) || {} as Quotation;
  };
  @computed get modalFreightServiceLegs(): FreightServiceLeg[] {
    const legs = this.controller.findFreightServiceLegs(this.modalItemId);
    if (isEmpty(legs)) return [];
    return quotationServicesToLegs(legs);
  };
  get modalItemIsFreight(): boolean {
     return !isEmpty(this.modalItemForm) && this.modalItemForm.get("serviceType") === "Freight";
  };

  constructor (props) {
    super(props);
    this.controller = new RfqDetailLspController();
    this.accordionController = new AccordionController("rfqDetailLsp", true);
  }

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    const { id } = this.props.match.params;
    if (!id || isNaN(Number(id))) return this.backToRfqs(null);
    msgCtrl.addDismissChatHandler(this.onDismissChatModal);
    return this.onLoad(Number(id));
  };

  ionViewWillLeave = () => {
    this.controller.dispose();
    msgCtrl.removeDismissChatHandler(this.onDismissChatModal);
  };

  showError = (err: StdErr, kickOut?: boolean, actionName?: string) =>
    ui.showError({
      err,
      actionName,
      buttons: [{
        text: UIText.generalConfirm,
        handler: () => kickOut && this.backToRfqs(null)
      }]
    });

  onLoad = (id: Rfq["id"]) => {
    this.loading = true;
    return this.controller.setId(id)
    .catch((err: StdErr) => this.showError(err, true))
    .finally(() => this.loading = false);
  };

  onRefresh = (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.loadAllData(this.id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  onRefreshQSModal = (event: any) => {
    preventDefaultStopProp(event);
    this.modalLoading = true;
    this.freightForm && this.freightForm.reset();
    return this.controller.loadAllData(this.id)
    .then(() => this.handleEntityClick(null, this.modalItemId))
    .catch(this.showError)
    .finally(() => this.modalLoading = false);
  };

  onRefreshSummaryModal = (event: any) => {
    preventDefaultStopProp(event);
    this.modalLoading = true;
    return this.controller.loadAllData(this.id)
    // TODO: Refresh just quotation
    .catch(this.showError)
    .finally(() => this.modalLoading = false);
  };

  onDismissModal = event => {
    this.isModalOpen = false;
    if (this.modalDismissCallback) this.modalDismissCallback(event);
  };

  onDismissNewQuotation = event => {
    this.isAddingQuotation = false;
    this.controller.onRemoveNewQuotation(this.modalNewQuotationId);
    return this.modalNewQuotationId = undefined;
  };

  onDismissChatModal = () => this.modalHidden = false;

  onSwitchSegment = (event: any) => {
    preventDefaultStopProp(event);
    const { value } = event.detail;
    return this.showSegment = value;
  };

  backToRfqs = (event?: any) => {
    preventDefaultStopProp(event);
    const urlFrags = this.url.split("/");
    urlFrags.pop();
    this.props.history.push(urlFrags.join("/"));
  };

  handleModalMenu = (event: any) => {
    preventDefaultStopProp(event);
    return ui.popoverMenu({
      event,
      menuItems: [
        !this.isModalItemTemp && {
          text: UIText.generalRefresh,
          handler: this.onRefreshQSModal
        },
        (this.modalItem.type === "service" || this.isModalItemTemp) &&
        !this.controller.isQuotationServiceSubmitted(this.modalItemId) && {
          text: this.isModalFormLocked ? UIText.generalEdit : UIText.generalSave,
          handler: this.handleModalFormEditOrSave
        },
        !this.controller.isQuotationServiceSubmitted(this.modalItemId) &&
        this.modalItem.type === "service" && {
          text: UIText.generalRemove,
          handler: event => (
            this.handleRemoveQuotationService(event, this.modalItemId, this.onDismissModal)
          )
        },
      ].filter(Boolean)
    });
  };

  handleModalFormEditOrSave = async (event: any) => {
    preventDefaultStopProp(event);
    if (!this.isModalFormLocked) {
      if (this.modalItemIsFreight) return this.handleFreightServiceSubmit(event);
      if (this.isModalItemTemp || this.modalItemForm.isDirty) {
        return this.handleModalFormSave();
      }
    }
    this.isModalFormLocked = !this.isModalFormLocked;
  };

  handleModalFormSave = async () => {
    if (!await this.modalItemForm.validate()) return;
    const save = () => {
      this.modalLoading = true;
      return (this.isModalItemTemp
        ? this.controller.onTempTopicSave
        : this.controller.onQuotationServiceFormSave)
      (this.modalItemId, this.modalItemForm.data)
      .then(this.isModalItemTemp ? this.onDismissModal : this.onRefreshQSModal)
      .catch(this.showError)
      .finally(() => this.modalLoading = false);
    };
    // return ui.alert({
    //   header: UIText.service,
    //   message: `Save ${UIText.service.toLowerCase()} as template ${UIText.comingSoon}`,
    //   buttons: [{
    //     text: UIText.generalConfirm,
    //     handler: save
    //   }]
    // });
    return save();
  };

  handleFreightServiceSubmit = (event: any) => {
    preventDefaultStopProp(event);
    if (!this.freightForm.validate()) return;
    const save = async () => {
      this.modalLoading = true;
      return this.controller.onFreightServiceSubmit(this.modalItemId, this.freightForm, !this.isModalItemTemp)
      // .then(this.isModalItemTemp ? this.onDismissModal : this.onRefreshQSModal)
      .then(this.onDismissModal)
      .catch(this.showError)
      .finally(() => this.modalLoading = false);
    };
    // return ui.alert({
    //   header: UIText.service,
    //   message: `Save ${UIText.service.toLowerCase()} as template ${UIText.comingSoon}`,
    //   buttons: [{
    //     text: UIText.generalConfirm,
    //     handler: save
    //   }]
    // });
    return save();
  };

  handleEntityClick = (event: any, id: number, tempTopic?: Partial<Topic>) => {
    preventDefaultStopProp(event);
    if (!id) return;
    this.modalMode = "qs";
    this.modalItemId = id;
    this.isModalFormLocked = true;
    const topic = tempTopic || this.controller.rfqItemTopics.find(
      t => t.id === this.modalItemId
    ) || {} as Topic;
    const typeId: number = topic.typeId;
    this.modalItemForm = new Form(
      topic.typeClassId,
      topic.data,
      { [typeId === topicTypeIds.requirement ? "requirement" : "quotation"]: true }
    );
    this.isModalOpen = true;
  };

  // handleAddQuotationService = (event: any, id: Requirement["id"]) => {
  //   preventDefaultStopProp(event);
  //   const { quotations } = this.controller;
  //   const component = () => (
  //     <IonList className="ion-no-padding">
  //       <IonListHeader color="primary">
  //         {UIText.chooseQuotation}
  //       </IonListHeader>
  //       {quotations
  //       .map(q => {
  //         const add = event => this.handleAddQuotationServiceConfirm(event, id, q.id);
  //         return <IonItem
  //           key={q.id}
  //           button
  //           lines="full"
  //           onClick={add}
  //         >
  //           {q.description}
  //         </IonItem>;
  //       })}
  //     </IonList>
  //   );
  //   return ui.popover({
  //     event,
  //     component
  //   });
  // };

  handleAddQuotationService = (event: any, id: Quotation["id"], requirementId: Requirement["id"]) => {
    preventDefaultStopProp(event);
    let tempTopic;
    try {
      tempTopic = this.controller.onNewTempQuotationService(id, requirementId);
    }
    catch (e) {
      return this.showError(e);
    }
    this.handleEntityClick(null, tempTopic.id, tempTopic);
    this.isModalFormLocked = false;
    this.isModalItemTemp = true;
    this.modalDismissCallback = () => {
      this.controller.onTempTopicDiscard(tempTopic.id);
      this.isModalItemTemp = false;
    };
  };

  handleRemoveQuotationService = (event: any, id: QuotationService["id"], callback?) => {
    preventDefaultStopProp(event);
    callback = callback || (() => {});
    const quotationService = this.controller.quotationServices.find(qs => qs.id === id);
    const remove = event => this.handleRemoveQuotationServiceConfirm(event, id).then(callback);
    return ui.reconfirm({
      header: `${UIText.generalDelete} ${UIText.service.toLowerCase()}`,
      name: quotationService.description,
      verb: UIText.generalDelete.toLowerCase(),
      handler: remove,
    });
  };

  handleRemoveQuotationServiceConfirm = (event: any, id: QuotationService["id"]) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onRemoveQuotationService(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  // handleAddQuotationServiceConfirm = (event: any, id: Requirement["id"], quotationId: Quotation["id"]) => {
  //   preventDefaultStopProp(event);
  //   ui.dismissPopover(event);
  //   this.loading = true;
  //   return this.controller.onAddQuotationService(id, quotationId)
  //   .catch(this.showError)
  //   .finally(() => this.loading = false);
  // };

  handleRemoveQuotation = (event: any, id: Quotation["id"], callback?) => {
    preventDefaultStopProp(event);
    callback = callback || (() => {});
    const quotation = this.controller.quotations.find(q => q.id === id);
    if (!quotation) return;
    if (quotation.new) return this.controller.onRemoveNewQuotation(id);
    const remove = event => this.handleRemoveQuotationConfirm(event, id).then(callback);
    return ui.reconfirm({
      header: `${UIText.generalDelete} ${UIText.quotation.toLowerCase()}`,
      name: quotation.description,
      verb: UIText.generalDelete.toLowerCase(),
      handler: remove,
    });
  };

  handleRemoveQuotationConfirm = (event: any, id: Quotation["id"]) => {
    this.loading = true;
    return this.controller.onRemoveQuotation(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleNewQuotation = (event: any) => {
    preventDefaultStopProp(event);
    return this.controller.onNewQuotation()
    .catch(this.showError);
  };

  handleNewQuotationWithModal = (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.handleNewQuotation(null)
    .then(quotation => this.modalNewQuotationId = quotation.id)
    .then(() => this.isAddingQuotation = true)
    .finally(() => this.loading = false);
  };

  handleNewQuotationNameChange = (event: any, id?: Quotation["id"]) => {
    preventDefaultStopProp(event);
    const { value } = event.detail;
    return this.controller.onNewQuotationNameChange(id, value)
    .catch(this.showError);
  };

  handleNewQuotationModalSave = (event: any) => {
    preventDefaultStopProp(event);
    return this.handleNewQuotationSave(null, this.modalNewQuotationId)
    .then(this.onDismissNewQuotation);
  };

  handleNewQuotationSave = (event: any, id?: Quotation["id"]) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onNewQuotationSave(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleQuotationDataChange = (event: any, id?: Quotation["id"]) => {
    preventDefaultStopProp(event);
    const { name, value } = event.target;
    return this.controller.onQuotationDataChange(id, name, value);
  };

  handleQuotationDataSave = (event: any, id: Quotation["id"]) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onQuotationDataSave(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleQuotationSubmit = (event: any, id: Quotation["id"]) => {
    preventDefaultStopProp(event);
    this.modalQuotationId = id;
    const name = this.modalQuotation.description || UIText.quotation.toLowerCase();
    return ui.reconfirm({
      header: UIText.submitQuotation,
      verb: UIText.generalSubmit.toLowerCase(),
      name,
      handler: this.handleQuotationSubmitConfirm
    });
  };

  handleQuotationSubmitConfirm = (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onQuotationSubmit(this.modalQuotationId)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleChatClick = async (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.getShipperChatThread()
    .then((thread: Thread) => {
      if (!thread) return ui.alert({
        header: UIText.chatWithShipper,
        message: UIText.chatWithUnavailable(UIText.groupTypes.Shipper),
        buttons: [UIText.generalConfirm]
      });
      this.modalHidden = true;
      return msgCtrl.openChat(thread.id, this.controller.group.id);
    })
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleChatMenu = (event: any) => {
    preventDefaultStopProp(event);
    return ui.popoverMenu({
      event,
      menuItems: [{
        text: UIText.chatWithShipper,
        icon: chatbubblesSharp,
        color: "primary",
        fill: "solid",
        handler: this.handleChatClick
      }]
    })
  };

  render () {
    const {
      quotations,
      quotationServices,
      requirements,
      isQuotationServiceSubmitted
    } = this.controller;

    const ContentChildren = <>
      {/*<RequirementsColumn*/}
      {/*  requirements={requirements}*/}
      {/*  onRequirementCardClick={this.handleEntityClick}*/}
      {/*  // onAddQuotationServiceClick={this.handleAddQuotationService}*/}
      {/*/>*/}
      <RfqForm
        readonly
        className="rfqFormLSP"
        onRefresh={this.onRefresh}
        data={this.rfqFormDTO}
      />
      <QuotationsColumnLsp
        readonly={!!this.rfq.isAwarded || !!this.rfq.isClosed}
        requirements={requirements}
        quotationServices={quotationServices}
        quotations={quotations}
        isAccordionExpanded={this.accordionController.isAccordionExpanded}
        onQuotationServiceCardClick={this.handleEntityClick}
        onRequirementCardClick={this.handleAddQuotationService}
        onRemoveFromQuotation={this.handleRemoveQuotationService}
        onNewQuotation={this.handleNewQuotationWithModal}
        onNewQuotationNameChange={this.handleNewQuotationNameChange}
        onNewQuotationSave={this.handleNewQuotationSave}
        onRemoveQuotation={this.handleRemoveQuotation}
        onQuotationSubmit={this.handleQuotationSubmit}
        onQuotationDataSave={this.handleQuotationDataSave}
        onQuotationDataChange={this.handleQuotationDataChange}
        onAccordionFold={this.accordionController.onAccordionFold}
      />
    </>;

    const hasAwarded = quotationServices.some(qs => qs.isAwarded);

    return (
      <IonPage className="headerOffset">
        <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
          <IonLoading translucent isOpen={this.loading}/>

          <IonCard className="flex max column container rfqDetailLsp">
            <RfqDetailHeader
              className={
                hasAwarded ? "awarded"
                  : (this.rfq.isClosed || this.rfq.isAwarded)
                  ? "closed"
                  : ""
              }
              title={this.title}
              titleRight={ui.isMobile ? (
                <IonButtons>
                  <IonButton onClick={this.handleChatMenu} shape="round">
                    <IonIcon icon={chatbubblesSharp} color="primary" />
                  </IonButton>
                </IonButtons>
              ) : (
                <IonButton onClick={this.handleChatClick}>
                  {this.loading
                    ? <IonSpinner />
                    : <><IonIcon icon={chatbubblesSharp} slot="start" />{UIText.chatWithShipper}</>}
                </IonButton>
              )}
              loading={this.loading}
              freightMode={this.rfq.mode}
              freightCount={this.rfq.freightCount}
              POL={this.rfq.POL}
              POD={this.rfq.POD}
              onRefresh={this.onRefresh}
              backHandler={this.backToRfqs}
            />

            <IonCardContent className="flex max column ion-no-padding">
              <IonContent className="max fullBackground rfqDetailColumnsContainer">
                {ui.isMobile ? (
                  <div className={`flex column rfqDetailLspSplit rfqDetailColumns ${this.showSegment}`}>
                    <IonSegment onIonChange={this.onSwitchSegment} value={this.showSegment}>
                      <IonSegmentButton value="rfq">
                        <IonLabel className="textBold textNoTransform">{UIText.rfqForm.headingReadonly}</IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="quotations">
                        <IonLabel className="textBold textNoTransform">{UIText.quotations}</IonLabel>
                      </IonSegmentButton>
                    </IonSegment>
                    {ContentChildren}
                  </div>
                ) : (
                  <div className="flex ion-align-items-start ion-no-wrap rfqDetailColumns">
                    {ContentChildren}
                  </div>
                )}
              </IonContent>
            </IonCardContent>
          </IonCard>

          {/*Add quotation modal*/}
          <IonModal
            key="rfqDetailLspAddQuotationModal"
            cssClass={`modal ${this.modalHidden ? "ion-hide" : ""}`}
            isOpen={this.isAddingQuotation}
            onDidDismiss={this.onDismissNewQuotation}
          >
            <IonHeader translucent>
              <IonToolbar>
                <IonTitle>{UIText.createNewQuotation}</IonTitle>
                <IonButtons slot="start">
                  <IonButton onClick={this.onDismissNewQuotation}>
                    <IonIcon slot="icon-only" icon={arrowBack} />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
              <IonLoading translucent isOpen={this.modalLoading}/>
              <div className="flex max ion-align-items-center ion-justify-content-center ion-text-center">
                <IonCard style={{ flex: 1 }}>
                  <IonCardContent className="max formView fancyScroll">
                    <IonList className="ion-no-padding">
                      <IonItem lines="full">
                        <IonLabel position="floating">
                          {UIText.createNewQuotationField}
                        </IonLabel>
                        <IonInput
                          value={this.modalNewQuotation.description}
                          onIonChange={e => this.handleNewQuotationNameChange(e, this.modalNewQuotationId)}
                        />
                      </IonItem>
                    </IonList>
                    <div className="flex ion-align-items-center ion-justify-content-center ion-margin-top">
                      <IonButton className="ion-margin-end" onClick={this.onDismissNewQuotation} color="danger">
                        {UIText.generalCancel}
                      </IonButton>
                      <IonButton onClick={this.handleNewQuotationModalSave} color="primary">
                        {UIText.generalCreate}
                      </IonButton>
                    </div>
                  </IonCardContent>
                </IonCard>
              </div>
            </IonContent>
          </IonModal>

          {/* Item detail modal */}
          <IonModal
            key="rfqDetailLspModal"
            cssClass={`modal ${this.modalItemIsFreight ? "exLarge" : "large"} ${this.modalHidden ? "ion-hide" : ""}`}
            isOpen={this.isModalOpen}
            onDidDismiss={this.onDismissModal}
          >
            {this.modalMode === "submission"
              ? <QuotationSummary
                quotation={this.modalQuotation}
                quotationServices={quotationServices}
                onQuotationSubmit={this.handleQuotationSubmitConfirm}
                loading={this.modalLoading}
                onRefresh={this.onRefreshSummaryModal}
                onDismissModal={this.onDismissModal}
              />
              : this.modalItemIsFreight
                ? <QuotationServiceFreightForm
                  ref={this.freightFormRef}
                  rfq={this.rfq}
                  modalItem={this.modalItem}
                  legs={this.modalFreightServiceLegs}
                  loading={this.modalLoading}
                  isFormLocked={this.isModalFormLocked || isQuotationServiceSubmitted(this.modalItemId)}
                  onMenu={this.handleModalMenu}
                  onRefresh={this.onRefreshQSModal}
                  onSubmit={this.handleFreightServiceSubmit}
                  onDismissModal={this.onDismissModal}
                />
                : <QuotationServiceDetail
                  modalItem={this.modalItem}
                  subHeader={isQuotationServiceSubmitted(this.modalItemId) && (
                    <IonText className="textBold textLight font-m">
                      {UIText.submittedService}
                    </IonText>
                  )}
                  isFormLocked={this.isModalFormLocked || isQuotationServiceSubmitted(this.modalItemId)}
                  form={this.modalItemForm}
                  loading={this.modalLoading}
                  onFormEditOrSave={this.handleModalFormEditOrSave}
                  onMenu={this.handleModalMenu}
                  onRefresh={this.onRefreshQSModal}
                  onDismissModal={this.onDismissModal}
                />}
          </IonModal>

        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(RfqDetailLsp);