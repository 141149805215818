import { Controller } from "./controller";
import { SortDirection } from "@material-ui/core";
import { computed, IObservableArray } from "mobx";

export interface ListStore<T> {
  searchValue: string;
  sortBy: keyof T;
  sortDir: SortDirection;
  selectedFilters: string[];
}

export interface ListControllerOptions<T> {
  storeName: string;
  initialSortBy: keyof T;
}

export class ListController<T> extends Controller<ListStore<T>> {
  initialSortBy: keyof T;
  
  constructor(options: ListControllerOptions<T>) {
    super(options.storeName);
    this.initialSortBy = options.initialSortBy;
  }

  @computed get searchValue(): string {
    this.storage.initProperty("searchValue", "");
    return this.store.searchValue;
  };
  @computed get sortBy(): keyof T {
    this.storage.initProperty("sortBy", this.initialSortBy);
    return this.store.sortBy;
  };
  @computed get sortDir(): SortDirection {
    this.storage.initProperty("sortDir", "asc");
    return this.store.sortDir;
  };
  @computed get selectedFilters(): string[] {
    this.storage.initProperty("selectedFilters", []);
    return this.store.selectedFilters;
  };

  onSearchValueChange = value => this.store.searchValue = value;

  onSortByChange = sortBy => this.store.sortBy = sortBy;

  onSortDirChange = () => this.store.sortDir = this.sortDir === "desc" ? "asc" : "desc";

  onFilterSelect = (event: any) => {
    const { name } = event.target;
    if (this.selectedFilters.includes(name)) {
      return this.store.selectedFilters = this.selectedFilters.filter(f => f !== name);
    }
    return this.selectedFilters.push(name);
  };

  onFilterClear = () => (this.store.selectedFilters as IObservableArray).clear();

  setFilters = (filters: string[]) => this.store.selectedFilters = filters;
}