import { observer } from "mobx-react";
import { IonButton, IonCard, IonItem, IonList, IonListHeader, IonText } from "@ionic/react";
import React, { useState } from "react";
import { Storage } from "@capacitor/core";
import { ui } from "../../../client/ui";
import { UIText } from "../../../client/lang";

export const NukeStorage: React.FC = observer(props => {
  const [ready, setReady] = useState(false);

  const nukeStorage = () =>
    Storage.clear()
    .then(() => ui.toast({ header: UIText.generalDone }))
    .then(() => setReady(false));

  const dumpStorage = () => Storage.keys()
  .then(result => Promise.all(result.keys.map(async key => ({
    key,
    ...await Storage.get({ key })
  }))))
  .then(results => ui.copyStringToClipboard(JSON.stringify(results)));

  return <IonCard className="flex column container cardedContainer" style={{ margin: "16px auto" }}>
    <IonList className="ion-no-padding">
      <IonListHeader className="textPrimary textBold">Ionic Capacitor Local Storage</IonListHeader>
      <IonItem className="ion-margin-bottom" lines="none">
        <IonButton
          className="ion-margin-end"
          color="primary"
          onClick={dumpStorage}
        >
          <IonText>Dump all data</IonText>
        </IonButton>
        <IonButton
          fill={ready ? undefined : "clear"}
          color="danger"
          onClick={ready ? nukeStorage : () => setReady(true)}
        >
          <IonText>Nuke Storage</IonText>
        </IonButton>
      </IonItem>
    </IonList>
  </IonCard>;
});