import * as isbot from 'isbot';

export const env =
  window.location.hostname.match(/(^3win\.app)|(www\.3win\.app)|front-end-bscjtjhnpa/g)
    ? "prod"
    // : window.location.hostname.match(/^testapp|test/g)
    // ? "test"
    : window.location.hostname.match(/^devapp|dev|testapp|test|front-end-4a4jgjutla/g)
    ? "dev"
    : window.location.port.match(/debugapp/g)
    ? "debug"
    : "local";


export const isCrawler = isbot(navigator.userAgent);