import { observer } from "mobx-react";
import React from "react";
import RateCard, { RateCardProps } from "../RateCard";
import "./styles.less";
import { isEmpty } from "../../../../utils/helpers";
import Fade from "react-reveal/Fade";
import { ui } from "../../../../client/ui";
import { UIText } from "../../../../client/lang";

export interface ComplexRateLegsColumnProps extends Partial<RateCardProps> {}

@observer
class ComplexRateLegsColumn extends React.Component<ComplexRateLegsColumnProps> {

  render() {
    const {
      className,
      plan,
      rate,
      modes,
      uocs,
      findDisplayUnits,
      checkModeChangeLock,
      doesRateHaveBreakdownType,
      handleRemove,
      handleDetailFieldChange,
      handleDescriptionChange,
      handleAddRateUnit,
      handleRemoveRateUnit,
      handleRateUnitFieldChange,
      handleChargeItemFieldChange,
      handlePortModalOpen,
      handleChargeModalOpen,
      handleCarrierModalOpen
    } = this.props;

    const description = `${UIText.rateManagement.leg} ${(rate.shippingPlanLeg || {}).ordinal + 1}`;

    const Card = <RateCard
      className="ion-no-margin"
      plan={plan}
      rate={rate}
      modes={modes}
      uocs={uocs}
      description={description}
      findDisplayUnits={findDisplayUnits}
      checkModeChangeLock={checkModeChangeLock}
      doesRateHaveBreakdownType={doesRateHaveBreakdownType}
      handleRemove={handleRemove}
      handleDetailFieldChange={handleDetailFieldChange}
      handleDescriptionChange={handleDescriptionChange}
      handleAddRateUnit={handleAddRateUnit}
      handleRemoveRateUnit={handleRemoveRateUnit}
      handleRateUnitFieldChange={handleRateUnitFieldChange}
      handleChargeItemFieldChange={handleChargeItemFieldChange}
      handlePortModalOpen={handlePortModalOpen}
      handleChargeModalOpen={handleChargeModalOpen}
      handleCarrierModalOpen={handleCarrierModalOpen}
    />;

    return <div className={`max fancyScroll complexRateLegsColumn ${className || ""}`}>
      {!isEmpty(rate) && (
        ui.isMobileIntermediate ? Card : <Fade key={rate.id} right duration={ui.defaultDuration} delay={50}>
          {Card}
        </Fade>
      )}
    </div>
  }
}

export default ComplexRateLegsColumn;