import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonSpinner,
  withIonLifeCycle,
} from "@ionic/react";
import { ProfileController } from "./controller";
import { computed, observable } from "mobx";
import { ui } from "../../client/ui";
import Refresher from "../../components/Refresher";
import { StdErr } from "../../lib/types/miscTypes";
import { UIText } from "../../client/lang";
import { SetupConfig } from "../../lib/types/setupTypes";
import { GroupSubRouteParams, ProfilePageModes } from "../../lib/types/propTypes";
import { Form } from "../../client/form";
import FormView from "../../components/Form";
import CardedContainerHeader from "../../components/CardedContainerHeader";
import "./styles.less";
import { contextReject, getDisplayNameEng, preventDefaultStopProp } from "../../utils/helpers";
import { Fade } from "react-reveal";
import { Topic } from "../../lib/types/topicTypes";
import { EmailChangeRequest } from "../../lib/types/dataTypes";
import { stateCtrl } from "../../client/state";

export interface ProfileProps extends RouteComponentProps<GroupSubRouteParams<{
  id: string | "new";
  setupId?: string;
}>> {}

@observer
class ProfilePage extends React.Component<ProfileProps> {
  url: string;
  controller: ProfileController = {} as ProfileController;
  title: string = UIText.profile;

  @observable loading: boolean = false;
  @observable passwordResetting: boolean = false;
  @observable emailChangeLoading: boolean = false;

  @computed get username(): string {
    return this.controller.user.userName;
  };
  @computed get defaultProfileUrl(): string {
    const { defaultGroup, defaultProfile } = this.controller;
    return `/Group/${defaultGroup.id}/Profile/${defaultProfile.id}`;
  };
  @computed get mode(): ProfilePageModes {
    return this.controller.mode;
  };
  @computed get topic(): Topic {
    return this.controller.topic;
  }
  @computed get form(): Form {
    return this.controller.form || {} as Form;
  };
  @computed get setupConfig(): SetupConfig {
    return this.controller.setupConfig || {} as SetupConfig;
  };
  @computed get isDirty(): boolean {
    return this.controller.isDirty;
  };

  @computed get emailChangeRequest(): EmailChangeRequest {
    return this.controller.emailChangeRequest;
  };
  @computed get emailChangeRevertDeadline(): string {
    const { revertEmailChangeDeadline } = this.controller;
    return revertEmailChangeDeadline && new Date(revertEmailChangeDeadline).toLocaleString();
  };
  // @computed get groupTypeName(): string {
  //   // return this.controller.groupType.groupTypeName;
  //   return "";
  // }

  constructor(props) {
    super(props);
    this.url = props.match.url;
    this.controller = new ProfileController();
  }

  ionViewWillEnter = () => {
    this.controller.reset();
    this.url = this.props.match.url;
    if (this.url.match(/DefaultProfile/g)) return this.redirectToDefaultProfile();
    const { id, setupId } = this.props.match.params;
    const entityId = id === "new" ? 0 : Number(id);
    const setupConfigId = id === "new" && Number(setupId) as SetupConfig["id"];
    this.loading = true;
    const isSetup = id === "new" && !isNaN(setupConfigId);
    const isTopicMode = this.url.match(/\/Topic\//ig);
    return (isSetup
      ? this.controller.initSetup(setupConfigId)
      : isTopicMode
      ? this.controller.loadTopic(entityId)
      : this.controller.loadProfile(entityId)
        .then(this.checkPendingEmailChange))
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  ionViewWillLeave = () => this.controller.reset();

  ionViewDidLeave = () => {
    stateCtrl.profilePageExplicitEditable = undefined;
  };

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  onRefresh =  (event?: any) => {
    this.loading = true;
    return this.controller.onRefresh()
    .then(this.checkPendingEmailChange)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  redirectToDefaultProfile = () => {
    this.controller.reset();
    this.props.history.replace(this.defaultProfileUrl);
    return this.controller.loadProfile(this.controller.defaultProfile.id);
  };

  checkPendingEmailChange = () => {
    if (this.mode !== "profile") return;
    if (!this.controller.hasPendingChange) return;
    const pendingChangeString = UIText.changeEmailPending(this.emailChangeRequest.newEmail);
    setTimeout(() => {
      this.form.setField("email", {
        multiLine: true,
        displayFormula: `\`\${value}\n(${pendingChangeString})\``
      });
    });
  };

  handleSubmit = async (event: any, bypassEmailCheck?: boolean) => {
    preventDefaultStopProp(event);
    if (this.loading || !this.isDirty) return;
    if (!await this.form.validate().catch(this.showError)) return;
    if (this.mode === "profile" && !bypassEmailCheck) {
      const dirtyFields = this.form.getDirty();
      if (dirtyFields.some(f => f.name === "email")) {
        const newEmail = this.form.get("email");
        return this.handleEmailChange(
          newEmail, dirtyFields.length > 1 && (() => this.handleSubmit(null, true))
        )
        .catch(this.showError);
      }
    }
    this.loading = true;
    return this.controller.onSubmit()
    .then(() =>
      this.mode === "setup" &&
      this.setupConfig.postSetupUrl &&
      this.props.history.replace(this.setupConfig.postSetupUrl)
    )
    .then(this.mode !== "setup" && this.onRefresh)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleResetPassword = (event: any) => {
    preventDefaultStopProp(event);
    this.passwordResetting = true;
    return this.controller.onPasswordResetRequest()
    .then(() => ui.alert({
      header: UIText.resetPassword,
      message: UIText.resetRequestSentKnownUser,
      buttons: [UIText.generalConfirm]
    }))
    .catch(this.showError)
    .finally(() => this.passwordResetting = false);
  };

  handleEmailChange = (newEmail: string, cb?) => {
    const onSubmit = async data => {
      const { password } = data;
      this.emailChangeLoading = true;
      const correct = await this.controller.verifyPassword(password).catch(contextReject);
      this.emailChangeLoading = false;
      if (!correct) return ui.alert({
        header: UIText.changeEmail,
        message: UIText.changeEmailIncorrectPassword,
        buttons: [{
          text: UIText.generalConfirm,
          handler: () => this.handleEmailChange(this.form.get("email"), cb)
        }]
      });
      this.form.set("email", this.controller.profileData.email);
      return this.handleEmailChangeExec(password, newEmail, cb);
    };
    return ui.alert({
      header: UIText.changeEmail,
      message: UIText.changeEmailPasswordAlert(newEmail),
      inputs: [{
        name: "password",
        type: "password",
        placeholder: UIText.currentPassword
      }],
      enterKeyHandler: onSubmit,
      buttons: [
        {
          role: "cancel",
          text: UIText.generalCancel
        },
        {
          text: UIText.generalConfirm,
          handler: onSubmit
        }
      ]
    })
  };

  handleEmailChangeExec = async (password: string, newEmail: string, cb?) => {
    this.emailChangeLoading = true;
    return this.controller.onChangeEmailRequest(password, newEmail)
    .then(() => ui.alert({
      header: UIText.changeEmail,
      message: UIText.changeEmailRequestSent,
      buttons: [{
        text: UIText.generalConfirm,
        handler: cb ? cb : this.onRefresh
      }]
    }))
    .catch(this.showError)
    .finally(() => this.emailChangeLoading = false);
  };

  handleEmailChangeCancel = (event: any) => {
    preventDefaultStopProp(event);
    return ui.reconfirm({
      header: UIText.changeEmail,
      verb: UIText.generalCancel.toLowerCase(),
      name: UIText.changeEmailPendingRequest.toLowerCase(),
      handler: this.handleEmailChangeCancelConfirm
    });
  };

  handleEmailChangeCancelConfirm = async (event: any) => {
    preventDefaultStopProp(event);
    this.emailChangeLoading = true;
    return this.controller.onChangeEmailCancel()
    .then(() => ui.alert({
      header: UIText.changeEmail,
      message: UIText.changeEmailCancelled,
      buttons: [{
        text: UIText.generalConfirm,
        handler: this.onRefresh
      }]
    }))
    .catch(this.showError)
    .finally(() => this.emailChangeLoading = false);
  };

  handleViewRecentEmailChange = (event: any) => {
    preventDefaultStopProp(event);
    const { emailChangeRequest } = this.controller;
    return ui.alert({
      header: UIText.changeEmailRecentChange,
      message: UIText.changeEmailRecentChangeSummary(
        emailChangeRequest.currentEmail,
        emailChangeRequest.newEmail,
        this.emailChangeRevertDeadline
      ),
      buttons: [
        {
          text: UIText.generalUndo,
          handler: this.handleEmailChangeRevert
        },
        {
          role: "cancel",
          text: UIText.generalConfirm
        }
      ],
    });
  };

  handleEmailChangeRevert = (event: any) => {
    preventDefaultStopProp(event);
    return ui.reconfirm({
      header: UIText.changeEmailRevert,
      verb: UIText.generalUndo,
      name: UIText.changeEmailYourRecentChange,
      handler: this.handleEmailChangeRevertConfirm
    });
  };

  handleEmailChangeRevertConfirm = async (event: any) => {
    preventDefaultStopProp(event);
    this.emailChangeLoading = true;
    return this.controller.onChangeEmailRevert()
    .then(() => ui.alert({
      header: UIText.changeEmailRevert,
      message: UIText.changeEmailRevertRequestSent,
      buttons: [{
        text: UIText.generalConfirm,
        handler: this.onRefresh
      }]
    }))
    .catch(this.showError)
    .finally(() => this.emailChangeLoading = false);
  };

  handleShowUsername = (event: any) => {
    preventDefaultStopProp(event);
    return ui.alert({
      header: UIText.username,
      message: this.username,
      buttons: [UIText.generalConfirm]
    });
  };

  render() {
    const { isForeign, isDefaultProfile, hasPendingChange, canRevertEmailChange } = this.controller;

    const withListHeader = component => isDefaultProfile ? (
      <IonList>
        <IonListHeader className="textBold font-s textPrimary">
          {UIText.profile}
        </IonListHeader>
        {component}
      </IonList>
    ) : component;

    const FormComponent = <Fade
      delay={200}
      duration={ui.defaultDuration}
    >
      <div>
        {withListHeader(
          <FormView
            noList={isDefaultProfile}
            form={this.form}
            validateOnBlur
            disabled={isForeign}
            onSubmit={this.handleSubmit}
          />
        )}
        {isDefaultProfile && (
          <IonList>
            <IonListHeader className="textBold font-s textPrimary">
              {UIText.account}
            </IonListHeader>
            <div className="flex ion-wrap">
              <IonButton fill="clear" color="primary" onClick={this.handleResetPassword}>
                {this.passwordResetting ? <IonSpinner color="primary" /> : UIText.resetPassword}
              </IonButton>
              <IonButton fill="clear" color="primary" onClick={this.handleShowUsername}>
                {UIText.showUsername}
              </IonButton>
              {!!hasPendingChange && (
                <IonButton fill="clear" color="primary" onClick={this.handleEmailChangeCancel}>
                  {this.emailChangeLoading ? <IonSpinner color="primary" /> : UIText.changeEmailCancel}
                </IonButton>
              )}
              {!!canRevertEmailChange && (
                <IonButton fill="clear" color="primary" onClick={this.handleViewRecentEmailChange}>
                  {this.emailChangeLoading ? <IonSpinner color="primary" /> : UIText.changeEmailRecentChange}
                </IonButton>
              )}
            </div>
          </IonList>
        )}
      </div>
    </Fade>;

    return (
      <IonPage className="profile headerOffset">
        <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
          <IonLoading translucent isOpen={this.loading || this.emailChangeLoading} />
          <div className="flex max ion-align-items-center">
            <IonCard className={`flex column profileContainer cardedContainer ${ui.isMobile ? "max" : ""}`}>
              {!this.loading && <>
                {this.mode === "setup" ? (
                  <CardedContainerHeader
                    title={this.setupConfig.header}
                    titleRight={
                      <IonButton onClick={this.handleSubmit} disabled={this.loading}>
                        {UIText.generalSubmit}
                      </IonButton>
                    }
                    backHandler={this.setupConfig.allowBackButton && this.props.history.goBack}
                  >
                    <div className={`${ui.isMobile ? "font-xs" : "font-s"} profileSubHeader`}>
                      {this.setupConfig.subHeader}
                    </div>
                  </CardedContainerHeader>
                ) : (
                  <CardedContainerHeader
                    title={this.mode === "topic" ? this.topic.description : getDisplayNameEng(this.controller.profileData)}
                    titleRight={
                      !isForeign && <IonButton onClick={this.handleSubmit} disabled={this.loading || !this.isDirty}>
                        {UIText.generalSave}
                      </IonButton>
                    }
                    onRefresh={this.onRefresh}
                    backHandler={this.props.history.goBack}
                  >
                    <div className={`${ui.isMobile ? "font-xs" : "font-s"} profileSubHeader`}>
                      {isForeign ? UIText.generalView : UIText.generalEdit}&nbsp;{this.mode === "profile" ? UIText.profile : ""}
                    </div>
                  </CardedContainerHeader>
                )}
                {ui.isMobile ? (
                  <IonContent className="fancyScroll fullBackground ion-padding">
                    <Refresher onRefresh={this.onRefresh} isMobile={ui.isMobile} />
                    <IonCardContent className="ion-no-padding">
                      {FormComponent}
                    </IonCardContent>
                  </IonContent>
                ) : (
                  <IonCardContent className="fancyScroll">
                    {FormComponent}
                  </IonCardContent>
                )}
              </>}
            </IonCard>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default withIonLifeCycle(ProfilePage);