import { ui } from "../../../../client/ui";
import { UIText } from "../../../../client/lang";
import { placesApi } from "../../../../client/places";
import { IonIcon } from "@ionic/react";
import { arrowForwardSharp } from "ionicons/icons";
import React from "react";
import { observer } from "mobx-react";
import { PlacesAutocompleteResult } from "../../../../lib/types/dataTypes";
import { Rfq } from "../../lib/types/dataTypes";
import "./styles.less";

export interface PolPodTrafficTextProps {
  pol: PlacesAutocompleteResult | string;
  pod: PlacesAutocompleteResult | string;
  mode: Rfq["mode"];
}

const PolPodTrafficText: React.FC<PolPodTrafficTextProps> = observer(({ mode, pol, pod }) => <>
  <div className={`flex ion-wrap ion-align-items-center ion-justify-content-start ${ui.isMobile ? "font-xs" : "font-s"}`}>
    {mode.match(/sea|rail/ig) && <span>{UIText.POL}:&nbsp;</span>}
    {mode.match(/air|road/ig) && <span>{UIText.rfqForm.origin}:&nbsp;</span>}
    <span className="ion-text-nowrap textPrimary textBold">{typeof pol === "string" ? pol : placesApi.getFormattedAddress(pol, "city")}</span>
  </div>
  <IonIcon className="polPodTrafficTextIcon" icon={arrowForwardSharp} size="small"/>
  <div className={`flex ion-wrap ion-align-items-center ion-justify-content-end ${ui.isMobile ? "font-xs" : "font-s"}`}>
    {mode.match(/sea|rail/ig) && <span>{UIText.POD}:&nbsp;</span>}
    {mode.match(/air|road/ig) && <span>{UIText.rfqForm.destination}:&nbsp;</span>}
    <span className="ion-text-nowrap textPrimary textBold">{typeof pod === "string" ? pod : placesApi.getFormattedAddress(pod, "city")}</span>
  </div>
</>);

export default PolPodTrafficText;