const enCAExceptions = {
  SERVICE_UNAVAILABLE: "Service not available",
  ACCOUNT_NOT_VERIFIED: "Please verify your email address before attempting to sign in.",
  ACCOUNT_NEED_PASSWORD_RESET: "A password reset is required to sign in.",
  ACCOUNT_TERMS_NOT_AGREED: "",

  VERIFICATION_EXPIRE_INVALID: "Sorry, the verification link is invalid or expired.",
  VERIFICATION_UNKNOWN_IDENTIFIER: "",
  PASSWORD_RESET_EXPIRE_INVALID: "Sorry, the password reset link is invalid or expired.",
  RECEIVED_INVALID_CREDENTIALS: "Received invalid credentials.",
  INVALID_VERIFICATION_TYPE: "",

  PROFILE_SETUP_INVALID_STATE: "",

  POPOVER_CONTAINER_NOT_FOUND: "No popover container present in the app, forgot to include one?",
  INVALID_SETUP_URL: "",

  QUOTATION_SERVICE_NOT_FOUND: "Quotation service does not exist.",
  QUOTATION_NOT_FOUND: "Quotation does not exist.",
  SOLUTION_NOT_FOUND: "Solution does not exist.",
  INVALID_SHIPPER_PROFILE_SUBMISSION: "",
  INVALID_LSP_PROFILE_SUBMISSION: "",
  INVALID_CARRIER_PROFILE_SUBMISSION: "",
  RFQ_LIST_INVALID_GROUP: "",
  CREATE_GROUP_PROFILE_EMPTY_RETURN: "",
  PROFILE_SETUP_INVALID_SUBMIT_HANDLER: "",
  GET_GROUP_ROLES_INVALID_GROUP_ID: "",

  SHIPPING_MODES_NOT_AVAILABLE: "No shipping modes available.",
  SHIPPING_UNIT_NOT_AVAILABLE: (breakdownType, mode) => `No ${breakdownType || "Shipping Unit"} available for Shipping Mode: ${mode}.`,
  ENCOUNTERED_PLAN_WITHOUT_RATE: "Encountered a Shipping Rate table with invalid data (Shipping Plan has no Shipping Rate)."
};

export default enCAExceptions;