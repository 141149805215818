import { Controller } from "../../../../lib/controller";
import { ShippingMode, ShippingPort } from "../../lib/types/rateTypes";
import { api } from "../../../../client/api";
import { endpointConfig } from "../../../../config/api";
import { computed, IObservableArray, observable } from "mobx";
import { getShippingModes } from "../../lib/common";
import { UIText } from "../../../../client/lang";
import { SortableStore } from "../../../../lib/types/miscTypes";
import { client } from "../../../../client/client";
import countries from "iso-3166-1/dist/iso-3166";
import { PlacesAutocompleteResult } from "../../../../lib/types/dataTypes";
import { isEqual } from "../../../../utils/helpers";

export interface ShippingPortListStore extends SortableStore<ShippingPort> {
  modeFilters: number[];
}

export class ShippingPortListController extends Controller<ShippingPortListStore> {
  @observable modes: IObservableArray<ShippingMode> = [] as IObservableArray<ShippingMode>;
  @observable shippingPorts: IObservableArray<ShippingPort> = [] as IObservableArray<ShippingPort>;

  @computed get isSa(): boolean {
    return !!client.user.isSa;
  };
  @computed get modeFilters(): IObservableArray<number> {
    this.storage.initProperty("modeFilters", []);
    return this.store.modeFilters as IObservableArray<number>;
  };

  loadAllData = () => Promise.all([
    this.getModes(),
    this.getShippingPorts(),
  ]);

  getModes = async () => getShippingModes()
  .then(modes => this.modes = modes);

  getShippingPorts = async () => api.GET(endpointConfig.shipping_ports)
  .then(response => this.shippingPorts = (Array.isArray(response.data) && response.data) || []);

  findAlpha2Code = (alpha3: string) => {
    const country = countries.find(c => c.alpha3 === alpha3);
    if (country) return country.alpha2;
  };

  findAlpha3Code = (alpha2: string) => {
    const country = countries.find(c => c.alpha2 === alpha2);
    if (country) return country.alpha3;
  };

  autoMatch3166 = (data: Partial<ShippingPort>, field: keyof ShippingPort, value: string) => {
    if (field === "iso3166A2Code") data["iso3166A3Code"] = this.findAlpha3Code(value);
    if (field === "iso3166A3Code") data["iso3166A2Code"] = this.findAlpha2Code(value);
  };

  autoLatLng = (data: Partial<ShippingPort>, field: keyof ShippingPort, value) => {
    if (field === "placeData") {
      value = (value || {}) as PlacesAutocompleteResult;
      if (value.lat) data.lat = value.lat;
      if (value.lng) data.lng = value.lng;
    }
  };

  onFieldChange = (id: number, _field: keyof ShippingPort, _value) => {
    const port = this.shippingPorts.find(c => c.id === Number(id));
    if (!port) return;
    const { field, value } = this.normalizeFieldChange(port[_field], _field, _value);
    this.autoMatch3166(port, field, value as string);
    this.autoLatLng(port, field, value as PlacesAutocompleteResult);
    if (isEqual(port[field], value)) return;
    (port[field] as ShippingPort[keyof ShippingPort]) = value;
    return true;
  };

  onUpdateField = async (id: number, _field: keyof ShippingPort, _value: ShippingPort[keyof ShippingPort]) => {
    const port = this.shippingPorts.find(c => c.id === Number(id));
    if (!port) return;
    const { field, value } = this.normalizeFieldChange(port[_field], _field, _value);
    const data = { [field]: value };
    this.autoMatch3166(data, field, value as string);
    this.autoLatLng(data, field, value as PlacesAutocompleteResult);
    return api.PATCH({
      endpoint: endpointConfig.shipping_port_by_id(id),
      data
    });
  };

  onAddShippingPort = async (modeId: number, iso3166A2Code: string) => api.POST({
    endpoint: endpointConfig.shipping_ports,
    data: {
      modeId,
      portCode: "CHANGEME",
      iso3166A2Code,
      iso3166A3Code: this.findAlpha3Code(iso3166A2Code)
    } as Partial<ShippingPort>
  })
  .then(this.getShippingPorts);

  onRemoveShippingPort = async (id: number) => {
    const shippingPort = this.shippingPorts.find(c => c.id === id);
    if (!shippingPort) return;
    this.shippingPorts.remove(shippingPort);
    return api.DELETE(endpointConfig.shipping_port_by_id(id))
    .finally(this.getShippingPorts);
  };

  onFilterClear = () => this.modeFilters.clear();

  onFilterSelect = (event: any) => {
    const { name } = event.target;
    if (this.modeFilters.includes(Number(name))) {
      return this.modeFilters.remove(Number(name));
    }
    return this.modeFilters.push(Number(name));
  };

  normalizeFieldChange = (originalValue, field: keyof ShippingPort, value: ShippingPort[keyof ShippingPort]) => {
    if (field === "modeIds") {
      (value as any[]) = Array.isArray(value)
        ? value.map(v => isNaN(Number(v)) ? null : Number(v)).filter(Boolean)
        : [value].filter(Boolean);
    }
    if (field === "lat" || field === "lng" || field === "placeData") {
      value = value || null;
    }
    if (field === "portName") {
      value = {
        ...originalValue,
        [UIText.preference]: value
      };
    }
    return { field, value };
  }
}
