import React, { MouseEvent } from "react";
import { observer } from "mobx-react";
import { UIText } from "../../../../client/lang";
import "./styles.less";
import { FreightService, Quotation, QuotationService } from "../../lib/types/dataTypes";
import Accordion from "../../../../components/Accordion";
import BaseColumn from "../BaseColumn";
import { getPriceString, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import { toJS } from "mobx";
import QuotationServiceCard from "../QuotationServiceCard";
import { getIonStyleVal } from "../../../../config/styles/style-utils";
import { IonButton, IonButtons, IonIcon, IonText } from "@ionic/react";
import { chatbubblesSharp, filterOutline, starSharp } from "ionicons/icons";
import { RfqDetailColumnProps } from "../../lib/types/propTypes";
import { ui } from "../../../../client/ui";
import { getQuotationServicesPrice, sortQuotationServicesByDate } from "../../lib/common";

export interface AllInColumnProps extends RfqDetailColumnProps {
  quotations: Quotation[];
  quotationServices: QuotationService[];
  isQuotationInSolution: (id: Quotation["id"]) => boolean;
  onQuotationClick?: (event: MouseEvent, id: Quotation["id"]) => void;
  onStarClick?: (event: MouseEvent, id: Quotation["id"]) => void;
}

interface QuotationGroup extends Quotation {
  providerName: string;
  totalPrice: number;
  quotationServices: QuotationService[];
}

@observer
class AllInColumn extends React.Component<AllInColumnProps> {
  getExpanded = (id: Quotation["id"]) => {
    const { isAccordionExpanded } = this.props;
    return isAccordionExpanded(id);
  };

  handleAccordionClick = (id: Quotation["id"]) => {
    const { onAccordionFold } = this.props;
    const isExpanded = this.getExpanded(id);
    if (onAccordionFold) return onAccordionFold(id, !isExpanded);
  };

  handleFilterSortClick = (event: MouseEvent) => {
    preventDefaultStopProp(event);
    return ui.comingSoon("Filter & Sort");
  };

  renderQuotations = (quotation: QuotationGroup) => {
    const {
      isQuotationInSolution,
      onStarClick,
      // onQuotationClick,
      onQuotationServiceCardClick
    } =  this.props;
    const handleAccordionClick = event => {
      preventDefaultStopProp(event);
      return this.handleAccordionClick(quotation.id);
    };
    // const handleQuotationClick = event => onQuotationClick(event, quotation.id);
    const handleCreateSolutionClick = event => onStarClick(event, quotation.id);
    const handleChatClick = event => this.props.onChatClick(event, quotation.id);
    const { providerName, totalPrice } = quotation;
    let { quotationServices } = quotation;
    quotationServices = quotationServices.filter(qs =>
      qs.serviceType !== "Freight" ||
      !quotationServices.some(q => (q as FreightService).segments && (q as FreightService).segments.includes(qs.id))
    );
    return <Accordion
      className="ion-margin"
      key={quotation.id || 0}
      color="light"
      rightButton={
        <div className="flex ion-align-items-center">
          {/*<IonButton*/}
          {/*  className="ion-no-margin"*/}
          {/*  fill="clear"*/}
          {/*  // size={ui.isMobile ? "small" : null}*/}
          {/*  onClick={handleQuotationClick}*/}
          {/*>*/}
          {/*  <IonIcon color="primary" icon={receiptOutline} slot="icon-only" />*/}
          {/*</IonButton>*/}
          <IonButton
            className="ion-no-margin"
            color="primary"
            onClick={handleChatClick}
          >
            <IonIcon icon={chatbubblesSharp} slot="icon-only" />
          </IonButton>
        </div>
      }
      backgroundColor={getIonStyleVal("--ion-color-light")}
      title={<span className="textPrimary textBold">
        {`${providerName} • ${getPriceString(totalPrice, null, true)}`}
      </span>}
      expanded={this.getExpanded(quotation.id)}
      isMobile={ui.isMobile}
      onClick={handleAccordionClick}
    >
      <div className="ion-padding">
        <IonButtons className="ion-justify-content-between baseColumnToolbar">
          <IonText className="font-s" color="primary">{quotation.description}</IonText>
          {!this.props.readonly && <IonButton onClick={handleCreateSolutionClick}>
            <IonIcon color={isQuotationInSolution(quotation.id) ? "warning" : "medium"} icon={starSharp} slot="icon-only" />
          </IonButton>}
        </IonButtons>
        {toJS(quotationServices)
        .sort(sortQuotationServicesByDate)
        .map(quote => {
          const handleStarClick = event => this.props.onStarClick(event, quote.id);
          const handleCardClick = event => {
            preventDefaultStopProp(event);
            return onQuotationServiceCardClick(null, quote.id)
          };
          return <QuotationServiceCard
            key={quote.id}
            quote={quote}
            serviceType={quote.displayTitle}
            noStar
            handleStarClick={handleStarClick}
            onClick={handleCardClick}
          />;
        })}
        <div className="quotationRemarks ion-padding">
          <IonText color="primary" className="font-s textBold">
            {UIText.remarks}:
          </IonText>
          <div className={`ion-padding quotationRemarksContent ${quotation.remarks ? "ion-text-left" : "ion-text-center"}`}>
            {!quotation.remarks ? (
              <IonText>{UIText.noRemarksAvailable}</IonText>
            ) : (
              <IonText color="dark" className="textPre">{quotation.remarks}</IonText>
            )}
          </div>
        </div>
      </div>
    </Accordion>;
  };

  render() {
    const {
      quotations,
      quotationServices
    } = this.props;

    const quoteGroups: QuotationGroup[] = quotations.map(quotation => {
      const { quotationServiceIds } = quotation;
      if (!quotationServiceIds) return null;
      const qs = quotationServiceIds
      .map(id => quotationServices.find(qs => qs.id === id))
      .filter(Boolean);
      if (isEmpty(qs)) return null;
      const totalPrice = getQuotationServicesPrice(qs);
      const providerName = qs[0].provider || UIText.unknownProvider;
      return {
        providerName,
        totalPrice,
        quotationServices: qs,
        ...quotation
      };
    });

    const Header = <div className="flex ion-justify-content-between ion-align-items-center">
      <IonText className="font-m baseColumnTitle">{UIText.allInQuotations}</IonText>
      <IonButton color="light" size="small" shape="round" onClick={this.handleFilterSortClick}>
        <IonIcon slot="icon-only" color="primary" icon={filterOutline}/>
      </IonButton>
    </div>;

    return <BaseColumn className="quotesColumn" header={Header}>
      {quoteGroups
      .sort((a, b) => a.totalPrice - b.totalPrice) // TODO: Default cheapest first, eventually we need buttons to sort.
      .map(this.renderQuotations)}
    </BaseColumn>
  }
}

export default AllInColumn;