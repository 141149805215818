import { Controller } from "../lib/controller";
import { autorun, computed, observable } from "mobx";
import { client } from "./client";
import { Group, GroupTypeRole } from "../lib/types/dataTypes";
import { preventDefaultStopProp } from "../utils/helpers";
import { defaultRoute, routeConfigs } from "../config/routes";
import { match, matchPath } from "react-router";
import { GroupSubRouteParams } from "../lib/types/propTypes";
import { msgCtrl } from "./msg";

export interface GlobalState {
  unreadCounters: { [key: number]: number };
}

// State.
// Main class instance for global ui and logic state store and control
// persistent data management and store.
export class State extends Controller<GlobalState> {
  constructor() {
    super();
    client.onLogout(this.storage.clearStore);
    client.onLogout(() => this.store.unreadCounters = {});
    autorun(() => this.currentGroupId);
    autorun(() => {
      for (const groupThread of msgCtrl.groupThreads) {
        this.updateHeaderUnreadCount(groupThread.unreadCount, groupThread.groupId);
      }
    });
  }

  /** Account verification states **/
  verificationLock: boolean = false;


  /** Group setup states **/
  lastSetupGroupId = 0;


  /** Post login pathname navigation states **/
  @observable lastPathname: string = defaultRoute.path;
  lastPathnameLocked: boolean;
  logoutResetPathname = (event?: any) => {
    preventDefaultStopProp(event);
    return client.logout()
    .then(() => {
      this.lastPathname = defaultRoute.path;
      this.lastPathnameLocked = true;
    });
  };


  /** Current view groupId and URL pathname states **/
  @observable currentPathname: string;
  @computed get currentGroupId(): Group["id"] {
    for (const routeConfig of routeConfigs) {
      const matching: match<GroupSubRouteParams> =
        matchPath(this.currentPathname, routeConfig);
      if (!matching) continue;
      const params = matching.params || {} as GroupSubRouteParams;
      const { groupId } = params;
      if (groupId) return Number(groupId);
    }
    return 0;
  };
  @computed get currentGroup(): Group {
    return client.findGroupById(this.currentGroupId);
  };
  @computed get currentAvatarUrl(): string {
    return ((this.currentGroup.profile || {}).data || {}).avatar;
  };
  updatePathname = (pathname: string) => pathname && (this.currentPathname = pathname);


  /** Registration states **/
  @observable registrationIntentLock: GroupTypeRole["id"][];
  @observable registrationLock: boolean = false;
  additionalRegistrationData = {};


  /** Profile screen states **/
  @observable profilePageExplicitBackPath: string;
  @observable profilePageExplicitEditable: boolean;
  @observable delegateDefaultProfileId: number; // For any non-default profile to pretend as default profile
  setDelegateDefaultProfileId = (profileId: number) => stateCtrl.delegateDefaultProfileId = profileId;


  /** Inbox unread counter states **/
  @observable unreadRefreshing: { [key: number]: boolean } = {};
  @computed get unreadCounters(): { [key: number]: number } {
    this.storage.initProperty("unreadCounters", {});
    return this.store.unreadCounters;
  };
  updateHeaderUnreadCount = (count: number, groupId: Group["id"]) => this.unreadCounters[groupId] = count;
}

// We would append Controller name here to prevent confusion with actual data.
export let stateCtrl = {} as State;
export const initStateCtrl = constructor => stateCtrl = constructor;
