import { Controller } from "../../../../lib/controller";
import { ShippingMode, ShippingRateBreakdown, ShippingUnit, ShippingUom } from "../../lib/types/rateTypes";
import { api } from "../../../../client/api";
import { endpointConfig } from "../../../../config/api";
import { computed, IObservableArray, observable } from "mobx";
import { getShippingModes, getShippingRateBreakdowns } from "../../lib/common";
import { UIText } from "../../../../client/lang";
import { SortableStore } from "../../../../lib/types/miscTypes";
import { client } from "../../../../client/client";
import { isEqual } from "../../../../utils/helpers";
import { defaultDimensionUnit } from "../../config/constants";
import * as convert from "convert-units";

export interface ShippingUnitListStore extends SortableStore<ShippingUnit> {
  modeFilters: number[];
  dimensionUnit: "m" | "cm";
}

export class ShippingUnitListController extends Controller<ShippingUnitListStore> {
  @observable modes: IObservableArray<ShippingMode> = [] as IObservableArray<ShippingMode>;
  @observable shippingUnits: IObservableArray<ShippingUnit> = [] as IObservableArray<ShippingUnit>;
  @observable shippingRateBreakdowns: IObservableArray<ShippingRateBreakdown> = [] as IObservableArray<ShippingRateBreakdown>;
  @observable uoms: IObservableArray<ShippingUom> = [] as IObservableArray<ShippingUom>;

  @computed get isSa(): boolean {
    return !!client.user.isSa;
  };
  @computed get dimensionUnit(): "m" | "cm" {
    this.storage.initProperty("dimensionUnit", defaultDimensionUnit);
    return this.store.dimensionUnit;
  };
  @computed get modeFilters(): IObservableArray<number> {
    this.storage.initProperty("modeFilters", []);
    return this.store.modeFilters as IObservableArray<number>;
  };

  loadAllData = () => Promise.all([
    this.getModes(),
    this.getShippingUnits(),
    this.getShippingRateBreakdowns(),
    this.getShippingUoms()
  ]);

  getModes = async () => getShippingModes()
  .then(modes => this.modes = modes);

  getShippingUnits = async () => api.GET(endpointConfig.shipping_units)
  .then(response => this.shippingUnits = (Array.isArray(response.data) && response.data) || []);

  getShippingRateBreakdowns = async () => getShippingRateBreakdowns()
  .then(shippingRateBreakdowns => this.shippingRateBreakdowns = shippingRateBreakdowns);

  getShippingUoms = async () => api.GET(endpointConfig.shipping_uoms)
  .then(response => this.uoms = (Array.isArray(response.data) && response.data) || []);

  isModeAvailable = (modeId: number, unit: ShippingUnit) => {
    const { breakdownId } = unit;
    if (!breakdownId) return false;
    const mode = this.modes.find(mode => mode.id === modeId);
    if (!mode) return false;
    return mode.breakdownIds.includes(Number(breakdownId));
  };

  isShippingUnitLikeWeightBreak = (unit: ShippingUnit) => {
    const { breakdownId } = unit;
    if (!breakdownId) return false;
    const breakdown = this.shippingRateBreakdowns.find(breakdown => breakdown.id === breakdownId);
    if (!breakdown) return false;
    return breakdown.unitType === "weightBreak" || breakdown.unitType === "w/m";
  };

  onFieldChange = (id: number, _field: keyof ShippingUnit, _value: ShippingUnit[keyof ShippingUnit]) => {
    const shippingUnit = this.shippingUnits.find(c => c.id === Number(id));
    if (!shippingUnit) return;
    const { field, value } = this.normalizeFieldChange(shippingUnit[_field], _field, _value);
    if (isEqual(shippingUnit[field], value)) return;
    (shippingUnit[field] as ShippingUnit[keyof ShippingUnit]) = value;
    return true;
  };

  onUpdateField = async (id: number, _field: keyof ShippingUnit, _value: ShippingUnit[keyof ShippingUnit]) => {
    const shippingUnit = this.shippingUnits.find(c => c.id === Number(id));
    if (!shippingUnit) return;
    const { field, value } = this.normalizeFieldChange(shippingUnit[_field], _field, _value);
    return api.PATCH({
      endpoint: endpointConfig.shipping_unit_by_id(id),
      data: { [field]: value }
    });
  };

  onAddShippingUnit = async (modeId: number) => api.POST({
    endpoint: endpointConfig.new_shipping_unit,
    data: { modeId }
  })
  .then(this.getShippingUnits);

  onRemoveShippingUnit = async (id: number) => {
    const shippingUnit = this.shippingUnits.find(c => c.id === id);
    if (!shippingUnit) return;
    this.shippingUnits.remove(shippingUnit);
    return api.DELETE(endpointConfig.shipping_unit_by_id(id))
    .finally(this.getShippingUnits);
  };

  onFilterClear = () => this.modeFilters.clear();

  onFilterSelect = (event: any) => {
    const { name } = event.target;
    if (this.modeFilters.includes(Number(name))) {
      return this.modeFilters.remove(Number(name));
    }
    return this.modeFilters.push(Number(name));
  };

  normalizeFieldChange = (originalValue, field: keyof ShippingUnit, value: ShippingUnit[keyof ShippingUnit]) => {
    if (field === "modeIds") {
      (value as any[]) = Array.isArray(value)
        ? value.map(v => isNaN(Number(v)) ? null : Number(v)).filter(Boolean)
        : [value].filter(Boolean);
    }
    if (field.match(/breakdownId|min|max|length|width|height|volume/g)) {
      value = Number(value) || 0;
    }
    if (field.match(/length|height|width/g)) {
      value = this.dimensionUnit !== defaultDimensionUnit
        ? this.roundDimension(value as number, this.dimensionUnit, defaultDimensionUnit)
        : value as number;
    }
    if (field === "unitName") {
      value = {
        ...originalValue,
        [UIText.preference]: value
      };
    }
    if (field === "description") {
      value = {
        ...originalValue,
        [UIText.preference]: value
      };
    }
    return { field, value };
  };


  roundDimension = (value: number, from, to) => Math.round(convert(value)
  .from(from || defaultDimensionUnit)
  .to(to || defaultDimensionUnit) * (to === "m" ? 1000 : 10)) / (to === "m" ? 1000 : 10);

  setDimensionUnit = (unit: "m" | "cm") => this.store.dimensionUnit = unit;
}
