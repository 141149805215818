import { observer } from "mobx-react";
import React from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonIcon,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonText,
  withIonLifeCycle,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { UIText } from "../../client/lang";
import "./styles.less";
import { RegisterController } from "./controller";
import FormView from "../../components/Form";
import { preventDefaultStopProp } from "../../utils/helpers";
import { observable } from "mobx";
import { StdErr } from "../../lib/types/miscTypes";
import { ui } from "../../client/ui";
import { Fade } from "react-reveal";
import { CompanyLinkLogo } from "../../tila/app3win/components/CompanyLinkLogo";
import { stateCtrl } from "../../client/state";
import { arrowBackCircleOutline } from "ionicons/icons";

export interface RegisterProps extends RouteComponentProps {}

@observer
class Register extends React.Component<RegisterProps> {
  controller: RegisterController = {} as RegisterController;
  title = UIText.signUp;

  @observable loading: boolean = false;

  constructor(props) {
    super(props);
    this.controller = new RegisterController();
  }

  ionViewWillEnter () {
    this.controller.initialize()
    .catch(this.showError);
  }

  ionViewWillLeave() {
    this.controller.reset();
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  onSuccessfulRegister = () =>
    ui.alert({
      header: UIText.signUpSuccessful,
      message: UIText.signUpSuccessfulMessage,
      buttons: [UIText.generalConfirm]
    });

  handleRegister = async (event: any) => {
    preventDefaultStopProp(event);
    if (stateCtrl.registrationLock || this.loading) return;
    if (!await this.controller.form.validate().catch(this.showError)) return;
    this.loading = true;
    return this.controller.onRegister()
    .then(() => this.loading = false)
    .then(() => stateCtrl.registrationLock = true)
    .then(this.onSuccessfulRegister)
    .then(this.handleSignIn)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleSignIn = (event: any) => {
    preventDefaultStopProp(event);
    return this.props.history.push("/Login");
  };

  render() {
    const { form, formValid } = this.controller;
    const header = <IonCardHeader className="font-l ion-text-left textPrimary ion-no-padding ion-padding-vertical">
      <IonButtons>
        <IonButton onClick={this.props.history.goBack}>
          <IonIcon slot="icon-only" icon={arrowBackCircleOutline} />
        </IonButton>
        &nbsp;{UIText.title} {UIText.signUp}
      </IonButtons>
    </IonCardHeader>;

    const registrationForm =
      <Fade duration={ui.defaultDuration}>
        <div className="flex column ion-padding authForm">
          <IonList>
            <IonListHeader className="textBold font-s">
              {UIText.signUpFormHeading}
            </IonListHeader>
            <FormView
              noList
              form={form}
              onSubmit={this.handleRegister}
              validateOnBlur
              validateOnChange
            />
          </IonList>
          <IonButton
            disabled={this.loading || !formValid}
            className={`ion-margin-vertical ${ui.isMobile ? "ion-align-self-center" : "ion-align-self-end"}`}
            onClick={this.handleRegister}
          >
            {UIText.signUp}
          </IonButton>
        </div>
      </Fade>;

    const registrationLinks =
      <div className="flex column ion-align-items-center ion-justify-content-center authLinks">
        <CompanyLinkLogo />
        <IonButton fill="clear" routerLink="/Login" routerDirection="back">
          <IonText className="textUnderline">{UIText.signUpLoginLink}</IonText>
        </IonButton>
        <IonButton fill="clear" routerLink="/Reset">
          <IonText className="textUnderline">{UIText.signUpResetLink}</IonText>
        </IonButton>
      </div>;

    return <IonPage className="register headerOffset">
      <IonContent fullscreen>
        <IonLoading translucent isOpen={this.loading} />
        <div
          className={
            `flex column container
             ion-align-items-center
             ion-justify-content-center
             ion-text-center
             ${ui.isMobile ? "" : "max"}`
          }
        >
          {ui.isMobile ? (
            <IonCard className="flex column container ion-padding registrationBox">
              {header}
              {registrationLinks}
              {registrationForm}
            </IonCard>
          ) : (
            <IonCard className="ion-padding registrationBox">
              <IonCardContent className="flex max">
                <div className="fancyScroll">
                  {header}
                  {registrationForm}
                </div>
                {registrationLinks}
              </IonCardContent>
            </IonCard>
          )}
        </div>
      </IonContent>
    </IonPage>;
  }
}

export default withIonLifeCycle(Register);