import { observer } from "mobx-react";
import React from "react";
import { computed, observable } from "mobx";
import { IonButton, IonButtons, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { Message, Thread, Topic } from "../../lib/types/topicTypes";
import { Group, Member } from "../../lib/types/dataTypes";
import { arrowBack } from "ionicons/icons";
import HeaderRefreshButton from "../HeaderRefreshButton";
import { getDisplayNameEng, isEmpty, preventDefaultStopProp, randomString } from "../../utils/helpers";
import { msgCtrl } from "../../client/msg";
import { client } from "../../client/client";
import ChatModalContent from "../ChatModalContent";
import { UIText } from "../../client/lang";
import { RouteComponentProps } from "react-router";
import { getThreadModalTitle } from "../../tila/app3win/lib/chat-utilities";
import { topicCtrl } from "../../client/topic";
import { getGroupParty } from "../../tila/app3win/lib/common";
import { StdErr } from "../../lib/types/miscTypes";
import { ui } from "../../client/ui";

export interface ChatModalProps extends RouteComponentProps {
  threadId: Thread["id"];
  groupId: Group["id"];
  isOpen: boolean;
}

@observer
class ChatModal extends React.Component<ChatModalProps> {
  @observable threadId: number;
  @observable groupId: number;
  @observable memberId: number;
  @observable loading: boolean = false;
  @observable hidden: boolean = false;

  @computed get topic(): Topic {
    return topicCtrl.findTopicById(this.thread.topicId);
  };
  @computed get thread(): Thread {
    return msgCtrl.findThreadById(this.threadId);
  };
  @computed get group(): Group {
    return client.findGroupById(this.groupId);
  };
  @computed get member(): Member {
    return (this.group.members && this.group.members.find(m => m.userId === client.userId)) || {} as Member;
  };

  constructor(props) {
    super(props);
    this.threadId = props.threadId;
    this.groupId = props.groupId;
  }

  componentDidUpdate(prevProps: Readonly<ChatModalProps>): void {
    if (prevProps.threadId !== this.props.threadId) this.threadId = this.props.threadId;
    if (prevProps.groupId !== this.props.groupId) this.groupId = this.props.groupId;
    if (!prevProps.isOpen && this.props.isOpen) this.onRefresh(null);
  };

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || UIText.messaging
    });

  onRefresh = (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return msgCtrl.updateThreadMessages(this.threadId, this.member.id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  onDismiss = () => msgCtrl.dismissChat();

  onMessageRead = async (message: Message) => {
    if (!msgCtrl.isUnread(message, this.member.id)) return;
    if (message.senderMemberId === this.member.id) return;
    return msgCtrl.readMessage(message.id, this.member.id)
    .then(this.onRefresh);
  };

  handleInputChange = (value: string) => msgCtrl.threadInputValues[this.thread.id] = value;

  handleSendClick = (event: any) => {
    preventDefaultStopProp(event);
    const value = msgCtrl.threadInputValues[this.thread.id].trim();
    if (!value) return;
    msgCtrl.threadInputValues[this.thread.id] = "";
    const watermark = randomString();
    const tempMessage: Message = {
      senderMemberId: this.member.id,
      text: value,
      threadId: this.threadId,
      topicId: this.thread.topicId,
      watermark
    };
    if (!this.thread.messages) this.thread.messages = [];
    this.thread.messages.push(tempMessage);
    return msgCtrl.sendMessage(
      this.thread.topicId,
      this.threadId,
      this.member.id,
      value,
      watermark
    )
    .then(this.onRefresh);
  };

  handleMessageAvatarClick = (event: any, id: Message["id"]) => {
    preventDefaultStopProp(event);
    const message = this.thread.messages.find(m => m.id === id);
    if (!message) return;
    const profile = msgCtrl.getMessageSenderProfile(message);
    if (isEmpty(profile)) return;
    this.hidden = true;
    return this.props.history.push(`/Group/${this.group.id}/Profile/${profile.id}`)
  };

  render() {
    return <IonModal
      cssClass={`chatModal modal large ${this.hidden ? "ion-hide" : ""}`}
      keyboardClose={false}
      isOpen={this.props.isOpen}
      onDidDismiss={this.onDismiss}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle className="textSelectable">
            {getThreadModalTitle(this.topic, this.thread, getGroupParty(this.group))}
          </IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={this.onDismiss}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <HeaderRefreshButton loading={this.loading} onRefresh={this.onRefresh} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <ChatModalContent
        inputValue={msgCtrl.threadInputValues[this.thread.id]}
        onInputValueChange={this.handleInputChange}
        onSendClick={this.handleSendClick}
        onMessageRead={this.onMessageRead}
        onMessageAvatarClick={this.handleMessageAvatarClick}
        messages={this.thread.messages}
        getMessageIsSystem={msgCtrl.isSystem}
        getMessageSenderName={m => getDisplayNameEng(msgCtrl.getMessageSenderProfile(m))}
        getMessageSelf={m => msgCtrl.isSelf(m, this.member.id)}
        getMessageUnread={m => msgCtrl.isUnread(m, this.member.id)}
        inputPlaceholderText={msgCtrl.isInfoThread(this.thread) && UIText.chatBottomBarEnterNotes}
      />
    </IonModal>;
  }
}

export default ChatModal;