// import { Controller } from "../../../../lib/controller";
// import { Group, Member } from "../../../../lib/types/dataTypes";
// import { computed, observable, toJS } from "mobx";
// import { Rfq } from "../../lib/types/dataTypes";
// import { topicTypeIds, typeClassIds } from "../../lib/constants";
// import { findRfqTopics, getGroupParty, getRfqTopics, topicToRfqWithShipperName } from "../../lib/common";
// import { endpointConfig } from "../../../../config/api";
// import { api } from "../../../../client/api";
// import { client } from "../../../../client/client";
// import { RfqFormDTO } from "../../lib/types/rfqFormTypes";
// import { Topic, TopicPrimitive } from "../../../../lib/types/topicTypes";
// import { stateCtrl } from "../../../../client/state";
// import { msgCtrl } from "../../../../client/msg";
//
// export class RfqListController extends Controller {
//   @observable groupId: Group["id"];
//
//   // Controller Data
//   @computed get group(): Group {
//     return client.findGroupById(this.groupId);
//   };
//   @computed get party(): ReturnType<typeof getGroupParty> {
//     return getGroupParty(this.group);
//   };
//   @computed get member(): Member {
//     return client.findMyMemberByGroupId(this.group.id);
//   };
//   @computed get rfqsTopics(): Topic<RfqFormDTO>[] {
//     return findRfqTopics(this.party === "shipper" && this.group.id);
//   };
//   @computed get rfqs(): Rfq[] {
//     return toJS(this.rfqsTopics).map(topicToRfqWithShipperName);
//   };
//
//   setGroupId = id => this.groupId = id;
//
//   loadAllData = async () => Promise.all([
//     this.getRfqs(),
//     this.getRfqUnreadCount()
//   ]);
//
//   reset = () => this.groupId = undefined;
//
//   getRfqs = async () => this.party && getRfqTopics(this.group.id, this.party);
//
//   // getRfqItems = async () => Promise.all(this.rfqs.map(getRfqItemTopics));
//
//   getRfqUnreadCount = async () => {
//     const groupId = Number(this.group.id), memberId = Number(this.member.id);
//     stateCtrl.unreadRefreshing[groupId] = true;
//     return msgCtrl.getInboxUnreads(topicTypeIds.rfq, groupId, memberId, true)
//     .then(msgCtrl.flattenTopicThreads)
//     .then(threads => msgCtrl.updateGroupThreads(groupId, threads))
//     .finally(() => stateCtrl.unreadRefreshing[groupId] = false);
//   };
//
//   onNewRfq = async (description: string) => {
//     if (!description) return;
//     const rfqTopic: TopicPrimitive = {
//       description,
//       isParentTemplate: 0,
//       isTemplate: 0,
//       typeClassId: typeClassIds.rfq.v1.id,
//       typeClassVersion: typeClassIds.rfq.v1.version,
//       typeId: topicTypeIds.rfq,
//       // Temporary data
//       data: JSON.stringify({
//         "mode" : "air",
//         "pod" : "HO Chi Minh",
//         "pol" : "Karachi Seaport",
//       })
//     };
//     const data = { topic: rfqTopic };
//     return api.POST({
//       endpoint: endpointConfig.create_topic,
//       data
//     })
//     .then(this.loadAllData);
//   };
// }


import { Controller } from "../../../../lib/controller";
import { Group, Member } from "../../../../lib/types/dataTypes";
import { computed, observable, toJS } from "mobx";
import { Rfq } from "../../lib/types/dataTypes";
import { topicTypeIds, typeClassIds } from "../../../../config/constants";
import { findRfqTopics, getGroupParty, getRfqItemTopics, getRfqTopics, topicToRfqWithShipperName } from "../../lib/common";
import { endpointConfig } from "../../../../config/api";
import { api } from "../../../../client/api";
import { client } from "../../../../client/client";
import { arrayFlat } from "../../../../utils/helpers";
import { RfqFormDTO } from "../../lib/types/rfqFormTypes";
import { stateCtrl } from "../../../../client/state";
import { Topic, TopicPrimitive } from "../../../../lib/types/topicTypes";
import { msgCtrl } from "../../../../client/msg";

export interface RfqListStore {
}

export class RfqListController extends Controller<RfqListStore> {
  @observable groupId: Group["id"];

  // Controller Data
  @computed get group(): Group {
    return client.findGroupById(this.groupId);
  };
  @computed get party(): ReturnType<typeof getGroupParty> {
    return getGroupParty(this.group);
  };
  @computed get member(): Member {
    return client.findMyMemberByGroupId(this.group.id);
  };
  @computed get rfqsTopics(): Topic<RfqFormDTO>[] {
    return findRfqTopics(this.party === "shipper" && this.group.id);
  };
  @computed get rfqs(): Rfq[] {
    return toJS(this.rfqsTopics).map(topicToRfqWithShipperName);
  };


  setGroupId = id => this.groupId = id;

  loadAllData = async () =>
    this.getRfqs()
    // .then(this.getRfqItemTopics)
    .then(() => setTimeout(this.getRfqUnreadCount));

  reset = () => this.groupId = undefined;

  getRfqs = async () => this.party && getRfqTopics(this.group.id, this.party);

  getRfqItems = async () => Promise.all(this.rfqs.map(getRfqItemTopics));

  getRfqUnreadCount = async () => {
    const groupId = Number(this.group.id), memberId = Number(this.member.id);
    stateCtrl.unreadRefreshing[groupId] = true;
    return Promise.all(this.rfqs.map(rfq =>
      msgCtrl.getThreadsAndMessagesByTopicId(rfq.id, memberId)
    ))
    .then(threads => stateCtrl.updateHeaderUnreadCount(
      arrayFlat(threads)
      .filter(Boolean)
      .reduce(
        (a, b) => a += (b.messages && b.messages.filter(msg => msgCtrl.isUnread(msg, memberId)).length) || 0, 0
      ), groupId
    ))
    .finally(() => stateCtrl.unreadRefreshing[groupId] = false);
  };

  onNewRfq = async (description: string) => {
    if (!description) return;
    const rfqTopic: TopicPrimitive = {
      description,
      isParentTemplate: 0,
      isTemplate: 0,
      typeClassId: typeClassIds.rfq.v1.id,
      typeClassVersion: typeClassIds.rfq.v1.version,
      typeId: topicTypeIds.rfq,
      // Temporary data
      data: JSON.stringify({
        "mode" : "air",
        "pod" : "HO Chi Minh",
        "pol" : "Karachi Seaport",
      })
    };
    const data = { topic: rfqTopic };
    return api.POST({
      endpoint: endpointConfig.create_topic,
      data
    })
    .then(this.loadAllData);
  };
}
