import { Group, GroupType, GroupTypeRole, Member, Profile, ProfilePrimitive, Role } from "./types/dataTypes";
import { endpointConfig } from "../config/api";
import { DataParser } from "./parser";
import { api } from "../client/api";
import { isEmpty, safeParseJSON } from "../utils/helpers";
import { UIException, UIText } from "../client/lang";
import { client } from "../client/client";
import { stateCtrl } from "../client/state";

export const createGroupProfile = async (
  group: Partial<Group>,
  profile: ProfilePrimitive,
  groupTypeRoleId: GroupTypeRole["id"]
) =>
  api.POST({
    endpoint: endpointConfig.create_group_profile,
    data: {
      group,
      profile
    }
  })
  .then(client.groupParser.parseResponseObject)
  .then(group => {
    if (isEmpty(group)) return Promise.reject(new UIException("CREATE_GROUP_PROFILE_EMPTY_RETURN"));
    return {
      group, profile, profileDetail: safeParseJSON(profile.data), groupTypeRoleId
    };
  });

export const getGroupRoles = async (createGroupSequence: {
  group: Partial<Group>,
  profile: ProfilePrimitive,
  profileDetail: Profile["data"],
  groupTypeRoleId: GroupTypeRole["id"]
}) => {
  const { group, profile, profileDetail, groupTypeRoleId } = createGroupSequence;
  const groupId = group.id;
  if (!groupId) return Promise.reject(new UIException("GET_GROUP_ROLES_INVALID_GROUP_ID"));
  return api.GET(endpointConfig.roles_by_group_id(groupId, groupTypeRoleId))
  .then(new DataParser<Role[]>().parseResponseObject)
  .then(roles => ({
    group, profile, profileDetail, roles, groupTypeRoleId
  }));
};

export const createSelfMember = async (getGroupRolesSequence: {
  group: Partial<Group>,
  profile: ProfilePrimitive,
  profileDetail: Profile["data"],
  roles: Role[],
  groupTypeRoleId: GroupTypeRole["id"]
}) => {
  const { group, roles, profileDetail, groupTypeRoleId } = getGroupRolesSequence;
  const member: Partial<Member> = {
    userId: client.userId,
    groupId: group.id,
    profileId: group.profileId,
    email: profileDetail.email,
    phone: profileDetail.phone
  };
  const roleIdList = [
    (roles.find(role => role["groupTypeRoleId"] === groupTypeRoleId) || {}).id
  ];
  return api.POST({
    endpoint: endpointConfig.create_member,
    data: {
      member,
      roleIdList
    }
  });
};

export const createSelfMemberProfile = async (getGroupRolesSequence: {
  group: Partial<Group>,
  profile: ProfilePrimitive,
  profileDetail: Profile["data"],
  roles: Role[],
  groupTypeRoleId: GroupTypeRole["id"]
}) => {
  const { group, profile, profileDetail, roles, groupTypeRoleId } = getGroupRolesSequence;
  const member: Partial<Member> = {
    userId: client.userId,
    groupId: group.id,
    email: profileDetail.email,
    phone: profileDetail.phone
  };
  const profileData: ProfilePrimitive = {
    userId: client.userId,
    type: 2,
    typeClassId: profile.typeClassId,
    typeClassVersion: profile.typeClassVersion,
    data: profile.data,
  };
  const roleIdList = [
    (roles.find(role => role["groupTypeRoleId"] === groupTypeRoleId) || {}).id
  ];
  return api.POST({
    endpoint: endpointConfig.create_member_profile,
    data: {
      member,
      profile: profileData,
      roleIdList
    }
  });
};

export const getGroupTypeNameByGroup = (group: Group) => {
  if (isEmpty(group)) return UIText.unknownGroupType;
  const groupTypes: GroupType[] = client.groupTypes;
  const typeName: string = (groupTypes.find(type => type.id === group.typeId) || {}).typeName;
  return UIText.groupTypes[typeName];
};

export const setLastSetupGroupId = createGroupSequence => {
  const { group } = createGroupSequence;
  stateCtrl.lastSetupGroupId = group.id;
  return createGroupSequence;
};