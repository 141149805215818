import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonIcon,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  withIonLifeCycle,
} from "@ionic/react";
import { ui } from "../../client/ui";
import { computed, observable } from "mobx";
import { Fade } from "react-reveal";
import { UIText } from "../../client/lang";
import { arrowBackCircleOutline } from "ionicons/icons";
import { ResetController } from "./controller";
import "./styles.less";
import { Form } from "../../client/form";
import FormView from "../../components/Form";
import { getQueryParameters, preventDefaultStopProp } from "../../utils/helpers";
import { StdErr } from "../../lib/types/miscTypes";
import { defaultAuthRoute } from "../../config/routes";
import { stateCtrl } from "../../client/state";

export interface ResetProps extends RouteComponentProps{}

@observer
class Reset extends React.Component<ResetProps> {
  url: string;
  title: string = UIText.resetPassword;
  controller: ResetController = {} as ResetController;

  @observable loading: boolean = true;
  @observable isExecution: boolean = false;
  @observable discarded: boolean = false;

  @computed get requestForm(): Form {
    return this.controller.requestForm;
  };
  @computed get execForm(): Form {
    return this.controller.execForm;
  };
  @computed get buttonDisabled(): boolean {
    return this.loading
      || (!this.isExecution && !this.controller.requestFormValid)
      || (this.isExecution && !this.controller.execFormValid);
  };

  constructor(props) {
    super(props);
    this.controller = new ResetController();
  }

  ionViewWillEnter = async () => {
    this.discarded = false;
    const search = this.props.location.search;
    const queryParams = getQueryParameters(search) || {};
    const { token, user, email } = queryParams;
    this.isExecution = !!token && !!user;
    if (this.isExecution) await this.controller.getExecVerification(token, user)
    .catch(this.showError);
    if (email && !this.isExecution) this.controller.requestForm.set("reset_email", email);
    this.loading = false;
  };

  ionViewWillLeave = () => this.discarded = true;

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    })
    .then(() => this.isExecution && this.props.history.replace(defaultAuthRoute.path));

  handleResetRequestSubmit = async (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    if (!await this.requestForm.validate()) return;
    return this.controller.onPasswordResetRequest()
    .then(() => ui.alert({
      header: this.title,
      message: UIText.resetRequestSent,
      buttons: [{
        text: UIText.generalConfirm,
        handler: () => this.props.history.replace(defaultAuthRoute.path)
      }]
    }))
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleResetExecSubmit = async (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    if (!await this.execForm.validate()) return;
    return this.controller.onPasswordResetExec()
    .then(() => stateCtrl.verificationLock = true)
    .then(() => ui.alert({
      header: UIText.generalSuccess,
      message: UIText.resetSuccess,
      buttons: [{
        text: UIText.generalConfirm,
        handler: () => this.props.history.replace(defaultAuthRoute.path)
      }]
    }))
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  render() {
    const header = <IonCardHeader className="font-l ion-text-left textPrimary ion-no-padding ion-padding-vertical">
      <IonButtons>
        <IonButton onClick={this.props.history.goBack}>
          <IonIcon slot="icon-only" icon={arrowBackCircleOutline} />
        </IonButton>
        &nbsp;{this.title}
      </IonButtons>
    </IonCardHeader>;

    const resetForm = <Fade duration={ui.defaultDuration}>
      <div className="flex column resetForm">
        <IonList>
        {this.isExecution ? (
          <>
            <IonListHeader className="textBold font-s ion-text-left">
              {UIText.resetFormExecHeading(this.controller.execUser)}
            </IonListHeader>
            {!this.discarded && this.controller.resetHash && <FormView
              noList
              form={this.execForm}
              onSubmit={this.handleResetExecSubmit}
              validateOnChange
              validateOnBlur
            />}
          </>
        ) : (
          <>
            <IonListHeader className="textBold font-s ion-text-left">
              {UIText.resetFormHeading}
            </IonListHeader>
            {!this.discarded && <FormView
              className={ui.isMobile ? "ion-margin-top" : ""}
              noList
              form={this.requestForm}
              onSubmit={this.handleResetRequestSubmit}
              validateOnChange
            />}
          </>
        )}
        </IonList>
        <IonButton
          disabled={this.buttonDisabled}
          onClick={this.isExecution ? this.handleResetExecSubmit : this.handleResetRequestSubmit}
          className="ion-margin-vertical ion-align-self-center"
        >
          {UIText.generalSubmit}
        </IonButton>
      </div>
    </Fade>;

    return <IonPage className="reset headerOffset">
      <IonContent fullscreen>
        <IonLoading translucent isOpen={this.loading} />
        <div
          className={
            `flex max column container
             ion-align-items-center
             ion-justify-content-center
             ion-text-center
             ${ui.isMobile ? "" : "max"}`
          }
        >
          {ui.isMobile ? (
            <IonCard className="flex max column container fancyScroll ion-padding loginBox">
              {header}
              <IonCardContent className="ion-no-padding">
                {resetForm}
              </IonCardContent>
            </IonCard>
          ) : (
            <IonCard className="ion-padding">
              {header}
              <IonCardContent className="flex">
                {resetForm}
              </IonCardContent>
            </IonCard>
          )}
        </div>
      </IonContent>
    </IonPage>;
  }
}

export default withIonLifeCycle(Reset);