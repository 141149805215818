import React from "react";
import { IonApp, setupConfig } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { Route } from "react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./config/styles/ionic/variables.css";
import "./config/styles/global.less";
import "./config/styles/container.less";
/* Components */
import MainPage from "./pages/MainPage";
import DevTools from "./pages/DevTools";
import { BaseUrlIndicator } from "./components/BaseUrlIndicator";
import PopoverContainer from "./components/PopoverContainer";
import FullScreenSpinner from "./components/FullScreenSpinner";
/* App Modules */
import { env } from "./config/env";
import { BootLoader } from "./client/boot";

// To keep platform styling consistency, set all styling mode to Material.
setupConfig({
  mode: "md"
});

@observer
class App extends React.Component {
  bootLoader: BootLoader;

  constructor(props) {
    super(props);
    this.bootLoader = new BootLoader();
    this.bootLoader.boot().catch(console.warn);
  };

  @computed get isReady(): boolean {
    return this.bootLoader.ready;
  };

  render() {
    return (
      <IonApp>
        {!this.isReady ? (
          <FullScreenSpinner />
        ) : (
          <IonReactRouter>
            <Route path="/" component={MainPage} />
            <Route exact path="/DevTools" component={env !== "prod" && DevTools} />
          </IonReactRouter>
        )}
        <BaseUrlIndicator />
        {/* Global popover menu container */}
        <PopoverContainer />
      </IonApp>
    );
  }
}

export default App;
