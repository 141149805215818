import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, withStyles } from "@material-ui/core";
import React from "react";
import { observer, Observer } from "mobx-react";
import { UIText } from "../../../../client/lang";
import { IonText } from "@ionic/react";
import getSymbolFromCurrency from "currency-symbol-map/currency-symbol-map";
import Picker from "../../../../components/FormFields/Picker";
import * as cc from "currency-codes";
import { ellipsisHorizontal } from "ionicons/icons";
import { getEventRealValue, getPriceString } from "../../../../utils/helpers";
import TwoStageInput from "../../../../components/TwoStageInput";
import { getIonStyleVal } from "../../../../config/styles/style-utils";
import { ShippingRate, ShippingRateCharge, ShippingRateChargeItem, ShippingRateUnit, ShippingUoc } from "../../lib/types/rateTypes";
import { ObserverList } from "../../../../components/ObserverList";
import "./styles.less";
import { getChargeItemsSum } from "../../lib/common";
import { autorun, computed, observable } from "mobx";

@observer
class FooterNoteField extends React.Component<{ readonly: boolean; parentEntity, handleParentEntityFieldChange }> {
  render() {
    const { readonly, parentEntity, handleParentEntityFieldChange } = this.props;
    return <TableRow>
      <Observer>{() => (
        <TableCell>
          <TwoStageInput
            readonly={readonly}
            name="note"
            className="ion-no-padding textDark"
            heading={UIText.rfqForm.notes}
            placeholder={UIText.rfqForm.notes}
            value={parentEntity.note}
            endIcon={ellipsisHorizontal}
            onIonBlur={!readonly && (e => handleParentEntityFieldChange(e, parentEntity))}
          />
        </TableCell>
      )}</Observer>
      <TableCell className="noBorder" />
      <TableCell className="noBorder" />
      <TableCell className="noBorder" />
    </TableRow>;
  }
}

@observer
class ChargeItemRow extends React.Component<{ readonly: boolean; shippingRateCharge, uocs, parentEntity, handleChargeItemFieldChange }> {
  @observable chargeItemAmount: string = "0";

  @computed get chargeItem(): ShippingRateChargeItem {
    const { shippingRateCharge, parentEntity } = this.props;
    return (
      parentEntity.shippingRateChargeItems &&
      parentEntity.shippingRateChargeItems.find(c => c.shippingRateChargeId === shippingRateCharge.id)
    );
  };

  constructor(props) {
    super(props);
    autorun(() => this.chargeItemAmount = this.chargeItem && (this.chargeItem.amount || "").toString());
  }

  render() {
    const { readonly, shippingRateCharge, uocs, handleChargeItemFieldChange } = this.props;

    if (!this.chargeItem || shippingRateCharge.isBasic) return null;

    return <TableRow hover>
      <Observer>{() => (
        <TableCell align="left">{shippingRateCharge.shippingCharge.chargeName[UIText.preference]}</TableCell>
      )}</Observer>
      <Observer>{() => (
        <TableCell align="left" className="ion-text-nowrap">
          <div className="flex ion-align-items-center">
            <IonText style={{ verticalAlign: "-webkit-baseline-middle"}}>
              {getSymbolFromCurrency(this.chargeItem.iso4217Code)}
            </IonText>
            <input
              readOnly={readonly}
              name="amount"
              value={this.chargeItemAmount}
              onChange={e => this.chargeItemAmount = getEventRealValue(e, true) || ""}
              onBlur={!readonly ? (e => handleChargeItemFieldChange(e, this.chargeItem)) : undefined}
            />
          </div>
        </TableCell>
      )}</Observer>
      <Observer>{() => (
        <TableCell align="left">
          <Picker
            disabled={readonly}
            name="iso4217Code"
            className="ion-no-padding"
            options={cc.codes().map(code => ({
              name: code, placeholder: code
            }))}
            value={this.chargeItem.iso4217Code}
            onIonChange={!readonly && (e => handleChargeItemFieldChange(e, this.chargeItem))}
          />
        </TableCell>
      )}</Observer>
      <Observer>{() => (
        <TableCell align="left">
          <Picker
            disabled={readonly}
            name="perUocId"
            className="ion-no-padding"
            options={uocs.map(uoc => ({
              name: uoc.id.toString(), placeholder: uoc.localeUoc[UIText.preference]
            }))}
            value={this.chargeItem.perUocId.toString()}
            onIonChange={!readonly && (e => handleChargeItemFieldChange(e, this.chargeItem))}
          />
        </TableCell>
      )}</Observer>
    </TableRow>;
  }
}

@observer
class FooterRows extends React.Component<{ readonly: boolean; isChargesOnly, chargesAmount?, parentEntity, handleParentEntityFieldChange }> {
  render() {
    const { readonly, isChargesOnly, parentEntity, handleParentEntityFieldChange } = this.props;

    const chargeItemsSum = getChargeItemsSum(parentEntity);
    const allInAmount = Number(Number(parentEntity.basicAmount || 0) + chargeItemsSum);

    return <TableFooter>
      <TableRow hover>
        <Observer>{() => (
          <TableCell align="left" className="textBold textDark">
            {isChargesOnly ? UIText.rateManagement.chargeSum : UIText.rateManagement.surchargeSum}
          </TableCell>
        )}</Observer>

        <Observer>{() => (
          <TableCell align="left" className="textBold textDark">
            {chargeItemsSum
              ? getPriceString(
                chargeItemsSum,
                getSymbolFromCurrency(parentEntity.iso4217Code),
                true)
              : "—"}
          </TableCell>
        )}</Observer>

        <TableCell padding="none" />
        <TableCell padding="none" />
      </TableRow>

      {!isChargesOnly && <TableRow hover>
        <TableCell align="left" size="medium" className="textBold textPrimary">
          {UIText.rateManagement.allInAmount || "—"}
        </TableCell>

        <Observer>{() => (
          <TableCell align="left" size="medium" className="textBold textPrimary">
            {allInAmount
              ? getPriceString(
                allInAmount,
                getSymbolFromCurrency(parentEntity.iso4217Code),
                true)
              : "—"}
          </TableCell>
        )}</Observer>

        <TableCell />
        <TableCell />
      </TableRow>}

      <Observer>{() => (
        <FooterNoteField
          readonly={readonly}
          parentEntity={parentEntity}
          handleParentEntityFieldChange={handleParentEntityFieldChange}
        />
      )}</Observer>
    </TableFooter>;
  }
}

export interface RateCardChargeItemTableProps {
  readonly?: boolean;
  isChargesOnly?: boolean;
  parentEntity?: ShippingRateUnit | ShippingRate;
  uocs: ShippingUoc[];
  shippingRateCharges: ShippingRateCharge[];
  chargesAmount?: number;
  handleParentEntityFieldChange?: (event: any, unit: ShippingRateUnit) => void;
  handleChargeItemFieldChange?: (event: any, rateChargeItem: ShippingRateChargeItem) => void;
}

@observer
class RateCardChargeItemTable extends React.Component<RateCardChargeItemTableProps> {
  render() {
    const {
      readonly,
      isChargesOnly,
      uocs,
      parentEntity,
      shippingRateCharges,
      chargesAmount,
      handleParentEntityFieldChange,
      handleChargeItemFieldChange
    } = this.props;

    // return null;

    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: isChargesOnly
          ? getIonStyleVal("--ion-color-primary-shade")
          : getIonStyleVal("--ion-color-primary-tint"),
        color: theme.palette.common.white,
      }
    }))(TableCell);

    return <TableContainer
      component={props => <Paper {...props} square />}
      className="rateChargeItems"
      elevation={0}
    >
      <Table size="small">
        <Observer>{() => (
          <TableHead>
            <TableRow>
              <StyledTableCell className="textBold">{isChargesOnly ? "" : UIText.rateManagement.surcharge}</StyledTableCell>
              <StyledTableCell className="textBold">{UIText.rateManagement.amount}</StyledTableCell>
              <StyledTableCell className="textBold">{UIText.rateManagement.currency}</StyledTableCell>
              <StyledTableCell className="textBold">{UIText.rateManagement.perUoc}</StyledTableCell>
            </TableRow>
          </TableHead>
        )}</Observer>
        <Observer>{() => (
          <TableBody>
            <ObserverList
              list={shippingRateCharges}
              render={shippingRateCharge => <Observer key={shippingRateCharge.id}>{() => (
                <ChargeItemRow
                  readonly={readonly}
                  parentEntity={parentEntity}
                  shippingRateCharge={shippingRateCharge}
                  uocs={uocs}
                  handleChargeItemFieldChange={handleChargeItemFieldChange}
                />
              )}</Observer>}
            />
          </TableBody>
        )}</Observer>
        <Observer>{() => (
          <FooterRows
            readonly={readonly}
            isChargesOnly={isChargesOnly}
            parentEntity={parentEntity}
            chargesAmount={chargesAmount}
            handleParentEntityFieldChange={handleParentEntityFieldChange}
          />
        )}</Observer>
      </Table>
    </TableContainer>
  }
}

export default RateCardChargeItemTable;
