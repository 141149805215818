import { Controller } from "../../lib/controller";
import { computed } from "mobx";
import { Group, GroupTypeRole, Member, User } from "../../lib/types/dataTypes";
import { client } from "../../client/client";
import { arrayFlat, isEmpty, safeParseJSON } from "../../utils/helpers";
import { SetupConfig } from "../../lib/types/setupTypes";
import { groupSetups } from "../../tila/app3win/lib/setups/groups";
import { topicCtrl } from "../../client/topic";
import { topicTypeIds } from "../../config/constants";

export class RootHubController extends Controller {
  @computed get isLoggedIn(): boolean {
    return client.isLoggedIn
  };
  @computed get user(): User {
    return client.user;
  };
  @computed get userGroups(): Group[] {
    return client.groups;
  };
  @computed get userMembers(): Member[] {
    return client.members;
  };

  getUserLastPendingStep = async (): Promise<[SetupConfig["id"], boolean]> => {
    await client.isLoggedInAndReady();
    const knownTypeRoleIds: GroupTypeRole["id"][] = arrayFlat(this.userMembers.map(
      member => !isEmpty(member.roleList) && member.roleList.filter(Boolean).map(
        role => role.groupTypeRoleId
      )
    ));
    const intentTypeRoleIds: GroupTypeRole["id"][] = arrayFlat([safeParseJSON(this.user.intentRole)].filter(Boolean));
    const needSetupTypeRoleIds = intentTypeRoleIds.filter(id => !knownTypeRoleIds.includes(id));
    const alreadyPartialSetup = intentTypeRoleIds.some(id => !!knownTypeRoleIds.includes(id));
    if (isEmpty(needSetupTypeRoleIds)) return [null, alreadyPartialSetup];
    const immediateNextTypeRoleId = needSetupTypeRoleIds[0];
    const foundSetup = groupSetups.find(setup => setup.metadata.groupTypeRoleId === immediateNextTypeRoleId);
    if (!foundSetup) return [null, alreadyPartialSetup];
    return [foundSetup.id, alreadyPartialSetup];
  };


  getRfqDrafts = async (groupId: number) => topicCtrl.getGroupTopicsByTypeId(groupId, topicTypeIds.rfqDraft);

  getLastGroup = async (): Promise<Group> => {
    //TODO: We want to be able to remember this;
    await client.isLoggedInAndReady();
    return client.nonDefaultGroups[0];
  }
}