import React, { MouseEvent, ReactNode } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { observer } from "mobx-react";
import { ComponentRef } from "@ionic/core";
import { preventDefaultStopProp } from "../../utils/helpers";
import { observable } from "mobx";
import "./styles.less";
import { closeOutline } from "ionicons/icons";
import { ui } from "../../client/ui";

type NativeInputProps = React.ComponentProps<typeof IonTextarea> & React.ComponentProps<typeof IonInput>;

export interface TwoStageInputProps extends NativeInputProps {
  forwardedRef?: (ref: HTMLIonInputElement | HTMLIonTextareaElement) => any;
  heading?: string;
  useIonInput?: boolean;
  endIcon: string | ReactNode | Element | ComponentRef | React.ReactElement | null;
  onEndIconClick?: (event: MouseEvent) => void;
  value?: string;
}

@observer
class TwoStageInput extends React.Component<TwoStageInputProps> {
  @observable isModalOpen: boolean = false;
  modalLastEvent: Partial<CustomEvent> = {};

  showModal = (event: any) => {
    preventDefaultStopProp(event);
    return this.isModalOpen = true;
  };

  onDismissModal = (event: any) => {
    preventDefaultStopProp(event);
    if (!this.modalLastEvent.detail) return this.isModalOpen = false;
    const method = this.props.onIonChange || this.props.onIonBlur;
    if (method) method(this.modalLastEvent as Required<CustomEvent>);
    return this.isModalOpen = false;
  };

  onModalValueChange = (event: any) => {
    preventDefaultStopProp(event);
    if (!event.detail || !event.detail) return;
    return this.modalLastEvent = event;
  };

  render() {
    const {
      forwardedRef,
      useIonInput,
      className,
      name,
      heading,
      placeholder,
      endIcon,
      onEndIconClick,
      value,
      maxlength,
      readonly
    } = this.props;

    const Component = <>
      {endIcon && <IonButton
        className="absolute"
        fill="clear"
        size="small"
        shape="round"
        onClick={onEndIconClick || this.showModal}
        color="medium"
      >
        <IonIcon icon={endIcon as string} slot="icon-only" />
      </IonButton>}

      <IonModal
        cssClass="twoStageInputModal"
        isOpen={this.isModalOpen}
        onDidDismiss={this.onDismissModal}
      >
        {ui.isMobile && (
          <IonHeader translucent>
            <IonToolbar>
              <IonTitle>{heading}</IonTitle>
              <IonButtons slot="start">
                <IonButton onClick={this.onDismissModal}>
                  <IonIcon slot="icon-only" icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent fullscreen className="ion-padding flex max ion-align-items-center ion-justify-content-center ion-text-center">
          <IonItem lines="full" className="flex">
            <IonLabel position="floating">
              {heading}
            </IonLabel>
            <IonTextarea
              name={name}
              className="twoStageTextarea fancyScroll"
              autoGrow
              maxlength={maxlength}
              placeholder={placeholder}
              value={value}
              readonly={readonly}
              onIonChange={this.onModalValueChange}
            />
          </IonItem>
        </IonContent>
      </IonModal>
    </>;

    return useIonInput
      ? <IonInput ref={forwardedRef} {...this.props} className={`twoStageInput ion-no-margin relative ${className || ""}`}>
        {Component}
      </IonInput>
      : <IonTextarea ref={forwardedRef} {...this.props} className={`twoStageInput ion-no-margin relative ${className || ""}`}>
        {Component}
      </IonTextarea>;
  }
}

export default TwoStageInput;