import { observer } from "mobx-react";
import HeaderNav from "../../tila/app3win/components/HeaderNav";
import { IonIcon, IonLoading, IonPage, IonRouterOutlet, withIonLifeCycle } from "@ionic/react";
import { matchPath, Route, RouteComponentProps } from "react-router";
import { ui } from "../../client/ui";
import React from "react";
import { UIText } from "../../client/lang";
import { languageSharp } from "ionicons/icons";
import { LanguageType } from "../../lib/types/miscTypes";
import { arrayFlat, isEmpty, preventDefaultStopProp } from "../../utils/helpers";
import { autorun, computed, reaction } from "mobx";
import { client } from "../../client/client";
import { stateCtrl } from "../../client/state";
import { defaultAuthRoute, defaultRoute, routeConfigs } from "../../config/routes";
import ChatModal from "../../components/ChatModal";
import { msgCtrl } from "../../client/msg";

interface MainPageProps extends RouteComponentProps {}

@observer
class MainPage extends React.Component<MainPageProps> {
  disposers = [];

  private readonly authRoutes = routeConfigs.filter(r => !!r.authRoute);

  private readonly authRouteRegExp = arrayFlat(routeConfigs.map(route => (
    route.authRoute
      ? typeof route.authRoute === "string"
      ? route.authRoute
      : route.path
      : undefined
  )))
  .concat("/DevTools")
  .filter(Boolean)
  .map(route => `(${route}$)`)
  .join("|");

  @computed get isLoggedIn(): boolean {
    return client.isLoggedIn;
  };

  constructor(props) {
    super(props);
    this.disposers.push(reaction(() => !client.isLoggedIn, this.redirectToLogin));
    this.disposers.push(autorun(() => {
      if (!client.isLoggedIn) return;
      if (stateCtrl.currentGroupId && isEmpty(stateCtrl.currentGroup)) return this.navigateRoot();
    }));
    this.redirectToLogin().catch(console.warn);
    stateCtrl.updatePathname(this.props.location.pathname);
  }

  componentDidUpdate() {
    stateCtrl.updatePathname(this.props.location.pathname);
    return this.redirectToLogin();
  }

  ionViewWillLeave() {
    this.disposers && this.disposers.map(disposer => disposer && disposer());
  }

  handleLangClick = (event: any) => ui.popoverMenu({
    event,
    menuItems: UIText.languages.map(lang => {
      const onSwitchLang = event => this.handleLangSwitch(event, lang.id);
      return {
        icon: <IonIcon
          style={{ visibility: !lang.selected && "hidden" }}
          slot="start"
          color="primary"
          icon={languageSharp}
        />,
        text: lang.description,
        color: lang.selected ? "primary" : "dark",
        handler: onSwitchLang
      };
    })
  });

  redirectToLogin = async () => {
    await client.storage.isReady();
    if (client.isLoggedIn) return this.checkValidRoute();
    if (!stateCtrl.lastPathnameLocked) {
      stateCtrl.lastPathname = this.props.location.pathname.replace(
        new RegExp(this.authRouteRegExp, "ig"), ""
      );
      stateCtrl.lastPathnameLocked = true;
    }
    const { pathname } = this.props.location;
    const notDevTools = !pathname.match("/DevTools");
    const matching = this.authRoutes.map(r => matchPath(pathname, r)).filter(Boolean);
    const matched = matching[matching.length - 1];
    const validAuthRoute = matched && this.authRoutes.some(
      r => r.path.includes(matched.path)
        && (r.authRoute === true || (r.authRoute as string).toLowerCase() === matched.path.toLowerCase())
    );
    if (!validAuthRoute && notDevTools) return this.props.history.replace(defaultAuthRoute.path);
  };

  checkValidRoute = () => {
    const { pathname } = this.props.location;
    if (!pathname) return;
    if (pathname.match(/DevTools/ig)) return;
    const matchingRoute = routeConfigs.find(r => matchPath(pathname, r));
    if (!matchingRoute) return this.navigateRoot();
  };

  navigateRoot = () => this.props.history.replace(defaultRoute.path);

  handleLangSwitch = (event: any, id: LanguageType["id"]) => {
    preventDefaultStopProp(event);
    return UIText.switchLanguage(id);
  };

  render() {
    return <IonPage className="mainPage">
      <HeaderNav
        history={this.props.history}
        onLangClick={this.handleLangClick}
      />

      <IonLoading
        translucent
        isOpen={client.credentialReady && !client.isLoggedIn && !client.hasError}
        message={UIText.postLoginProgress}
      />

      <IonRouterOutlet id="main">
        {routeConfigs.map(route => (
          <Route
            key={route.name}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}
      </IonRouterOutlet>


      {/* Global chat pop-up modal */}
      <ChatModal
        threadId={msgCtrl.modalThreadId}
        groupId={msgCtrl.modalGroupId}
        isOpen={msgCtrl.modalOpen}
        history={this.props.history}
        location={this.props.location}
        match={this.props.match}
      />
    </IonPage>
  }
}

export default withIonLifeCycle(MainPage);