import { observer } from "mobx-react";
import React from "react";
import { SortDirection, TableCell, TableHead, TableRow } from "@material-ui/core";
import { IonButton, IonIcon, IonRippleEffect } from "@ionic/react";
import { caretDownSharp, caretUpSharp } from "ionicons/icons";
import { stopProp } from "../../utils/helpers";

export interface SortableTableHeadCustomColumn {
  name: string;
  component: React.ReactNode | Element;
}

export interface SortableTableHeadSortColumn {
  name: string;
  text: string;
  routerLink?: string;
  noSort?: boolean;
}

export type SortableTableHeadColumn = SortableTableHeadCustomColumn | SortableTableHeadSortColumn;

export interface SortableTableHeadProps {
  columns: SortableTableHeadColumn[];
  onSort?: (event: any, column: string) => void;
  sortBy?: string;
  sortDir?: SortDirection;
  noSort?: boolean;
}

const getColumnTextComponent = (sortColumn: SortableTableHeadSortColumn) => sortColumn.routerLink ? (
  <IonButton
    fill="clear"
    routerLink={sortColumn.routerLink}
    size="small"
    className="ion-no-margin ion-no-padding textNoTransform textBold textUnderline"
    onClick={stopProp}
  >
    {sortColumn.text}
  </IonButton>
) : sortColumn.text;

const SortableTableHead: React.FC<SortableTableHeadProps> = observer(({ columns, sortBy, sortDir, onSort }) => (
  <TableHead>
    <TableRow>
      {columns.map(column => {
        const customColumn = column as SortableTableHeadCustomColumn;
        const sortColumn = column as SortableTableHeadSortColumn;
        if (customColumn.component) return <React.Fragment key={customColumn.name}>{customColumn.component}</React.Fragment>;
        return <TableCell
          key={sortColumn.name}
          className={`textBold relative ion-activatable ${sortColumn.noSort ? "noSort" : ""}`}
          onClick={e => !sortColumn.noSort && onSort && onSort(e, sortColumn.name)}
        >
          {sortColumn.noSort ? (
            <div className="ion-text-center">
              {getColumnTextComponent(sortColumn)}
            </div>
          ) : (
            <div className="flex ion-align-items-center">
              {sortBy === sortColumn.name && (
                <IonIcon icon={sortDir === "desc" ? caretDownSharp : caretUpSharp} style={{ marginRight: "5px" }} />
              )}
              {getColumnTextComponent(sortColumn)}
            </div>
          )}
          <IonRippleEffect />
        </TableCell>;
      })}
    </TableRow>
  </TableHead>
));

export default SortableTableHead;