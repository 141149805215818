import { observer } from "mobx-react";
import React, { MouseEvent } from "react";
import "./styles.less";
import { IonAvatar, IonItem, IonSpinner } from "@ionic/react";
import Fade from "react-reveal/Fade";
import { ui } from "../../client/ui";
import MdIcon from "../MdIcon";
import { mdiAsterisk } from "@mdi/js";
import VizSensor from "react-visibility-sensor";
import { UIText } from "../../client/lang";
import { placeholderAvatarUrl } from "../../config/constants";

export interface ChatMessageProps extends Omit<React.HTMLProps<HTMLDivElement>, "id"> {
  isSelf?: boolean;
  isUnread?: boolean;
  isSending?: boolean;
  isSystem?: boolean;
  avatar?: string; // URL
  senderName: string;
  text: string;
  html?: string;
  timestamp?: string | number | Date;
  readMessage: () => void;
  onAvatarClick?: (event: MouseEvent) => void;
}

@observer
class ChatMessage extends React.Component<ChatMessageProps> {
  render() {
    const {
      className,
      isSelf,
      isUnread,
      isSending,
      isSystem,
      avatar,
      senderName,
      text,
      html,
      timestamp,
      readMessage,
      onAvatarClick
    } = this.props;

    const __html = html;

    return <VizSensor onChange={visible => visible && readMessage()}>
      <Fade left={!isSending && !isSelf} right={!isSending && isSelf} duration={ui.defaultDuration}>
        <IonItem
          lines="full"
          style={{ "--background": isUnread
              ? "#f444441f"
              : isSelf
              ? ""
              : "#f6f6f6" }}
          className={`chatMessage textSelectable flex ion-align-items-vertical ${isSelf ? "self" : ""} ${className || ""}`}
        >
          {!isSystem && <IonAvatar
            slot={isSelf ? "end" : "start"}
            className={`chatMessageAvatar ${onAvatarClick ? "ion-activatable" : ""}`}
            onClick={onAvatarClick}
          >
            <img alt="avatar" src={avatar || placeholderAvatarUrl} />
          </IonAvatar>}
          <div className="flex column chatMessageContent">
            <div className="chatMessageSender font-xs textBold textDarkMedium">{isSystem ? UIText.infoThread : senderName}</div>
            {!html && <div className="chatMessageBody font-xs">{text}</div>}
            {html && <div className="chatMessageHtml font-xs" dangerouslySetInnerHTML={{ __html }} />}
            {timestamp && <span className="chatMessageTimestamp textPrimary">{timestamp}</span>}
            {isSending && <IonSpinner className="chatMessageSpinner" color="primary" />}
          </div>
          {!isSelf && isUnread && <MdIcon icon={mdiAsterisk} color="tooling" size="14px" />}
        </IonItem>
      </Fade>
    </VizSensor>;
  }
}

export default ChatMessage;