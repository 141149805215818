import { observer } from "mobx-react";
import React from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { UIText } from "../../../../client/lang";
import { chevronDownSharp, closeSharp } from "ionicons/icons";
import ListControlToolbar from "../../../../components/ListControlToolbar";
import { getEventRealValue, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import { ShippingMode, ShippingPort, ShippingRate, ShippingRateLeg } from "../../lib/types/rateTypes";
import "./styles.less";
import { ListController } from "../../../../lib/list-controller";
import ListControlFilterBar from "../../../../components/ListControlFilterBar";
import { shippingPortSortProperties } from "../../config/constants";
import { ObserverList } from "../../../../components/ObserverList";
import { sortByProperty } from "../../lib/common";
import { computed } from "mobx";
import { matchShippingPort } from "../../lib/matchers";
import { ui } from "../../../../client/ui";
import PlacesAutocomplete from "../../../../components/FormFields/PlaceAutocomplete";
import { PortSelectionPropertyNames } from "../../lib/types/propTypes";

export interface RatePortSelectionModalProps {
  modes: ShippingMode[];
  portProperty: PortSelectionPropertyNames;
  ports: ShippingPort[];
  rate: ShippingRate | ShippingRateLeg;
  loading: boolean;
  isOpen: boolean;
  onDismiss: (event: any) => void;
  onPortSelect: (portId: number) => void;
  onModeFieldChange?: (event: any) => void;
}

@observer
class RatePortSelectionModal extends React.Component<RatePortSelectionModalProps> {
  listController: ListController<ShippingPort>;

  @computed get selectedPort(): ShippingPort {
    return this.props.ports.find(port => (
      this.props.rate && this.props.rate[this.props.portProperty] === port.id
    )) || {} as ShippingPort;
  };
  @computed get searchedPorts(): ShippingPort[] {
    return this.props.ports.filter(port =>
      matchShippingPort(port, this.listController.searchValue) &&
      this.selectedPort.id !== port.id
    );
  };
  @computed get sortedPorts(): ShippingPort[] {
    return [...this.searchedPorts].sort(
      (a, b) => sortByProperty(
        a,
        b,
        this.listController.sortBy,
        this.listController.sortDir
      )
    );
  };

  get sortProperties() {
    return shippingPortSortProperties.map(sort => ({
      name: sort,
      placeholder: UIText.shippingPortSortProperties[sort]
    }))
  }

  constructor(props) {
    super(props);
    this.listController = new ListController({
      storeName: "portSelectionModalFilterSort",
      initialSortBy: "portCode"
    });
  }

  checkSelectedPort = (port: ShippingPort): "pol" | "pod" | false => {
    if (!port) return false;
    if (port.id === this.props.rate.polPortId) return "pol";
    if (port.id === this.props.rate.podPortId) return "pod";
  };

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  handlePortSelect = (event: any, portId: number) => {
    preventDefaultStopProp(event);
    this.props.onPortSelect(portId);
    return this.props.onDismiss(event);
  };

  handleModeChange = (event: any) => ui.popoverMenu({
    event,
    menuItems: this.props.modes.map(mode => ({
      text: (mode.localeMode || {})[UIText.preference],
      handler: () => this.wrapModeFieldEvent("modeId", mode.id)
    }))
  });

  wrapModeFieldEvent = (name: keyof ShippingRate, value) => {
    const event = { target: { name, value } };
    return this.props.onModeFieldChange && this.props.onModeFieldChange(event);
  };

  renderPortCard = (port: ShippingPort) => {
    const {
      portProperty,
      rate
    } = this.props;

    const selectedForPort = this.checkSelectedPort(port);

    return <IonCard
      className="portCard card ion-margin-vertical"
      key={port.id}
    >
      <IonCardHeader className="flex ion-align-items-center ion-justify-content-between">
        <IonCardTitle color="primary" className="textPrimary textBold font-m" style={{ flex: 1 }}>
          {port.portName[UIText.preference]}
        </IonCardTitle>
        {rate[portProperty] === port.id ? (
          <IonText
            color="primary"
            className="deSelectText textBold font-s textUpperCase ion-padding-vertical ion-activatable"
            onClick={e => this.handlePortSelect(e, 0)}
          >
            {UIText.generalSelected}&nbsp;<IonIcon icon={closeSharp} />
          </IonText>
        ) : selectedForPort ? (
          <IonText color="primary" className="textBold font-xs textUpperCase ion-padding-vertical">
            {UIText.rateManagement.selectedForPort[selectedForPort]}
          </IonText>
        ) : (
          <IonButton onClick={e => this.handlePortSelect(e, port.id)}>
            {UIText.generalSelect}
          </IonButton>
        )}
      </IonCardHeader>
      <IonCardContent className="formView ion-no-padding">
        <div className="flex ion-wrap">
          <div className={`flex subWrap ${ui.isMobile ? "ion-wrap" : ""}`}>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.shippingPortSortProperties.portCode}
              </IonLabel>
              <IonInput
                className="textBold"
                readonly
                name="portCode"
                placeholder={UIText.shippingPortSortProperties.portCode}
                value={port.portCode}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.shippingPortGooglePlace}
              </IonLabel>
              <PlacesAutocomplete
                readonly
                name="placeData"
                placeholder={UIText.shippingPortGooglePlace}
                value={port.placeData || "—"}
              />
            </IonItem>
          </div>

          <div className={`flex subWrap ${ui.isMobile ? "ion-wrap" : ""}`}>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.shippingPortSortProperties.iso3166A2Code}
              </IonLabel>
              <IonInput
                readonly
                name="iso3166A2Code"
                placeholder={UIText.shippingPortSortProperties.iso3166A2Code}
                value={port.iso3166A2Code || "—"}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.shippingPortSortProperties.iso3166A3Code}
              </IonLabel>
              <IonInput
                readonly
                name="iso3166A3Code"
                placeholder={UIText.shippingPortSortProperties.iso3166A3Code}
                value={port.iso3166A3Code || "—"}
              />
            </IonItem>
          </div>

          <div className={`flex subWrap ${ui.isMobile ? "ion-wrap" : ""}`}>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.shippingPortSortProperties.lat}
              </IonLabel>
              <IonInput
                readonly
                name="lat"
                placeholder={UIText.shippingPortSortProperties.lat}
                value={port.lat || "—"}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.shippingPortSortProperties.lng}
              </IonLabel>
              <IonInput
                readonly
                name="lng"
                placeholder={UIText.shippingPortSortProperties.lng}
                value={port.lng || "—"}
              />
            </IonItem>
          </div>
        </div>
      </IonCardContent>
    </IonCard>;
  };

  render() {
    const {
      modes,
      portProperty,
      rate,
      isOpen,
      loading,
      onDismiss,
      onModeFieldChange
    } = this.props;

    const {
      sortBy,
      sortDir,
      searchValue,
      onSearchValueChange,
      onSortByChange,
      onSortDirChange
    } = this.listController;

    const currentMode = modes.find(mode => mode.id === rate.modeId) || {} as ShippingMode;

    return <IonModal
      key={`portSelectionModal ${rate.id}`}
      cssClass="modal portSelectionModal large"
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{UIText.rateManagement.selectPolPodPort[portProperty]}</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={closeSharp} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" className="ion-padding-horizontal">
            {loading && <IonSpinner name="crescent" />}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <ListControlToolbar
          searchValue={searchValue}
          onSearchChange={(e: any) => onSearchValueChange(getEventRealValue(e))}
          searchBarPlaceholder={UIText.searchStuff(UIText.rateManagement.shippingPort)}
        />

        <div className="flex ion-wrap ion-justify-content-between ion-align-items-center ion-margin-top">
          <ListControlFilterBar
            sorts={this.sortProperties}
            sortDir={sortDir}
            sortBy={sortBy}
            count={this.sortedPorts.length}
            onSortByChange={onSortByChange}
            onSortDirChange={onSortDirChange}
          />
          <IonButtons>
            {onModeFieldChange ? (
              <IonButton
                color="light"
                className="textBold textNoTransform"
                onClick={this.handleModeChange}
              >
                <IonIcon slot="start" icon={chevronDownSharp} />
                {UIText.rateManagement.mode}:&nbsp;{(currentMode.localeMode || {})[UIText.preference]}
              </IonButton>
            ) : (
              <IonText color="light" className="textBold textNoTransform ion-margin-horizontal font-xs">
                {UIText.rateManagement.mode}:&nbsp;{(currentMode.localeMode || {})[UIText.preference]}
              </IonText>
            )}
          </IonButtons>
        </div>

        {!isEmpty(this.selectedPort) && !searchValue && this.renderPortCard(this.selectedPort)}
        <ObserverList
          list={this.sortedPorts}
          render={this.renderPortCard}
        />
      </IonContent>
    </IonModal>
  }
}

export default RatePortSelectionModal;