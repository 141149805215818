import { Form } from "../../../client/form";
import { IonCard, IonCardContent, IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import React from "react";
import FormView from "../../../components/Form";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { Store } from "../../../lib/storage";
import TwoStageInput from "../../../components/TwoStageInput";
import { ellipsisHorizontal } from "ionicons/icons";
import { safeParseJSON } from "../../../utils/helpers";
import PlacesAutocomplete from "../../../components/FormFields/PlaceAutocomplete";

@observer
class FormTest extends React.Component {
  storage: Store<any>;
  @observable form: Form;

  @computed get store() { return this.storage.data };
  @computed get formJSON(): string { return this.store.formJSON };

  constructor(props) {
    super(props);
    this.storage = new Store("FormTest");
    this.storage.isReady()
    .then(this.setForm);
  }

  loadForm = (event: any) => {
    this.store.formJSON = event.detail.value;
    const json = safeParseJSON(this.formJSON, true);
    if (!json || !Array.isArray(json)) return;
    this.setForm();
  };

  setForm = () => this.form = new Form(this.formJSON, {});

  render() {
    return <IonCard className="flex column container cardedContainer" style={{ margin: "16px auto" }}>
      <IonList className="ion-no-padding">
        <IonListHeader className="textPrimary textBold">Form Test</IonListHeader>
        <IonItem>
          <IonLabel>
            JSON
          </IonLabel>
          <style>{`.formTest > textarea { min-height: 100px }`}</style>
          <TwoStageInput
            endIcon={ellipsisHorizontal}
            className="editorTextArea formTest"
            value={this.formJSON}
            placeholder="Enter TypeClass JSON"
            onIonChange={this.loadForm}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="floating">
            Autocomplete
          </IonLabel>
          <PlacesAutocomplete onPositionChange={console.log} />
        </IonItem>
      </IonList>
      <IonCardContent>
        <FormView form={this.form} />
      </IonCardContent>
    </IonCard>
  }
}

export default FormTest;