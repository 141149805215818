// TODO Performance:
//  - Add virtual scroll
//  - New table component

import React from "react";
import { IonButton, IonButtons, IonContent, IonIcon, IonLoading, IonPage, IonText, withIonLifeCycle } from "@ionic/react";
import { observer, Observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import "./styles.less";
import { RateListController } from "./controller";
import { ui } from "../../../../client/ui";
import { computed, IObservableArray, observable, toJS } from "mobx";
import RateCard from "../../components/RateCard";
import { defaultRoute } from "../../../../config/routes";
import { StdErr } from "../../../../lib/types/miscTypes";
import { UIText } from "../../../../client/lang";
import { asyncPause, getDisplayNameEng, getEventRealValue, isEmpty, isEqual, preventDefaultStopProp } from "../../../../utils/helpers";
import { addOutline, checkbox, squareOutline } from "ionicons/icons";
import ListControlToolbar from "../../../../components/ListControlToolbar";
import HeaderRefreshButton from "../../../../components/HeaderRefreshButton";
import { ShippingMode, ShippingPlan, ShippingRate, ShippingRateLeg } from "../../lib/types/rateTypes";
import RateChargeSelectionModal from "../../components/RateChargeSelectionModal";
import RateCarrierSelectionModal from "../../components/RateCarrierSelectionModal";
import { matchPlan, matchPlanByPolPod } from "../../lib/matchers";
import CarrierChargeCustomizeModal from "../../components/CarrierChargeCustomizeModal";
import RatePortSelectionModal from "../../components/RatePortSelectionModal";
import HelpTip from "../../../../components/HelpTip";
import RateTransitPortModal from "../../components/RateTransitPortModal";
import ListControlFilterBar from "../../../../components/ListControlFilterBar";
import { ListController } from "../../../../lib/list-controller";
import { getShippingPlanFilterProperties, shippingPlanSortProperties } from "../../config/constants";
import { sortByProperty } from "../../lib/common";
import { filterShippingPlanBySelectedFilters } from "../../lib/filters";
import ComplexRateEntryCard from "../../components/ComplexRateEntryCard";
import { ObserverList } from "../../../../components/ObserverList";
import { RateListAdvancedSearchBar } from "../../components/RateListAdvancedSearchBar";
import RateDataView from "../../components/RateDataView";

export interface RateListProps extends RouteComponentProps<GroupSubRouteParams<{}>> {}


@observer
class RateList extends RateDataView<RateListProps, RateListController> {
  title = UIText.rateManagement.shippingRates;

  contentElm: HTMLIonContentElement;

  url: string;
  listController: ListController<ShippingPlan>;


  @observable transitPortModalRateId: number;
  @computed get transitPortModalRate(): ShippingRate {
    return this.rates.find(r => r.id === this.transitPortModalRateId) || {} as ShippingRate;
  };
  @computed get transitPortModalOpen(): boolean {
    return !!this.transitPortModalRateId;
  };

  @observable carrierModalPlanId: number;
  @computed get carrierModalPlan(): ShippingPlan {
    return this.plans.find(plan => plan.id === this.carrierModalPlanId) || {} as ShippingPlan;
  };

  @observable searchHasFocus: boolean;
  @computed get advancedSearch() {
    return this.controller.advancedSearch;
  };

  @computed get searchValue(): string { return this.listController.searchValue };
  @computed get searchedPlans(): ShippingPlan[] {
    return this.plans.filter(plan =>
      this.advancedSearch.isEnabled
        ? matchPlanByPolPod(plan, this.advancedSearch.matchPol, this.advancedSearch.matchPod)
        : matchPlan(plan, this.searchValue)
    );
  };

  @computed get filteredPlans(): ShippingPlan[] {
    return this.searchedPlans.filter(
      plan => filterShippingPlanBySelectedFilters(
        plan,
        this.listController.selectedFilters,
        this.controller.modes
      )
    );
  };
  @computed get sortedPlans(): ShippingPlan[] {
    return [...this.filteredPlans].sort((a, b) =>
      sortByProperty(
        a,
        b,
        this.listController.sortBy,
        this.listController.sortDir
      )
    );
  };


  get sortProperties() {
    return shippingPlanSortProperties.map(sort => ({
      name: sort,
      placeholder: UIText.shippingPlanSortProperties[sort]
    }));
  };

  get filterProperties() {
    return getShippingPlanFilterProperties(true).map(filter => ({
      name: typeof filter === "string" && !(filter.trim()) ? "divider" : filter,
      placeholder: UIText.shippingPlanFilterProperties[filter]
    }));
  }

  @computed get filterPropertiesForFilterBar() {
    return !this.advancedSearch.isEnabled && this.filterProperties;
  };

  constructor(props) {
    super(props, new RateListController());
    this.url = props.match.url;
    this.listController = new ListController({
      storeName: "rateListFilterSort",
      initialSortBy: "createTime"
    });
  }

  showError = (err: StdErr, actionName?: string) => ui.showError({
    err,
    actionName: actionName || this.title
  });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    const { groupId } = this.props.match.params;
    if (isNaN(Number(groupId))) return this.props.history.replace(defaultRoute.path);
    this.controller.setGroupId(Number(groupId));
    return this.onRefresh();
  };

  ionViewDidLeave = () => this.doneLoading();

  setContentRef = elm => this.contentElm = elm;

  scrollToEnd = () => this.contentElm && this.contentElm.scrollToBottom(300);

  handleAddShippingPlan = (event: any) => ui.popoverMenu({
    event,
    menuItems: [
      {
        text: UIText.rateManagement.singleMode,
        handler: () => this.handleAddShippingPlanConfirm("single")
      },
      {
        text: UIText.rateManagement.multimodal,
        handler: () => this.handleAddShippingPlanConfirm("multimodal")
      },
      {
        text: UIText.rateManagement.complexMultimodal,
        handler: () => this.handleAddShippingPlanConfirm("complex")
      }
    ]
  });

  handleAddShippingPlanConfirm = (type: ShippingPlan["type"]) => {
    this.loading = this.heavyLoading = true;
    return this.controller.onAddShippingPlan(type)
    .then(() => asyncPause(500))
    .then(this.scrollToEnd)
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = false);
  };

  handleRemoveRate = (event: any, rate: ShippingRate, plan?: ShippingPlan) => {
    preventDefaultStopProp(event);
    // for simple and multimodal, assume the only ShippingRate is the current one in the ShippingPlan collection.
    const shippingPlan = plan || this.plans.find(pl => pl.shippingRates.some(r => r.id === rate.id));
    if (!shippingPlan) return;
    const name = shippingPlan.description || shippingPlan.type === "multimodal"
      ? UIText.rateManagement.multimodalShippingRateTable
      : shippingPlan.type === "complex"
        ? UIText.rateManagement.complexShippingRateTable
        : UIText.rateManagement.shippingRate;
    return ui.reconfirm({
      header: `${UIText.generalDelete} ${UIText.rateManagement.shippingRate}`,
      name,
      verb: UIText.generalDelete.toLowerCase(),
      handler: () => this.handleRemoveRateConfirm(event, shippingPlan)
    });
  };

  handleRemoveRateConfirm = async (event: any, shippingPlan: ShippingPlan) => {
    this.loading = this.heavyLoading = true;
    await asyncPause();
    return this.controller.onRemoveShippingPlan(shippingPlan.id)
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = false);
  };



  handleTransitPortModalOpen = (event: any, rateId: number) => this.transitPortModalRateId = rateId;

  handleTransitPortModalClose = event => this.transitPortModalRateId = undefined;

  handleTransitPortAddLeg = (ordinal: number) => {
    this.loading = true;
    return this.controller.onAddRateLeg(this.transitPortModalRateId, ordinal)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleTransitPortRemoveLeg = (legId: number) => {
    this.loading = true;
    return this.controller.onRemoveRateLeg(legId)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleShippingRateLegFieldChange = (event: any, shippingRateLeg: ShippingRateLeg) => {
    preventDefaultStopProp(event);
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    this.controller.onShippingRateLegFieldUpdate(shippingRateLeg, name, value);
    return this.updateTransitPortLegFieldAsync(shippingRateLeg.id, name, value);
  };

  updateTransitPortLegFieldAsync = async (id: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateShippingRateLeg(id, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };



  private _handleCarrierModalOpen = (event: any, planId: number, rateId: number) => {
    this.carrierModalPlanId = planId;
    return this.handleCarrierModalOpen(event, rateId);
  };

  private _handleCarrierModalClose = event => {
    this.carrierModalPlanId = undefined;
    return this.handleCarrierModalClose(event);
  };



  handleToggleAdvancedSearch = () => {
    this.advancedSearch.isEnabled = !this.advancedSearch.isEnabled;
    this.advancedSearch.savedFilters.push(...this.listController.selectedFilters);
    if (this.advancedSearch.isEnabled) {
      this.listController.onFilterClear();
    } else {
      this.listController.setFilters([...this.advancedSearch.savedFilters]);
      (this.advancedSearch.savedFilters as IObservableArray<string>).clear();
    }
  };

  handleAdvancedSearchChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "modes") {
      const { selectedFilters, onFilterClear, setFilters } = this.listController;
      if (isEqual(selectedFilters, value)) return;
      onFilterClear();
      return setFilters(toJS(value.filter(Boolean)));
    }
    this.advancedSearch[name] = value;
  };



  renderHeading = () => <Observer>{() => (
    <div className="flex ion-padding ion-align-items-center font-l textLight">
      <span className="ion-margin-end textBold">{this.title}</span>
      <IonButton onClick={this.handleAddShippingPlan}>
        <IonText className="textBold">
          {UIText.generalNew}&nbsp;{UIText.rateManagement.rate}
        </IonText>
        <IonIcon slot="end" icon={addOutline} />
      </IonButton>
      <HelpTip
        className="ion-margin-horizontal"
        color="light"
        help={{
          header: "Shipping Rate Multimodal",
          message: "Shipping Rate Multimodal Help"
        }}
      />
    </div>
  )}</Observer>;

  render() {
    const {
      groupId,
      modes,
      uocs,
      isSa,
      isShippingChargeSelected,
      checkModeChangeLock,
      doesRateHaveBreakdownType,
      findModesForRate,
      findDisplayUnits,
      findAvailableShippingPorts,
      findAvailableShippingCharges,
      findAvailableCarriers,
      getCurrentCarrierCharges
    } = this.controller;

    const {
      sortBy,
      sortDir,
      searchValue,
      onSearchValueChange,
      onSortByChange,
      onSortDirChange,
      selectedFilters,
      onFilterSelect,
      onFilterClear
    } = this.listController;

    const { matchPol, matchPod } = this.advancedSearch;

    return <IonPage className="headerOffset rateList">
      <IonContent
        fullscreen
        ref={this.setContentRef}
        className="ion-padding"
      >
        <Observer>{() => <IonLoading translucent isOpen={this.heavyLoading} />}</Observer>

        <div className="flex column container">
          <Observer>{() => (
            <div className="flex ion-align-items-center ion-justify-content-between ion-padding-end ion-margin-bottom">
              {this.renderHeading()}
              {!ui.isMobile && <IonButtons>
                <HeaderRefreshButton
                  color="light"
                  spinnerColor="light"
                  fill="clear"
                  // icon={refreshCircleOutline}
                  loading={this.loading}
                  onRefresh={this.onRefresh}
                />
              </IonButtons>}
            </div>
          )}</Observer>

          <Observer>{() => (
            <div className="advancedListControlToolbar container search bar">
              {this.advancedSearch.isEnabled ? (
                <RateListAdvancedSearchBar
                  modes={modes}
                  selectedModes={selectedFilters as ShippingMode["mode"][]}
                  matchPol={matchPol}
                  matchPod={matchPod}
                  onFieldChange={this.handleAdvancedSearchChange}
                />
              ) : (
                <ListControlToolbar
                  searchValue={searchValue}
                  searchBarPlaceholder={
                    this.searchHasFocus
                      ? UIText.rateManagement.searchHelp
                      : UIText.searchStuff(UIText.rateManagement.rateTables)
                  }
                  onSearchChange={(e: any) => onSearchValueChange(getEventRealValue(e))}
                  onIonFocus={() => this.searchHasFocus = true}
                  onIonBlur={() => this.searchHasFocus = false}
                />
              )}
              <IonText
                className={`font-xxs relative ion-activatable ${this.advancedSearch.isEnabled ? "textBold" : ""}`}
                color="light"
                onClick={this.handleToggleAdvancedSearch}
              >
                <IonIcon icon={this.advancedSearch.isEnabled ? checkbox : squareOutline} />&nbsp;
                <span className="textUnderline">{UIText.rateManagement.useAdvancedSearch}</span>
              </IonText>
            </div>
          )}</Observer>

          <Observer>{() => (
            <ListControlFilterBar
              className="ion-margin"
              sorts={this.sortProperties}
              sortDir={sortDir}
              sortBy={sortBy}
              onSortByChange={onSortByChange}
              onSortDirChange={onSortDirChange}
              filters={this.filterPropertiesForFilterBar}
              count={this.sortedPlans.length}
              selectedFilters={selectedFilters}
              onFilterSelect={onFilterSelect}
              onFilterClear={onFilterClear}
            />
          )}</Observer>

          <ObserverList
            list={this.sortedPlans}
            render={plan => <Observer key={plan.id}>{() => {
              const rate: ShippingRate = !isEmpty(plan.shippingRates) && plan.shippingRates[0];
              return plan.type !== "complex" ? (
                <RateCard
                  className={ui.isMobile ? "ion-no-margin ion-margin-vertical" : "ion-margin"}
                  plan={plan}
                  modes={findModesForRate(plan)}
                  uocs={uocs}
                  isSa={isSa}
                  checkModeChangeLock={checkModeChangeLock}
                  findDisplayUnits={findDisplayUnits}
                  doesRateHaveBreakdownType={doesRateHaveBreakdownType}
                  handleRemove={e => this.handleRemoveRate(e, rate, plan)}
                  handleDetailFieldChange={e => this.handleRateFieldChange(e, rate)}
                  handleDescriptionChange={e => this.handlePlanFieldChange(e, plan)}
                  handleAddRateUnit={(e, breakdownId) => this.handleAddRateUnit(e, rate, breakdownId)}
                  handleRemoveRateUnit={(e, rateUnitId) => this.handleRemoveRateUnit(e, rateUnitId)}
                  handleRateUnitFieldChange={(e, rateUnit) => this.handleRateUnitFieldChange(e, rateUnit)}
                  handleChargeItemFieldChange={(e, rateChargeItem) => this.handleRateChargeItemFieldChange(e, rateChargeItem)}
                  handlePortModalOpen={(e, property) => this.handlePortModalOpen(e, rate.id, property)}
                  handleTransitPortModalOpen={e => this.handleTransitPortModalOpen(e, rate.id)}
                  handleChargeModalOpen={e => this.handleChargeMenu(e, rate.id)}
                  handleCarrierModalOpen={e => this._handleCarrierModalOpen(e, plan.id, rate.id)}
                />
              ) : (
                <ComplexRateEntryCard
                  className={ui.isMobile ? "ion-no-margin ion-margin-vertical" : "ion-margin"}
                  plan={plan}
                  isSa={isSa}
                  getEntryLink={() => `/Group/${groupId}/Rates/${plan.id}`}
                  onLoading={this.onLoading}
                  history={this.props.history}
                  handlePlanFieldChange={e => this.handlePlanFieldChange(e, plan)}
                  handleRemove={e => this.handleRemoveRate(e, null, plan)}
                />
              )
            }}</Observer>}
          />
        </div>

        <Observer>{() => (
          <RatePortSelectionModal
            modes={modes}
            portProperty={this.portModalPropertyName}
            ports={findAvailableShippingPorts(this.portModalRate)}
            rate={this.portModalRate}
            loading={this.loading}
            isOpen={this.portModalOpen}
            onDismiss={this.handlePortModalClose}
            onPortSelect={this.handlePortModalSelect}
            onModeFieldChange={!checkModeChangeLock(this.portModalRate) && (e => this.handleRateFieldChange(e, this.portModalRate))}
          />
        )}</Observer>

        <Observer>{() => (
          <RateTransitPortModal
            modes={modes.filter(m => m.mode !== "multimodal")}
            findAvailableShippingPorts={findAvailableShippingPorts}
            rate={this.transitPortModalRate}
            loading={this.loading}
            isOpen={this.transitPortModalOpen}
            onDismiss={this.handleTransitPortModalClose}
            onAddLeg={this.handleTransitPortAddLeg}
            onRemoveLeg={this.handleTransitPortRemoveLeg}
            onLegFieldChange={this.handleShippingRateLegFieldChange}
            updateLegFieldAsync={this.updateTransitPortLegFieldAsync}
          />
        )}</Observer>

        <Observer>{() => (
          <RateChargeSelectionModal
            rate={this.chargeModalRate}
            carrierName={getDisplayNameEng(this.chargeModalCarrier.profile)}
            shippingCharges={findAvailableShippingCharges(this.chargeModalRate)}
            isOpen={this.chargeModalOpen}
            isAllChargesSelected={this.chargeModalAllChargesSelected}
            isShippingChargeSelected={isShippingChargeSelected}
            loading={this.loading}
            onDismiss={this.handleChargeModalClose}
            onCheckboxSelectAll={this.handleChargeModalCheckboxSelectAll}
            onCheckboxSelect={this.handleChargeModalCheckboxSelect}
            onChargeCustomizeModalOpen={this.handleChargeCustomizeModalOpen}
          />
        )}</Observer>

        <Observer>{() => (
          <RateCarrierSelectionModal
            carriers={findAvailableCarriers(this.carrierModalPlan, this.carrierModalRate)}
            modes={modes}
            rate={this.carrierModalRate}
            loading={this.loading}
            isOpen={this.carrierModalOpen}
            onDismiss={this._handleCarrierModalClose}
            onCarrierSelect={this.handleCarrierModalSelect}
          />
        )}</Observer>

        <Observer>{() => (
          <CarrierChargeCustomizeModal
            shippingCharges={findAvailableShippingCharges(this.chargeCustomizeModalRate)}
            carrier={this.chargeCustomizeModalCarrier}
            rate={this.chargeCustomizeModalRate}
            loading={this.loading}
            isOpen={this.chargeCustomizeModalOpen}
            onDismiss={this.handleChargeCustomizeModalClose}
            onRefresh={getCurrentCarrierCharges}
            onCreateCarrierOverride={this.handleCreateCarrierChargeOverride}
            onRemoveCarrierOverride={this.handleRemoveCarrierChargeOverride}
            onFieldChange={this.handleCarrierChargeFieldChange}
            onAddCustomCharge={this.handleCreateCarrierCustomCharge}
            onDuplicateCustomCharge={this.handleDuplicateCarrierCustomCharge}
          />
        )}</Observer>
      </IonContent>
    </IonPage>
  }
}

export default withIonLifeCycle(RateList);
