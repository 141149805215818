import { IonSearchbar } from "@ionic/react";
import React from "react";
import "./styles.less";
import { PredefinedColors } from "@ionic/core";
import { filterOutline } from "ionicons/icons";
import { whenFulfill } from "../../utils/helpers";

export interface ListControlToolbarProps extends React.ComponentProps<typeof IonSearchbar> {
  color?: PredefinedColors;
  searchValue?: string;
  searchBarPlaceholder?: string;
  onSearchChange: (event: CustomEvent) => void;
  onSearchIconClick?: (event: any) => void;
}

class ListControlToolbar extends React.Component<ListControlToolbarProps> {
  searchBarElm: HTMLIonSearchbarElement;
  searchBarIconElm: HTMLIonIconElement;

  constructor(props) {
    super(props);
    const getSearchBarIconElm = (): HTMLIonIconElement =>
      this.searchBarElm && this.searchBarElm.getElementsByClassName("searchbar-search-icon")[0] as HTMLIonIconElement;
    whenFulfill(() => !!this.searchBarElm)
    .then(() => whenFulfill(() => !!getSearchBarIconElm()))
    .then(() => this.searchBarIconElm = getSearchBarIconElm())
    .then(() => this.searchBarIconElm.onclick = this.handleSearchIconClick);
  }

  searchBarRef = elm => this.searchBarElm = elm;

  handleSearchIconClick = (event: any) => this.props.onSearchIconClick && this.props.onSearchIconClick(event);

  render() {
    const {
      className,
      color,
      searchValue,
      searchBarPlaceholder,
      onSearchChange,
      onIonChange,
      onIonFocus,
      onIonBlur,
      onSearchIconClick
    } = this.props;
    return <div className={`flex search listControlToolbar ${className || ""}`}>
      <IonSearchbar
        ref={this.searchBarRef}
        className="bar ion-no-padding"
        debounce={100}
        color={color}
        value={searchValue}
        searchIcon={onSearchIconClick && filterOutline}
        onIonChange={onSearchChange || onIonChange}
        onIonFocus={onIonFocus}
        onIonBlur={onIonBlur}
        placeholder={searchBarPlaceholder}
      />
    </div>;
  }
}

export default ListControlToolbar;