import { observer, Observer } from "mobx-react";
import React from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { UIText } from "../../../../client/lang";
import { closeSharp } from "ionicons/icons";
import ListControlToolbar from "../../../../components/ListControlToolbar";
import { getEventRealValue, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import { Carrier, CarrierProfile, ShippingMode, ShippingRate } from "../../lib/types/rateTypes";
import "./styles.less";
import { ListController } from "../../../../lib/list-controller";
import ListControlFilterBar from "../../../../components/ListControlFilterBar";
import { carrierSortProperties } from "../../config/constants";
import { ObserverList } from "../../../../components/ObserverList";
import { getCarrierModes, getCarrierTypePlaceholder, sortByProperty } from "../../lib/common";
import { computed } from "mobx";
import { matchCarrier } from "../../lib/matchers";
import { ui } from "../../../../client/ui";

export interface RateCarrierSelectionModalProps {
  carriers: Carrier[];
  modes: ShippingMode[];
  rate: ShippingRate;
  loading: boolean;
  isOpen: boolean;
  onDismiss: (event: any) => void;
  onCarrierSelect: (carrierId: number) => void;
}

@observer
class RateCarrierSelectionModal extends React.Component<RateCarrierSelectionModalProps> {
  listController: ListController<CarrierProfile>;

  @computed get selectedCarrier(): Carrier {
    return this.props.carriers.find(carrier => (
      this.props.rate && this.props.rate.carrierId === carrier.id
    )) || {} as Carrier;
  };
  @computed get searchedCarriers(): Carrier[] {
    return this.props.carriers.filter(carrier =>
      matchCarrier(carrier, this.listController.searchValue) &&
      this.selectedCarrier.id !== carrier.id
    );
  };
  @computed get sortedCarriers(): Carrier[] {
    return [...this.searchedCarriers].sort(
      (a, b) => sortByProperty(
        a.profile.data,
        b.profile.data,
        this.listController.sortBy,
        this.listController.sortDir
      )
    );
  };

  get sortProperties() {
    return carrierSortProperties.map(sort => ({
      name: sort,
      placeholder: UIText.carrierSortProperties[sort]
    }))
  }

  constructor(props) {
    super(props);
    this.listController = new ListController({
      storeName: "rateCarrierSelectionModalFilterSort",
      initialSortBy: "displayName"
    });
  }

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  handleCarrierSelect = (event: any, carrierId: number) => {
    preventDefaultStopProp(event);
    this.props.onCarrierSelect(carrierId);
    return this.props.onDismiss(event);
  };

  renderCarrierCard = (carrier: Carrier) => {
    const {
      modes,
      rate
    } = this.props;

    const profileData = (carrier.profile || {}).data;
    if (isEmpty(profileData)) return null;

    return <Observer key={carrier.id}>{() => <IonCard className="carrierCard card ion-margin-vertical">
      <IonCardHeader className="flex ion-align-items-center ion-justify-content-between">
        <IonCardTitle color="primary" className="textPrimary textBold font-m" style={{ flex: 1 }}>
          {profileData.displayName}
        </IonCardTitle>
        {rate.carrierId === carrier.id ? (
          <IonText
            color="primary"
            className="deSelectText textBold font-s textUpperCase ion-padding-vertical ion-activatable"
            onClick={e => this.handleCarrierSelect(e, 0)}
          >
            {UIText.generalSelected}&nbsp;<IonIcon icon={closeSharp} />
          </IonText>
        ) : (
          <IonButton onClick={e => this.handleCarrierSelect(e, carrier.id)}>
            {UIText.generalSelect}
          </IonButton>
        )}
      </IonCardHeader>
      <IonCardContent className="formView ion-no-padding">
        <div className="flex ion-wrap">
          <div className={`flex subWrap ${ui.isMobile ? "ion-wrap" : ""}`}>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.carrierSortProperties["id"]}
              </IonLabel>
              <IonInput
                className="textBold"
                readonly
                name="id"
                placeholder={UIText.carrierSortProperties["id"]}
                value={carrier.id}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.carrierSortProperties.abbreviation}
              </IonLabel>
              <IonInput
                className="textBold"
                readonly
                name="abbreviation"
                placeholder={UIText.carrierSortProperties.abbreviation}
                value={profileData.abbreviation || "—"}
              />
            </IonItem>
          </div>

          <div className={`flex subWrap ${ui.isMobile ? "ion-wrap" : ""}`}>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.carrierSortProperties.tradeName}
              </IonLabel>
              <IonInput
                readonly
                name="tradeName"
                placeholder={UIText.carrierSortProperties.tradeName}
                value={profileData.tradeName || "—"}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.carrierSortProperties.country}
              </IonLabel>
              <IonInput
                readonly
                name="country"
                placeholder={UIText.carrierSortProperties.country}
                value={profileData.country || "—"}
              />
            </IonItem>
          </div>

          <div className={`flex subWrap ${ui.isMobile ? "ion-wrap" : ""}`}>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.rateManagement.modes}
              </IonLabel>
              <IonInput
                readonly
                name="modes"
                placeholder={UIText.rateManagement.modes}
                value={getCarrierModes(modes, carrier) || "—"}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.carrierSortProperties.type}
              </IonLabel>
              <IonInput
                readonly
                name="type"
                placeholder={UIText.carrierSortProperties.type}
                value={getCarrierTypePlaceholder(carrier) || "—"}
              />
            </IonItem>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
    }</Observer>;
  };

  render() {
    const {
      rate,
      isOpen,
      loading,
      onDismiss
    } = this.props;

    const {
      sortBy,
      sortDir,
      searchValue,
      onSearchValueChange,
      onSortByChange,
      onSortDirChange
    } = this.listController;

    return <IonModal
      key={`rateCarrierSelectionModal ${rate.id}`}
      cssClass="modal rateCarrierSelectionModal large"
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{UIText.rateManagement.selectACarrier}</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={closeSharp} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" className="ion-padding-horizontal">
            {loading && <IonSpinner name="crescent" />}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <ListControlToolbar
          searchValue={searchValue}
          onSearchChange={(e: any) => onSearchValueChange(getEventRealValue(e))}
          searchBarPlaceholder={UIText.searchStuff(UIText.rateManagement.systemCarriers)}
        />

        <ListControlFilterBar
          className="ion-margin-vertical"
          sorts={this.sortProperties}
          sortDir={sortDir}
          sortBy={sortBy}
          count={this.sortedCarriers.length}
          onSortByChange={onSortByChange}
          onSortDirChange={onSortDirChange}
        />

        {!isEmpty(this.selectedCarrier) && !searchValue && this.renderCarrierCard(this.selectedCarrier)}
        <ObserverList
          list={this.sortedCarriers}
          render={this.renderCarrierCard}
        />
      </IonContent>
    </IonModal>
  }
}

export default RateCarrierSelectionModal;