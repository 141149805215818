import React from "react";
import { observer } from "mobx-react";
import "./styles.less";
import { IonButton, IonButtons, IonText } from "@ionic/react";
import { Breadcrumbs, Tooltip } from "@material-ui/core";
import { ShippingRate, ShippingRateLeg } from "../../lib/types/rateTypes";
import { UIText } from "../../../../client/lang";
import { getPortDisplayCode, getPortName } from "../../lib/common";
import MdIcon from "../../../../components/MdIcon";
import { mdiRayStartArrow, mdiRayStartEnd } from "@mdi/js";
import { isEmpty } from "../../../../utils/helpers";
import { toJS } from "mobx";

export interface RateTransitPortsIndicatorProps extends React.HTMLProps<HTMLDivElement> {
  legs: ShippingRateLeg[] | ShippingRate[];
  showTransshipment?: boolean;
  onLegClick?: (event: any, leg: ShippingRateLeg | ShippingRate) => void;
}

const RateTransitPortsIndicator: React.FC<RateTransitPortsIndicatorProps> = observer(({ className, legs, showTransshipment, onLegClick }) => (
  <div className={`flex ion-align-items-center ion-wrap rateTransitPortsIndicator ${className || ""}`}>
    <Breadcrumbs separator={null} maxItems={999} >
      {(legs as ShippingRate[])
      .filter(leg => !!leg.polPort)
      .map((leg, i) => (
        <IonButtons key={leg.id}>
          <Tooltip
            arrow
            enterDelay={isEmpty(leg.polPort) ? Number.MAX_SAFE_INTEGER : undefined}
            placement="bottom"
            title={getPortName(leg.polPort)}
          >
            <IonButton
              className={`flex ion-no-margin ion-align-items-center textNoTransform`}
              onClick={e => onLegClick && onLegClick(e, leg)}
            >
              <IonText className="textBold font-xs" color="primary">
                {getPortDisplayCode(leg.polPort)}
              </IonText>
            </IonButton>
          </Tooltip>
          <IonText className="flex column ion-align-items-center transitPortModeIndicator" color="tooling">
            <span>{leg.shippingMode && leg.shippingMode.localeMode[UIText.preference]}</span>
            <MdIcon
              icon={mdiRayStartArrow}
              color="tooling"
            />
          </IonText>
          {showTransshipment && <>
            <Tooltip
              arrow
              enterDelay={isEmpty(leg.podPort) ? Number.MAX_SAFE_INTEGER : undefined}
              placement="bottom"
              title={getPortName(leg.podPort)}
            >
              <IonButton
                className={`flex ion-no-margin ion-align-items-center textNoTransform`}
                onClick={e => onLegClick && onLegClick(e, leg)}
              >
                <IonText className="textBold font-xs" color="primary">
                  {getPortDisplayCode(leg.podPort) || "—"}
                </IonText>
              </IonButton>
            </Tooltip>
            {toJS(legs)[i + 1] && <>
              <IonText className="flex column ion-align-items-center transitPortModeIndicator" color="tooling">
                <MdIcon
                  className="transshipment"
                  icon={mdiRayStartEnd}
                  color="tooling"
                />
              </IonText>
            </>}
          </>}
        </IonButtons>
      ))}
      {!showTransshipment && (
        <IonButtons>
          <Tooltip
            arrow
            enterDelay={isEmpty((legs[legs.length - 1] || {}).podPort) ? Number.MAX_SAFE_INTEGER : undefined}
            placement="bottom"
            title={getPortName((legs[legs.length - 1] || {}).podPort)}
          >
            <IonButton className="ion-no-margin textBold font-xs noEvents" color="primary">
              {getPortDisplayCode((legs[legs.length - 1] || {}).podPort)}
            </IonButton>
          </Tooltip>
        </IonButtons>
      )}
    </Breadcrumbs>
  </div>
));

export default RateTransitPortsIndicator;