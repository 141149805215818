import { IonButton, IonCard, IonCardContent, IonContent, IonLoading, IonPage, IonText, withIonLifeCycle } from "@ionic/react";
import { computed, observable, toJS, when } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import "./styles.less";
import { StdErr } from "../../../../lib/types/miscTypes";
import { ui } from "../../../../client/ui";
import { UIText } from "../../../../client/lang";
import { RfqSubmissionController } from "./controller";
import { isEmpty, isEqual, preventDefaultStopProp, safeParseJSON, whenFulfill } from "../../../../utils/helpers";
import { RouteComponentProps } from "react-router";
import RfqForm from "../../components/RfqForm";
import { RfqFormDTO } from "../../lib/types/rfqFormTypes";
import CardedContainerHeader from "../../../../components/CardedContainerHeader";
// import { prefillRfqFormDTO } from "../../config/rfqFormDTO";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import { stateCtrl } from "../../../../client/state";
import { groupTypeIds, topicTypeIds } from "../../../../config/constants";
import { Group } from "../../../../lib/types/dataTypes";
import { defaultAuthRoute, defaultRoute } from "../../../../config/routes";
import { ScrollDetail } from "@ionic/core";

interface RfqSubmissionProps extends RouteComponentProps<GroupSubRouteParams<{
  id: string;
}>> {}

@observer
class RfqSubmission extends React.Component<RfqSubmissionProps> {
  url: string;
  controller: RfqSubmissionController = {} as RfqSubmissionController;

  @observable id: number;
  @observable loading: boolean = false;
  @observable submitting: boolean = false;
  @observable readonly: boolean;
  @observable prefilled: RfqFormDTO;
  @observable submitted: boolean = false;
  @observable scrollTop: number = 0;

  @computed get title(): string {
    if (this.loading) return UIText.submittedRFQ;
    return this.readonly ? UIText.submittedRFQ : UIText.newRFQ;
  };
  @computed get group(): Group {
    return this.controller.group;
  };
  @computed get isDraftTopic(): boolean {
    const { rfqTopic } = this.controller;
    return rfqTopic && rfqTopic.typeId === topicTypeIds.rfqDraft;
  };

  rfqForm: RfqForm;
  rfqFormRef = ref => this.rfqForm = ref;

  @computed get rfq(): RfqFormDTO {
    return (this.rfqForm && this.rfqForm.data) || {} as RfqFormDTO;
  };

  constructor(props) {
    super(props);
    this.controller = new RfqSubmissionController();
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    const { id, groupId } = this.props.match.params;
    if (this.controller.isLoggedIn && isNaN(Number(groupId))) {
      return this.props.history.replace(
        `/Group/${this.controller.myShipperGroup.id}/MyRFQ/New`
      );
    }
    if ((this.controller.party === "lsp" && this.url.match(/MyRFQ/ig)) ||
      (this.controller.party === "shipper" && this.url.match(/\/RFQ/ig))) {
      return this.props.history.replace(defaultRoute.path);
    }
    if (id) {
      this.id = Number(id);
      return this.onRefresh();
    }
  };

  ionViewDidEnter = () => {
    if (isEmpty(this.prefilled) && this.controller.preLoginRfqForm) {
      this.prefilled = toJS(this.controller.preLoginRfqForm);
      this.controller.clearPreLoginRfqForm();
      if (this.loading) this.loading = false;
    }
  };

  ionViewWillLeave = () => this.reset();

  onRefresh = async () => {
    this.loading = true;
    return this.controller.getRfqForm(this.id)
    .then((data: RfqFormDTO) => {
      if (this.isDraftTopic) {
        this.prefilled = data;
        // TODO: Eventually add editing capability to rfq drafts.
        return this.props.history.replace(`/Group/${this.group.id}/MyRFQ/new`)
      }
      this.readonly = true;
      return this.prefilled = data;
    })
    .finally(() => this.loading = false);
  };

  reset = () => {
    this.prefilled = undefined;
    this.readonly = undefined;
    this.submitted = false;
    this.rfqForm && setTimeout(this.rfqForm.reset);
  };

  handleBack = (event?: any) => {
    preventDefaultStopProp(event);
    const back = () => {
      if (this.isDraftTopic) return this.props.history.push(`/Group/${this.group.id}/MyRFQ`);
      const urlFrags = this.url.split("/");
      urlFrags.pop();
      this.props.history.push(urlFrags.join("/"));
    };
    if (!this.controller.isLoggedIn) return this.props.history.push(defaultAuthRoute.path);
    if (!this.readonly && !isEqual(this.rfq, {...new RfqFormDTO()})) {
      return ui.alert({
        header: this.submitted ? UIText.rfqForm.submitted : UIText.generalWarning,
        message: this.submitted ? UIText.rfqForm.resubmitMsg : UIText.rfqForm.unsavedChangesBack,
        backdropDismiss: true,
        buttons: [
          {
            role: "cancel",
            text: this.submitted ? UIText.generalYes : UIText.generalCancel,
            handler: this.submitted && (() => this.submitted = false)
          },
          {
            text: this.submitted ? UIText.generalNo : UIText.generalDiscard,
            cssClass: "textDanger",
            handler: back
          },
        ]
      });
    }
    return back();
  };

  // _prefillData = () => this.prefilled = prefillRfqFormDTO as RfqFormDTO;

  // _reset = (event: any) => window.location.reload();

  // onRfqChange = (rfq: RfqFormDTO) => {
  //   console.log(rfq);
  //   this.rfq = rfq;
  // };

  onRfqSubmit = async (event: any) => {
    preventDefaultStopProp(event);
    // console.log(this.rfq);
    if (!this.rfqForm.validate()) {
      const getErrorFieldNames = () => Array.from(new Set(Array.from(
        document.querySelectorAll(".sc-ion-label-md-h.ion-color-danger")
      )
      .map(n => n.textContent && n.textContent.replace(/\*(.*)/gi, ""))))
      .filter(Boolean);
      await whenFulfill(() => !isEmpty(getErrorFieldNames()));
      const errorFieldNames = getErrorFieldNames();
      return ui.alert({
        cssClass: "alertUnlimitedHeight",
        header: UIText.rfqForm.fieldsAttention,
        message: UIText.rfqForm.fieldsRequireAttention(errorFieldNames),
        buttons: [UIText.generalConfirm]
      });
    }
    this.submitting = true;
    return this.controller.onSubmit(this.rfq)
    .then(() => this.submitted = true)
    .then(this.handleBack)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  onRfqSubmitLogin = (event: any) => {
    preventDefaultStopProp(event);
    this.controller.setPreLoginForm(this.rfq);
    stateCtrl.registrationIntentLock = [groupTypeIds.shipper];
    stateCtrl.additionalRegistrationData = {
      rfqFormDTOJson: JSON.stringify(this.rfq)
    };
    this.setAfterLoginRfqFormRedirect();
    return this.props.history.push("/Login");
  };

  onRfqClone = (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    this.controller.setPreLoginForm({
      ...this.rfq,
      [`_${this.rfq.mode}_items`]: safeParseJSON(this.rfq.items)
    });
    this.reset();
    const shipperGroup = this.controller.myShipperGroup;
    return this.props.history.push(`/Group/${shipperGroup.id}/MyRFQ/new`);
  };

  setAfterLoginRfqFormRedirect = () => {
    const redirectAfterLoginDispose = when(
      () => this.controller.isLoggedIn,
      () => {
        stateCtrl.registrationIntentLock = undefined;
        stateCtrl.additionalRegistrationData = {};
        const shipperGroup = this.controller.myShipperGroup;
        if (isEmpty(shipperGroup)) return console.log("setAfterLoginRfqFormRedirect failed");
        this.props.history.replace(`/Group/${shipperGroup.id}/MyRFQ/new`);
      }
    );
    when(() => stateCtrl.registrationLock, redirectAfterLoginDispose);
  };

  adjustHeader = (event: CustomEvent<ScrollDetail>) => {
    const { scrollTop } = event.detail;
    return this.scrollTop = scrollTop;
  };

  render() {
    return (
      <IonPage className="headerOffset">
        <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
          <IonLoading translucent isOpen={this.loading || this.submitting} />

          <IonCard className="flex max column container cardedContainer">
            <CardedContainerHeader
              title={this.title}
              titleRight={!this.loading && (
                this.controller.isLoggedIn
                  ? this.readonly
                  ? <IonButton onClick={this.onRfqClone}>
                    {UIText.rfqForm.createSimilar}
                  </IonButton>
                  : <IonButton onClick={this.onRfqSubmit}>
                    {UIText.generalSubmit}
                  </IonButton>
                  : <IonButton onClick={this.onRfqSubmitLogin}>
                    {UIText.rfqForm.signUpAndSubmit}
                  </IonButton>
              )}
              loading={this.loading}
              backHandler={this.handleBack}
              contentScrollTop={this.scrollTop}
            >
              {!this.controller.isLoggedIn && (
                <IonText className="font-s ion-padding-top ion-padding-horizontal">
                  {UIText.rfqForm.demoGreeting}
                </IonText>
              )}
            </CardedContainerHeader>

            <IonCardContent className="flex max column ion-no-padding">
              <RfqForm
                ref={this.rfqFormRef}
                data={!this.loading && this.prefilled}
                readonly={this.readonly}
                onIonScroll={this.adjustHeader}
                onRefresh={this.readonly && this.id && this.onRefresh}
              />
            </IonCardContent>
          </IonCard>
        </IonContent>

      </IonPage>
    );
  }
}

export default withIonLifeCycle(RfqSubmission);
