import { IonButton, IonButtons, IonCard, IonContent, IonIcon, IonInput, IonPage, withIonLifeCycle } from "@ionic/react";
import { computed, observable } from "mobx";
import { observer, Observer } from "mobx-react";
import React from "react";
import "./styles.less";
import { StdErr } from "../../../../lib/types/miscTypes";
import { ui } from "../../../../client/ui";
import { RouteComponentProps } from "react-router";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import CardedContainerHeader from "../../../../components/CardedContainerHeader";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { UIText } from "../../../../client/lang";
import { ShippingUoc } from "../../lib/types/rateTypes";
import { addOutline, closeOutline } from "ionicons/icons";
import { asyncPause, getEventRealValue, preventDefaultStopProp, tableViewScrollToBottom } from "../../../../utils/helpers";
import { AutoObserverList } from "../../../../components/ObserverList";
import { ShippingUocListController } from "./controller";
import { sortByProperty } from "../../lib/common";
import SortableTableHead from "../../../../components/SortableTableHead";
import { ListController } from "../../../../lib/list-controller";
import Picker from "../../../../components/FormFields/Picker";

export interface ShippingUocListProps extends RouteComponentProps<GroupSubRouteParams<{}>> {}

@observer
class ShippingUocList extends React.Component<ShippingUocListProps> {
  url: string;
  title = UIText.rateManagement.systemShippingUocsLong;

  controller: ShippingUocListController = {} as ShippingUocListController;
  listController: ListController<ShippingUoc>;

  @observable loading: boolean = false;

  @computed get shippingUocs(): ShippingUoc[] {
    return this.controller.shippingUocs;
  };
  @computed get sortedUocs(): ShippingUoc[] {
    return [...this.shippingUocs].sort(
      (a, b) => sortByProperty(a, b, this.listController.sortBy, this.listController.sortDir)
    );
  };

  constructor(props) {
    super(props);
    this.url = props.match.url;
    this.controller = new ShippingUocListController();
    this.listController = new ListController({
      storeName: "ShippingUocListFilterSort",
      initialSortBy: "id"
    });
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    if (!this.controller.isSa) return this.props.history.replace("/");
    return this.onRefresh();
  };

  onRefresh = (event?: any) => {
    this.loading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleFieldChange = (event: any) => {
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const id = name.split(".")[0];
    const field = name.split(".")[1];
    const changed = this.controller.onFieldChange(id, field, value);
    return !!changed && setTimeout(() => this.updateFieldAsync(id, field, value));
  };

  updateFieldAsync = async (id: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateField(id, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleAdd = (event: any) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onAddShippingUoc()
    .then(() => asyncPause(500))
    .then(tableViewScrollToBottom)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRemove = (event: any, uoc: ShippingUoc) => ui.reconfirm({
    header: `${UIText.generalDelete} Shipping UOC`,
    name: `the System Shipping UOC ID ${uoc.id}${
      uoc.localeUoc[UIText.preference] ? ` "${uoc.localeUoc[UIText.preference]}"` : ""
    }`,
    verb: UIText.generalDelete.toLowerCase(),
    handler: () => this.handleRemoveConfirm(event, uoc.id)
  });

  handleRemoveConfirm = (event: any, id: number) => {
    if (!id) return;
    this.loading = true;
    return this.controller.onRemoveShippingUoc(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  render() {
    const { sortBy, sortDir } = this.listController;

    const { shippingUoms } = this.controller;

    return (
      <IonPage className="headerOffset shippingUocList">
        <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
          <IonCard className="flex max column container inboxContainer cardedContainer">
            <Observer>{() => (
              <CardedContainerHeader
                title={this.title}
                titleRight={
                  <IonButtons>
                    <IonButton color="primary" className="textNoTransform" onClick={this.handleAdd}>
                      <IonIcon slot="icon-only" icon={addOutline}/>
                    </IonButton>
                  </IonButtons>
                }
                backHandler={this.props.history.goBack}
                loading={this.loading}
                onRefresh={this.onRefresh}
              />
            )}</Observer>
            <IonContent className="max fullBackground ion-no-padding" scrollY={false}>
              <TableContainer component={props => <Paper {...props} square />} elevation={0} className="max">
                <Table size="small" stickyHeader>
                  <Observer>{() => (
                    <SortableTableHead
                      columns={[
                        { name: "id", text: "ID *" },
                        { name: "localeUoc", text: `Locale UOC (${UIText.preference})` },
                        { name: "description", text: `Description (${UIText.preference})` },
                        { name: "perUomId", text: `Link to UOM`, noSort: true },
                        { name: "perUomRatio", text: `UOM 1:UOC` },
                        { name: "delete", text: UIText.generalDelete, noSort: true }
                      ]}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      onSort={this.handleColumnSort}
                    />
                  )}</Observer>
                  <Observer>{() => (
                    <TableBody>
                      <AutoObserverList
                        list={this.sortedUocs}
                        getItemKey={uoc => uoc.id}
                        render={(uoc: ShippingUoc) => {
                          return <TableRow>
                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                {uoc.id}
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${uoc.id}.localeUoc`}
                                  placeholder="Name of the UOC"
                                  value={uoc.localeUoc[UIText.preference] || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${uoc.id}.description`}
                                  placeholder="Description of the UOC"
                                  value={uoc.description[UIText.preference] || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <Picker
                                  className="ion-no-padding"
                                  name={`${uoc.id}.perUomId`}
                                  options={shippingUoms.map(uom => ({
                                    name: uom.id.toString(), placeholder: uom.localeUom[UIText.preference]
                                  }))}
                                  value={(uoc.perUomId || 0).toString()}
                                  onIonChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${uoc.id}.perUomRatio`}
                                  placeholder="UOM 1:UOC"
                                  value={uoc.perUomRatio || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="center" padding="none">
                                <IonButtons className="flex ion-align-items-center ion-justify-content-center">
                                  <IonButton color="danger" onClick={e => this.handleRemove(e, uoc)}>
                                    <IonIcon slot="icon-only" icon={closeOutline}/>
                                  </IonButton>
                                </IonButtons>
                              </TableCell>
                            )}</Observer>
                          </TableRow>
                        }}
                      />
                    </TableBody>
                  )}</Observer>
                </Table>
              </TableContainer>
            </IonContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(ShippingUocList);
