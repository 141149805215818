import { IonButton, IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonRippleEffect, IonText } from "@ionic/react";
import { chatbubblesSharp, documentTextOutline, starSharp } from "ionicons/icons";
import React, { MouseEvent } from "react";
import { observer } from "mobx-react";
import { ReactComponentLike } from "prop-types";
import { getPriceString } from "../../../../utils/helpers";
import "./styles.less";
import { QuotationService } from "../../lib/types/dataTypes";
import { UIText } from "../../../../client/lang";
import { ui } from "../../../../client/ui";
import { Colors } from "../../../../lib/types/miscTypes";

export interface QuotationServiceCardProps extends React.ComponentProps<typeof IonCard> {
  quote?: QuotationService;
  icon?: string;
  logo?: ReactComponentLike;
  serviceType?: string;
  price?: QuotationService["price"];
  currencySymbol?: string;
  provider?: QuotationService["provider"];
  description?: QuotationService["description"];
  serviceDatetime?: Date | string;
  noStar?: boolean;
  starColor?: Colors;
  starIcon?: string | React.ReactNode;
  handleStarClick?: (event: MouseEvent) => void;
  handleChatClick?: (event: MouseEvent) => void;
}

const QuotationServiceCard: React.FC<QuotationServiceCardProps> = observer(
  ({
     className,
     style,
     quote,
     icon,
     logo,
     serviceType,
     price,
     currencySymbol,
     provider,
     description,
     noStar,
     starColor,
     starIcon,
     handleStarClick,
     handleChatClick,
     onClick,
     children,
     serviceDatetime
   }) => {
    const getPrice = (price: number, currencySymbol?: string): string =>
      getPriceString(price, currencySymbol, true, true);

    quote = quote || {} as QuotationService;

    return <div className={`quotationServiceCard ${className || ""}`} style={style}>
      <IonCard button className="ion-no-margin " onClick={onClick}>
        <IonItem lines="full" className="quotationServiceCardHeader">
          <IonIcon icon={icon || documentTextOutline} />
          {logo && logo}
          <IonLabel className="ion-margin-start quotationServiceCardTitle">
            <span className={`textBold ${ui.isMobile ? "font-xs" : ""}`}>
              {serviceType || quote.serviceType}
            </span>
          </IonLabel>
          {!noStar && (
            <IonButton shape="round" slot="end" fill="clear" onClick={handleStarClick}>
              {(typeof starIcon === "undefined" || typeof starIcon === "string") ? (
                <IonIcon color={starColor || "warning"} slot="icon-only" icon={starIcon || starSharp} />
              ) : starIcon}
            </IonButton>
          )}
        </IonItem>
        <IonCardContent>
          {children || <>
            <div className="flex ion-align-items-center quotationServiceCardText">
              <IonText className="quotationServiceCardDescription">
                <IonText color="primary" className="flex ion-align-items-center">
                  {provider || quote.provider}&nbsp;
                  {handleChatClick && (
                    <span className="relative textUpperCase ion-activatable flex ion-align-items-center chatButton" onClick={handleChatClick}>
                      <IonIcon className="font-m" icon={chatbubblesSharp} />&nbsp;{UIText.chat}
                      <IonRippleEffect />
                    </span>
                  )}
                </IonText>
              </IonText>
              <IonLabel color="tooling" className="ion-margin-start font-s">
                {getPrice(price || quote.price, currencySymbol)}
              </IonLabel>
            </div>
            <div className="flex ion-align-items-center quotationServiceCardText">
              <IonText color="dark" className="quotationServiceCardDescription">
                {description || quote.description || ""}
              </IonText>
            </div>
            <div className="flex ion-align-items-center quotationServiceCardText">
              <IonText color="dark" className="quotationServiceCardDescription">
                {UIText.serviceDatetime}:&nbsp;
                {(serviceDatetime
                  ? typeof serviceDatetime === "string"
                    ? serviceDatetime
                    : new Date(serviceDatetime).toLocaleString()
                  : null) ||
                (quote.serviceDatetime && quote.serviceDatetime.toLocaleString()) || ""}
              </IonText>
            </div>
          </>}
        </IonCardContent>
      </IonCard>
    </div>
  });

export default QuotationServiceCard;