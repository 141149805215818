import { Controller } from "../../../../lib/controller";
import { autorun, computed, IObservableArray, observable, set, when } from "mobx";
import {
  Carrier,
  ShippingCharge,
  ShippingMode,
  ShippingPlan,
  ShippingPlanLeg,
  ShippingPort,
  ShippingRate,
  ShippingRateBreakdown,
  ShippingRateCharge,
  ShippingRateChargeItem,
  ShippingRateLeg,
  ShippingRateUnit,
  ShippingUnit,
  ShippingUoc,
  ShippingUom,
} from "../../lib/types/rateTypes";
import { arrayFlat, isEmpty, isEqual, isNonZeroFalse } from "../../../../utils/helpers";
import { api } from "../../../../client/api";
import { getCarriers, getCharges, getPerUocs, getPerUoms, getPorts, getShippingModes, getUnits } from "../../lib/common";
import { client } from "../../../../client/client";
import { UIException, UIText } from "../../../../client/lang";
import { endpointConfig } from "../../../../config/api";
import { modeContainerDependencyMap } from "../../config/constants";
import { PickerOption } from "../../../../lib/types/formTypes";
import { Group, Member } from "../../../../lib/types/dataTypes";

class RateDataViewController<T = {}> extends Controller<T> {
  @observable groupId: Group["id"];

  @observable modes: IObservableArray<ShippingMode> = [] as IObservableArray<ShippingMode>;
  @observable ports: IObservableArray<ShippingPort> = [] as IObservableArray<ShippingPort>;
  @observable units: IObservableArray<ShippingUnit> = [] as IObservableArray<ShippingUnit>;

  @observable systemCharges: IObservableArray<ShippingCharge> = [] as IObservableArray<ShippingCharge>;
  @observable currentCarrierId: number;
  @observable carrierCharges: { [key: number]: IObservableArray<ShippingCharge> } = {};

  @observable plans: IObservableArray<ShippingPlan> = [] as IObservableArray<ShippingPlan>;
  @observable uoms: IObservableArray<ShippingUom> = [] as IObservableArray<ShippingUom>;
  @observable uocs: IObservableArray<ShippingUoc> = [] as IObservableArray<ShippingUoc>;
  @observable carriers: IObservableArray<Carrier> = [] as IObservableArray<Carrier>;

  @observable pendingAddChargeIds: IObservableArray<number> = [] as IObservableArray<number>;

  @computed get isSa(): boolean {
    return client.user && !!client.user.isSa;
  };
  @computed get group(): Group {
    return client.findGroupById(this.groupId);
  };
  @computed get member(): Member {
    return client.findMyMemberByGroupId(this.group.id);
  };

  @computed get rates(): ShippingRate[] {
    return arrayFlat(this.plans.map(plan => plan.shippingRates));
  };
  @computed get nonBasicCharges(): ShippingCharge[] {
    if (isEmpty(this.modes)) return [];
    const baseModeRegExp = new RegExp(`(${this.modes.map(mode => mode.mode).join("|")})`, "i");
    const systemCharges = this.systemCharges.filter(charge => !charge.baseCode.match(baseModeRegExp));
    const carrierCharges = this.carrierCharges[this.currentCarrierId] || [];
    return [...systemCharges, ...carrierCharges];
  };
  @computed get allRateUnits(): ShippingRateUnit[] {
    return arrayFlat(this.rates.map(r => r.shippingRateUnits));
  };
  @computed get allRateCharges(): ShippingRateCharge[] {
    return arrayFlat(this.rates.map(r => r.shippingRateCharges));
  };
  @computed get allRateChargeItems(): ShippingRateChargeItem[] {
    return arrayFlat([
      ...this.allRateUnits.map(ru => ru.shippingRateChargeItems),
      ...this.rates.map(rate => rate.shippingRateChargeItems)
    ]);
  };

  constructor(storeName: string) {
    super(storeName);
    this.disposers.push(this.autoDependencies);
    this.disposers.push(this.autoGetCarrierCharges);
  }

  setGetPlansMethod(method: (() => Promise<any>)) { this.getPlans = method; }

  async loadAllData(additionalLoads?: Promise<any>[]) {
    if (!this.getPlans) return;
    return Promise.all([
      this.getPlans(),
      this.getModes(),
      this.getPorts(),
      this.getUnits(),
      this.getCharges(),
      this.getPerUoms(),
      this.getPerUocs(),
      this.getCarriers(),
      ...(Array.isArray(additionalLoads) ? additionalLoads : [])
    ].filter(Boolean));
  };

  cleanup() {
    this.modes.clear();
    this.ports.clear();
    this.units.clear();
    this.uoms.clear();
    this.systemCharges.clear();
    this.carriers.clear();
  };

  setGroupId = id => this.groupId = id;

  setCurrentCarrierId = (id: number) => this.currentCarrierId = id;

  getPlans: (() => Promise<ShippingPlan[]>);

  getModes = async () => getShippingModes()
  .then(modes => this.modes = modes)
  .then(() => isEmpty(this.modes) && client.isLoggedIn && Promise.reject(new UIException("SHIPPING_MODES_NOT_AVAILABLE")));

  getPorts = async () => getPorts()
  .then(ports => this.ports = ports);

  getUnits = async () => getUnits()
  .then(units => this.units = units);

  getCharges = async () => getCharges()
  .then(charges => this.systemCharges = charges);

  getPerUocs = async () => getPerUocs()
  .then(uocs => this.uocs = uocs);

  getPerUoms = async () => getPerUoms()
  .then(uoms => this.uoms = uoms);

  getCarriers = async () => getCarriers()
  .then(carriers => this.carriers = carriers);

  getCurrentCarrierCharges = async () => (
    !isNonZeroFalse(this.currentCarrierId) && api.GET(endpointConfig.carrier_shipping_charges(this.groupId, this.currentCarrierId))
    .then(response => this.carrierCharges[this.currentCarrierId] = response.data)
  );

  autoDependencies = autorun(() => {
    for (const rate of this.rates) {
      const dependency = modeContainerDependencyMap[rate.shippingMode.mode];
      if (!dependency || dependency.includes(rate.container)) continue;
      rate.container = modeContainerDependencyMap[rate.shippingMode.mode][0];
    }
  });

  autoGetCarrierCharges = autorun(this.getCurrentCarrierCharges);

  findDisplayUnits = (rate: ShippingRate, shippingRateUnit: ShippingRateUnit): PickerOption[] => {
    if (!rate.shippingMode) return [];
    const mode = this.modes.find(m => m.mode === rate.shippingMode.mode);
    if (!mode) return [];
    if (isEmpty(shippingRateUnit)) return [];
    const shouldShowUnit = (unit: ShippingUnit) => (
      unit.modeIds.includes(mode.id) &&
      unit.breakdownId === (shippingRateUnit.shippingUnit || {}).breakdownId
    );
    return this.units.map(unit => shouldShowUnit(unit) && ({
      name: unit.id.toString(),
      placeholder: (unit.unitName || {})[UIText.preference] || unit.id.toString(),
      disabled: (shippingRateUnit.unitId !== unit.id && rate.shippingRateUnits.some(rateUnit => rateUnit.unitId === unit.id))
    })).filter(Boolean);
  };

  findModesForRate = (plan: ShippingPlan) => {
    const multimodalMode = this.modes.find(mode => mode.mode === "multimodal");
    if (!multimodalMode) return [];
    if (plan.type === "multimodal") return [multimodalMode];
    return this.modes;
  };

  findAvailableShippingPorts = (rate: ShippingRate, findAll?: boolean): ShippingPort[] => {
    if (findAll) return this.ports;
    if (isEmpty(rate)) return [];
    const { modeId } = rate;
    return this.ports.filter(port => port.modeIds.includes(Number(modeId)));
  };

  findAvailableShippingCharges = (rate: ShippingRate): ShippingCharge[] => {
    if (isEmpty(rate)) return [];
    const { modeId } = rate;
    return this.nonBasicCharges.filter(c => c.modeIds.includes(modeId));
  };

  isShippingChargeSelected = (rate: ShippingRate, charge: ShippingCharge): boolean => {
    if (this.pendingAddChargeIds.includes(charge.id)) return true;
    const { shippingRateCharges } = rate;
    return shippingRateCharges.some(src => src.shippingChargeId === charge.id);
  };

  isAllShippingChargesSelected = (rate: ShippingRate): boolean => {
    const nonBasicCharges = this.nonBasicCharges.filter(charge => charge.modeIds.includes(rate.modeId));
    if (!isEmpty(this.pendingAddChargeIds) &&
      this.pendingAddChargeIds.every(id => nonBasicCharges.some(charge => charge.id === id))
    ) return true;
    return nonBasicCharges
    .every(charge => rate.shippingRateCharges.some(src => src.shippingChargeId === charge.id));
  };

  checkModeChangeLock = (rate: ShippingRate): boolean => {
    return (!!rate.polPortId || !!rate.podPortId || !!rate.carrierId || !isEmpty(rate.shippingRateUnits));
  };

  doesRateHaveBreakdownType = (rate: ShippingRate, breakdown: ShippingRateBreakdown) => {
    const { shippingMode } = rate || {};
    if (isEmpty(shippingMode) || isEmpty(breakdown)) return false;
    return this.units.some(unit => unit.modeIds.includes(shippingMode.id) && unit.breakdownId === breakdown.id);
  };


  findAvailableCarriers = (plan: ShippingPlan, rate: ShippingRate): Carrier[] => {
    if (isEmpty(rate) || isEmpty(plan)) return [];
    const { modeId } = rate;
    const mode = this.modes.find(m => m.id === modeId);
    if (!mode) return [];
    // TODO: For simple-multi show all carriers for now.
    if (plan.type === "multimodal") return this.carriers;
    return this.carriers.filter(carrier => ((carrier.profile || {}).data || {}).modes.includes(mode.mode));
  };

  onAddShippingPlan = async (type: ShippingPlan["type"]) => {
    await when(() => !isEmpty(this.modes));
    return api.POST({
      endpoint: endpointConfig.new_shipping_plan,
      data: {
        currentGroupId: this.group.id,
        type
      }
    })
    .then(response => this.plans.push(response.data))
    .then(this.getPlans);
  };

  onRemoveShippingPlan = async (planId: number) => {
    const plan = this.plans.find(pl => pl.id === planId);
    if (!plan) return;
    return api.DELETE(endpointConfig.shipping_plan_by_id(plan.id))
    .then(() => this.plans.remove(plan));
  };

  onPlanFieldChange = (p: ShippingPlan, field: string, value) => {
    const plan = this.plans[this.plans.indexOf(p)];
    if (!plan) return;
    if (isEqual(plan[field], value)) return;
    plan[field] = value;
    return true;
  };

  onUpdatePlan = async (planId: number, field: keyof ShippingPlan, value) => {
    const plan = this.plans.find(pl => pl.id === planId);
    if (!plan) return;
    return api.PATCH({
      endpoint: endpointConfig.shipping_plan_by_id(plan.id),
      data: { [field]: value }
    })
    .then(() => this.refreshShippingPlan(plan.id));
  };

  onUpdatePlanLeg = async (planId: number, rateId: number, field: keyof ShippingPlanLeg, value) => {
    const plan = this.plans.find(plan => plan.id === planId);
    if (!plan) return;
    const shippingRate: ShippingRate = plan.shippingRates.find(rate => rate.id === rateId);
    if (!shippingRate) return;
    const { shippingPlanLeg } = shippingRate;
    if (!shippingPlanLeg) return;
    if (field === "ordinal") {
      value = value ? Number(value) : 0
    }
    return api.PATCH({
      endpoint: endpointConfig.shipping_plan_leg_by_id(shippingPlanLeg.id),
      data: { [field]: value }
    })
    .then(() => this.refreshShippingPlan(plan.id))
  };

  onAddRate = async (planId: number, ordinal: number, refRateId?: number) => {
    await when(() => !isEmpty(this.modes));
    const plan = this.plans.find(plan => plan.id === planId);
    if (!plan) return;
    let rateId;
    return api.POST({
      endpoint: endpointConfig.new_shipping_rate_for_plan(planId),
      data: { ordinal, refRateId }
    })
    .then(response => rateId = (response.data || {}).id)
    .then(() => this.refreshShippingPlan(plan.id))
    .then(() => rateId);
  };

  onRemoveRate = async (rateId: number) => {
    const rate = this.rates.find(r => r.id === rateId);
    if (!rate) return;
    const plans = this.plans.filter(plan => plan.shippingRates.some(rate => rate.id === rateId));
    return Promise.all(plans.map(plan => (
      api.DELETE(endpointConfig.shipping_rate_By_id_for_plan(plan.id, rate.id))
      .then(() => Promise.all(plans.map(plan => this.refreshShippingPlan(plan.id))))
    )));
  };

  onRateFieldChange = (r: ShippingRate, field: string, value) => {
    const rate = this.rates[this.rates.indexOf(r)];
    if (!rate) return;
    if (isEqual(rate[field], value)) return;
    rate[field] = value;
    return true;
  };

  onUpdateRate = async (rateId: number, field: string, value) => {
    const rate = this.rates.find(r => r.id === rateId);
    if (!rate) return;
    const plans = this.plans.filter(plan => plan.shippingRates.some(rate => rate.id === rateId));
    return api.PATCH({
      endpoint: endpointConfig.shipping_rate_by_id(rate.id),
      data: { [field]: value }
    })
    .then(() => Promise.all(plans.map(plan => this.refreshShippingPlan(plan.id))));
  };

  onAddRateUnit = async (rateId: number, breakdownId: number) => {
    const rate = this.rates.find(r => r.id === rateId);
    if (!rate) return;
    const plans = this.plans.filter(plan => plan.shippingRates.some(rate => rate.id === rateId));
    return api.POST({
      endpoint: endpointConfig.new_shipping_rate_unit,
      data: {
        breakdownId,
        shippingRateId: rate.id
      }
    })
    .then(() => Promise.all(plans.map(plan => this.refreshShippingPlan(plan.id))))
    .catch(err => {
      const error = err.response && err.response.data && err.response.data.error;
      if (error && error.unitUnavailable) {
        const { breakdown, mode } = error;
        throw new UIException("SHIPPING_UNIT_NOT_AVAILABLE", null, [
          (breakdown || {}).localeUnitNamePlural[UIText.preference],
          (mode || {}).localeMode[UIText.preference]
        ]);
      }
      throw err;
    });
  };

  onRemoveRateUnit = async (rateUnitId: number) => {
    let rateUnit: ShippingRateUnit;
    const rate = this.rates.find(r => {
      rateUnit = r.shippingRateUnits.find(ru => ru.id === rateUnitId);
      return rateUnit;
    });
    if (!rate || !rateUnit) return;
    const plans = this.plans.filter(plan => plan.shippingRates.some(r => r.id === rate.id));
    (rate.shippingRateUnits as IObservableArray).remove(rateUnit);
    return api.DELETE(endpointConfig.shipping_rate_unit_by_id(rateUnitId))
    .then(() => Promise.all(plans.map(plan => this.refreshShippingPlan(plan.id))));
  };

  onRateUnitFieldUpdate = (ru: ShippingRateUnit, field: keyof ShippingRateUnit, value) => {
    const rateUnit = this.allRateUnits[this.allRateUnits.indexOf(ru)];
    if (!rateUnit) return;
    if (field === "basicAmount" || field === "basicAmountPerUocId") {
      value = Number(value) || 0;
      if (field === "basicAmount") value = value.toFixed(2);
    }
    if (isEqual(rateUnit[field], value)) return;
    (rateUnit[field] as ShippingRateUnit[keyof ShippingRateUnit]) = value;
    return true;
  };

  onUpdateRateUnit = async (rateUnitId: number, field: keyof ShippingRateUnit, value) => {
    let rateUnit: ShippingRateUnit;
    const rate = this.rates.find(r => {
      rateUnit = r.shippingRateUnits.find(ru => ru.id === rateUnitId);
      return rateUnit;
    });
    if (!rate || !rateUnit) return;
    const plans = this.plans.filter(plan => plan.shippingRates.some(r => r.id === rate.id));
    if (field === "basicAmount" || field === "basicAmountPerUocId") value = Number(value) || 0;
    return api.PATCH({
      endpoint: endpointConfig.shipping_rate_unit_by_id(rateUnit.id),
      data: { [field]: value }
    })
    .then(data => field === "basicAmount" && Promise.all(plans.map(plan => this.refreshShippingPlan(plan.id))));
  };

  onAddRateCharge = async (rate: ShippingRate, shippingCharge?: ShippingCharge, skipRefresh?: boolean) => {
    shippingCharge && this.pendingAddChargeIds.push(shippingCharge.id);
    if (rate.shippingRateCharges.some(src => src.shippingChargeId === shippingCharge.id)) return;
    const plans = this.plans.filter(plan => plan.shippingRates.some(r => r.id === rate.id));
    return api.POST({
      endpoint: endpointConfig.new_shipping_rate_charge,
      data: {
        shippingRateId: rate.id,
        shippingChargeId: shippingCharge ? shippingCharge.id : undefined
      }
    })
    .then(data => !skipRefresh && Promise.all(plans.map(plan => this.refreshShippingPlan(plan.id))))
    .finally(!skipRefresh && (() => {
      this.pendingAddChargeIds.remove(shippingCharge.id)
    }));
  };

  onRemoveRateCharge = async (rateChargeId: number, skipRefresh?: boolean) => {
    let rateCharge: ShippingRateCharge;
    const rate = this.rates.find(r => {
      rateCharge = r.shippingRateCharges.find(rc => rc.id === rateChargeId);
      return rateCharge;
    });
    if (!rate || !rateCharge) return;
    const plans = this.plans.filter(plan => plan.shippingRates.some(r => r.id === rate.id));
    (rate.shippingRateCharges as IObservableArray).remove(rateCharge);
    return api.DELETE(endpointConfig.shipping_rate_charge_by_id(rateChargeId))
    .then(data => !skipRefresh && Promise.all(plans.map(plan => this.refreshShippingPlan(plan.id))));
  };

  onAddAllRateCharges = async (rateId: number) => {
    const rate = this.rates.find(r => r.id === rateId);
    if (!rate) return;
    const plans = this.plans.filter(plan => plan.shippingRates.some(r => r.id === rate.id));
    return Promise.all(this.nonBasicCharges
    .filter(charge => charge.modeIds.includes(rate.modeId))
    .map(charge => this.onAddRateCharge(rate, charge, true)))
    .then(() => Promise.all(plans.map(plan => this.refreshShippingPlan(plan.id))))
    .finally(() => this.pendingAddChargeIds = [] as IObservableArray<number>);
  };

  onRemoveAllRateCharge = async (rateId: number) => {
    const rate = this.rates.find(r => r.id === rateId);
    if (!rate) return;
    const shippingRateChargeIds = rate.shippingRateCharges.map(src => src.id);
    const plans = this.plans.filter(plan => plan.shippingRates.some(r => r.id === rate.id));
    return Promise.all(shippingRateChargeIds.map(id => this.onRemoveRateCharge(id, true)))
    .then(() => Promise.all(plans.map(plan => this.refreshShippingPlan(plan.id))));
  };

  onRateChargeFieldUpdate = (rc: ShippingRateCharge, field: string, value) => {
    const rateCharge = this.allRateCharges[this.allRateCharges.indexOf(rc)];
    if (!rateCharge) return;
    if (field === "shippingChargeId") value = Number(value);
    if (isEqual(rateCharge[field], value)) return;
    rateCharge[field] = value;
    return true;
  };

  onUpdateRateCharge = async (rateChargeId: number, field: string, value) => {
    let rateCharge: ShippingRateCharge;
    const rate = this.rates.find(r => {
      rateCharge = r.shippingRateCharges.find(rc => rc.id === rateChargeId);
      return rateCharge;
    });
    if (!rateCharge) return;
    const plans = this.plans.filter(plan => plan.shippingRates.some(r => r.id === rate.id));
    return api.PATCH({
      endpoint: endpointConfig.shipping_rate_charge_by_id(rateCharge.id),
      data: { [field]: value }
    })
    .then(() => Promise.all(plans.map(plan => this.refreshShippingPlan(plan.id))));
  };

  onRateChargeItemFieldUpdate = (shippingRateChargeItem: ShippingRateChargeItem, field: string, value) => {
    const rateChargeItem = this.allRateChargeItems[this.allRateChargeItems.indexOf(shippingRateChargeItem)];
    if (!rateChargeItem) return;
    if (field === "amount") value = (Number(value) || 0).toFixed(2);
    if (isEqual(rateChargeItem[field], value)) return;
    rateChargeItem[field] = value;
    return true;
  };

  onUpdateRateChargeItem = async (rateChargeItemId: number, field: string, value) => {
    const rateChargeItem = this.allRateChargeItems.find(ruc => ruc.id === rateChargeItemId);
    if (!rateChargeItem) return;
    if (field === "amount") value = Number(value) || 0;
    return api.PATCH({
      endpoint: endpointConfig.shipping_rate_charge_item_by_id(rateChargeItem.id),
      data: { [field]: value }
    });
  };

  onCarrierSelect = async (rateId: number, carrierId: number) => {
    if (carrierId) {
      const carrier = this.carriers.find(c => c.id === carrierId);
      if (!carrier) return;
    }
    return this.onUpdateRate(rateId, "carrierId", carrierId);
  };

  onCreateCarrierChargeOverride = async (systemCharge: ShippingCharge, rateId: number) => {
    const rate = this.rates.find(r => r.id === rateId);
    if (!rate) return;
    if (isNonZeroFalse(this.currentCarrierId)) return;
    if (isEmpty(systemCharge)) return;
    return api.POST({
      endpoint: endpointConfig.new_shipping_charge,
      data: {
        ...systemCharge,
        systemShippingChargeId: systemCharge.id,
        lspGroupId: this.groupId,
        carrierGroupId: rate.carrierId
      } as Partial<ShippingCharge>
    })
    .then(this.getCurrentCarrierCharges);
  };

  onDuplicateCarrierCharge = async (shippingCharge: ShippingCharge, rateId: number) => {
    const rate = this.rates.find(r => r.id === rateId);
    if (!rate) return;
    if (isNonZeroFalse(this.currentCarrierId)) return;
    if (isEmpty(shippingCharge)) return;
    return api.POST({
      endpoint: endpointConfig.new_shipping_charge,
      data: {...shippingCharge} as Partial<ShippingCharge>
    })
    .then(this.getCurrentCarrierCharges);
  };

  onCreateCarrierCustomCharge = async (rateId: number) => {
    const rate = this.rates.find(r => r.id === rateId);
    if (!rate) return;
    if (isNonZeroFalse(this.currentCarrierId)) return;
    return api.POST({
      endpoint: endpointConfig.new_shipping_charge,
      data: {
        modeIds: [rate.modeId],
        lspGroupId: this.groupId,
        carrierGroupId: rate.carrierId,
        baseCode: "new",
        chargeName: { [UIText.preference] : "" },
        description: { [UIText.preference] : "" }
      } as Partial<ShippingCharge>
    })
    .then(this.getCurrentCarrierCharges);
  };

  onRemoveCarrierCharge = async (carrierCharge: ShippingCharge) => {
    if (isEmpty(this.carrierCharges[this.currentCarrierId])) return;
    const charge: ShippingCharge = this.carrierCharges[this.currentCarrierId].find(c => c.id === carrierCharge.id);
    if (!charge) return;
    return api.DELETE(endpointConfig.shipping_charge_by_id(charge.id))
    .then(this.getCurrentCarrierCharges);
  };

  onCarrierChargeFieldUpdate = (charge: ShippingCharge, field: keyof ShippingCharge, value) => {
    if (field === "chargeName") {
      if (isEqual(charge.chargeName[UIText.preference], value)) return;
      return charge.chargeName[UIText.preference] = value;
    }
    if (field === "description") {
      if (isEqual(charge.description[UIText.preference], value)) return;
      return charge.description[UIText.preference] = value;
    }
    if (field === "isSurcharge") {
      return charge.isSurcharge = !!value ? 1 : 0;
    }
    if (isEqual(charge[field], value)) return;
    charge[field as string] = value;
    return true;
  };

  onUpdateCarrierCharge = async (shippingChargeId: number, field: keyof ShippingCharge, value) => {
    const charge = this.nonBasicCharges.find(c => c.id === Number(shippingChargeId));
    if (!charge) return;
    const updated = this.onCarrierChargeFieldUpdate(charge, field, value);
    if (!updated) return;
    if (field === "chargeName") value = {
      ...charge.chargeName,
      [UIText.preference]: value
    };
    if (field === "description") value = {
      ...charge.description,
      [UIText.preference]: value
    };
    if (field === "isSurcharge") value = !!value ? 1 : 0;
    return api.PATCH({
      endpoint: endpointConfig.shipping_charge_by_id(charge.id),
      data: { [field]: value }
    })
    .then(this.getCurrentCarrierCharges);
  };

  onAddRateLeg = async (rateId: number, ordinal: number) => {
    const rate = this.rates.find(r => r.id === rateId);
    if (!rate) return;
    return api.POST({
      endpoint: endpointConfig.new_shipping_rate_leg,
      data: { shippingPlanId: rate.shippingPlanId, ordinal }
    })
    .then(data => this.refreshShippingPlan(rate.shippingPlanId));
  };

  onRemoveRateLeg = async (legId: number) => {
    let shippingRateLeg: ShippingRateLeg;
    const rate = this.rates.find(r => {
      shippingRateLeg = r.shippingRateLegs && r.shippingRateLegs.find(leg => leg.id === legId);
      return shippingRateLeg;
    });
    if (!rate || !shippingRateLeg) return;
    (rate.shippingRateLegs as IObservableArray).remove(shippingRateLeg);
    return api.DELETE(endpointConfig.shipping_rate_leg_by_id(legId))
    .then(data => this.refreshShippingPlan(rate.shippingPlanId));
  };

  onShippingRateLegFieldUpdate = (leg: ShippingRateLeg, field: keyof ShippingRateLeg, value) => {
    if (field === "modeId" || field === "ordinal") {
      value = value ? Number(value) : 0
    }
    if (isEqual(leg[field], value)) return;
    leg[field] = value;
    return true;
  };

  onUpdateShippingRateLeg = async (legId: number, field: keyof ShippingRateLeg, value) => {
    let shippingRateLeg: ShippingRateLeg;
    const rate = this.rates.find(r => {
      shippingRateLeg = r.shippingRateLegs && r.shippingRateLegs.find(leg => leg.id === legId);
      return shippingRateLeg;
    });
    if (!rate || !shippingRateLeg) return;
    if (field === "modeId" || field === "ordinal") {
      value = value ? Number(value) : 0
    }
    return api.PATCH({
      endpoint: endpointConfig.shipping_rate_leg_by_id(legId),
      data: { [field]: value }
    })
    .then(() => this.refreshShippingPlan(rate.shippingPlanId));
  };

  refreshShippingPlan = (id: number) => api.GET(endpointConfig.shipping_plan_by_id(id))
  .then(response => {
    const plan: ShippingPlan = response.data;
    const existing = this.plans.find(pl => pl.id === id);
    if (existing) {
      if (isEmpty(plan)) return this.plans.remove(existing);
      if (isEqual(existing, plan)) return;
      return set(existing, plan);
    }
    return this.plans.push(plan);
  });
}

export default RateDataViewController;