import { observer, Observer } from "mobx-react";
import React from "react";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { UIException, UIText } from "../../../../client/lang";
import { closeSharp } from "ionicons/icons";
import ListControlToolbar from "../../../../components/ListControlToolbar";
import { getEventRealValue, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import { ShippingPlan, ShippingRate } from "../../lib/types/rateTypes";
import "./styles.less";
import { ListController } from "../../../../lib/list-controller";
import ListControlFilterBar from "../../../../components/ListControlFilterBar";
import { AutoObserverList } from "../../../../components/ObserverList";
import RateCard, { RateCardProps } from "../RateCard";
import { computed, observable } from "mobx";
import { matchPlan } from "../../lib/matchers";
import { filterShippingPlanBySelectedFilters } from "../../lib/filters";
import { sortByProperty } from "../../lib/common";
import { getShippingPlanFilterProperties, shippingPlanSortProperties } from "../../config/constants";
import { StdErr } from "../../../../lib/types/miscTypes";
import { ui } from "../../../../client/ui";
import HeaderRefreshButton from "../../../../components/HeaderRefreshButton";

export interface ComplexRateLegSelectionModalProps extends Partial<RateCardProps> {
  getSinglePlans: (type: ShippingPlan["type"]) => Promise<ShippingPlan[]>;
  plan: ShippingPlan;
  isOpen: boolean;
  onDismiss: (event: any) => void;
  onRateSelect?: (rateId: number) => Promise<void>;
}

@observer
class ComplexRateLegSelectionModal extends React.Component<ComplexRateLegSelectionModalProps> {
  listController: ListController<ShippingPlan>;

  @observable plans: ShippingPlan[] = [];
  @observable loading: boolean;

  @computed get searchValue(): string { return this.listController.searchValue };
  @computed get searchedPlans(): ShippingPlan[] {
    return this.plans.filter(plan => matchPlan(plan, this.searchValue));
  };

  @computed get filteredPlans(): ShippingPlan[] {
    return this.searchedPlans.filter(
      plan => filterShippingPlanBySelectedFilters(
        plan,
        this.listController.selectedFilters,
        this.props.modes
      )
    );
  };
  @computed get sortedPlans(): ShippingPlan[] {
    return [...this.filteredPlans].sort((a, b) =>
      sortByProperty(
        a,
        b,
        this.listController.sortBy,
        this.listController.sortDir
      )
    );
  };
  @computed get availablePlans(): ShippingPlan[] {
    return this.sortedPlans.filter(plan => plan.type === "single");
  };

  get sortProperties() {
    return shippingPlanSortProperties.map(sort => ({
      name: sort,
      placeholder: UIText.shippingPlanSortProperties[sort]
    }));
  };

  get filterProperties() {
    return getShippingPlanFilterProperties(false).map(filter => ({
      name: typeof filter === "string" && !(filter.trim()) ? "divider" : filter,
      placeholder: UIText.shippingPlanFilterProperties[filter]
    }));
  }

  constructor(props) {
    super(props);
    this.listController = new ListController({
      storeName: "complexRateLegSelectionModalFilterSort",
      initialSortBy: "createTime"
    });
  }

  componentDidUpdate(prevProps: Readonly<ComplexRateLegSelectionModalProps>) {
    if (!prevProps.isOpen && this.props.isOpen) {
      setTimeout(this.getSinglePlans, 300);
    }
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || UIText.rateManagement.selectAShippingRate
    });

  getSinglePlans = async () => {
    this.loading = true;
    return this.props.getSinglePlans("single")
    .then(plans => this.plans = plans)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  handleRateSelect = (event: any, rateId: number) => {
    preventDefaultStopProp(event);
    this.loading = true;
    this.props.onRateSelect(rateId)
    .then(this.props.onDismiss)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  renderRateCard = (plan: ShippingPlan) => {
    const { modes, findDisplayUnits, checkModeChangeLock, doesRateHaveBreakdownType, uocs } = this.props;
    const rate = !isEmpty(plan.shippingRates) && plan.shippingRates[0];
    if (!rate) {
      const err = new UIException("ENCOUNTERED_PLAN_WITHOUT_RATE");
      ui.showError({ err, actionName: UIText.invalidShippingRateData }).catch(console.warn);
      return null;
    }
    return <Observer key={plan.id}>{() => (
      <RateCard
        className={ui.isMobile ? "ion-no-margin ion-margin-vertical" : "ion-margin-bottom"}
        readonly
        plan={plan}
        rate={rate}
        modes={modes}
        uocs={uocs}
        findDisplayUnits={findDisplayUnits}
        checkModeChangeLock={checkModeChangeLock}
        doesRateHaveBreakdownType={doesRateHaveBreakdownType}
        extraButtons={this.renderSelectButton(rate)}
      />
    )}</Observer>;
  };

  renderSelectButton = (rate: ShippingRate) => (
    <IonButton
      disabled={this.loading}
      onClick={e => this.handleRateSelect(e, rate.id)}
    >
      {UIText.generalSelect}
    </IonButton>
  );

  render() {
    const {
      plan,
      isOpen,
      onDismiss
    } = this.props;

    const {
      sortBy,
      sortDir,
      searchValue,
      onSearchValueChange,
      onSortByChange,
      onSortDirChange,
      selectedFilters,
      onFilterSelect,
      onFilterClear
    } = this.listController;

    return <IonModal
      key={`complexRateLegSelectionModal ${plan.id}`}
      cssClass="modal complexRateLegSelectionModal exLarge"
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{UIText.rateManagement.selectAShippingRate}</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={closeSharp} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <HeaderRefreshButton loading={this.loading} onRefresh={this.getSinglePlans} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <ListControlToolbar
          searchValue={searchValue}
          onSearchChange={(e: any) => onSearchValueChange(getEventRealValue(e))}
          searchBarPlaceholder={UIText.searchStuff(UIText.rateManagement.shippingRates)}
        />

        <ListControlFilterBar
          className="ion-margin-vertical"
          sorts={this.sortProperties}
          sortDir={sortDir}
          sortBy={sortBy}
          count={this.availablePlans.length}
          onSortByChange={onSortByChange}
          onSortDirChange={onSortDirChange}
          filters={this.filterProperties}
          selectedFilters={selectedFilters}
          onFilterSelect={onFilterSelect}
          onFilterClear={onFilterClear}
        />

        <AutoObserverList
          list={this.availablePlans}
          getItemKey={plan => plan.id}
          render={this.renderRateCard}
        />
      </IonContent>
    </IonModal>
  }
}

export default ComplexRateLegSelectionModal;