import { observer, Observer } from "mobx-react";
import React from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addCircleOutline, caretDownSharp, closeSharp } from "ionicons/icons";
import { ShippingMode, ShippingPort, ShippingRate, ShippingRateLeg } from "../../lib/types/rateTypes";
import "./styles.less";
import { getIonStyleVal } from "../../../../config/styles/style-utils";
import MdIcon from "../../../../components/MdIcon";
import { mdiDragVertical } from "@mdi/js";
import { UIText } from "../../../../client/lang";
import { preventDefaultStopProp } from "../../../../utils/helpers";
import { computed, observable } from "mobx";
import { ItemReorderEventDetail } from "@ionic/core";
import { ui } from "../../../../client/ui";
import { ObserverList } from "../../../../components/ObserverList";
import { PortSelectionPropertyNames } from "../../lib/types/propTypes";
import RatePortSelectionModal from "../RatePortSelectionModal";
import RateTransitPortsIndicator from "../RateTransitPortsIndicator";

export interface RateTransitPortLegItemProps extends React.HTMLProps<HTMLDivElement> {
  modes: ShippingMode[];
  leg: ShippingRateLeg;
  polPort: ShippingPort;
  podPort: ShippingPort;
  onRemoveLeg: (event: any) => void;
  onFieldChange: (event: any) => void;
  onPortModalOpen: (event: any, property: PortSelectionPropertyNames) => void;
}

@observer
class RateTransitPortLegItem extends React.Component<RateTransitPortLegItemProps> {
  onModeChangeSelect = (event: any) => {
    const { modes, onFieldChange } = this.props;
    if (modes.length === 1) return;
    preventDefaultStopProp(event);
    return ui.popoverMenu({
      event,
      menuItems: modes.map(mode => ({
        text: UIText.rfqForm[mode.mode],
        handler: () => onFieldChange({
          target: { name: "modeId", value: mode.id }
        })
      }))
    });
  };

  render() {
    const {
      className,
      modes,
      leg,
      polPort,
      podPort,
      onRemoveLeg,
      onPortModalOpen
    } = this.props;

    const mode = modes.find(m => m.id === leg.modeId);

    return <div className={`flex legReorder ${className || ""}`}>
      <IonReorder
        style={{ background: getIonStyleVal("--ion-color-light-tint") }}
        className="flex ion-align-items-center ion-justify-content-center ion-align-self-stretch ion-no-margin"
      >
        <MdIcon icon={mdiDragVertical} color="dark" />
      </IonReorder>
      <div className="flex column leg">
        <IonItemDivider color="primary">
          <IonText
            className="flex ion-align-items-center ion-activatable"
            onClick={this.onModeChangeSelect}
          >
            {leg.ordinal + 1}.&nbsp;{UIText.rateManagement.mode}:&nbsp;<b>{UIText.rfqForm[mode.mode]}</b>&nbsp;
            <IonIcon icon={caretDownSharp} />
          </IonText>
          <IonButton
            fill="clear"
            slot="end"
            className="ion-no-margin max"
            onClick={onRemoveLeg}
          >
            <IonIcon color="light" icon={closeSharp} className="font-l" />
          </IonButton>
        </IonItemDivider>
        <div className="half">
          <IonItem>
            <IonLabel position="stacked">
              {mode.mode === "air" ? UIText.rfqForm.origin : UIText.rfqForm.pol}
            </IonLabel>
            <div className="relative ion-activatable linkButton" onClick={e => onPortModalOpen(e, "polPortId")}>
              <IonInput
                readonly
                className="noEvents textPrimary textUnderline"
                value={((polPort || {}).portName || {})[UIText.preference] || ""}
                placeholder={UIText.rateManagement.selectShippingPort}
              />
            </div>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              {mode.mode === "air" ? UIText.rfqForm.destination : UIText.rfqForm.pod}
            </IonLabel>
            <div className="relative ion-activatable linkButton" onClick={e => onPortModalOpen(e, "podPortId")}>
              <IonInput
                readonly
                className="noEvents textPrimary textUnderline"
                value={((podPort || {}).portName || {})[UIText.preference] || ""}
                placeholder={UIText.rateManagement.selectShippingPort}
              />
            </div>
          </IonItem>
        </div>
      </div>
    </div>
  }
}


export interface RateTransitPortModalProps {
  modes: ShippingMode[];
  findAvailableShippingPorts: (leg: ShippingRate | ShippingRateLeg, findAll?: boolean) => ShippingPort[];
  rate: ShippingRate;
  loading: boolean;
  isOpen: boolean;
  onDismiss: (event: any) => void;
  onAddLeg: (ordinal: number) => void;
  onRemoveLeg: (shippingLegId: number) => void;
  onLegFieldChange: (event: any, leg: ShippingRateLeg) => void;
  updateLegFieldAsync: (legId: number, field: keyof ShippingRateLeg, value: any) => void;
}

@observer
class RateTransitPortModal extends React.Component<RateTransitPortModalProps> {
  @observable portModalLegId: number;
  @observable portModalPropertyName: PortSelectionPropertyNames;

  legElms = {};

  @computed get portModalLeg(): ShippingRateLeg {
    return this.legs.find(leg => leg.id === this.portModalLegId) || {} as ShippingRateLeg;
  };
  @computed get portModalOpen(): boolean {
    return !!this.portModalLegId && !!this.portModalPropertyName;
  };

  @computed get legs(): ShippingRateLeg[] {
    return (this.props.rate && this.props.rate.shippingRateLegs) || [];
  }

  legRef = (leg, elm) => this.legElms[leg.id] = elm;

  onAddLeg = (event: any, ordinal: number) => {
    preventDefaultStopProp(event);
    return this.props.onAddLeg(ordinal);
  };

  onRemoveLeg = (event: any, id: number) => {
    preventDefaultStopProp(event);
    return this.props.onRemoveLeg(id);
  };

  onLegReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    const { complete } = event.detail;
    complete(this.legs);
    return this.legs.forEach((leg, i) => (
      this.props.updateLegFieldAsync(leg.id, "ordinal", i)
    ));
  };

  handlePortModalOpen = (event: any, propertyName: PortSelectionPropertyNames, legId: number) => {
    preventDefaultStopProp(event);
    this.portModalPropertyName = propertyName;
    this.portModalLegId = legId;
  };

  handlePortModalClose = () => this.portModalLegId = this.portModalPropertyName = undefined;

  handlePortModalSelect = (shippingPortId: number) => this.props.onLegFieldChange({
    target: { name: this.portModalPropertyName, value: shippingPortId }
  }, this.portModalLeg);

  handleSummaryLegClick = (event: any, leg: ShippingRateLeg) => {
    preventDefaultStopProp(event);
    const legElm = this.legElms[leg.id];
    if (!legElm) return;
    return legElm.scrollIntoView({
      behavior: "smooth"
    });
  };

  render() {
    const {
      modes,
      findAvailableShippingPorts,
      rate,
      isOpen,
      loading,
      onDismiss,
      onLegFieldChange
    } = this.props;

    const AddLeg = index =>
      <IonItem
        className="addLegButton ion-margin-bottom"
        button
        lines="none"
        onClick={e => this.onAddLeg(e, index)}
      >
        <IonText className="flex ion-align-items-center ion-justify-content-center font-xl">
          <IonIcon icon={addCircleOutline} color="medium" />
        </IonText>
      </IonItem>;

    return <IonModal
      key={`rateTransitPortModal ${rate.id}`}
      cssClass="modal rateTransitPortModal exLarge"
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{UIText.rateManagement.transitPorts}</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={closeSharp} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" className="ion-padding-horizontal">
            {loading && <IonSpinner name="crescent" />}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <div className="flex column max">
          <div className="flex ion-justify-content-start ion-align-items-center ion-padding-horizontal textBold textLight font-m">
            <IonText>
              {UIText.rateManagement.transitPortRouteSummary}
            </IonText>
          </div>
          <IonCard>
            <IonCardContent>
              <RateTransitPortsIndicator showTransshipment legs={this.legs} onLegClick={this.handleSummaryLegClick} />
            </IonCardContent>
          </IonCard>
          <IonCard className="max" style={{ flex: 1 }}>
            <IonCardContent className="max formView fancyScroll ion-no-padding ion-padding-horizontal">
              <IonList className="ion-no-padding">
                <IonListHeader className="ion-no-padding textBold font-s textPrimary">
                  {UIText.rateManagement.transitPortLegs}
                </IonListHeader>
              </IonList>
              {AddLeg(0)}
              <IonReorderGroup onIonItemReorder={this.onLegReorder} disabled={false}>
                <ObserverList
                  list={this.legs}
                  render={(leg, i) => <Observer key={leg.id}>{() => {
                    const ports = findAvailableShippingPorts(null, true);
                    return <div className="flex column" key={leg.id} ref={elm => this.legRef(leg, elm)}>
                      <RateTransitPortLegItem
                        className="ion-margin-bottom"
                        modes={modes}
                        leg={leg}
                        polPort={ports.find(p => p.id === leg.polPortId)}
                        podPort={ports.find(p => p.id === leg.podPortId)}
                        onRemoveLeg={e => this.onRemoveLeg(e, leg.id)}
                        onFieldChange={e => onLegFieldChange(e, leg)}
                        onPortModalOpen={(e, prop) => this.handlePortModalOpen(e, prop, leg.id)}
                      />
                      {AddLeg(i + 1)}
                    </div>;
                  }}</Observer>}
                />
              </IonReorderGroup>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>

      <Observer>{() => (
        <RatePortSelectionModal
          modes={modes}
          loading={loading}
          ports={findAvailableShippingPorts(this.portModalLeg)}
          portProperty={this.portModalPropertyName}
          rate={this.portModalLeg}
          isOpen={this.portModalOpen}
          onDismiss={this.handlePortModalClose}
          onPortSelect={this.handlePortModalSelect}
          onModeFieldChange={e => this.props.onLegFieldChange(e, this.portModalLeg)}
        />
      )}</Observer>
    </IonModal>
  }
}

export default RateTransitPortModal;