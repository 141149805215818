import { observer } from "mobx-react";
import React from "react";
import { observable, reaction } from "mobx";
import { AxiosResponse, Method } from "axios";
import { api } from "../../../client/api";
import { serverConfig } from "../../../config/api/base";
import { getArgsNames, isEmpty, objectToMultipart, safeParseJSON } from "../../../utils/helpers";
import { IonButton, IonCard, IonCardContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonText } from "@ionic/react";
import { ui } from "../../../client/ui";
import { caretDownSharp, ellipsisHorizontal, playSharp } from "ionicons/icons";
import FormField from "../../../components/FormFields";
import { endpointConfig } from "../../../config/api";
import "./styles.less";
import { getIonStyleVal } from "../../../config/styles/style-utils";

@observer
class ApiTest extends React.Component {
  @observable response: AxiosResponse = {} as AxiosResponse;
  @observable postData = "";
  @observable url = "";
  @observable method: Method = "GET";
  @observable noCred = false;
  @observable openNewTab = false;
  @observable loading = false;
  @observable duration = "Not started";
  @observable isMultiPart = false;

  constructor(props) {
    super(props);
    reaction(() => this.url, this.reset);
  }

  reset = () => {
    this.duration = "Not started";
    this.response = {} as AxiosResponse;
  };

  getData = async () => {
    if (!this.url) return;
    this.loading = true;
    this.reset();
    const start = new Date().getTime();

    return api.async(this.method, {
      headers: this.noCred && serverConfig.defaultHeaders,
      endpoint: this.url,
      noKickOut: true,
      noRenew: true,
      data: this.postData && this.isMultiPart
        ? objectToMultipart(safeParseJSON(this.postData))
        : safeParseJSON(this.postData)
    })
    .then(response => {
      console.log(response);
      this.response = response || {};
      if (this.openNewTab) {
        const win = window.open("about:blank");
        setTimeout(() => win.document.write(`
          <div style="white-space: pre-wrap;">${JSON.stringify(this.response, null, 2)}</div>
        `));
      }
    })
    .catch(err => {
      ui.showError({ err });
      this.response = err.response || {};
    })
    .finally(() => {
      this.loading = false;
      const end = new Date().getTime();
      this.duration = `${end - start} ms`;
    });
  };

  showApiList = (event: any) => {
    const search = observable({ value: "" });
    const component = observer(() => <IonList className="ion-no-padding">
      <IonItem lines="full" color="primary" id="apiTestSearch">
        <style>
          {`#apiTestSearch > ion-label { color: ${getIonStyleVal("--ion-color-light")}`}
        </style>
        <IonLabel position="floating" color="light">
          Search
        </IonLabel>
        <IonInput value={search.value} onIonChange={e => search.value !== e.detail.value && (search.value = e.detail.value)}/>
      </IonItem>
      {Object.keys(endpointConfig).map(name => {
        const endpoint = typeof endpointConfig[name] === "function"
          ? endpointConfig[name](...getArgsNames(endpointConfig[name]).map(arg => `{${arg}}`))
          : endpointConfig[name];
        if (!endpoint.match(search.value) && !name.match(search.value)) return null;
        const onClick = () => (this.url = endpoint) && ui.dismissPopover();
        return <IonItem button key={name} onClick={onClick}>
          <IonLabel position="stacked" color="primary">
            {name}
          </IonLabel>
          <IonInput readonly className="noEvents textNoSelect" value={endpoint} />
        </IonItem>
      }).filter(Boolean)}
    </IonList>);
    return ui.popover({
      event,
      component,
      cssClass: "apiTestSelection"
    });
  };

  grabToken = () => {
    const token = (api.OAuth2Data || {}).access_token;
    if (!token) return ui.toast({ header: "No token yet! Try sign in with embedded app." });
    ui.copyStringToClipboard(token);
  };

  render() {
    return <IonCard className="flex column container cardedContainer" style={{ margin: "16px auto" }}>
      <IonCardContent className="ion-no-padding">
        <IonList className="ion-no-padding">
          <IonListHeader className="textPrimary textBold">API Test</IonListHeader>
          <IonItem
            button
            lines="full"
            onClick={this.grabToken}
          >
            <IonText color="primary" className="textUnderline textBold">
              Grab a token {isEmpty(api.OAuth2Data) ? "(Not logged in)" : ""}
            </IonText>
          </IonItem>
          <IonItem className="ion-no-padding">
            <IonItem lines="none" style={{ flex: 1 }}>
              <IonLabel position="floating">URL</IonLabel>
              <IonInput
                value={this.url}
                onIonChange={e => (this.url = e.detail.value)}
                {...ui.bindKeyCode("onKeyUp", {
                  13: this.getData
                })}
              />
            </IonItem>
            <div>
              <IonButton fill="clear" onClick={this.showApiList}>
                <IonIcon icon={caretDownSharp} color="dark" slot="icon-only"/>
              </IonButton>
              <IonButton fill="clear" onClick={this.getData}>
                <IonIcon icon={this.loading ? ellipsisHorizontal : playSharp} color="primary" slot="icon-only"/>
              </IonButton>
            </div>
          </IonItem>
          <FormField
            field={{
              name: "method",
              type: "picker",
              placeholder: `Method`,
              options: ["GET", "POST", "PATCH", "PUT", "DELETE"]
              .map(item => ({ name: item, placeholder: item })),
              value: this.method,
            }}
            onChange={e => (this.method = e.detail.value)}
          />
          <FormField
            field={{
              name: "newTab",
              type: "checkbox",
              placeholder: `Open in new tab`,
              value: this.openNewTab,
            }}
            onChange={(e) => (this.openNewTab = e.detail.checked)}
          />
          <FormField
            field={{
              name: "noCred",
              type: "checkbox",
              placeholder: `Use basic auth (No cred)`,
              value: this.noCred,
            }}
            onChange={e => (this.noCred = e.detail.checked)}
          />
          {(this.method === "POST" ||
            this.method === "PUT" ||
            this.method === "PATCH") && <>
            <FormField
              field={{
                name: "multipart",
                type: "checkbox",
                placeholder: `Use multipart/form-data`,
                value: this.isMultiPart,
              }}
              onChange={e => (this.isMultiPart = e.detail.checked)}
            />
            <FormField
              field={{
                name: "data",
                type: "input",
                placeholder: `Post data (JSON)`,
                multiLine: true,
                value: this.postData
              }}
              onChange={e => (this.postData = e.detail.value)}
            />
          </>}
          <FormField
            field={{
              name: "duration",
              type: "input",
              value: this.duration,
              placeholder: `Duration`,
              disabled: true
            }}
          />
          <FormField
            field={{
              name: "status",
              type: "input",
              value: this.response.status,
              placeholder: `Status`,
              disabled: true
            }}
          />
          <FormField
            field={{
              name: "result",
              type: "input",
              placeholder: `Result data`,
              multiLine: true,
              value: JSON.stringify(this.response.data || "", null, 2),
              autoLineHeight: true,
              disabled: true
            }}
          />
        </IonList>
      </IonCardContent>
    </IonCard>
  }
}

export default ApiTest;