import { env } from "../config/env";
import { autorun, computed, observable } from "mobx";
import { client } from "./client";
import { asyncPause, capitalize, isEmpty, safeParseJSON } from "../utils/helpers";
import { Controller } from "../lib/controller";
import { EventSourcePolyfill } from "../utils/eventsource";
import { api } from "./api";
import { endpointConfig } from "../config/api";
import { stateCtrl } from "./state";
import { topicCtrl } from "./topic";
import { ui } from "./ui";
import flags from "../config/flags";
import { msgCtrl } from "./msg";

type EventSource = {
  onopen: () => void;
  onmessage: () => void;
  onerror: () => void;
  url: string;
  readyState: number;
  withCredentials: unknown;
  _close: unknown;

  addEventListener: (event: string, handler: Function) => void;
  removeEventListener: (event: string) => void;
  close: () => void;
}

export interface TxStore {
  disable?: boolean;
  pollingDisable?: boolean;
}

export class Tx extends Controller<TxStore> {
  eventSource: EventSource;
  eventTimer;
  pollingInterval: number = 1000;

  @observable hasActivity = false;
  @observable connecting = false;
  polling: boolean = false;
  sseEventListeners = [];
  logEventListeners = [];

  @computed get OAuth2Data() {
    return (
      client.oauth &&
      `${capitalize(client.oauth["token_type"])} ${
        client.oauth["access_token"]
        }`
    );
  }
  @computed get options() {
    return { headers: {
      Authorization: this.OAuth2Data,
      "X-Base-Switch": api.xBaseSwitch && api.xBaseSwitch.replace("8080", "8082")
    } };
  }
  @computed get endpoint() {
    return (
      api.baseUrl +
      endpointConfig.transaction_sse(client.id)
    );
  }
  @computed get lastTxId() {
    return client.user && client.user.txId;
  }
  @computed get disabled() {
    this.storage.initProperty("disable", flags.txDisable || undefined);
    return this.store.disable;
  }
  @computed get pollingDisabled() {
    this.storage.initProperty("pollingDisable", flags.txPollingDisable || undefined);
    return this.store.pollingDisable;
  }

  get connected() {
    return (
      !!this.eventSource &&
      this.eventSource.readyState >= 1 &&
      this.eventSource.readyState <= 3
    );
  }

  constructor() {
    super();
    client.storage.isReady()
    .then(this.storage.isReady)
    .then(this._initializeTx);
  }

  _initializeTx = () => {
    this.disposer = autorun(this.autoConnect);
  };

  _registerEvents = () => {
    if (!this.eventSource) return;
    this.eventSource.addEventListener("open", this.onOpen);
    this.eventSource.addEventListener("message", this.onMessage);
    this.eventSource.addEventListener("error", this.onError);
  };

  autoConnect = () => {
    if (
      !!client &&
      client.initialized &&
      client.isLoggedIn &&
      !!client.id
    ) {
      !this.connected &&
      !this.connecting &&
      (this.disabled
          ? (!this.pollingDisabled && this.startTxLogPolling())
          : this.connectEventSource()
      )
    } else {
      this.disconnectEventSource();
    }
  };

  connectEventSource = () => {
    // console.log("connect");
    this.connecting = true;
    if (this.eventSource) this.disconnectEventSource();
    this.eventSource = (new EventSourcePolyfill(this.endpoint, this.options)) as unknown as EventSource;
    if (window && env !== "prod") (window as any).eventSource = this.eventSource;
    this._registerEvents();
    // return this.syncTxLogs();
  };

  disconnectEventSource = () => {
    // console.log("disconnect");
    if (!this.eventSource) return;
    this.eventSource.close();
    this.eventSource = undefined;
  };

  onOpen = message => {
    this.connecting = false;
    console.log("eventSource", message.type, message.data);
  };

  onError = error => {
    console.warn("txService error", error);
    this.autoConnect();
    // if (env.match(/local/g)) return ui.showError({
    //   actionName: this.constructor.name,
    //   err: { response: error } as any
    // });
  };

  onMessage = message => {
    const data = safeParseJSON(message.data) || message.data;
    console.log("eventSource", message.type, data);

    this.addActivity();

    for (let eventListener of this.sseEventListeners) {
      const { listener } = eventListener;
      typeof listener === "function" && listener(data);
    }
    // Tx log update SSE
    if (data.type === "tx") {
      return this.handlers[data.message]().finally(this.removeActivity);
    }
    // Messaging service SSE
    if (data.type === "msg") {
      return msgCtrl.onNewMessage(data).finally(this.removeActivity);
    }
    // Direct Tx log update
    if (data.type === "update") {
      return this.processUpdates([[data], null]);
    }

    return this.removeActivity();
  };

  addActivity = () => (this.hasActivity = true);

  removeActivity = () => (this.hasActivity = false);

  startTxLogPolling = () => {
    if (this.polling) return;
    this.polling = true;
    const poll = async () => {
      if (this.hasActivity) return;
      if (!client.credentialReady || this.pollingDisabled) return;
      this.addActivity();
      return this.syncTxLogs()
      .then(this.removeActivity)
      .then(() => asyncPause(this.pollingInterval))
      .then(poll);
    };
    return poll().catch(err => ui.showError({ err }));
  };

  stopTxLogPolling = () => clearTimeout(this.eventTimer);

  get handlers() {
    return {
      new: this.syncTxLogs,
      logout: () => stateCtrl.logoutResetPathname(),
      refresh: () => window.location.reload()
    };
  }

  syncTxLogs = async () =>
    api.GET({
      method: "get",
      endpoint: endpointConfig.logs_after_tx_id(this.lastTxId),
      noRenew: this.disabled
    })
    .then(response => {
      const logs = response.data || [];
      const newLogs = logs.filter(log => log.txId !== this.lastTxId);
      if (isEmpty(newLogs)) return;
      return this.processTxLogs(logs)
      .then(this.processUpdates)
      .then(this.updateLastTxId)
      .catch(console.warn);
    });

  processTxLogs = async logs => {
    if (!Array.isArray(logs) || logs.length === 0) return [];

    logs = logs.sort(this.sortTxId);

    const updates = {};
    const final = [];

    for (let log of logs) {
      console.log("tx log:", log.id);
      const data = log.data && safeParseJSON(log.data);
      !Array.isArray(updates[data.entity]) && (updates[data.entity] = []);
      if (!updates[data.entity].includes(data.id)) {
        updates[data.entity].push(data.id);
        final.push(data);
      }
    }

    const lastTxId = logs[logs.length - 1].txId;
    return [final, lastTxId];
  };

  processUpdates = async ([updates, lastTxId]) => {
    if (isEmpty(updates)) return;

    for (let update of updates) {
      if (!update.entity || !update.id || typeof update.entity !== "string") continue;
      console.log(update);
      for (let eventListener of this.logEventListeners) {
        const { listener } = eventListener;
        typeof listener === "function" && listener(update);
      }
      if (!this.updateHandlers[update.entity.toLowerCase()]) continue;
      await this.updateHandlers[update.entity.toLowerCase()](update.id);
    }
    return lastTxId;
  };

  updateHandlers = {
    // group: async groupId => {
    //   const member = clientController.findMembers(
    //     m => m.groupId === groupId
    //   )[0];
    //   if (isEmpty(member)) return;
    //
    //   return apiController.getGroupById(groupId, member.id).then(group => {
    //     if (group.id) {
    //       return clientController.updateGroup(group, groupId);
    //     }
    //   });
    // },
    user: async userId => {
      return client.getAndStoreUser();
    },
    member: async memberId => {
      return client.getMemberById(memberId)
      .then(client.updateMember);
    },
    profile: async profileId => {
      return client.getProfileById(profileId)
      .then(client.updateProfile);
    },
    topic: async topicId => {
      return topicCtrl.getTopicById(topicId)
      .then(topicCtrl.updateTopic);
    },
  };

  updateLastTxId = txId => {
    if (!txId) return;
    console.log("set lastTxId:", txId);
    // TODO: UNSAFE!! The better way to increment this is to negotiate with server for ensured txId update,
    //  however since the nature of webapp, this should be fine for now.
    client.setLastTxId(txId);
  };

  sortTxId = (a, b) => {
    if (!a.txId || !b.txId) return 0;
    return a.txId - b.txId;
  };

  addSSEEventListener = (name, listener) => {
    if (!name || typeof listener !== "function") return;
    if (this.sseEventListeners.some(l => l.name === name)) return;

    return this.sseEventListeners.push({
      name,
      listener
    });
  };

  addLogEventListener = (name, listener) => {
    if (!name || typeof listener !== "function") return;
    if (this.logEventListeners.some(l => l.name === name)) return;

    return this.logEventListeners.push({
      name,
      listener
    });
  };

  removeSSEEventListener = name => {
    if (!name) return;
    const index = this.sseEventListeners.findIndex(l => l.name === name);
    if (index < 0) return;
    return this.sseEventListeners.splice(index, 1);
  };

  removeLogEventListener = name => {
    if (!name) return;
    const index = this.logEventListeners.findIndex(l => l.name === name);
    if (index < 0) return;
    return this.logEventListeners.splice(index, 1);
  };
}

export let tx = {} as Tx;
export const initTx = constructor => tx = constructor;

