import { observer } from "mobx-react";
import React, { MouseEvent } from "react";
import "./styles.less";
import { IonButton, IonButtons, IonItem, IonTextarea } from "@ionic/react";
import MdIcon from "../MdIcon";
import { mdiEmoticon, mdiKeyboardTab, mdiSend, mdiWrap } from "@mdi/js";
import { ui } from "../../client/ui";
import { UIText } from "../../client/lang";
import { preventDefaultStopProp } from "../../utils/helpers";
import { Controller } from "../../lib/controller";
import { computed } from "mobx";

export interface ChatBottomBarProps extends React.HTMLProps<HTMLDivElement> {
  inputValue?: string;
  onMessageChange?: (text: string) => void;
  onMessageSend?: (event: MouseEvent) => void;
  placeholderText?: string;
}

@observer
class ChatBottomBar extends React.Component<ChatBottomBarProps> {
  controller: Controller<{ enterSend: boolean }> =
    new Controller<{enterSend: boolean}>("chatBottomBar");

  @computed get enterSend(): boolean { return this.controller.store.enterSend };

  handleEmojiClick = () => {
    const os = window.navigator.userAgent.match(/Windows NT 10/g)
      ? "win10"
      : window.navigator.userAgent.match(/Mac OS X 10_/g)
        ? "osx"
        : "";
    return ui.alert({
      header: UIText.chatEmoji,
      message: os === "osx"
        ? UIText.chatEmojiOSX
        : os === "win10"
          ? UIText.chatEmojiWin10
          : UIText.chatEmojiNotSupported,
      buttons: [UIText.generalConfirm]
    });
  };

  handleEnterSendClick = () => this.controller.store.enterSend = !this.controller.store.enterSend;

  handleMessageChange = (event: any) => {
    preventDefaultStopProp(event);
    if (!this.props.onMessageChange) return;
    const { value } = event.detail;
    return this.props.onMessageChange(value);
  };

  handleSendClick = (event: any) => {
    preventDefaultStopProp(event);
    if (this.props.onMessageSend) return this.props.onMessageSend(event);
  };

  render() {
    const { className, inputValue, placeholderText } = this.props;
    return <div className={`flex column chatBottomBar ${className || ""}`}>
      <IonItem lines="full">
        <IonButtons slot="end">
          {!ui.isMobile && <IonButton className="ion-margin-horizontal" onClick={this.handleEmojiClick}>
            <MdIcon icon={mdiEmoticon} color="primary" />
          </IonButton>}
          <IonButton className="ion-margin-horizontal" onClick={this.handleEnterSendClick}>
            <MdIcon icon={this.enterSend ? mdiKeyboardTab : mdiWrap} color="primary"/>
          </IonButton>
        </IonButtons>
      </IonItem>
      <div className="chatBottomBarInput flex ion-align-items-center">
        <IonTextarea
          autoGrow
          className="editorTextArea ion-no-margin"
          placeholder={placeholderText || UIText.chatBottomBarPlaceholder}
          value={inputValue}
          onIonChange={this.handleMessageChange}
          {...ui.bindKeyCode("onKeyUp", {
            13: () => this.props.onMessageSend(null)
          }, { shiftKey: !this.enterSend })}
        />
        <IonButton fill="clear" size="small" shape="round" onClick={this.handleSendClick}>
          <MdIcon icon={mdiSend} color="primary" />
        </IonButton>
      </div>
    </div>
  }
}

export default ChatBottomBar;