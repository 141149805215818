import { computed, observable } from "mobx";
import { SelectInterface } from "@ionic/core";

class ResponsiveStyles {
  mobileWidth: number = 768;
  mobileWidthIntermediate: number = 960;
  viewMaxWidth: number = 1366;

  mql = window.matchMedia(`(max-width: ${this.mobileWidth}px)`);
  miMql = window.matchMedia(`(max-width: ${this.mobileWidthIntermediate}px)`);
  viewMaxMql = window.matchMedia(`(max-width: ${this.viewMaxWidth}px)`);
  @observable deviceDimension = {
    isMobile: this.mql.matches,
    isMobileIntermediate: this.miMql.matches,
    isViewMax: this.viewMaxMql.matches
  };

  // @computed get pickerInterface(): SelectInterface {
  //   return (this.deviceDimension.isMobile
  //     ? isPlatform("ios")
  //     ? "alert"
  //     : "popover"
  //     : "popover") as SelectInterface;
  // };

  @computed get pickerInterface(): SelectInterface {
    return "popover";
  };

  constructor () {
    this.mql.addListener(event => (
      this.deviceDimension.isMobile = event.matches
    ));
    this.miMql.addListener(event => (
      this.deviceDimension.isMobileIntermediate = event.matches
    ));
    this.viewMaxMql.addListener(event => (
      this.deviceDimension.isViewMax = event.matches
    ));
  }
}

export const responsive = new ResponsiveStyles();