import { observer } from "mobx-react";
import React from "react";
import { Rfq } from "../../lib/types/dataTypes";
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonText } from "@ionic/react";
import { UIText } from "../../../../client/lang";
import "./styles.less";
import { ui } from "../../../../client/ui";
import MdIcon from "../../../../components/MdIcon";
import { mdiCancel, mdiSeal } from "@mdi/js";
import { getGroupParty } from "../../lib/common";
import { placesApi } from "../../../../client/places";

export interface RfqCardProps extends React.ComponentProps<typeof IonCard> {
  rfq: Rfq;
  party: ReturnType<typeof getGroupParty>;
  description?: string;
  routerLink?: string;
}

const RfqCardInsert: React.FC<{ pod: string; pol: string; isAir: boolean } & React.HTMLProps<HTMLDivElement>> =
  observer(({ pol, pod, isAir, className }) => (
    <div className={`flex ion-justify-content-between textDarkMedium rfqCardInsert ${className || ""}`}>
      <div className="rfqCardInsertPointOf ion-text-left">
        <div className="font-s">{isAir ? UIText.rfqForm.origin : UIText.POL}</div>
        <div className={ui.isMobile ? "font-m" : "font-l"}>{placesApi.getFormattedAddress(pol, "city")}</div>
      </div>
      <div className="flex ion-align-items-center ion-no-wrap rfqCardInsertArrow">
        <div className="line" />
        <div className="point" />
      </div>
      <div className="rfqCardInsertPointOf ion-text-right">
        <div className="font-s">{isAir ? UIText.rfqForm.destination : UIText.POD}</div>
        <div className={ui.isMobile ? "font-m" : "font-l"}>{placesApi.getFormattedAddress(pod, "city")}</div>
      </div>
    </div>
  ));

const RfqCard: React.FC<RfqCardProps> = observer(
  ({ rfq, party, description, onClick, routerLink }) => {
    const {
      POL,
      POD,
      mode,
      createDatetime
    } = rfq;

    return <IonCard
      button
      routerLink={routerLink}
      className={`
        ${ui.isMobile ? "rfqCard ion-no-margin ion-margin-vertical" : "rfqCard ion-margin"}
        ${rfq.isAwarded ? "awarded" : ""}
      `}
      onClick={onClick}
    >
      <IonCardHeader translucent className="textSelectable">
        <IonCardTitle className="textPrimary flex ion-justify-content-between ion-align-items-start">
          <span style={{ flex: 1 }} className="flex column">
            <span className="rfqCardTitleText">{description || rfq.description}</span>
            <IonCardSubtitle className="textDarkMedium">
              {UIText.freightMode + ":"}&nbsp;{(mode && UIText.rfqForm[mode]) || ""}
            </IonCardSubtitle>
          </span>
          <span className="flex column font-xs ion-wrap rfqCardTitleStatus">
            {party === "shipper" && <>
              {rfq.shipperQuotationCount > 0 && <IonText className="textPrimary ion-text-end">
                {rfq.shipperQuotationCount}&nbsp;{rfq.shipperQuotationCount > 1 ? UIText.quotations : UIText.quotation}
              </IonText>}
              {rfq.solutionCount > 0 && <IonText className="textTooling ion-text-end">
                {rfq.solutionCount}&nbsp;{rfq.solutionCount > 1 ? UIText.solutions : UIText.solution}
              </IonText>}
              {!!rfq.isAwarded && <IonText className="textPrimary flex ion-align-items-center textBold ion-justify-content-end">
                <MdIcon icon={mdiSeal} color="warning" />&nbsp;{UIText.awarded}
              </IonText>}
            </>}
            {party === "lsp" && <>
              {rfq.lspQuotationCount > 0 && <>
                <IonText className="textPrimary ion-text-end textBold textUnderline">
                  {UIText.quotations}
                </IonText>
                {rfq.submittedQuotationCount > 0 && <IonText className="ion-text-end" style={{ color: "#41C041" }}>
                  {rfq.submittedQuotationCount}&nbsp;{UIText.submittedAt}
                </IonText>}
                {rfq.lspQuotationCount - rfq.submittedQuotationCount > 0 && <IonText className="textDanger ion-text-end">
                  {rfq.lspQuotationCount - rfq.submittedQuotationCount}&nbsp;{UIText.inProgressQuotation}
                </IonText>}
              </>}
              {!!rfq.hasAwarded && <IonText className="textPrimary flex ion-align-items-center textBold ion-justify-content-end">
                <MdIcon icon={mdiSeal} color="warning" />&nbsp;{UIText.awarded}&nbsp;{UIText.quotation.toLowerCase()}
              </IonText>}
            </>}
            {!!rfq.isClosed && <IonText className="textPrimary flex ion-align-items-center textDarkMedium ion-justify-content-end">
              <MdIcon icon={mdiCancel} />&nbsp;{UIText.closed}
            </IonText>}
          </span>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="textSelectable">
        <RfqCardInsert pol={POL} pod={POD} isAir={(mode || "").substr(0, 3).toLowerCase() === "air"}/>
        {rfq.freightCount > 1 && <div className="font-s textDarkMedium rfqCardFreightMore">
          + {rfq.freightCount - 1} {UIText.generalMore.toLowerCase()}
        </div>}
        <IonText className="textDarkMedium">
          {UIText[party === "lsp" ? "submitDate" : "createdAt"] + ":"}&nbsp;{createDatetime ? createDatetime.toLocaleString() : ""}
        </IonText>
      </IonCardContent>
    </IonCard>
  });

export default RfqCard;