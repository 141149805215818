import { observer } from "mobx-react";
import { IonButton, IonCardHeader, IonCardTitle, IonIcon } from "@ionic/react";
import { arrowBackCircleOutline, refreshCircleOutline } from "ionicons/icons";
import HeaderRefreshButton from "../../components/HeaderRefreshButton";
import React, { MouseEvent } from "react";
import "./styles.less";
import { ui } from "../../client/ui";
import { observable } from "mobx";

export interface CardedContainerHeaderProps extends Omit<React.ComponentProps<typeof IonCardHeader>, "title"> {
  title: string | React.ReactNode;
  titleRight?: string | React.ReactNode;
  loading?: boolean;
  onRefresh?: (event: MouseEvent) => void;
  backHandler?: (event: MouseEvent) => void;
  contentScrollTop?: number;
}

@observer
class CardedContainerHeader extends React.Component<CardedContainerHeaderProps> {
  childDiv: HTMLDivElement;

  @observable childDivMaxHeight: number;

  componentDidUpdate() {
    setTimeout(() => this.childDivMaxHeight = this.getChildDivMaxHeight());
  }

  getChildDivMaxHeight = () => this.props.contentScrollTop > 0
    ? 0
    : this.childDiv
    ? this.childDiv.scrollHeight > 0
    ? this.childDiv.scrollHeight
    : 200
    : 200;

  dynamicHeaderRef = (elm: HTMLDivElement) => this.childDiv = elm;

  render() {
    const { className, title, titleRight, loading, backHandler, onRefresh, children } = this.props;
    return <IonCardHeader translucent className={`cardedContainerHeader ${className || ""}`}>
      <IonCardTitle
        className={`
          textPrimary flex ion-justify-content-between ion-align-items-center
          ${ui.isMobile ? "ion-wrap" : ""}
        `}
        style={{ ...children && { marginBottom: "8px" } }}
      >
        <div className="flex ion-align-items-center">
          {backHandler && <IonButton fill="clear" className="ion-no-padding" onClick={backHandler}>
            <IonIcon slot="icon-only" icon={arrowBackCircleOutline} />
          </IonButton>}
          {typeof title === "string" ? (
            <span className="cardedContainerHeaderText">{title}</span>
          ) : title}
          {onRefresh && <HeaderRefreshButton
            className="ion-no-padding"
            color="medium"
            spinnerColor="primary"
            fill="clear"
            loading={loading}
            icon={refreshCircleOutline}
            onRefresh={onRefresh}
          />}
        </div>
        {titleRight && (
          <div className="flex ion-align-items-center titleRight">
            {titleRight}
          </div>
        )}
      </IonCardTitle>
      <div
        className="dynamicHeader"
        ref={this.dynamicHeaderRef}
        style={ui.isMobile ? { maxHeight: this.childDivMaxHeight } : undefined}
      >
        <div className="flex ion-justify-content-start ion-align-items-center">
          {children && children}
        </div>
      </div>
    </IonCardHeader>
  }
}

export default CardedContainerHeader;