import { IonButton, IonButtons, IonCard, IonContent, IonIcon, IonPage, withIonLifeCycle } from "@ionic/react";
import { computed, IObservableArray, observable } from "mobx";
import { observer, Observer } from "mobx-react";
import React from "react";
import "./styles.less";
import { ElementType, StdErr } from "../../../../lib/types/miscTypes";
import { ui } from "../../../../client/ui";
import { RouteComponentProps } from "react-router";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import CardedContainerHeader from "../../../../components/CardedContainerHeader";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { UIText } from "../../../../client/lang";
import { Carrier, ShippingMode } from "../../lib/types/rateTypes";
import { addOutline, closeOutline, funnel, funnelOutline, pencilOutline } from "ionicons/icons";
import { getDisplayNameEng, isEmpty, preventDefaultStopProp } from "../../../../utils/helpers";
import { AutoObserverList } from "../../../../components/ObserverList";
import { SystemCarrierListController } from "./controller";
import { getCarrierModes, getCarrierTypePlaceholder, sortByProperty } from "../../lib/common";
import SortableTableHead, { SortableTableHeadColumn } from "../../../../components/SortableTableHead";
import FilterItemList from "../../../../components/FilterItemList";
import MdIcon from "../../../../components/MdIcon";
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from "@mdi/js";
import { stateCtrl } from "../../../../client/state";
import { client } from "../../../../client/client";
import { carrierSortProperties } from "../../config/constants";
import { ListController } from "../../../../lib/list-controller";

export interface CarrierListProps extends RouteComponentProps<GroupSubRouteParams<{}>> {}

export type CarrierTableHeadColumn = SortableTableHeadColumn & {
  name: ElementType<typeof carrierSortProperties>;
}

@observer
class SystemCarrierList extends React.Component<CarrierListProps> {
  url: string;
  title = UIText.rateManagement.systemCarriers;

  controller: SystemCarrierListController = {} as SystemCarrierListController;
  listController: ListController<Carrier>;

  @observable loading: boolean = false;

  @computed get carriers(): Carrier[] {
    return this.controller.carriers;
  };
  @computed get carrierProfileFieldColumns(): SortableTableHeadColumn[] {
    return this.controller.carrierProfileFieldColumns || [];
  };
  @computed get sortedCarriers(): Carrier[] {
    return [...this.carriers].sort(
      (a, b) => this.sortCarrier(a, b, this.listController.sortBy, this.listController.sortDir)
    );
  };
  @computed get modeFilters(): IObservableArray<ShippingMode["mode"]> {
    return [...this.controller.modeFilters].sort() as IObservableArray;
  };
  @computed get filteredCarriers(): Carrier[] {
    if (isEmpty(this.modeFilters)) return this.sortedCarriers;
    return this.sortedCarriers.filter(carrier =>
      carrier.profile.data.modes.some(mode => this.modeFilters.includes(mode))
    );
  };

  constructor(props) {
    super(props);
    this.url = props.match.url;
    this.controller = new SystemCarrierListController();
    this.listController = new ListController({
      storeName: "SystemCarrierListFilterSort",
      initialSortBy: "id"
    });
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    if (!this.controller.isSa) return this.props.history.replace("/");
    return this.onRefresh();
  };

  onRefresh = (event?: any) => {
    this.loading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  sortCarrier = (a: Carrier, b: Carrier, sortBy, sortDir) => {
    if (sortBy === "id") return sortByProperty(a, b, "id", sortDir);
    return sortByProperty(a.profile.data, b.profile.data, sortBy, sortDir);
  };

  handleEdit = (event: any, id: number) => {
    preventDefaultStopProp(event);
    const group = this.carriers.find(c => c.id === id);
    if (!group) return;
    stateCtrl.profilePageExplicitEditable = true;
    return this.props.history.push(`/Group/${client.defaultGroup.id}/Profile/${group.profileId}`);
  };

  handleAdd = (event: any) => {
    preventDefaultStopProp(event);
    return this.props.history.push(`/Profile/new/type/3`);
  };

  handleRemove = (event: any, carrier: Carrier) => ui.reconfirm({
    header: `${UIText.generalDelete} Shipping Carrier`,
    name: `the System Shipping Carrier ID ${carrier.id} "${getDisplayNameEng(carrier.profile)}"`,
    verb: UIText.generalDelete.toLowerCase(),
    handler: () => this.handleRemoveConfirm(event, carrier.id)
  });

  handleRemoveConfirm = (event: any, id: number) => {
    if (!id) return;
    this.loading = true;
    return this.controller.onRemoveCarrier(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  handleModeFilterMenu = (event: any) => {
    const { modes, onFilterClear, onFilterSelect } = this.controller;
    const filters = modes.map(mode => ({
      name: mode.mode.toString(), placeholder: mode.localeMode[UIText.preference]
    }));
    return ui.popover({
      event,
      component: () => <FilterItemList
        filters={filters}
        selectedFilters={this.modeFilters.map(f => f.toString())}
        onFilterClear={onFilterClear}
        onFilterSelect={onFilterSelect}
      />,
      showBackdrop: false
    });
  };

  render() {
    const { modes } = this.controller;

    const { sortBy, sortDir } = this.listController;

    return (
      <IonPage className="headerOffset systemCarrierList">
        <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
          <IonCard className="flex max column container inboxContainer cardedContainer">
            <Observer>{() => (
              <CardedContainerHeader
                title={this.title}
                titleRight={
                  <IonButtons>
                    <IonButton
                      className="textBold textNoTransform"
                      fill="clear"
                      size="small"
                      onClick={this.handleModeFilterMenu}
                    >
                      <IonIcon icon={isEmpty(this.modeFilters) ? funnelOutline : funnel} slot="start" />
                      {UIText.rateManagement.systemShippingModes}
                    </IonButton>
                    <IonButton color="primary" className="textNoTransform" onClick={this.handleAdd}>
                      <IonIcon slot="icon-only" icon={addOutline}/>
                    </IonButton>
                  </IonButtons>
                }
                backHandler={this.props.history.goBack}
                loading={this.loading}
                onRefresh={this.onRefresh}
              />
            )}</Observer>
            <IonContent className="max fullBackground ion-no-padding" scrollY={false}>
              <TableContainer component={props => <Paper {...props} square />} elevation={0} className="max">
                <Table size="small" stickyHeader>
                  <Observer>{() => (
                    <SortableTableHead
                      columns={[
                        ...carrierSortProperties.map(sort => ({
                          name: sort,
                          text: UIText.carrierSortProperties[sort]
                        })),
                        { name: "modes", text: "Modes" },
                        { name: "isPublic", text: "Standardized" },
                        { name: "isPublicApproved", text: "Approved" },
                        { name: "edit", text: UIText.generalEdit, noSort: true },
                        { name: "delete", text: UIText.generalDelete, noSort: true }
                      ] as CarrierTableHeadColumn[]}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      onSort={this.handleColumnSort}
                    />
                  )}</Observer>
                  <Observer>{() => (
                    <TableBody>
                      <AutoObserverList
                        list={this.filteredCarriers}
                        getItemKey={carrier => carrier.id}
                        render={carrier => {
                          return <TableRow>
                            <TableCell align="left" padding="none" className="textSelectable">
                              {carrier.id}
                            </TableCell>
                            <TableCell align="left" padding="none" className="textSelectable">
                              {carrier.profile.data.displayName || "—"}
                            </TableCell>
                            <TableCell align="left" padding="none" className="textSelectable">
                              {carrier.profile.data.tradeName || "—"}
                            </TableCell>
                            <TableCell align="left" padding="none" className="textSelectable">
                              {carrier.profile.data.abbreviation || "—"}
                            </TableCell>
                            <TableCell align="left" padding="none" className="textSelectable">
                              {carrier.profile.data.country || "—"}
                            </TableCell>
                            <TableCell align="left" padding="none" className="textSelectable">
                              {getCarrierTypePlaceholder(carrier) || "—"}
                            </TableCell>
                            <TableCell align="left" padding="none" className="textSelectable">
                              {getCarrierModes(modes, carrier) || "—"}
                            </TableCell>
                            <TableCell align="left" padding="none" className="checkbox ion-text-center">
                              <MdIcon
                                icon={carrier.profile.data.isPublic ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
                                style={{ verticalAlign: "middle" }}
                              />
                            </TableCell>
                            <TableCell align="left" padding="none" className="checkbox ion-text-center">
                              <MdIcon
                                icon={carrier.profile.data.isPublicApproved ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
                                style={{ verticalAlign: "middle" }}
                              />
                            </TableCell>
                            <TableCell align="center" padding="none" className="checkbox">
                              <IonButtons className="flex ion-align-items-center ion-justify-content-center">
                                <IonButton color="primary" onClick={e => this.handleEdit(e, carrier.id)}>
                                  <IonIcon slot="icon-only" icon={pencilOutline}/>
                                </IonButton>
                              </IonButtons>
                            </TableCell>
                            <TableCell align="center" padding="none" className="checkbox">
                              <IonButtons className="flex ion-align-items-center ion-justify-content-center">
                                <IonButton color="danger" onClick={e => this.handleRemove(e, carrier)}>
                                  <IonIcon slot="icon-only" icon={closeOutline}/>
                                </IonButton>
                              </IonButtons>
                            </TableCell>
                          </TableRow>
                        }}
                      />
                    </TableBody>
                  )}</Observer>
                </Table>
              </TableContainer>
            </IonContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(SystemCarrierList);