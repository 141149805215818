import { observer } from "mobx-react";
import React, { MouseEvent } from "react";
import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonLoading, IonTitle, IonToolbar } from "@ionic/react";
import { Quotation, QuotationService } from "../../lib/types/dataTypes";
import { arrowBack } from "ionicons/icons";
import { UIText } from "../../../../client/lang";
import Refresher from "../../../../components/Refresher";
import { ui } from "../../../../client/ui";

export interface QuotationSummaryProps extends React.ComponentProps<typeof IonContent> {
  quotation: Quotation;
  quotationServices: QuotationService[];
  onQuotationSubmit?: (event: MouseEvent) => void;
  loading?: boolean;
  onRefresh?: (event: MouseEvent | CustomEvent) => void;
  onDismissModal: (event: MouseEvent) => void;
}

const QuotationSummary: React.FC<QuotationSummaryProps> = observer(
  ({
     quotation,
     quotationServices,
     onQuotationSubmit,
     loading,
     onRefresh,
     onDismissModal
   }) => <>
    <IonHeader translucent>
      <IonToolbar>
        <IonTitle>{UIText.quotationSummary}</IonTitle>
        <IonButtons slot="start">
          <IonButton onClick={onDismissModal}>
            <IonIcon slot="icon-only" icon={arrowBack} />
          </IonButton>
        </IonButtons>
        <IonButtons slot="end">
          <IonButton color="primary" onClick={onQuotationSubmit} className="textBold">
            {UIText.generalSave}
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen className="ion-padding">
      <Refresher onRefresh={onRefresh} isMobile={ui.isMobile} />
      <IonLoading translucent isOpen={loading}/>
      <div className="flex max ion-align-items-center ion-justify-content-center ion-text-center">
        <IonCard className="max" style={{ flex: 1 }}>
          <IonCardContent className="max formView fancyScroll">

          </IonCardContent>
        </IonCard>
      </div>
    </IonContent>
  </>
);

export default QuotationSummary;